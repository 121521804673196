import type { Entity } from '@trustyou/shared';
import { EntityInfoBanner, Stack } from '@trustyou/ui';

import { CustomAttributesValuesView } from '../../../../components';

type Props = {
  entity: Entity;
};

export const OverviewTab = ({ entity }: Props) => (
  <Stack gap={3}>
    <EntityInfoBanner entity={entity} />
    <CustomAttributesValuesView entity={entity} />
  </Stack>
);
