import { useIntl } from 'react-intl';

import { faBell } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  openNotifications as openUserlaneNotifications,
  useMembershipStore,
} from '@trustyou/shared';
import { IconButtonWithTooltip } from '@trustyou/ui';

export function NotificationsMenu() {
  const intl = useIntl();
  const { getMembershipDetails } = useMembershipStore();
  const membershipDetails = getMembershipDetails();

  if (!membershipDetails) {
    return null;
  }

  return (
    <IconButtonWithTooltip
      icon={faBell}
      tooltip={intl.formatMessage({
        id: 'topbar.notifications',
        defaultMessage: 'Notifications',
      })}
      IconProps={{ size: 'sm' }}
      data-testid="notifications-nav-icon"
      data-gtm-id="notifications-nav-icon"
      id="notifications-button"
      onClick={openUserlaneNotifications}
    />
  );
}
