import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { changeUserlaneLanguage, getInitialLocale, isRTL } from '../utils';

type LanguageState = {
  locale: string;
  setLocale: (locale: string) => void;
};

export const useLanguageStore = create<LanguageState>()(
  persist(
    (set, get) => ({
      locale: getInitialLocale(),
      setLocale: (locale) => {
        document.documentElement.lang = locale.substring(0, 2);
        document.documentElement.dir = isRTL(locale) ? 'rtl' : 'ltr';
        changeUserlaneLanguage(locale);
        return set({ locale });
      },
    }),
    {
      name: 'language-storage',
    }
  )
);
