import type { IStyles } from '@trustyou/shared';
import { CONTENT_MAX_WIDTH } from '@trustyou/ui';

const styles: IStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    maxWidth: CONTENT_MAX_WIDTH,
    marginX: 'auto',
    height: '100%',
    '& > h6': {
      mt: 5,
    },
    '& > div': {
      minHeight: '356px',
      height: 'calc(100% - 120px)',
    },
  },
};

export default styles;
