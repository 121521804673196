import { useState } from 'react';
import { type IntlShape, useIntl } from 'react-intl';

import { lightBlue } from '@mui/material/colors';
import type { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { faEdit, faLayerPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  DEFAULT_PAGE_SIZE,
  QUESTION_UPDATE_PERMISSION,
  capitalizeFirstLetter,
  getPaginationRequest,
  toSentence,
  useDataGridIntl,
  useLanguageStore,
  usePermissionStore,
} from '@trustyou/shared';
import {
  Avatar,
  Box,
  Chip,
  DataGridPro,
  IconButton,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import type { ListQuestionsWithCountOut, Question_Output } from '../../../client';
import { QUESTION_TYPE_TO_ICON } from '../../../components/question-library/constants/questions';
import { CustomToolbar } from '../../../components/shared/custom-toolbar';
import styles from '../../../components/shared/styles';
import { useQuestions } from '../../../service/hooks/use-questions';

function useQuestionLibraryColumns({
  intl,
  locale,
  hasQuestionUpdatePermission,
}: {
  intl: IntlShape;
  locale: string;
  hasQuestionUpdatePermission: boolean;
}) {
  const columns: GridColDef<Question_Output>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'internal_name',
      headerName: intl.formatMessage({
        id: 'survey.question-library.internal_name',
        defaultMessage: 'Internal name',
      }),
      flex: 1,
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => {
        const internalName =
          value.internal_name?.[locale.split('-')[0]] ??
          value.internal_name?.[value.default_language];

        const QuestionType =
          QUESTION_TYPE_TO_ICON[
            ['nps', 'number'].includes(value.primitive.type)
              ? (value.primitive.type as keyof typeof QUESTION_TYPE_TO_ICON)
              : (value.primitive.repr as keyof typeof QUESTION_TYPE_TO_ICON)
          ];

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              sx={{
                backgroundColor: QuestionType.color,
                height: (theme) => theme.spacing(3),
                width: (theme) => theme.spacing(3),
              }}
              variant="rounded"
            >
              <StyledFontAwesomeIcon icon={QuestionType.icon} size="xs" />
            </Avatar>
            <Typography variant="subtitle2">{capitalizeFirstLetter(internalName ?? '')}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'type',
      headerName: intl.formatMessage({
        id: 'survey.question-library.type',
        defaultMessage: 'Type',
      }),
      width: 100,
      valueGetter: ({ row }) => row.primitive.type,
      renderCell: ({ value }) => toSentence(value),
    },
    {
      field: 'handling_type',
      headerName: intl.formatMessage({
        id: 'survey.question-library.response-handling-group',
        defaultMessage: 'Response handling group',
      }),
      flex: 1,
      renderCell: ({ value }) => value.replace(/_/g, ' '),
    },
    {
      field: 'category',
      headerName: intl.formatMessage({
        id: 'survey.question-library.category',
        defaultMessage: 'Category',
      }),
      flex: 1,
      valueGetter: ({ row }) => row.handling_type,
      renderCell: ({ value }) => (
        <Box>
          {value === 'custom' ? (
            <Chip
              label={intl
                .formatMessage({
                  id: 'survey.common.custom',
                  defaultMessage: 'Custom',
                })
                .toUpperCase()}
              sx={{ color: 'info.main', backgroundColor: lightBlue[50] }}
            />
          ) : (
            <Chip
              label={intl
                .formatMessage({
                  id: 'survey.common.basic',
                  defaultMessage: 'Basic',
                })
                .toUpperCase()}
            />
          )}
        </Box>
      ),
    },
    {
      field: 'translations',
      headerName: intl.formatMessage({
        id: 'survey.question-library.translations',
        defaultMessage: 'Translations',
      }),
      flex: 1,
      valueGetter: ({ row }) => Object.keys(row.title),
      renderCell: ({ value }) => value.length,
    },
    ...(hasQuestionUpdatePermission
      ? [
          {
            field: 'edit',
            headerName: intl.formatMessage({
              id: 'survey.question-library.edit',
              defaultMessage: 'Edit',
            }),
            width: 50,
            renderCell: ({ row }: { row: { id: string } }) => (
              <IconButton
                onClick={() => alert(`to be implemented: edit question "${row.id}"`)}
                size="small"
                sx={{ aspectRatio: '1 / 1' }}
                disabled
              >
                <StyledFontAwesomeIcon icon={faEdit} fixedWidth />
              </IconButton>
            ),
          },
        ]
      : []),
    {
      field: 'duplicate',
      headerName: intl.formatMessage({
        id: 'survey.question-library.duplicate',
        defaultMessage: 'Duplicate',
      }),
      width: 100,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => alert(`to be implemented: duplicate question "${row.id}"`)}
          size="small"
          sx={{ aspectRatio: '1 / 1' }}
          disabled
        >
          <StyledFontAwesomeIcon icon={faLayerPlus} fixedWidth />
        </IconButton>
      ),
    },
  ];

  return columns;
}

export const QuestionLibraryList = () => {
  const { locale } = useLanguageStore();
  const { hasPermissions } = usePermissionStore();
  const hasQuestionUpdatePermission = hasPermissions(QUESTION_UPDATE_PERMISSION);
  const intl = useIntl();
  const dataGridIntl = useDataGridIntl();
  const columns = useQuestionLibraryColumns({ intl, locale, hasQuestionUpdatePermission });

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const { data, isLoading, isRefetching } = useQuestions(getPaginationRequest(paginationModel));
  const { questions, total } = (data as ListQuestionsWithCountOut) ?? [];

  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h6" component="h2">
          {intl.formatMessage({
            id: 'survey.question-library.title',
            defaultMessage: 'Question library',
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: 'survey.question-library.subtitle',
            defaultMessage: 'Create and manage your custom questions here.',
          })}
        </Typography>
      </Box>
      <DataGridPro
        columns={columns}
        rows={questions || []}
        rowCount={total ?? 0}
        loading={isLoading || isRefetching}
        pagination
        paginationMode="server"
        pageSizeOptions={[15, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        // filterMode="server"
        // onFilterModelChange={updateFilter}
        disableRowSelectionOnClick
        ignoreDiacritics
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        sx={styles.disableCellOutline}
        localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
        density="standard"
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              duplicate: false,
            },
          },
        }}
      />
    </Stack>
  );
};
