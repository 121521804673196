import { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { faGripDotsVertical } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { CustomAttributeType } from '@trustyou/shared';
import {
  Chip,
  FormHelperText,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

import { ActionButton } from './ActionButton';
import { AddButton } from './AddButton';

import { manageDrawer } from '../../../constants/messages/customAttributes';
import { styles } from './styles';

export type Option = {
  name: string;
  id?: string;
  _id?: string;
  isDefault?: boolean;
};

const OPTION_MAX_LENGTH = 100;
const MAX_OPTION_COUNT = 100;

export const OptionsController = () => {
  const intl = useIntl();
  const { control, getValues, trigger } = useFormContext();
  const {
    fields: options,
    append,
    remove,
    update,
    move,
  } = useFieldArray({
    control,
    name: 'options',
    keyName: '_id',
  });

  const newOptionRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (newOptionRef.current) {
      newOptionRef.current.focus();
    }
  }, [options.length]);

  const toggleDefault = (index: number, option: Option) => {
    if (getValues('type') === CustomAttributeType.SELECT && !option.isDefault) {
      options.forEach((row, rowIndex) => {
        if (index !== rowIndex) {
          update(rowIndex, {
            ...row,
            isDefault: false,
          });
        }
      });
    }
    update(index, {
      ...option,
      isDefault: !option.isDefault,
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    move(source.index, destination.index);
  };

  return (
    <Stack gap={2}>
      <Stack sx={styles.header}>
        <Typography variant="subtitle1">
          {intl.formatMessage(manageDrawer.optionSelectorHeader)}
        </Typography>
        <Typography variant="body2" color="error.main">
          *
        </Typography>
      </Stack>
      {!!options.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-attribute-options">
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef} gap={3.5}>
                {(options as Option[]).map((option, index) => (
                  <Draggable
                    key={option.id as string}
                    draggableId={option.id as string}
                    index={index}
                  >
                    {(provided) => (
                      <Stack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={option.id}
                        sx={styles.option}
                      >
                        <IconButton size="medium">
                          <Icon icon={faGripDotsVertical} />
                        </IconButton>
                        <Controller
                          name={`options.${index}.name`}
                          control={control}
                          rules={{
                            required: intl.formatMessage(manageDrawer.optionSelectorOptionRequired),
                            validate: (value) =>
                              (options as Option[])
                                .map((row) => (row.id !== option.id ? row.name.trim() : null))
                                .includes(value)
                                ? intl.formatMessage(manageDrawer.optionSelectorOptionExist)
                                : true,
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              fullWidth
                              onBlur={(e) => {
                                update(index, {
                                  ...option,
                                  name: e.target.value,
                                });
                                setTimeout(() => {
                                  trigger(options.map((_, idx) => `options.${idx}.name`));
                                }, 10);
                              }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputProps={{
                                endAdornment: option.isDefault && (
                                  <Chip
                                    label={intl.formatMessage(manageDrawer.optionSelectorDefault)}
                                    size="small"
                                    variant="pastelInfo"
                                  />
                                ),
                              }}
                              inputProps={{
                                maxLength: OPTION_MAX_LENGTH,
                                ref:
                                  options.length - 1 === index && option.id?.startsWith('NEW_')
                                    ? newOptionRef
                                    : null,
                              }}
                            />
                          )}
                        />
                        <ActionButton
                          isDefault={option.isDefault}
                          onRemove={() => {
                            remove(index);
                          }}
                          onToggleDefault={() => {
                            toggleDefault(index, option);
                          }}
                        />
                        {control._defaultValues.type && option.id?.startsWith('NEW_') && (
                          <FormHelperText sx={styles.newText}>
                            {intl.formatMessage(manageDrawer.optionSelectorNew)}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <AddButton
        isDisabled={options.length >= MAX_OPTION_COUNT}
        onClick={() => append({ name: '', id: `NEW_${Date.now()}` })}
      />
    </Stack>
  );
};
