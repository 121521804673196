import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import { faFlag, faThumbsDown, faThumbsUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Alert, Button, StyledFontAwesomeIcon, snackbar } from '@trustyou/ui';

import { SurveyModerationDetailsDialog } from './details-dialog';
import { alertMessages, snackbarMessages } from './messages';
import { SurveyModerationRejectionDialog } from './rejection-dialog';

import { ALERT_INFO_TITLE_COLOR } from '../../constants';
import { useApproveModeration, useRejectModeration, useReview } from '../../hooks';
import useModerationRevisionPermission from '../../hooks/permissions/use-moderation-revision-permission';

export function MarkedAsInappropriateReviewAlert() {
  const intl = useIntl();
  const { accessTokenPayload } = useOidcAccessToken();

  const approveModeration = useApproveModeration();
  const rejectModeration = useRejectModeration();
  const hasModerationPermission = useModerationRevisionPermission();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });

  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const openDetailsDialog = () => setIsDetailsDialogOpen(true);
  const closeDetailsDialog = () => setIsDetailsDialogOpen(false);

  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState(false);
  const openRejectionDialog = () => setIsRejectionDialogOpen(true);
  const closeRejectionDialog = () => setIsRejectionDialogOpen(false);

  const handleRejectModeration = (notes: string) => {
    rejectModeration.mutate(
      {
        review_id: reviewRoot?.review.id ?? '',
        notes: notes,
        username: accessTokenPayload.name,
      },
      {
        onSuccess: () => {
          snackbar.success(intl.formatMessage(snackbarMessages.rejected));
        },
      }
    );
  };

  const handleApproveModeration = () => {
    approveModeration.mutate(
      {
        review_id: reviewRoot?.review.id ?? '',
        username: accessTokenPayload.name,
      },
      {
        onSuccess: () => {
          snackbar.success(intl.formatMessage(snackbarMessages.approved));
        },
      }
    );
  };

  return (
    <>
      <Alert
        severity="info"
        icon={<StyledFontAwesomeIcon icon={faFlag} size="xs" color={ALERT_INFO_TITLE_COLOR} />}
        sx={{
          borderRadius: 0,
          padding: 2,
          '& .MuiAlert-message': { padding: 0 },
          '& .MuiAlert-icon': { paddingBlockStart: 0.5 },
        }}
      >
        {intl.formatMessage(alertMessages.markedInappropriate)}
        <Stack direction="row" sx={{ justifyContent: 'space-between', marginBlockStart: 1 }}>
          <Button onClick={openDetailsDialog} sx={{ paddingInline: 0, minWidth: 0 }}>
            {intl.formatMessage(alertMessages.details)}
          </Button>
          {hasModerationPermission && (
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<StyledFontAwesomeIcon icon={faThumbsDown} />}
                onClick={openRejectionDialog}
              >
                {intl.formatMessage(alertMessages.reject)}
              </Button>
              <LoadingButton
                variant="contained"
                startIcon={<StyledFontAwesomeIcon icon={faThumbsUp} />}
                loading={approveModeration.isPending}
                loadingPosition="start"
                onClick={handleApproveModeration}
              >
                {intl.formatMessage(alertMessages.approve)}
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Alert>
      <SurveyModerationRejectionDialog
        isOpen={isRejectionDialogOpen}
        onClose={closeRejectionDialog}
        onConfirm={handleRejectModeration}
      />
      <SurveyModerationDetailsDialog isOpen={isDetailsDialogOpen} onClose={closeDetailsDialog} />
    </>
  );
}
