import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  useCompetitorStore,
  useEntity,
  useMembershipStore,
  useSetEntityCompetitors,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  ContentPane,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { CompetitorSelection } from '../CompetitorSelection';
import styles from './styles';

type AddCompetitorsProps = {
  entityId?: string;
  onClose?: () => void;
};

export const AddCompetitors = ({ entityId, onClose }: AddCompetitorsProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { selectedCompetitors, setSelectedCompetitors } = useCompetitorStore();
  const { membership } = useMembershipStore();
  const { entityId: routeEntityId } = useParams();
  const activeEntityId = entityId || routeEntityId;
  const { data: entity } = useEntity(activeEntityId ?? '');

  const closeHandler = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  };

  const setEntityCompetitors = useSetEntityCompetitors();

  const onSave = () => {
    setEntityCompetitors.mutate(
      {
        organization_id: membership?.organizationId as string,
        entity_id: activeEntityId as string,
        competitors: selectedCompetitors.map((row) => row.id),
      },
      {
        onSuccess: () => {
          snackbar.success(
            intl.formatMessage({
              id: 'organization.competitors.added',
              defaultMessage: 'Competitors added',
            })
          );
        },
      }
    );
  };

  useEffect(() => {
    if (entity?.competitors) {
      setSelectedCompetitors(entity?.competitors);
    }
  }, [entity, setSelectedCompetitors]);

  if (entityId) {
    return (
      <Dialog open onClose={closeHandler} sx={styles.dialog}>
        <DialogTitle variant="h6">
          <Typography sx={styles.dialogHeader} variant="h6">
            <FormattedMessage id="organization.competitors.add" defaultMessage="Add competitors" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={styles.body}>{entity && <CompetitorSelection entity={entity} />}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="cancel-add-competitors"
            onClick={closeHandler}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="organization.action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button data-testid="accept-add-competitors" variant="contained" onClick={onSave}>
            <FormattedMessage id="organization.action.save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <ContentPane sx={styles.wrapper}>
      <Box sx={styles.header}>
        <IconButton data-testid="back-button" onClick={closeHandler}>
          <Icon icon={faArrowLeft} />
        </IconButton>
        <Typography sx={styles.headerText} variant="h6">
          <FormattedMessage id="organization.competitors.add" defaultMessage="Add competitors" />
        </Typography>
      </Box>
      <Box sx={styles.body}>{entity && <CompetitorSelection entity={entity} />}</Box>
      <Box sx={styles.footer}>
        <Button
          data-testid="cancel-add-competitors"
          onClick={closeHandler}
          variant="outlined"
          color="primary"
        >
          <FormattedMessage id="organization.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button data-testid="accept-add-competitors" variant="contained" onClick={onSave}>
          <FormattedMessage id="organization.action.save" defaultMessage="Save" />
        </Button>
      </Box>
      <BackdropSpinner isLoading={setEntityCompetitors.isPending} />
    </ContentPane>
  );
};
