import {
  CREATE_ORGANIZATION_BENCHMARKS_PERMISSION,
  CREATE_USER_BENCHMARKS_PERMISSION,
} from '../constants';
import { usePermissionStore } from '../store';
import { isTemporaryHidden } from '../utils';

export const useManageBenchmarksPermission = () => {
  const { hasPermissions } = usePermissionStore();
  if (isTemporaryHidden('CXP-customers-manage-benchmarks')) return false;
  if (hasPermissions(CREATE_ORGANIZATION_BENCHMARKS_PERMISSION)) return 'organization';
  if (hasPermissions(CREATE_USER_BENCHMARKS_PERMISSION)) return 'user';
  return false;
};
