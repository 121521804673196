import { useIntl } from 'react-intl';

import { faGear } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { EditorSettingsContent } from './content/settings';

import type { Step } from '../../shared/editor-side-nav';

export function useSurveyEditorSteps() {
  const intl = useIntl();

  const steps: Step[] = [
    {
      icon: faGear,
      label: intl.formatMessage({ id: 'survey.common.settings', defaultMessage: 'Settings' }),
      content: <EditorSettingsContent />,
    },
  ];

  return steps;
}
