import { type PropsWithChildren, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { commonFields } from '@trustyou/shared';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  type FileProp,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  Typography,
} from '@trustyou/ui';

import CropTool from './CropTool/CropTool';
import Dropzone, { type DropzoneProps } from './Dropzone';

type ImageUploadDialogProps = DropzoneProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function ImageUploadDialog({ file, setFile, open, setOpen }: ImageUploadDialogProps) {
  const canvasRef = useRef(null);
  const [variant, setVariant] = useState('Banner');
  const [error, setError] = useState(false);

  const handleDone = () => {
    // @ts-expect-error Property 'toBlob' does not exist on type 'never'.ts(2339)
    canvasRef.current?.toBlob((blob) => {
      const { name, type } = file as File;
      const croppedImageFile = new File([blob], name, { type });
      const newImage = Object.assign(croppedImageFile, {
        preview: URL.createObjectURL(croppedImageFile),
        path: name,
      }) as FileProp;
      setFile(newImage);
    }, file?.type);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <BootstrapDialogTitle onClose={handleClose}>
          <FormattedMessage {...commonFields.uploadImage} />
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent sx={{ paddingBottom: 1 }}>
          {!file && <Dropzone file={file} setFile={setFile} />}
          {file && (
            <CropTool variant={variant} file={file} canvasRef={canvasRef} setFile={setFile} />
          )}
          <Stack flexDirection="row" sx={{ marginTop: 2 }}>
            <Button
              variant={variant === 'Banner' ? 'contained' : 'outlined'}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => setVariant('Banner')}
            >
              <FormattedMessage id="common.banner" defaultMessage="Banner" />
            </Button>
            <Button
              variant={variant === 'Logo' ? 'contained' : 'outlined'}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => setVariant('Logo')}
            >
              <FormattedMessage id="common.logo" defaultMessage="Logo" />
            </Button>
          </Stack>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {variant === 'Banner' ? (
              <FormattedMessage
                id="image-upload.banner.description"
                defaultMessage="Recommended size: min. 640 x 318px."
              />
            ) : (
              <FormattedMessage
                id="image-upload.logo.description"
                defaultMessage="Recommended size: min. 200 x 200px."
              />
            )}
          </Typography>
        </DialogContent>
        {error && (
          <Alert severity="error" onClose={() => setError(!error)}>
            <AlertTitle>
              <FormattedMessage
                id="image-upload.error.title"
                defaultMessage="Image upload failed"
              />
            </AlertTitle>
            <FormattedMessage
              id="image-upload.error.description"
              defaultMessage="Please try again"
            />
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Button>
          <Button variant={!file ? 'text' : 'contained'} disabled={!file} onClick={handleDone}>
            <FormattedMessage id="common.finish" defaultMessage="Finish" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function BootstrapDialogTitle({ onClose, children }: PropsWithChildren<{ onClose: () => void }>) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: 'text.secondary',
          }}
        >
          <Icon icon={faXmark} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
