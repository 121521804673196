import type { CSSProperties } from 'react';
import Highlighter from 'react-highlight-words';

import { amber } from '@mui/material/colors';
import { useInboxStore } from '@trustyou/shared';

import { removeDiacritics } from '../../utils/misc';

const highlightStyle: CSSProperties = {
  display: 'inline',
  WebkitBoxDecorationBreak: 'clone',
  boxDecorationBreak: 'clone',
  paddingInline: '4px',
  borderRadius: '4px',
  backgroundColor: amber.A100,
};

export function SearchTermsHighlighter({ value = '' }: { value: string }) {
  const currentView = useInboxStore((state) => state.currentView);

  const getTruncatedText = () => {
    // clear text of special characters include "... great, food ..." in matches for "great food"
    const cleanText = value.replace(/[^\w\s]/gi, '');
    const lowercaseKeywords = currentView.filters?.search_terms?.map((keyword) =>
      keyword?.toLowerCase().replace(/[^\w\s]/gi, '')
    );
    const keywordIndices = lowercaseKeywords
      ?.map((keyword) => cleanText?.toLowerCase().indexOf(keyword))
      .filter((index) => index !== -1);

    if (!keywordIndices || keywordIndices.length === 0) return value;

    const truncatedWords = cleanText
      .substring(Math.max(0, Math.min(...keywordIndices)), cleanText.length)
      .split(' ');
    const allWords = value.split(' ');

    // ensure that 5 words before the first matching keyword are also included
    const truncateAt = allWords.length - truncatedWords.length - 5;
    return truncateAt > 0 ? '... ' + allWords.slice(truncateAt).join(' ') : value;
  };

  return (
    <Highlighter
      searchWords={currentView.filters?.search_terms ?? []}
      textToHighlight={getTruncatedText()}
      highlightStyle={highlightStyle}
      autoEscape
      sanitize={removeDiacritics}
    />
  );
}
