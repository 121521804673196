import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { ThemeProvider } from '@emotion/react';
import { Stack, theme } from '@trustyou/ui';

import { generateCustomTheme } from '../../utils/survey-questionnaire-editor';

type EditorContentProps = {
  sideNavigator: ReactNode;
  content: ReactNode;
  contentExtraStyles?: Record<string, unknown>;
  preview?: ReactNode;
  previewExtraStyles?: Record<string, unknown>;
};

export const EditorContent = ({
  sideNavigator,
  content,
  contentExtraStyles,
  preview,
  previewExtraStyles,
}: EditorContentProps) => {
  const { watch } = useFormContext();
  const surveyTheme = watch('surveyQuestionnaireEditorTheme');
  const generatedTheme = surveyTheme ? generateCustomTheme(surveyTheme) : theme;
  return (
    <>
      <Stack
        sx={{
          gridColumn: '1',
          gridRow: '2 / 4',
          backgroundColor: 'primary.main',
        }}
      >
        {sideNavigator}
      </Stack>
      <Stack
        sx={{
          gridColumn: '2',
          gridRow: '2',
          padding: 3,
          overflowY: 'auto',
          ...contentExtraStyles,
        }}
      >
        {content}
      </Stack>
      <ThemeProvider theme={generatedTheme}>
        <Stack
          sx={{
            gridColumn: '3',
            gridRow: '2 / 4',
            backgroundColor: (theme) => theme.palette.background.default,
            ...previewExtraStyles,
          }}
        >
          {preview}
        </Stack>
      </ThemeProvider>
    </>
  );
};
