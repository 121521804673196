import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SURVEY_CREATE_PERMISSION, usePermissionStore } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

// eslint-disable-next-line @nx/enforce-module-boundaries
import personFillingAForm from '../../../../../../ui/src/lib/assets/illustrations/person-filling-a-form.svg';
import { useStore } from '../../store/store';

export function SurveyEmptyState() {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionStore();
  const hasSurveyCreatePermission = hasPermissions(SURVEY_CREATE_PERMISSION);
  const isSurveyEditorOpen = useStore.use.setIsSurveyEditorOpen();

  const navigateToSurveyEditor = () => {
    isSurveyEditorOpen(true);
    navigate('survey/editor?new');
  };

  return (
    <EmptyStatePlaceholder
      img={personFillingAForm}
      title={<FormattedMessage id="survey.empty-state.title" defaultMessage="No Surveys yet" />}
      description={
        <FormattedMessage
          id="survey.empty-state.description"
          defaultMessage="Click below to create your first one"
        />
      }
      emptyStateHeader={{ marginTop: 3 }}
      // TODO: change the implementation of EmptyStatePlaceholder into composable component to allow passing the whole action Button from outside
      action={
        hasSurveyCreatePermission
          ? {
              testId: 'survey-empty-state',
              startIcon: <FontAwesomeIcon icon={faPlus} />,
              message: (
                <FormattedMessage id="survey.common.new-survey" defaultMessage="New Survey" />
              ),
              handler: navigateToSurveyEditor,
              styles: { marginTop: 3 },
            }
          : undefined
      }
    />
  );
}
