import type { GridSortModel } from '@mui/x-data-grid-pro';
import {
  EXPORT_OWNED_ENTITIES,
  downloadCSV,
  getSortRequest,
  toShortDate,
  useExportEntities,
  usePermissionStore,
} from '@trustyou/shared';

export default function useExportEntitiesMutation(
  sortModel: GridSortModel,
  searchKey: string | undefined,
  groupId: string | undefined
) {
  const exportEntities = useExportEntities();
  const { hasPermissions } = usePermissionStore();
  const isExportAllowed = hasPermissions(EXPORT_OWNED_ENTITIES);

  if (!isExportAllowed) return undefined;

  const onExport = () => {
    exportEntities.mutate(
      { sortKey: getSortRequest(sortModel), searchKey, groupId },
      {
        onSuccess: (content: string) => {
          downloadCSV(`Entities_${toShortDate(new Date())}.csv`, content);
        },
      }
    );
  };

  return {
    export: onExport,
    isLoading: exportEntities.isPending,
  };
}
