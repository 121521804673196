import { type MouseEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { RatingProps } from '@mui/material';
import { Card, Menu, Rating, Stack, Typography } from '@trustyou/ui';

import { FeedbackFormStep } from './feedback-form-step';
import { ThankYouStep } from './thank-you-step';
import { useResponseAIRating } from './use-response-ai-rating';

import type { Score } from '../../../types';

export const ResponseAIRatingDesktop = () => {
  const {
    issueOptions,
    score,
    setScore,
    showThankYouStep,
    setShowThankYouStep,
    selectedIssues,
    isOtherSelected,
    feedbackText,
    setFeedbackText,
    toggleSelectedIssue,
    submit,
  } = useResponseAIRating();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange: RatingProps['onChange'] = (event, value) => {
    if (value === null) {
      return;
    }
    setScore(value as Score);
    if (value > 3) {
      return;
    }
    handleOpen(event as MouseEvent<HTMLElement>);
  };

  return (
    <>
      <Stack
        gap={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        paddingY={1}
        justifyContent="flex-end"
      >
        <Typography variant="caption" color="text.secondary">
          <FormattedMessage
            id="inbox.response.ai.rating.label"
            defaultMessage="Rate this response"
          />
          {': '}
        </Typography>
        <Rating
          id="response-ai-rating"
          size="small"
          value={score}
          onChange={handleChange}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        />
      </Stack>
      <Menu
        elevation={3}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'response-ai-rating',
        }}
        sx={{
          '.MuiMenu-list': {
            padding: 0,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card sx={{ width: (theme) => theme.spacing(60) }}>
          {showThankYouStep ? (
            <ThankYouStep onClose={handleClose} />
          ) : (
            <FeedbackFormStep
              issueOptions={issueOptions}
              selectedIssues={selectedIssues}
              isOtherSelected={isOtherSelected}
              feedbackText={feedbackText}
              setFeedbackText={setFeedbackText}
              onSkip={() => setShowThankYouStep(true)}
              toggleSelectedIssue={toggleSelectedIssue}
              onSend={submit}
            />
          )}
        </Card>
      </Menu>
    </>
  );
};
