import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingButton } from '@mui/lab';
import {
  type Entity,
  type Subscription,
  getAddEntitiesPayload,
  useAddEntities,
} from '@trustyou/shared';
import { Box, FullScreenModal, Typography, snackbar } from '@trustyou/ui';

import { AddEntitySelector } from './AddEntitySelector';

import { useDashboardDelayMessage } from '../../hooks';
import styles from './styles';

export interface AddEntityDialogProps {
  subscription?: Subscription;
  onClose: () => void;
}

export const AddEntityDialog = ({ subscription, onClose }: AddEntityDialogProps) => {
  const intl = useIntl();
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);

  const dashboardDelayMessage = useDashboardDelayMessage();

  const addEntities = useAddEntities({
    shouldRefetchSubscriptionEntities: !subscription?.is_org_subscription,
  });

  const handleAddEntities = () => {
    const payload = getAddEntitiesPayload(selectedEntities, subscription?.id);
    addEntities.mutate(payload, {
      onSuccess: (data, variables) => {
        if (variables.entities.length === 1) {
          snackbar.success(
            intl.formatMessage(
              {
                id: 'add-entity.success.single-add',
                defaultMessage: '{entity} added. {delay}',
              },
              { entity: selectedEntities[0].name, delay: dashboardDelayMessage }
            )
          );
        } else {
          snackbar.success(
            intl.formatMessage(
              {
                id: 'add-entity.success.multi-add',
                defaultMessage: '{entities} entities added. {delay}',
              },
              { entities: selectedEntities.length, delay: dashboardDelayMessage }
            )
          );
        }
        setSelectedEntities([]);
        onClose();
      },
    });
  };

  const renderActionButton = () => (
    <LoadingButton
      data-testid="add-entity-submit"
      variant="contained"
      loading={addEntities.isPending}
      loadingPosition="start"
      onClick={handleAddEntities}
    >
      <FormattedMessage id="add-entity.submit-button" defaultMessage="Add entity" />
    </LoadingButton>
  );

  const getEntitiesRemaining = () => {
    if (!subscription) return 0;
    return (
      subscription.number_of_allowed_entities -
      subscription.number_of_consumed_entities -
      selectedEntities.length
    );
  };

  const isSingleEntitySubscription = subscription?.number_of_allowed_entities === 1;

  return (
    <FullScreenModal open={!!subscription} onClose={onClose} footer={renderActionButton()}>
      <Box sx={styles.wrapper}>
        <Typography variant="h6">
          <FormattedMessage id="add-entity.title" defaultMessage="Add entity by search" />
        </Typography>
        <Typography variant="body2">
          {isSingleEntitySubscription ? (
            <FormattedMessage
              id="add-entity.description-single"
              defaultMessage="{count} remaining"
              values={{ count: getEntitiesRemaining() }}
            />
          ) : (
            <FormattedMessage
              id="add-entity.description"
              defaultMessage="{count} remaining. Existing entities in your organization are not included below."
              values={{ count: getEntitiesRemaining() }}
            />
          )}
        </Typography>
        <AddEntitySelector
          selectedEntities={selectedEntities}
          setSelectedEntities={setSelectedEntities}
          maxEntitiesAllowed={subscription?.number_of_allowed_entities || 0}
          areItemsDisabled={getEntitiesRemaining() <= 0}
        />
      </Box>
    </FullScreenModal>
  );
};
