import { type FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { type IntlShape, type WrappedComponentProps, injectIntl } from 'react-intl';

import {
  type SelectChangeEvent,
  type SxProps,
  type Theme,
  Typography,
  capitalize,
} from '@mui/material';
import type { GroupAttributeValue } from '@trustyou/shared';

import styles from './MultipleSelectWithCheckboxes.styles';

import {
  Box,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '../../mui';

const getValueFromMsgs = (value: string) => ({
  id: `MultipleSelect-${value}`,
  defaultMessage: value,
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '300px',
      zIndex: 1,
    },
  },
};

export interface MultipleSelectWithCheckboxesProps {
  names: GroupAttributeValue[];
  nameLabels?: string[];
  all: GroupAttributeValue;
  label?: string;
  fieldName: string;
  inputRef?: React.Ref<unknown> | undefined;
  defaultValue?: string[];
  variant?: 'standard' | 'outlined';
  sx?: SxProps<Theme>;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (values: string[]) => void;
}

const mapTheKeys = (keys: GroupAttributeValue[]) => {
  return keys.map((item) => item.label);
};

const isEqual = (a: string[], b: string[]) =>
  JSON.stringify([...a].sort()) === JSON.stringify([...b].sort());

export const renderSelectedValues = ({
  values,
  all,
  names,
  formatListItem,
  intl,
}: {
  values: string[];
  all: GroupAttributeValue;
  names: GroupAttributeValue[];
  formatListItem?: (item: string) => string;
  intl: IntlShape;
}) => {
  if (values.includes(all.label)) return [intl.formatMessage(getValueFromMsgs(all.label))];
  if (isEqual(values, mapTheKeys(names))) return [intl.formatMessage(getValueFromMsgs(all.label))];
  return values
    .map((item) => names.find((el) => el.key === item)?.label || item)
    .map((name) => {
      const formattedText = formatListItem ? formatListItem(name) : name;

      const translatedName = getValueFromMsgs(formattedText)
        ? intl.formatMessage(getValueFromMsgs(formattedText))
        : formattedText;
      return translatedName;
    })
    .join(', ');
};

const MultipleSelectWithCheckboxesWithIntl: FC<
  MultipleSelectWithCheckboxesProps & WrappedComponentProps
> = ({
  names,
  all,
  label,
  fieldName,
  nameLabels,
  defaultValue = [],
  intl,
  variant,
  sx,
  placeholder,
  disabled,
  onChange,
}) => {
  const { register } = useFormContext();

  const [chosenNames, setChosenNames] = useState<string[]>(defaultValue);
  const isAllOptionSelected = chosenNames.length === 1 && chosenNames.includes('all');
  const allNamesAlreadySelected = isEqual(chosenNames, mapTheKeys(names)) || isAllOptionSelected;

  const handleChange = (event: SelectChangeEvent<typeof chosenNames>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      setChosenNames(value.split(','));
      return;
    }
    const isAllMenuItemClicked = value.includes(all.label);
    if (!isAllMenuItemClicked) {
      setChosenNames(value);
      return;
    }
    if (allNamesAlreadySelected) {
      setChosenNames([]);
      return;
    }

    setChosenNames(mapTheKeys(names));
  };

  useEffect(() => {
    // Check for the source select component
    if (defaultValue.length === 1 && defaultValue[0] === 'all') {
      setChosenNames(mapTheKeys(names));
    } else {
      setChosenNames(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange && onChange(chosenNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenNames]);

  function translatedText(intl: IntlShape, index: number, name: string, nameLabels?: string[]) {
    if (fieldName === 'score' && nameLabels) {
      const firstHalf = nameLabels[index].split(' ')[0];
      const secondHalf = nameLabels[index].split(' ')[1];
      const translatedFirstHalf = intl.formatMessage(getValueFromMsgs(firstHalf));
      return `${translatedFirstHalf} ${secondHalf}`;
    }

    if (nameLabels) {
      return getValueFromMsgs(nameLabels[index])
        ? intl.formatMessage(getValueFromMsgs(nameLabels[index]))
        : nameLabels[index];
    }

    return getValueFromMsgs(name) ? intl.formatMessage(getValueFromMsgs(name)) : name;
  }

  return (
    <Box sx={sx}>
      <FormControl fullWidth>
        {label && <InputLabel id={`multiple-select-label-${fieldName}`}>{label}</InputLabel>}
        <Select
          data-testid={`multiple-select-${fieldName}`}
          labelId={`multiple-select-label-${fieldName}`}
          id={`multiple-select-${fieldName}`}
          name={fieldName}
          multiple
          value={chosenNames}
          onChange={handleChange}
          disabled={disabled}
          input={
            variant === 'standard' ? (
              <Input inputProps={{ ...register(fieldName) }} />
            ) : (
              <OutlinedInput label={label} inputProps={{ ...register(fieldName) }} />
            )
          }
          displayEmpty={!!placeholder}
          renderValue={(selected) => {
            const renderValue =
              fieldName === 'filters.source'
                ? renderSelectedValues({ values: selected, all, names, intl })
                : renderSelectedValues({
                    values: selected,
                    all,
                    names,
                    intl,
                    formatListItem: capitalize,
                  });
            return !renderValue || (disabled && !selected.length) ? (
              <Typography color="gray">{placeholder}</Typography>
            ) : (
              renderValue
            );
          }}
          MenuProps={MenuProps}
        >
          <MenuItem
            data-testid={`menuitem-${all.label}`}
            disableGTMTracking
            value={all.label}
            style={styles.checkedAll}
          >
            <Checkbox checked={allNamesAlreadySelected} />
            <ListItemText primary={intl.formatMessage(getValueFromMsgs(all.label))} />
          </MenuItem>
          {names.map((name, index) => (
            <MenuItem
              disableGTMTracking
              data-testid={`menuitem-${name.label}`}
              key={name.key}
              value={name.key}
            >
              <Checkbox checked={chosenNames.includes(name.key)} />
              <ListItemText primary={translatedText(intl, index, name.label, nameLabels)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const MultipleSelectWithCheckboxes = injectIntl(MultipleSelectWithCheckboxesWithIntl);
