import { FormattedMessage } from 'react-intl';

import {
  USER_ROLES,
  roleDefinitions,
  useChangelingStore,
  useCustomerLogout,
  useMembershipStore,
} from '@trustyou/shared';
import { APP_BAR_HEIGHT, Alert, Box, Button, Typography } from '@trustyou/ui';

export function ChangelingAlert() {
  const { isChangeling, userInfo } = useChangelingStore();
  const { getMembershipDetails } = useMembershipStore();
  const customerLogout = useCustomerLogout();

  const displayUser = userInfo?.isOwner ? <OwnerName /> : userInfo?.name || userInfo?.email;
  const organizationName = getMembershipDetails()?.organization?.name;

  if (!isChangeling) {
    return null;
  }

  return (
    <Alert
      severity="info"
      sx={{
        position: 'fixed',
        top: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: APP_BAR_HEIGHT,
        zIndex: 10000,
        '& > .MuiAlert-message': {
          display: 'flex',
          flex: 1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Typography variant="body2">
          <FormattedMessage
            id="changeling.msgChangelingDetails"
            defaultMessage="Impersonating {displayUser} of {organizationName}"
            values={{ displayUser, organizationName }}
          />
        </Typography>
        <Button variant="outlined" data-testid="changeling-test" onClick={customerLogout}>
          <FormattedMessage
            id="changeling.msgStopImpersonation"
            defaultMessage="Stop Impersonation"
          />
        </Button>
      </Box>
    </Alert>
  );
}

function OwnerName() {
  return (
    <Typography component="span" variant="body2" sx={{ textTransform: 'lowercase' }}>
      <FormattedMessage
        id={roleDefinitions[USER_ROLES.OWNER].id}
        defaultMessage={roleDefinitions[USER_ROLES.OWNER].defaultMessage}
      />
    </Typography>
  );
}
