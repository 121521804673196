import { FormattedMessage } from 'react-intl';

import { capitalize } from '@mui/material';
import { faBuildings } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type AccessGroup,
  USER_SCOPES,
  type UserScopeDetails,
  type UserScopeGroup,
  commonFields,
  detectUserScope,
  usersMessages,
} from '@trustyou/shared';
import { Box, StyledFontAwesomeIcon as Icon, Stack, Typography } from '@trustyou/ui';

import styles from './styles';

export interface ScopeDetailsProps {
  scope: UserScopeDetails | AccessGroup;
}

type GroupsSegmentsMap = {
  [key: string]: UserScopeGroup[];
};

export const ScopeDetails = ({ scope }: ScopeDetailsProps) => {
  const userScope = detectUserScope(scope);

  const groups = scope.groups.reduce((prev, cur) => {
    const key = capitalize(cur.segment.name);
    return {
      ...prev,
      [key]: [...(prev[key] || []), cur],
    };
  }, {} as GroupsSegmentsMap);

  return (
    <>
      <Box sx={styles.scopeDetailsHeader}>
        <Icon icon={faBuildings} />
        <Typography variant="body2">
          <FormattedMessage id="user.details.access" defaultMessage="Access" />
        </Typography>
      </Box>

      <Box sx={styles.scopeDetailsBody}>
        {userScope === USER_SCOPES.ALL_ENTITIES && (
          <Box sx={styles.userScopeCell}>
            <Typography variant="body2">
              <FormattedMessage id="scope.all-entities" defaultMessage="All entities" />
            </Typography>
            <Typography variant="caption">
              {scope.subscription_id ? (
                <FormattedMessage id="scope.in-subscription" defaultMessage="In subscription" />
              ) : (
                <FormattedMessage id="scope.in-organization" defaultMessage="In organization" />
              )}
            </Typography>
          </Box>
        )}
        {userScope === USER_SCOPES.BY_ENTITIES && (
          <Stack direction="column" spacing={2}>
            <Typography variant="body2">
              <FormattedMessage
                id="tyDesign.user.accessSelectedEntities"
                defaultMessage="can access selected entities ({count})"
                values={{ count: scope.entities.length }}
              />
            </Typography>
            {scope.entities.map((entity) => (
              <Stack direction="column" key={entity.id}>
                <Typography color="text.hint" variant="body2">
                  {entity.city}, {entity.country_name}
                </Typography>
                <Typography variant="body2">{entity.name}</Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {userScope === USER_SCOPES.ACCESS_GROUP && (
          <Stack direction="column" spacing={2}>
            <Typography variant="body2">
              {'name' in scope && `${scope.name} - `}
              <FormattedMessage {...commonFields.accessGroup} />
            </Typography>
          </Stack>
        )}
        {userScope === USER_SCOPES.BY_GROUPS && (
          <Stack direction="column" spacing={2}>
            <Typography variant="body2">
              <FormattedMessage
                id="tyDesign.user.accessSelectedGroups"
                defaultMessage="can access selected groups of entities"
              />
            </Typography>
            {Object.entries(groups).map(([key, values]) => (
              <Stack direction="column" key={key}>
                <Typography color="text.hint" variant="body2">
                  {key}
                </Typography>
                <Typography variant="body2">
                  {values.map((group) => group.name).join(', ')}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {userScope === USER_SCOPES.ADVANCED_SELECTION && (
          <Box sx={styles.userScopeCell}>
            <Typography variant="body2">
              <FormattedMessage
                {...usersMessages.scopesEntityCount}
                values={{ count: scope.entities_count }}
              />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage {...usersMessages.scopesAdvancedRules} />
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
