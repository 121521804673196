import {
  GET_ORGANIZATION_BENCHMARKS_PERMISSION,
  GET_USER_BENCHMARKS_PERMISSION,
} from '../constants';
import { usePermissionStore } from '../store';

export const useViewBenchmarksPermission = () => {
  const { hasPermissions } = usePermissionStore();
  if (hasPermissions(GET_ORGANIZATION_BENCHMARKS_PERMISSION)) return 'organization';
  if (hasPermissions(GET_USER_BENCHMARKS_PERMISSION)) return 'user';
  return false;
};
