import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { MenuItem, Stack, Typography } from '@trustyou/ui';

import type { ListThemesApiSurveyOrganizationIdThemeListGetResponse } from '../../../../client';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorThemeContent({
  themeList,
}: {
  themeList: ListThemesApiSurveyOrganizationIdThemeListGetResponse;
}) {
  const { register, setValue, watch } = useFormContext();
  const themeId = watch('surveyQuestionnaireEditorTheme')?.id || themeList[0].id;

  useEffect(() => {
    register('surveyQuestionnaireEditorTheme');
    if (!watch('surveyQuestionnaireEditorTheme')) {
      setValue('surveyQuestionnaireEditorTheme', themeList.filter((item) => item.is_default)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeList]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage id="survey.common.theme" defaultMessage="Theme" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.theme.description"
            defaultMessage="Choose a theme that defines the look and feel of your Survey and the Survey invitation email. To change which entity has access to which theme or to edit the it, visit the Theme editor. "
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        value={themeId}
        onChange={(e) => {
          const selectedMessage = themeList.find((item) => item.id === e.target.value);
          setValue('surveyQuestionnaireEditorTheme', selectedMessage);
        }}
      >
        {themeList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
    </Stack>
  );
}
