import type { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';

import {
  getAllowedNodes,
  navigationNodes,
  routePermissions,
  useActiveDeepNode,
  useActiveNode,
  useChangelingStore,
  useCustomerLogout,
  useMembershipStore,
  usePermissionStore,
  useResponsive,
  useRouteUtils,
  useSidebarStore,
} from '@trustyou/shared';
import { Box, ChangelingAlert, CollapsibleDrawer, TopBar } from '@trustyou/ui';

import { combinedNodes, footerNodes, nodes } from '../../../constants/sideBarNodes';
import { MobileLayout } from '../../Mobile/Layout/Layout';
import { NotificationsMenu } from '../NotificationsMenu/NotificationsMenu';
import styles from './styles';

export const NavigationSidebar = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const { isMobile } = useResponsive();
  const { isCollapsed } = useSidebarStore();
  const { navigateToHome } = useRouteUtils();
  const { isChangeling } = useChangelingStore();
  const customerLogout = useCustomerLogout();
  const { permissions } = usePermissionStore();
  const { membership } = useMembershipStore();
  const membershipIdPath = membership?.id ? `/org/${membership.id}` : '';

  const activeNode = useActiveNode(nodes(membershipIdPath), footerNodes(membershipIdPath));
  const activeDeepNode = useActiveDeepNode(combinedNodes(membershipIdPath));
  const allowedNodes = getAllowedNodes(nodes(membershipIdPath), routePermissions, permissions);
  const allowedFooterNodes = getAllowedNodes(
    footerNodes(membershipIdPath),
    routePermissions,
    permissions
  );
  const pageName = intl.formatMessage(
    activeDeepNode
      ? { id: activeDeepNode?.id, defaultMessage: activeDeepNode.name }
      : { id: navigationNodes.home?.id, defaultMessage: navigationNodes.home?.defaultMessage }
  );

  if (isMobile)
    return (
      <MobileLayout
        pageName={pageName}
        nodes={allowedNodes.filter((node) => !node.isHiddenOnMobile)}
        footerNodes={allowedFooterNodes.filter((node) => !node.isHiddenOnMobile)}
        isRootNode={activeNode?.id === activeDeepNode?.id}
      >
        <ChangelingAlert />
        {children}
      </MobileLayout>
    );

  return (
    <Box>
      <ChangelingAlert />
      <Box
        sx={{
          ...styles.container,
          ...(isChangeling && {
            'MuiDrawer-paper': styles.changelingMode,
          }),
        }}
      >
        <CollapsibleDrawer
          logoColor="blue"
          isChangeling={!!isChangeling}
          primaryNodes={allowedNodes}
          secondaryNodes={allowedFooterNodes}
          onHeaderClick={navigateToHome}
        />
        <Box
          sx={{
            ...styles.rightContainer,
            ...(isCollapsed ? styles.collapsed : styles.expanded),
            ...(isChangeling && styles.changelingMode),
          }}
        >
          <TopBar pageName={pageName} onLogout={customerLogout}>
            <NotificationsMenu />
            <Box />
          </TopBar>
          <Box component="main" sx={styles.mainContainer}>
            {children}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
