import type { ReactNode } from 'react';

import { Box, Typography } from '@trustyou/ui';
import DOMPurify from 'dompurify';

export type QuestionWrapperProps = {
  children: ReactNode;
  name: string;
};

export const QuestionWrapper = ({ children, name }: QuestionWrapperProps) => {
  const sanitizedName = DOMPurify.sanitize(name);
  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 'bold' }}
        dangerouslySetInnerHTML={{ __html: sanitizedName }}
      />
      {children}
    </Box>
  );
};
