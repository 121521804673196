import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { deepOrange, green, orange, red } from '@mui/material/colors';
import { supportedLanguages, useLanguageStore } from '@trustyou/shared';
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
} from '@trustyou/ui';

import { useGetResponseTemplates } from '../../../../hooks';
import { getResponseTemplatesByLanguage } from '../../../../utils/settings';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingInlineStart: theme.spacing(4),
}));

const LanguageLabel = () => (
  <FormattedMessage id="inbox.response.template.language.label" defaultMessage="Language" />
);
const TemplatesLabel = () => (
  <FormattedMessage id="inbox.response.template" defaultMessage="Template" />
);

export type ResponseTemplateProps = {
  onSelect: (value: string) => void;
};

export const ResponseTemplate = ({ onSelect }: ResponseTemplateProps) => {
  const { locale } = useLanguageStore();
  const [selectedLanguage, setSelectedLanguage] = useState(locale);
  const [selectedTemplate, setSelectedTemplate] = useState('none');
  const { data: allTemplatesObject } = useGetResponseTemplates();

  const templates = getResponseTemplatesByLanguage(allTemplatesObject, selectedLanguage);

  useEffect(() => {
    const template = templates.find((template) => template.name === selectedTemplate);
    if (!template) return;
    onSelect(template.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, selectedTemplate]);

  const positiveTemplates = templates.filter((template) => template.sentiment === 'positive');
  const neutralTemplates = templates.filter((template) => template.sentiment === 'neutral');
  const negativeTemplates = templates.filter((template) => template.sentiment === 'negative');

  const onTemplateSelect = (templateName: string) => {
    setSelectedTemplate(templateName);
    const selectedTemplate = templates.find((template) => template.name === templateName);
    // clear the text field when clicking the "Choose template" option (value='none')
    if (!selectedTemplate) {
      onSelect('');
      return;
    }
    onSelect(selectedTemplate.text);
  };

  if (!allTemplatesObject) return;

  return (
    <Stack direction="column" gap={2}>
      <FormControl sx={{ width: 220 }}>
        <InputLabel id="template-language-label">
          <LanguageLabel />
        </InputLabel>
        <Select
          labelId="template-language-label"
          id="template-language"
          value={selectedLanguage}
          onChange={(event) => {
            setSelectedLanguage(event.target.value);
          }}
          input={<OutlinedInput label={<LanguageLabel />} />}
        >
          {supportedLanguages.map((item) => (
            <MenuItem key={item.value} value={item.value} data-testid={item.dataTestId}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 'calc(100% - 44px)' }}>
        <InputLabel id="template-template-label">
          <TemplatesLabel />
        </InputLabel>
        <Select
          labelId="template-template-label"
          id="template-template"
          value={selectedTemplate}
          onChange={(event) => {
            onTemplateSelect(event.target.value);
          }}
          input={<OutlinedInput label={<TemplatesLabel />} />}
          MenuProps={{ sx: { maxHeight: 480 } }}
        >
          {templates.length === 0 && (
            <StyledMenuItem value="empty">
              <Box color="text.secondary" sx={{ fontSize: 14 }}>
                <FormattedMessage
                  id="inbox.response.template.empty"
                  defaultMessage="No templates yet"
                />
              </Box>
            </StyledMenuItem>
          )}
          {templates.length > 0 && (
            <StyledMenuItem value="none">
              <Box color="text.secondary" sx={{ fontSize: 14 }}>
                <FormattedMessage
                  id="inbox.response.template.choose"
                  defaultMessage="Choose template"
                />
              </Box>
            </StyledMenuItem>
          )}
          {positiveTemplates.length > 0 && (
            <ListSubheader
              sx={{ backgroundColor: green[50], color: green[900], lineHeight: '36px' }}
            >
              <FormattedMessage
                id="inbox.response.templates.positive"
                defaultMessage="Positive templates"
              />
            </ListSubheader>
          )}
          {positiveTemplates.map((template) => (
            <StyledMenuItem key={template.name} value={template.name}>
              {template.name}
            </StyledMenuItem>
          ))}
          {neutralTemplates.length > 0 && (
            <ListSubheader
              sx={{ backgroundColor: orange[50], color: deepOrange[900], lineHeight: '36px' }}
            >
              <FormattedMessage
                id="inbox.response.templates.neutral"
                defaultMessage="Neutral templates"
              />
            </ListSubheader>
          )}
          {neutralTemplates.map((template) => (
            <StyledMenuItem key={template.name} value={template.name}>
              {template.name}
            </StyledMenuItem>
          ))}
          {negativeTemplates.length > 0 && (
            <ListSubheader sx={{ backgroundColor: red[50], color: red[900], lineHeight: '36px' }}>
              <FormattedMessage
                id="inbox.response.templates.negative"
                defaultMessage="Negative templates"
              />
            </ListSubheader>
          )}
          {negativeTemplates.map((template) => (
            <StyledMenuItem key={template.name} value={template.name}>
              {template.name}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
