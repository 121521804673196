import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useOrgCenter } from '@trustyou/shared';

import type { Invitation } from '../../../../types/model/auth';
import {
  FETCH_ACCESS_TOKEN,
  FETCH_MEMBERSHIPS_AND_INVITATIONS,
  FETCH_TOKEN_INFO,
} from '../../../constants/queryKeys';
import {
  acceptInvitation,
  fetchAccessToken,
  fetchChangelingAccessToken,
  fetchMembershipsAndInvitations,
  fetchTokenInfo,
} from '../../../queries';

export function useAccessToken({ isChangeling }: { isChangeling?: boolean }) {
  return useMutation({
    mutationFn: isChangeling ? fetchChangelingAccessToken : fetchAccessToken,
    mutationKey: [FETCH_ACCESS_TOKEN],
  });
}

export function useTokenInfo(accessToken?: string | null) {
  const orgCenter = useOrgCenter();
  return useQuery({
    queryKey: [FETCH_TOKEN_INFO, accessToken],
    queryFn: () => fetchTokenInfo(orgCenter?.apis || []),
    refetchOnWindowFocus: false,
    enabled: !!accessToken,
  });
}

export function useMembershipsAndInvitations({
  keycloakAccessToken,
  enabled,
}: {
  keycloakAccessToken: string;
  enabled: boolean;
}) {
  return useQuery({
    queryKey: [FETCH_MEMBERSHIPS_AND_INVITATIONS],
    queryFn: () => fetchMembershipsAndInvitations({ keycloakAccessToken }),
    enabled,
    refetchOnWindowFocus: false,
  });
}

export function useAcceptAllInvitations({ keycloakAccessToken }: { keycloakAccessToken: string }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (invitationId: string) => acceptInvitation({ keycloakAccessToken, invitationId }),
  });

  const acceptAll = useCallback(
    async (invitations: Invitation[], onFailed: () => void, onSettled: () => void) => {
      const promises = invitations.map((invitation) => mutation.mutateAsync(invitation.id));

      const results = await Promise.allSettled(promises);
      const failedInvitations = results.filter((result) => result.status === 'rejected');
      if (failedInvitations.length > 0) {
        onFailed();
      }
      queryClient.removeQueries({ queryKey: [FETCH_MEMBERSHIPS_AND_INVITATIONS] });

      onSettled();
    },
    [mutation, queryClient]
  );

  return { acceptAll };
}
