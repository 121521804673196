import { temporaryHiddenEnvironments } from '../constants';
import { areUITestsRunning, getEnvironment } from '../service/utils';
import type { Environment, FeatureFlag } from '../types';

export const isTemporaryHidden = (featureFlag: FeatureFlag) => {
  if (areUITestsRunning()) return false;

  const hiddenEnvs = temporaryHiddenEnvironments[featureFlag] as Environment[];
  if (!hiddenEnvs) return false;
  if (hiddenEnvs.includes('none')) return false;
  if (hiddenEnvs.includes('all')) return true;

  const currentEnv = getEnvironment();
  return hiddenEnvs.includes(currentEnv);
};
