import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, InputLabel, MenuItem, Stack, Typography } from '@trustyou/ui';

import { LANGUAGE_OPTIONS } from '../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorSettingsContent() {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="survey.common.settings" defaultMessage="Settings" />
      </Typography>
      <Stack spacing={6} sx={{ maxWidth: 300 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink>
            <FormattedMessage
              id="survey.survey-editor.internal-name"
              defaultMessage="Internal name"
            />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            fullWidth
            error={!!errors?.surveyQuestionnaireEditorInternalName}
            helperText={
              !!errors?.surveyQuestionnaireEditorInternalName && (
                <FormattedMessage
                  id="survey.common.mandatory-field"
                  defaultMessage="Mandatory field"
                />
              )
            }
            {...register('surveyQuestionnaireEditorInternalName', { required: true })}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink>
            <FormattedMessage
              id="survey.survey-editor.default-language.label"
              defaultMessage="Default Language"
            />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            fullWidth
            select
            error={!!errors?.surveyEditorDefaultLanguage}
            helperText={
              !!errors?.surveyEditorDefaultLanguage && (
                <FormattedMessage
                  id="survey.common.mandatory-field"
                  defaultMessage="Mandatory field"
                />
              )
            }
            defaultValue={'en'}
            value={watch('surveyEditorDefaultLanguage')}
            {...register('surveyEditorDefaultLanguage', { required: true })}
          >
            {LANGUAGE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomTextFieldWithSeparatedLabel>
        </FormControl>
      </Stack>
    </>
  );
}
