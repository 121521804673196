import { green, orange, red } from '@mui/material/colors';
import type { SemaMatchesSchema } from '@trustyou/inbox';

const sentimentColors = {
  n: {
    bg: red[50],
    text: red[900],
  },
  p: {
    bg: green[50],
    text: green[900],
  },
  o: {
    bg: orange[50],
    text: orange[900],
  },
};

type Props = {
  text: string;
  semaMatches?: SemaMatchesSchema | null;
};

export const HighlightedText = ({ text, semaMatches }: Props) => {
  if (semaMatches?.opinions) {
    const matchedTexts = semaMatches.opinions
      .map((opinion) => opinion.text)
      .sort((a, b) => text.indexOf(a) - text.indexOf(b));
    // Text is splitted by matched sub texts
    const pattern = new RegExp(
      '(' + matchedTexts.map((text) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|') + ')',
      'g'
    );
    const splitTextArray = text.split(pattern).filter(Boolean);
    const highlightedTexts = splitTextArray.map((row, index) => {
      const opinion = semaMatches.opinions?.find((opinion) => opinion.text === row);
      if (opinion) {
        const color = sentimentColors[opinion.sentiment] || 'inherit';
        return (
          <span
            key={`text-item-${index}`}
            style={{
              backgroundColor: color.bg,
              display: 'inline-block',
              padding: '2px 4px',
              color: color.text,
            }}
          >
            {row}
          </span>
        );
      }
      return <span key={`text-item-${index}`}>{row}</span>;
    });
    return highlightedTexts;
  }
  return text;
};
