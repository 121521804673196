import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_ENTITIES,
  useBasicEntities,
  useEntityCount,
  useInfiniteEntities,
} from '@trustyou/shared';
import { Box, Button, ListItemText, Skeleton, Typography } from '@trustyou/ui';

import type { EmailNotificationSettings_Input } from '../../../client';
import { MAX_ENTITIES } from '../../../constants';
import { SelectorModal } from '../../selector-modal';

export const EntitiesSection = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { setValue, getValues } = useFormContext<EmailNotificationSettings_Input>();

  const [entities, setEntities] = useState<Entity[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);
  const [searchKey, setSearchKey] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { data: entityCount = 0, isPending: isEntityCountPending } = useEntityCount();
  useEffect(() => {
    // If there is only one entity, select it by default
    if (entityCount === 1 && entities.length === 1) {
      setValue('filters.entities', [entities[0].id], { shouldDirty: false });
    }
  }, [entityCount, entities, setValue]);

  const { data: basicEntities = [], isPending: isBasicEntitiesPending } = useBasicEntities(
    getValues('filters.entities') ?? []
  );
  const restrictedScopeEntities = basicEntities.slice(0, MAX_ENTITIES) as Entity[];
  useEffect(() => {
    if (!isBasicEntitiesPending && basicEntities.length > 0) {
      setSelectedEntities(restrictedScopeEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBasicEntitiesPending, basicEntities]);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteEntities(
    MAX_ENTITIES,
    DEFAULT_SORT_KEY,
    searchKey
  );
  useEffect(() => {
    setEntities(data?.pages.flatMap((page) => page.data) ?? []);
  }, [data]);

  const saveEntities = () => {
    const ids = selectedEntities.map(({ id }) => id);
    setValue('filters.entities', ids, { shouldDirty: true });
    setIsOpen(false);
  };

  const cancel = () => {
    setSelectedEntities(restrictedScopeEntities);
    setIsOpen(false);
  };

  const renderRowContent = (entity: Entity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const search = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
  };

  const isPending = isEntityCountPending || isBasicEntitiesPending;

  if (!isPending && entityCount <= 1) {
    return null;
  }

  return (
    <Box>
      <Typography variant="subtitle1">
        <FormattedMessage id="inbox.settings.notifications.entities" defaultMessage="Entities" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="inbox.settings.notifications.entities.description"
          defaultMessage="You can select up to {maxEntities} entities to receive notifications for."
          values={{ maxEntities: MAX_ENTITIES }}
        />
      </Typography>
      {isEntityCountPending ? (
        <Skeleton height={24 + 36.5} />
      ) : (
        <>
          <Typography variant="body1">
            <FormattedMessage
              id="inbox.settings.notifications.entities.selected"
              defaultMessage="{count} / {total} selected"
              values={{
                count:
                  selectedEntities.length > 0
                    ? selectedEntities.length
                    : entityCount > MAX_ENTITIES
                      ? MAX_ENTITIES
                      : entityCount,
                total: entityCount,
              }}
            />
          </Typography>
          <Button variant="text" onClick={() => setIsOpen(true)}>
            <FormattedMessage id="inbox.action.change" defaultMessage="Change" />
          </Button>
        </>
      )}
      <SelectorModal
        isOpen={isOpen}
        items={entities}
        selectedItems={selectedEntities}
        setSelectedItems={setSelectedEntities}
        onClose={cancel}
        onSave={saveEntities}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.entities',
          defaultMessage: 'Search entities',
        })}
        title={<FormattedMessage id="inbox.filter.entity.modal.title" defaultMessage="Entities" />}
        allTab={
          <FormattedMessage
            id="inbox.filter.entity.modal.button.all"
            defaultMessage="All entities"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.entity.modal.button.select"
            defaultMessage="Select entities"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.entity.modal.selected-count"
            defaultMessage="Selected entities ({count})"
            values={{ count: selectedEntities.length }}
          />
        }
        alertTitle={
          selectedEntities.length === 0 ? (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.min-error"
              defaultMessage="Select at least one entity"
            />
          ) : (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.max-error"
              defaultMessage="More than {maxEntities} entities selected"
              values={{ maxEntities: MAX_ENTITIES }}
            />
          )
        }
        alertDescription={
          selectedEntities.length > MAX_ENTITIES && (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-description.min-error"
              defaultMessage="Remove a few entities from the list and try again"
            />
          )
        }
        isLoading={isLoading}
        onFetchNextPage={handleFetchNextPage}
        onSearch={search}
        hideButtonGroup
        maxItems={MAX_ENTITIES}
      />
    </Box>
  );
};
