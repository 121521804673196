import { useCallback, useState } from 'react';

export function useConfirm(isDirty: boolean) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);

  const handleAction = useCallback(
    (action: () => void) => {
      if (isDirty) {
        setIsDialogOpen(true);
        setPendingAction(() => action);
      } else {
        action();
      }
    },
    [isDirty]
  );

  const handleConfirm = useCallback(() => {
    setIsDialogOpen(false);
    if (pendingAction) {
      pendingAction();
      setPendingAction(null);
    }
  }, [pendingAction]);

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
    setPendingAction(null);
  }, []);

  return {
    isDialogOpen,
    handleAction,
    handleConfirm,
    handleCancel,
  };
}
