import {
  ResponseAISettingsForm,
  SignatureSettingsForm,
  useResponseAIPermission,
} from '@trustyou/inbox';
import { Box } from '@trustyou/ui';

import { styles } from '../styles';

export function InboxProfileSettings() {
  const hasResponseAIPermission = useResponseAIPermission();

  return (
    <Box sx={styles.generalPageContainer}>
      <SignatureSettingsForm />
      {hasResponseAIPermission && <ResponseAISettingsForm />}
    </Box>
  );
}
