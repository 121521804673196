import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Entity, useEntity, useLanguageStore, useResponsive } from '@trustyou/shared';
import { Box, Divider, Stack, Typography } from '@trustyou/ui';

import { ReviewMetadata } from './review-metadata';
import { TextWithHighlights } from './text-with-highlights';

import type { ReviewEnvelopeDetailSchema } from '../../../client';
import type { ReviewRoot } from '../../../types';
import { formatNewSurveyGuestInfo } from '../../../utils/guest-information';
import { hasMatches } from '../../../utils/sema';
import { GuestInformationDialog } from '../../guest-information-dialog';
import { ScoreChip } from '../../score-chip';
import { SemanticAnalysis } from '../../semantic-analysis';
import { Survey } from '../../survey';

export type ReviewItemProps = {
  reviewData: ReviewRoot;
  shouldDisplayTranslation: boolean;
};

export const ReviewSide = ({ reviewData, shouldDisplayTranslation }: ReviewItemProps) => {
  const { isDesktop } = useResponsive();
  const { locale } = useLanguageStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);
  const { review, survey, meta } = reviewData ?? {};
  const entityQuery = useEntity(meta?.entity_id ?? '');

  const title = shouldDisplayTranslation ? review?.translations?.[locale]?.title : review?.title;
  const text = shouldDisplayTranslation ? review?.translations?.[locale]?.text : review?.text;
  const sema_matches = shouldDisplayTranslation
    ? {}
    : (reviewData as ReviewEnvelopeDetailSchema)?.sema_matches;

  const formatGuestInformation = () => {
    if (survey) {
      return formatNewSurveyGuestInfo(survey.contact_info);
    }
    // in this case its just a review so show the author name
    return [{ label: 'name', value: review?.author }];
  };

  return (
    <>
      <Box
        sx={{
          ...(isDesktop && {
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          }),
        }}
      >
        <ReviewMetadata
          reviewRoot={reviewData as ReviewRoot}
          entity={entityQuery.data as Entity}
          onPressGuestIcon={toggle}
        />
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack spacing={2} sx={{ padding: 2 }}>
            <Stack direction="row">
              <Box width="40px">
                <ScoreChip value={review?.score ?? 0} isRound />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 14,
                  marginLeft: 1,
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'pre-wrap',
                  ...(!title && { color: (theme) => theme.palette.text.secondary }),
                }}
              >
                {title || <FormattedMessage id="inbox.no-title" defaultMessage="No title" />}
              </Typography>
            </Stack>
            <TextWithHighlights text={text ?? ''} semaMatches={sema_matches ?? {}} />
            {survey && survey?.answers?.length > 0 && <Survey />}
          </Stack>
          {sema_matches && hasMatches(sema_matches) && (
            <>
              <Divider />
              <Stack spacing={1} sx={{ padding: 2 }}>
                <Typography variant="subtitle2">
                  <FormattedMessage
                    id="inbox.review.semanticAnalysis.accordion.title"
                    defaultMessage="Semantic analysis"
                  />
                </Typography>
                <SemanticAnalysis />
              </Stack>
            </>
          )}
        </Stack>
      </Box>
      <GuestInformationDialog isOpen={isOpen} onClose={toggle} data={formatGuestInformation()} />
    </>
  );
};
