import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, InputLabel, Stack, Typography } from '@trustyou/ui';

import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorSettingsContent() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id="survey.common.settings" defaultMessage="Settings" />
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: 3 }} color="text.secondary">
        <FormattedMessage
          id="survey.survey-editor.settings-step.description"
          defaultMessage="All fields can be edited later."
        />
      </Typography>
      <Stack spacing={6} sx={{ maxWidth: 300 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink>
            <FormattedMessage
              id="survey.survey-editor.internal-name"
              defaultMessage="Internal name"
            />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            fullWidth
            error={!!errors?.surveyEditorInternalName}
            helperText={
              !!errors?.surveyEditorInternalName && (
                <FormattedMessage
                  id="survey.common.mandatory-field"
                  defaultMessage="Mandatory field"
                />
              )
            }
            {...register('surveyEditorInternalName', { required: true })}
          />
        </FormControl>
      </Stack>
    </>
  );
}
