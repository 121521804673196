import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { FETCH_INFINITE_ENTITIES, useEntityCount } from '@trustyou/shared';
import { ListItemText, Skeleton } from '@trustyou/ui';

import { useEntityFilterSearch } from './use-entity-filter-search';
import { useEntityFilterSelection } from './use-entity-filter-selection';

import { MAX_ENTITIES } from '../../../constants';
import { type FiltersForm, FiltersFormFields } from '../../../types';
import { FilterDialogButton } from '../../filter-dialog-button';
import { SelectorModal } from '../../selector-modal';

export function EntityFilter() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { setValue } = useFormContext<FiltersForm>();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const entityCountQuery = useEntityCount();
  const { entities, setSearchKey, infiniteEntitiesQuery } = useEntityFilterSearch();
  const {
    selectedEntities,
    setSelectedEntities,
    restrictedScopeEntitiesQuery,
    currentViewEntitiesQuery,
  } = useEntityFilterSelection();

  const isPending =
    entityCountQuery.isPending ||
    infiniteEntitiesQuery.isPending ||
    restrictedScopeEntitiesQuery.isPending ||
    currentViewEntitiesQuery.isPending;

  const handleFetchNextPage = () => {
    if (infiniteEntitiesQuery.hasNextPage) {
      infiniteEntitiesQuery.fetchNextPage();
    }
  };

  const handleSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
  };

  const handleSave = () => {
    const selectedEntityIds = selectedEntities.map(({ id }) => id);
    setValue(FiltersFormFields.Entities, selectedEntityIds, { shouldDirty: true });
    setIsDialogOpen(false);
  };

  return (
    <>
      <FilterDialogButton
        title={<FormattedMessage id="inbox.filter.entities.name" defaultMessage="Entities" />}
        description={
          isPending ? (
            <Skeleton variant="rounded" height={20} width={114} />
          ) : (
            <FormattedMessage
              id="inbox.filter.selected-ratio"
              defaultMessage="{count} / {total} selected"
              values={{
                count: selectedEntities.length,
                total: entityCountQuery.data,
              }}
            />
          )
        }
        onClick={() => setIsDialogOpen(true)}
      />
      <SelectorModal
        isOpen={isDialogOpen}
        items={entities}
        selectedItems={selectedEntities}
        setSelectedItems={setSelectedEntities}
        onFetchNextPage={handleFetchNextPage}
        hideButtonGroup
        maxItems={MAX_ENTITIES}
        isLoading={isPending}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleSave}
        renderRowContent={(entity) => (
          <ListItemText
            primary={entity.name}
            secondary={`${entity.city}, ${entity.country_name}`}
          />
        )}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.entities',
          defaultMessage: 'Search entities',
        })}
        onSearch={handleSearch}
        title={
          <FormattedMessage id="inbox.filter.entities.modal.title" defaultMessage="Entities" />
        }
        subHeader={
          <FormattedMessage
            id="inbox.filter.entity.modal.subheader"
            defaultMessage="Choose up to {maxEntities} entities whose reviews you'd like to view in your Inbox."
            values={{ maxEntities: MAX_ENTITIES }}
          />
        }
        allTab={
          <FormattedMessage
            id="inbox.filter.entities.modal.button.all"
            defaultMessage="All entities"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.entity.modal.button.select"
            defaultMessage="Select entities"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.entities.modal.selected-count"
            defaultMessage="Selected entities ({count})"
            values={{ count: selectedEntities.length }}
          />
        }
        alertTitle={
          selectedEntities.length === 0 ? (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.min-error"
              defaultMessage="Select at least one entity"
            />
          ) : (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-title.max-error"
              defaultMessage="More than {maxEntities} entities selected"
              values={{ maxEntities: MAX_ENTITIES }}
            />
          )
        }
        alertDescription={
          selectedEntities.length > MAX_ENTITIES && (
            <FormattedMessage
              id="inbox.filter.entity.modal.alert-description.min-error"
              defaultMessage="Remove a few entities from the list and try again"
            />
          )
        }
      />
    </>
  );
}
