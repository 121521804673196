import type { ReactNode } from 'react';

import type { IconDefinition } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  ICON_BUTTON_HEIGHT,
  ICON_HEIGHT,
  ICON_WIDTH,
  ListItemIcon,
  MenuItem,
  type MenuItemProps,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

export function CustomMenuItem({
  id,
  icon,
  text,
  onClick,
}: {
  id: string;
  icon: IconDefinition;
  text: ReactNode;
  onClick: MenuItemProps['onClick'];
}) {
  return (
    <MenuItem
      onClick={onClick}
      data-testid={`${id}-list-item-button`}
      sx={{ height: ICON_BUTTON_HEIGHT }}
    >
      <ListItemIcon>
        <StyledFontAwesomeIcon
          icon={icon}
          sx={{
            height: ICON_HEIGHT,
            width: ICON_WIDTH,
          }}
        />
      </ListItemIcon>
      <Typography variant="body2">{text}</Typography>
    </MenuItem>
  );
}
