import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { SxProps, Theme } from '@mui/material';
import { Box, StyledFontAwesomeIcon } from '@trustyou/ui';

import styles from './SpotIcon.styles';

import { stringToBrandedColor } from '../../utils/stringBrandedAvatar';

export interface SpotIconProps {
  icon: IconDefinition;
  altText?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  brandedColorString?: string;
}

export const SpotIcon = ({ icon, disabled, altText, sx, brandedColorString }: SpotIconProps) => (
  <Box
    sx={{
      ...styles.box,
      ...(disabled && styles.disabled),
      ...(brandedColorString && { backgroundColor: stringToBrandedColor(brandedColorString) }),
      ...sx,
    }}
  >
    <StyledFontAwesomeIcon size="lg" icon={icon} title={altText} />
  </Box>
);
