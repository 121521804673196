export const FETCH_ACCESS_TOKEN = 'access_token';
export const FETCH_TOKEN_INFO = 'token_info';

export const FETCH_ENTITIES = 'entities';
export const FETCH_BASIC_ENTITIES = 'basic_entities';
export const FETCH_INFINITE_ENTITIES = 'infinite_entities';
export const FETCH_ENTITY_DETAILS = 'entity_details';
export const FETCH_ENTITY_COUNT = 'entity_count';
export const FETCH_SEGMENTS = 'segments';
export const FETCH_INFINITE_SEGMENTS = 'infinite_segments';
export const FETCH_SEGMENT_GROUPS = 'segment_groups';
export const FETCH_INFINITE_SEGMENT_GROUPS = 'infinite_segment_groups';
export const FETCH_GROUP_ENTITIES = 'group_entities';
export const FETCH_ATTRIBUTES = 'attributes';
export const FETCH_ATTRIBUTES_FOR_RULES = 'attributes_for_rules';
export const FETCH_ATTRIBUTE_VALUES = 'attribute_values';
export const FETCH_ATTRIBUTE_VALUES_FOR_RULES = 'attribute_values_for_rules';
export const FETCH_ORGANIZATIONS = 'organizations';
export const FETCH_USER_ORGANIZATION = 'user_organization';
export const FETCH_ORGANIZATION_LOGO = 'organization_logo';
export const FETCH_REVIEW_PROVIDERS = 'review_providers';
export const FETCH_REVIEW_PROVIDER_DETAILS = 'review_provider_details';
export const FETCH_INFINITE_COMPETITORS = 'infinite_competitors';
export const FETCH_ADDABLE_ENTITIES = 'addable_entities';
export const FETCH_UPLOAD_TEMPLATE = 'upload_template';
export const FETCH_MEMBERSHIPS_AND_INVITATIONS = 'memberships_and_invitations';
export const FETCH_ACCESS_GROUPS = 'access_groups';
export const FETCH_INFINITE_ACCESS_GROUPS = 'infinite_access_groups';
export const FETCH_ACCESS_GROUP_DETAILS = 'access_group_details';
export const FETCH_CURRENT_SUBSCRIPTION = 'current_subscription';
export const FETCH_SPECIFIC_SUBSCRIPTION = 'specific_subscription';
export const FETCH_ENTITY_SUBSCRIPTIONS = 'entity_subscriptions';
export const FETCH_SUBSCRIPTION_MEMBERS = 'subscription_members';
export const FETCH_SUBSCRIPTION_ENTITIES = 'subscription_entities';
export const FETCH_ASSIGNABLE_ENTITIES_COUNT = 'assignable_entities_count';
export const FETCH_INFINITE_SUBSCRIPTION_ENTITIES = 'infinite_subscription_entities';
export const FETCH_INFINITE_ASSIGNABLE_ENTITIES = 'infinite_assignable_entities';
export const FETCH_BENCHMARKS = 'benchmarks';
export const FETCH_BENCHMARK_OWNED_ENTITIES = 'benchmark_owned_entities';
export const FETCH_BENCHMARK_COMPETITOR_ENTITIES = 'benchmark_competitor_entities';
export const FETCH_BENCHMARK_SETS = 'benchmark_sets';
export const FETCH_BENCHMARK_ADDITIONAL_ENTITIES = 'benchmark_additional_entities';
export const FETCH_ATTRIBUTE_VALUES_FOR_BENCHMARK = 'attribute_values_for_benchmark';
export const FETCH_ENTITY_REVIEW_PROVIDERS = 'entity_review_providers';
export const FETCH_ENTITY_REVIEW_PROVIDER_DETAILS = 'entity_review_provider_details';

export const FETCH_GLOBAL_USERS = 'global_users';
export const FETCH_USERS_BY_ORGANIZATION = 'users_by_organization';
export const FETCH_COMBINED_ORGANIZATION_USERS = 'combined_organization_users';
export const FETCH_GLOBAL_ORGANIZATION_USERS = 'global_organization_users';
export const FETCH_GLOBAL_SERVICE_USERS = 'global_service_users';
export const UPDATE_USER_SETTINGS = 'update_user_settings';
export const FETCH_USER_SETTINGS = 'fetch_user_settings';
export const FETCH_ORGANIZATION_ADMIN = 'fetch_organization_admin';

export const FETCH_GLOBAL_ENTITIES = 'global_entities';
export const FETCH_CUSTOM_ATTRIBUTE_DEFINITIONS = 'custom_attribute_definitions';
export const FETCH_CUSTOM_ATTRIBUTE_DEFINITION = 'custom_attribute_definition';
export const FETCH_CAN_MARK_AS_UNIQUE_CUSTOM_ATTRIBUTE = 'can_mark_as_unique_custom_attribute';
export const FETCH_CAN_DELETE_CUSTOM_ATTRIBUTE_DEFINITION = 'can_delete_custom_attribute';
export const FETCH_CAN_UPDATE_CUSTOM_ATTRIBUTE_DEFINITION = 'can_update_custom_attribute';

export const DELETE_ENTITY = 'delete_entity';
export const DELETE_GROUP = 'delete_group';
export const DELETE_SEGMENT = 'delete_segment';
export const DELETE_USER = 'delete_user';
export const DELETE_ORGANIZATION = 'delete_organization';
export const DELETE_ACCESS_GROUPS = 'delete_access_groups';
export const DELETE_CUSTOM_ATTRIBUTE_DEFINITION = 'delete_custom_attribute_definition';
export const DELETE_BENCHMARK = 'delete_benchmark';
export const DELETE_ENTITY_REVIEW_PROVIDER_ACCOUNT = 'delete_entity_review_provider_account';

export const UPLOAD_ENTITIES = 'upload_entities';
export const UPLOAD_ENTITIES_ADMIN = 'upload_entities_admin';
export const ADD_ENTITIES = 'add_entities';
export const UPLOAD_COMPETITORS_ADMIN = 'upload_competitors_admin';
export const IMPORT_SOURCES_URLS = 'import_sources_urls';

export const CREATE_SEGMENT = 'create_segment';
export const CREATE_GROUP = 'create_group';
export const CREATE_USER = 'create_user';
export const CREATE_ORGANIZATION = 'create_organization';
export const CREATE_ENTITY = 'create_entity';
export const CREATE_CUSTOM_ATTRIBUTE_DEFINITION = 'create_custom_attribute_definition';
export const CREATE_BENCHMARK = 'create_benchmark';
export const CREATE_ENTITY_BOOKING_CONNECTION = 'create_entity_booking_connection';
export const CREATE_ENTITY_GOOGLE_CONNECTION = 'create_entity_google_connection';

export const UPDATE_SEGMENT = 'update_segment';
export const UPDATE_GROUP = 'update_group';
export const UPDATE_USER = 'update_user';
export const UPDATE_DASHBOARD_SEGMENTS = 'update_dashboard_segments';
export const EDIT_ORGANIZATION = 'edit_organization';
export const SET_ENTITY_COMPETITORS = 'set_entity_competitors';
export const EDIT_ENTITY = 'edit_entity';
export const CREATE_UPDATE_ACCESS_GROUP = 'create_update_access_group';
export const EDIT_SUBSCRIPTION = 'edit_subscription';
export const UPDATE_CUSTOM_ATTRIBUTE_DEFINITION = 'update_custom_attribute_definition';
export const UPDATE_ENTITY_CUSTOM_ATTRIBUTES = 'update_entity_custom_attributes';
export const EDIT_BENCHMARK = 'edit_benchmark';

export const IS_VALID_SEGMENT = 'valid_segment';
export const IS_VALID_GROUP = 'valid_group';
export const IS_VALID_ORGANIZATION = 'valid_organization';
export const IS_VALID_ENTITY = 'valid_entity';
export const IS_VALID_ACCESS_GROUP = 'valid_access_group';
export const IS_VALID_CUSTOM_ATTRIBUTE_NAME = 'valid_custom_attribute_name';
export const IS_VALID_BENCHMARK_NAME = 'valid_benchmark_name';

export const UPDATE_INVITATION = 'update_invitation';
export const RESEND_INVITATION = 'resend_invitation';
export const DELETE_INVITATION = 'delete_invitation';

export const CREATE_BOOKING_CONNECTION = 'create_booking_connection';
export const CREATE_GOOGLE_CONNECTION = 'create_google_connection';
export const FETCH_GOOGLE_AUTH_URL = 'fetch_google_auth_url';
export const DELETE_PROVIDER_ACCOUNT = 'delete_provider_account';
export const DISCONNECT_PROVIDER = 'disconnect_provider';
export const FETCH_PROVIDER_ACCOUNTS = 'provider_accounts';

export const FETCH_SISENSE_JWT_TOKEN = 'fetch_sisense_jWT_token';
export const FETCH_SISENSE_IS_AUTHENTICATED = 'fetch_sisense_authenticated';
export const FETCH_SISENSE_ACCESS_TOKEN = 'fetch_sisense_access_token';
export const FETCH_DASHBOARD_ID = 'fetch_dashboard_id';
export const FETCH_DASHBOARD = 'fetch_dashboard';
export const SET_ROW_LEVEL_SECURITY = 'set_row_level_security';
export const LOGOUT = 'logout';
