import {
  type BookingProviderAccount,
  CONNECT_PROVIDER_NAMES,
  type GoogleProviderAccount,
  type MembershipFromToken,
  type ProviderAccount,
  type Subscription,
  type SubscriptionShort,
  getMembershipIdFromUrl,
  redirectToOriginalMembership,
  useChangelingStore,
  useMembershipStore,
  useOrganizationLogo,
} from '@trustyou/shared';
import { Box, MembershipSelector } from '@trustyou/ui';

type OrganizationsMenuProps = {
  onClick?: () => void;
};

export function OrganizationsMenu({ onClick }: OrganizationsMenuProps) {
  const { membership, setMembership, organizations } = useMembershipStore();
  const membershipIdFromUrl = getMembershipIdFromUrl();
  const { data } = useOrganizationLogo();
  const { isChangeling } = useChangelingStore();

  if (!organizations || !organizations.length) return undefined;

  const changelingLogo = isChangeling ? data?.logo_uri : undefined;

  const onSelect = (value: MembershipFromToken) => {
    onClick?.();
    setMembership(value);
    if (membershipIdFromUrl) {
      redirectToOriginalMembership(value.id as string, { silent: true });
    } else {
      // we don't need to modify url if no organization id in the path. For example, dev tools
      window.location.reload();
    }
  };

  return (
    <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
      <MembershipSelector
        organizations={organizations}
        defaultLogo={changelingLogo}
        selectedMembership={membership?.id}
        onSelect={onSelect}
      />
    </Box>
  );
}

export const isGoogleProviderAccount = (account: ProviderAccount): boolean =>
  Object.prototype.hasOwnProperty.call((account as GoogleProviderAccount).credential, 'name');

export const isBookingProviderAccount = (account: ProviderAccount): boolean =>
  Object.prototype.hasOwnProperty.call(
    (account as BookingProviderAccount).credential,
    'legal_entity_id'
  ) ||
  Object.prototype.hasOwnProperty.call(
    (account as BookingProviderAccount).credential,
    'contact_email'
  );

export const defineProvider = (account: ProviderAccount): CONNECT_PROVIDER_NAMES => {
  if (isGoogleProviderAccount(account)) {
    return CONNECT_PROVIDER_NAMES.GOOGLE;
  }
  if (isBookingProviderAccount(account)) {
    return CONNECT_PROVIDER_NAMES.BOOKING;
  }
  // Placeholder for future provider checks
  // else if (isNewProviderAccount(account)) {
  //   return CONNECT_PROVIDER_NAMES.NEW_PROVIDER;
  // }
  throw new Error('Provider cannot be determined or is not supported.');
};

export const checkOrganizationSubscription = (subscription?: Subscription | SubscriptionShort) => {
  return subscription?.is_org_subscription === true;
};
