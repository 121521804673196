import type {
  CustomAttributeDefinition,
  CustomAttributeDeleteCheckResponse,
  CustomAttributeUpdateCheckResponse,
  EntityCustomAttributesUpdatePayload,
} from '../../../../types/model';
import { $assApi } from '../../../api';
import { CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH, ORGANIZATION_BASE_PATH } from '../../path';

export async function fetchCustomAttributeDefinitions(
  filter: string
): Promise<CustomAttributeDefinition[]> {
  const { data } = await $assApi.get(`${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/get_all${filter}`);
  return data;
}

export async function fetchCustomAttributeDefinition(
  id: string
): Promise<CustomAttributeDefinition> {
  const { data } = await $assApi.get(`${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/get`, {
    params: {
      id,
    },
  });
  return data;
}

export async function isValidCustomAttributeName(
  name: string
): Promise<{ error: string; valid: boolean }> {
  const { data } = await $assApi.get(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/is_valid_custom_attribute_name`,
    {
      params: {
        name,
      },
    }
  );
  return data;
}

export async function createCustomAttributeDefinition(
  customAttribute: Omit<CustomAttributeDefinition, 'id'>
): Promise<CustomAttributeDefinition> {
  const { data } = await $assApi.post(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/add`,
    customAttribute
  );
  return data;
}

export async function updateCustomAttributeDefinition(
  customAttribute: CustomAttributeDefinition
): Promise<CustomAttributeDefinition> {
  const { data } = await $assApi.post(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/update`,
    customAttribute
  );
  return data;
}

export async function deleteCustomAttributeDefinition(id: string): Promise<void> {
  const { data } = await $assApi.delete(`${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/delete`, {
    params: {
      id,
    },
  });
  return data;
}

export async function canMarkAsUniqueCustomAttribute(id: string): Promise<boolean> {
  const { data } = await $assApi.get(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/can_mark_as_unique`,
    {
      params: {
        id,
      },
    }
  );
  return data?.valid;
}

export async function canDeleteCustomAttributeDefinition(
  id: string
): Promise<CustomAttributeDeleteCheckResponse> {
  const { data } = await $assApi.get(`${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/can_delete`, {
    params: {
      id,
    },
  });
  return data;
}

export async function canUpdateCustomAttributeDefinition(
  customAttribute: CustomAttributeDefinition
): Promise<CustomAttributeUpdateCheckResponse> {
  const { data } = await $assApi.post(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/can_update`,
    customAttribute
  );
  return data;
}

export async function updateEntityCustomAttributes(
  payload: EntityCustomAttributesUpdatePayload
): Promise<void> {
  const { data } = await $assApi.post(
    `${ORGANIZATION_BASE_PATH}/set_entity_custom_attributes`,
    payload
  );
  return data.data;
}
