import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  headerContainer: {
    width: 'calc(50% - 38px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButtonIcon: {
    mr: 1,
  },
  addModal: {
    '& .MuiDialog-paper': {
      minWidth: '1024px',
      height: '100%',
    },
  },
};

export default styles;
