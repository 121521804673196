import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { InfoModal, ReportsDrawer, useExportDashboardHandle } from '@trustyou/analytics';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useHasSchedulePermission } from '@trustyou/reports';
import {
  SISENSE_SERVER_PATH,
  trackDashboardLoading,
  useApplySecurityFilters,
  useChangelingStore,
} from '@trustyou/shared';
import { Box, DashboardLoader } from '@trustyou/ui';

import styles from './styles';

type BaseDashboard = {
  dashboardId: string;
  iFrameRef?: React.RefObject<HTMLIFrameElement>;
};

export const BaseDashboard = ({ dashboardId, iFrameRef }: BaseDashboard) => {
  const { isChangeling } = useChangelingStore();
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  const hasSchedulePermission = useHasSchedulePermission();
  const [showReports, setShowReports] = useState<string | undefined>();
  const internalIFrameRef = useRef<HTMLIFrameElement>(null);
  const effectiveIFrameRef = iFrameRef || internalIFrameRef;
  const sisenseFrameRef = useRef<unknown>(null);
  const loadingStartTimeRef = useRef<unknown>(null);

  const { exportDashboard } = useExportDashboardHandle();
  const { applySecurityFilters } = useApplySecurityFilters();

  const navigate = useNavigate();
  const location = useLocation();

  const trackLoadingStart = () => {
    if (!loadingStartTimeRef.current) {
      loadingStartTimeRef.current = new Date();
    }
  };

  const trackLoadingEnd = (id: string, title: string) => {
    trackDashboardLoading(id, title, loadingStartTimeRef.current as Date);
    loadingStartTimeRef.current = null;
  };

  // Navigate to previous page after back button
  const popEventHandler = () => {
    if (window.location.href.includes('/analytics') && !location.state?.path) {
      // Skip sisense jwt route on back button click
      navigate(-2);
    }
  };

  const messageEventHandler = (event: MessageEvent) => {
    if (event.origin !== SISENSE_SERVER_PATH) return;
    if (typeof event.data !== 'string') return;
    if (event.data.includes('dashboardAction')) {
      const split = event.data.split('-');
      const action = split[1];
      const dashboardId = split[2];
      if (action === 'reports') {
        setShowReports(dashboardId);
      } else if (action === 'downloadExcel') {
        exportDashboard(dashboardId, effectiveIFrameRef);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', popEventHandler);
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('popstate', popEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableDashboardActions = () => {
    effectiveIFrameRef.current?.contentWindow?.postMessage(
      {
        action: 'dashboardActions-enable',
        enableReports: hasSchedulePermission,
      },
      SISENSE_SERVER_PATH as string
    );
  };

  const renderDashboard = () => {
    trackLoadingStart();

    // TODO: Investigate why declaration.d.ts is not considered by current tsconfig
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { SisenseFrame } = window['sisense.embed'];

    // Create an instance of SisenseFrame
    const sisenseFrame = new SisenseFrame({
      url: SISENSE_SERVER_PATH,
      dashboard: dashboardId,
      settings: {
        showToolbar: false,
        showLeftPane: false,
        showRightPane: true,
      },
      // Existing iFrame DOM element
      element: effectiveIFrameRef.current,
      // Disable the autosave of user interactions with the dashboard
      // volatile: true,
    });
    sisenseFrameRef.current = sisenseFrame;

    // Calling render() will apply the above configuration to the existing iFrame element
    sisenseFrame.render();
    sisenseFrame.app.on('stateappchanged', trackLoadingStart);

    // When dashboard is loaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sisenseFrame.dashboard.on('dashboardloaded', (args: any) => {
      // Push state to trigger pop state event and skip the empty page (jwt return_to)
      window.history.pushState(null, '', window.location.href);
      applySecurityFilters(args.dashboard.filters, sisenseFrame);
      enableDashboardActions();
      // Some delay to render actions widgets in firefox
      setTimeout(() => {
        enableDashboardActions();
      }, 500);
      setDashboardLoaded(true);
      trackLoadingEnd(args.dashboard.oid, args.dashboard.title);
    });
  };

  return (
    <Box sx={styles.container}>
      {!dashboardLoaded && (
        <DashboardLoader
          message={
            <FormattedMessage
              id="analytics.loadingDashboard"
              defaultMessage="Loading your dashboard..."
            />
          }
        />
      )}
      <iframe
        ref={effectiveIFrameRef}
        data-hj-allow-iframe
        title="Sisense Embed"
        width="80%"
        height="100%"
        style={{
          border: 'none',
          ...(!dashboardLoaded && { display: 'none' }),
        }}
      />
      {hasSchedulePermission && showReports ? (
        <ReportsDrawer
          dashboardId={showReports}
          onClose={() => {
            setShowReports(undefined);
          }}
          changelingMode={isChangeling}
        />
      ) : null}
      <InfoModal />
    </Box>
  );
};
