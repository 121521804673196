import { useQuery } from '@tanstack/react-query';
import { $assApi, type PaginationRequest, SURVEY_BASE_PATH } from '@trustyou/shared';

import type {
  ContentEnum,
  GetDefaultsOut,
  ListQuestionsWithCountOut,
  ListThemesApiSurveyOrganizationIdThemeListGetResponse,
  Question_Output,
} from '../../client';
import type { Content } from '../../types/content';
import { CONTENT_DEFAULTS, CONTENT_LIST, QUESTION_LIST, THEME_LIST } from '../constants/query-keys';

async function fetchQuestionList(
  pagination?: PaginationRequest
): Promise<Question_Output[] | ListQuestionsWithCountOut> {
  // const queryParams = primitive_types
  //   ? primitive_types.map((type) => `primitive_types=${encodeURIComponent(type)}`).join('&')
  //   : '';
  //`${SURVEY_BASE_PATH}/question/list${queryParams ? '?' + queryParams : ''}`

  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/question/list`, {
    params: pagination,
  });
  return data;
}

async function fetchContentList(type: ContentEnum): Promise<Content[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/content/list`, {
    params: {
      type,
    },
  });
  return data;
}

async function fetchMultipleContentLists(types: ContentEnum[]): Promise<Content[]> {
  // Approach A: Multiple requests
  const requests = types?.map((type) => fetchContentList(type));
  const multipleData = await Promise.all(requests);
  return multipleData.flat();

  // TODO: Waiting for backend.
  // Approach B: Multiple values for the same query parameter
  // const params = new URLSearchParams();
  // types.forEach((type) => params.append('type', type));
  // const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/content/list`, { params });
  // return data;
}

async function fetchThemeList(): Promise<ListThemesApiSurveyOrganizationIdThemeListGetResponse> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/theme/list`);
  return data;
}

async function fetchContentDefaults(): Promise<GetDefaultsOut> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/questionnaire/get-defaults`);
  return data;
}

export function useQuestions(pagination?: PaginationRequest) {
  return useQuery({
    queryKey: [QUESTION_LIST, { pagination }],
    queryFn: () => fetchQuestionList(pagination),
  });
}

export function useContents(types: ContentEnum[]) {
  return useQuery({
    queryKey: [CONTENT_LIST, { types }],
    queryFn: () => fetchMultipleContentLists(types),
  });
}

export function useFetchThemeList() {
  return useQuery({
    queryKey: [THEME_LIST],
    queryFn: () => fetchThemeList(),
  });
}

export function useGetContentDefaults() {
  return useQuery({
    queryKey: [CONTENT_DEFAULTS],
    queryFn: fetchContentDefaults,
  });
}
