import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import type { StepperProps } from '@mui/material';
import {
  type CreateSegmentResponse,
  type Segment,
  type SegmentGroupPayload,
  useCreateSegment,
  useMembershipStore,
} from '@trustyou/shared';
import { BackdropSpinner, Box, FullScreenModal, SetupStepper, snackbar } from '@trustyou/ui';

import AddSegmentsForm from './AddSegmentsForm';

import { useDashboardDelayMessage } from '../../../../../hooks';
import { UpdateGroupsForm } from '../Groups/UpdateGroups';
import styles from './styles';

type AddSegmentsProps = {
  onClose: () => void;
  onSegmentCreated: (data: CreateSegmentResponse) => void;
};

const steps = defineMessages({
  1: {
    id: 'segment.create.add.name',
    defaultMessage: 'Name segment',
  },
  2: {
    id: 'segment.create.add.group',
    defaultMessage: 'Add group',
  },
});

const AddSegments = ({ onClose, onSegmentCreated }: AddSegmentsProps) => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();

  const [activeStep, setActiveStep] = useState<StepperProps['activeStep']>(0);
  const [segment, setSegment] = useState<Partial<Segment>>({ name: '' });
  const { membership } = useMembershipStore();
  const createSegment = useCreateSegment();

  const goBack = () => {
    setActiveStep(0);
  };

  const onNameSave = (value: Partial<Segment>) => {
    setSegment(value);
    setActiveStep(1);
  };

  const onSave = (data: SegmentGroupPayload) => {
    createSegment.mutate(
      {
        organization_id: membership?.organizationId as string,
        name: segment?.name as string,
        groups: [data],
      },
      {
        onSuccess: (data) => {
          snackbar.success(
            intl.formatMessage(
              {
                id: 'organization.segments.created',
                defaultMessage: '{segment} added. {delay}.',
              },
              { segment: data.name, delay: dashboardDelayMessage }
            )
          );
          onSegmentCreated(data);
          onClose();
        },
      }
    );
  };

  return (
    <FullScreenModal open onClose={onClose}>
      <SetupStepper sx={styles.stepper} steps={steps} activeStep={activeStep} />
      <Box sx={styles.container}>
        {activeStep === 0 && <AddSegmentsForm segment={segment} onSave={onNameSave} />}
        {activeStep === 1 && (
          <UpdateGroupsForm
            segment={segment}
            onSave={onSave}
            onGoBack={goBack}
            dataName="add-segment"
          />
        )}
      </Box>
      <BackdropSpinner isLoading={createSegment.isPending} />
    </FullScreenModal>
  );
};

export default AddSegments;
