import type { UserScopeEntity } from './user';

export interface CheckValidName {
  name: string;
}

export interface NameIsValid {
  valid: boolean;
}

export interface PaginationRequest {
  limit: number;
  offset: number;
}

export interface Pagination extends PaginationRequest {
  total: number;
}

export interface PaginationResponse<T> {
  data: T[];
  pagination: Pagination;
}

export enum USER_ROLES {
  OWNER = 'owner',
  ADMIN = 'admin',
  EDITOR = 'editor',
  READ_ONLY = 'read_only',
  SERVICE = 'service',
}

export enum USER_SCOPES {
  BY_ENTITIES = 'by_entities',
  BY_GROUPS = 'by_groups',
  ALL_ENTITIES = 'all_entities',
  ACCESS_GROUP = 'by_access_group',
  ADVANCED_SELECTION = 'advanced_selection',
  NONE = 'none',
}

export enum USER_STATUSES {
  ACTIVE = 'active',
  INVITED = 'invited',
}

export interface Owner {
  id: string;
  scope_id: string;
  name: string;
  email: string;
  role: USER_ROLES;
  status: string;
}

export interface ImpersonatePayload {
  organization_id: string;
  subscription_id: string;
  membership_id: string;
  scope_id: string;
  role: USER_ROLES;
  organization_name: string;
  subscription_name: string;
  onboarded?: boolean;
  user_name?: string;
  user_email?: string;
  plan: string;
  scope_entities?: UserScopeEntity[];
}

export type SelectionMode = 'single' | 'multiple';
