import type { TypographyProps } from '@mui/material';
import { Typography } from '@trustyou/ui';

export function SurveySectionTitle({ children, ...props }: TypographyProps) {
  const { sx } = props;
  return (
    <Typography
      component="h2"
      variant="h6"
      sx={{
        fontWeight: 400,
        textAlign: 'center',
        paddingBlockStart: 5,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}
