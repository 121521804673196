import {
  UPDATE_ENTITY_PROTECTED_CUSTOM_ATTRIBUTES_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';

const useUpdateProtectedCustomAttributes = () => {
  const { hasPermissions } = usePermissionStore();
  return hasPermissions(UPDATE_ENTITY_PROTECTED_CUSTOM_ATTRIBUTES_PERMISSION);
};

export default useUpdateProtectedCustomAttributes;
