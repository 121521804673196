import { FormattedMessage } from 'react-intl';

import {
  downloadCSV,
  introductionInstructions,
  useDownloadTemplate,
  useMembershipStore,
} from '@trustyou/shared';
import { Box, Typography } from '@trustyou/ui';

import CsvDownload from './CsvDownload';
import InfoList from './InfoList';
import ProTips from './ProTips';

import { Footer } from '../Footer';
import styles from './styles';

const infoList = [
  {
    id: 1,
    messageId: introductionInstructions.downloadCustomized.id,
    defaultMessage: introductionInstructions.downloadCustomized.defaultMessage,
  },
  {
    id: 2,
    messageId: introductionInstructions.fillNewEntities.id,
    defaultMessage: introductionInstructions.fillNewEntities.defaultMessage,
  },
  {
    id: 3,
    messageId: introductionInstructions.saveAs.id,
    defaultMessage: introductionInstructions.saveAs.defaultMessage,
  },
];

const SETUP_SHEET_DOWNLOAD_NAME = 'Setup Sheet.csv';

type AdditionalEntitiesIntroductionViewProps = {
  onClickBack: () => void;
  onClickNext: () => void;
};

export default function AdditionalEntitiesIntroductionView({
  onClickBack,
  onClickNext,
}: AdditionalEntitiesIntroductionViewProps) {
  const { membership } = useMembershipStore();
  const downloadTemplate = useDownloadTemplate();

  const onDownloadSetupSheet = () => {
    downloadTemplate.mutate(membership?.organizationId ?? '', {
      onSuccess: (data) => {
        downloadCSV(SETUP_SHEET_DOWNLOAD_NAME, data);
      },
    });
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box>
          <Typography variant="h6">
            <FormattedMessage
              id="entitiesImporter.importNewEntities"
              defaultMessage="Import new entities"
            />
          </Typography>
          <Typography variant="subtitle2" sx={styles.infoSubHeader}>
            <FormattedMessage
              id="entitiesImporter.importNewEntitiesSubHeading"
              defaultMessage="To import additional entities, use the provided spreadsheet that contains the custom attributes from your first data import. Follow these steps to upload new entities:"
            />
          </Typography>
          <InfoList list={infoList} />
          <CsvDownload onDownload={onDownloadSetupSheet} />
        </Box>
        <ProTips />
      </Box>
      <Footer onClickBack={onClickBack} onClickNext={onClickNext} />
    </>
  );
}
