import { useQuery } from '@tanstack/react-query';
import {
  $assApi,
  GET_USERS_PERMISSION,
  USERS_BASE_PATH,
  usePermissionStore,
} from '@trustyou/shared';

import { RECIPIENTS } from '../../constants/query-keys';
import type { Recipient } from '../../types/recipient';

export async function fetchRecipients(searchKey?: string): Promise<Recipient[]> {
  const { data } = await $assApi.get(`${USERS_BASE_PATH}/get_combined_users_and_invitations`, {
    params: {
      offset: 0,
      limit: 50,
      order: 'name',
      ...(searchKey && { q: searchKey }),
    },
  });
  return data.data;
}

export function useRecipients(searchKey?: string) {
  const { hasPermissions } = usePermissionStore();
  const hasAPIPermissions = hasPermissions(GET_USERS_PERMISSION);
  return useQuery({
    queryKey: [RECIPIENTS, searchKey],
    queryFn: () => (hasAPIPermissions ? fetchRecipients(searchKey) : []),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
