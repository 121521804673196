import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { isTemporaryHidden } from '@trustyou/shared';
import { Tab, Tabs } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';
import styles from './styles';

const tabs = [
  {
    value: 'dashboard-report',
    label: <FormattedMessage {...commonMessages.dashboardReports} />,
  },
];

if (!isTemporaryHidden('CXP-customers-inbox-reports')) {
  tabs.push({
    value: 'inbox-report',
    label: <FormattedMessage {...commonMessages.inboxReports} />,
  });
}

export const ReportViewSelector = () => {
  const navigate = useNavigate();
  const newCreatedReportTypeExist =
    !!window.location.href.includes('one-time') || !!window.location.href.includes('scheduled');
  const isInboxReport = window.location.href.includes('inbox-report');

  const [tabValue, setTabValue] = useState('dashboard-report');

  useEffect(() => {
    if (isInboxReport && !newCreatedReportTypeExist) {
      navigate('inbox-report');
      setTabValue('inbox-report');
    } else if (isInboxReport && newCreatedReportTypeExist) {
      setTabValue('inbox-report');
    } else if (!newCreatedReportTypeExist) {
      navigate('dashboard-report');
      setTabValue('dashboard-report');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      value={tabValue}
      sx={styles.reportViewSelector}
      onChange={(_, value) => {
        setTabValue(value);
        navigate(value);
      }}
    >
      {tabs.map((tab) => (
        <Tab value={tab.value} key={tab.value} label={tab.label} />
      ))}
    </Tabs>
  );
};
