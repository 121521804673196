import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useCanCustomAttributeMarkAsUnique } from '@trustyou/shared';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  StyledFontAwesomeIcon as Icon,
  Stack,
  Tooltip,
} from '@trustyou/ui';

import { manageDrawer } from '../../constants/messages/customAttributes';
import { styles } from './styles';

export const UniqueController = () => {
  const { control } = useFormContext();
  const { data: canMarkAsUnique } = useCanCustomAttributeMarkAsUnique(
    !control._defaultValues.unique ? control._defaultValues.id : undefined
  );
  return (
    <FormControl>
      <Stack sx={styles.checkboxControllerWrapper}>
        <Controller
          name="unique"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={
                    !control._defaultValues.unique && control._defaultValues.id && !canMarkAsUnique
                  }
                />
              }
              label={<FormattedMessage {...manageDrawer.uniqueLabel} />}
              sx={styles.checkboxFormControlLabel}
            />
          )}
        />
        <Tooltip placement="top" arrow title={<FormattedMessage {...manageDrawer.uniqueInfo} />}>
          <Icon icon={faInfoCircle} sx={{ color: 'text.secondary' }} />
        </Tooltip>
      </Stack>
      {canMarkAsUnique === false && (
        <FormHelperText sx={styles.checkboxHelperText}>
          <FormattedMessage {...manageDrawer.hasValuesAssigned} />
        </FormHelperText>
      )}
    </FormControl>
  );
};
