import { defineMessages, useIntl } from 'react-intl';

import { Box } from '@mui/material';

import bubblesBlue from '../../assets/logos/trustyou-bubbles-blue-logo.svg';
import bubblesWhite from '../../assets/logos/trustyou-bubbles-white-logo.svg';
import fullBlue from '../../assets/logos/trustyou-full-blue-logo.svg';
import fullWhite from '../../assets/logos/trustyou-full-white-logo.svg';

const altMessages = defineMessages({
  bubblesBlue: {
    id: 'logo.alt.bubbles-blue',
    defaultMessage: 'TrustYou bubbles blue logo',
  },
  bubblesWhite: {
    id: 'logo.alt.bubbles-white',
    defaultMessage: 'TrustYou bubbles white logo',
  },
  fullBlue: {
    id: 'logo.alt.full-blue',
    defaultMessage: 'TrustYou full blue logo',
  },
  fullWhite: {
    id: 'logo.alt.full-white',
    defaultMessage: 'TrustYou full white logo',
  },
});

export type TrustYouLogoProps = {
  variant: 'bubbles' | 'full';
  color: 'blue' | 'white';
};

export function TrustYouLogo({ variant, color }: TrustYouLogoProps) {
  const intl = useIntl();

  const LOGO_MAPPING = {
    full: {
      blue: {
        icon: fullBlue,
        alt: intl.formatMessage(altMessages.fullBlue),
      },
      white: {
        icon: fullWhite,
        alt: intl.formatMessage(altMessages.fullWhite),
      },
    },
    bubbles: {
      blue: {
        icon: bubblesBlue,
        alt: intl.formatMessage(altMessages.bubblesBlue),
      },
      white: {
        icon: bubblesWhite,
        alt: intl.formatMessage(altMessages.bubblesWhite),
      },
    },
  };

  return (
    <Box
      component="img"
      src={LOGO_MAPPING[variant][color].icon}
      alt={LOGO_MAPPING[variant][color].alt}
    />
  );
}
