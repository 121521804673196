import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { FETCH_INFINITE_ENTITIES, useScopeStore } from '@trustyou/shared';

import { useCurrentViewEntitiesQuery } from './use-current-view-entities-query';
import { useRestrictedScopeEntitiesQuery } from './use-restricted-scope-entities-query';

export function useEntityFilterSelection() {
  const queryClient = useQueryClient();
  const { selectedEntities, setSelectedEntities } = useScopeStore();

  const restrictedScopeEntitiesQuery = useRestrictedScopeEntitiesQuery();
  const currentViewEntitiesQuery = useCurrentViewEntitiesQuery();

  useEffect(() => {
    const restrictedScopeEntities = restrictedScopeEntitiesQuery.data ?? [];
    const currentViewEntities = currentViewEntitiesQuery.data ?? [];
    setSelectedEntities(
      currentViewEntities
        ? Array.from(new Set(currentViewEntities))
        : Array.from(new Set(restrictedScopeEntities))
    );
    return () => {
      queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictedScopeEntitiesQuery.data, currentViewEntitiesQuery.data]);

  return {
    selectedEntities,
    setSelectedEntities,
    restrictedScopeEntitiesQuery,
    currentViewEntitiesQuery,
  };
}
