import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  type AccessGroupCreateUpdatePayload,
  type AdvancedSelectionScope,
  type BasicEntity,
  getParsedScopeRules,
  getSavingAdvancedSelectionScope,
  isValidAdvancedSelectionScope,
  useAccessGroupDetails,
  useBasicEntities,
  useCreateUpdateAccessGroup,
  useRouteUtils,
  useScopeStore,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  ContentPane,
  DiscardChangesModal,
  Typography,
  snackbar,
} from '@trustyou/ui';

import AccessGroupName from './AccessGroupName';
import { AccessOptionSelection } from './AccessOptionSelection';
import { EntitySelection } from './EntitySelection';
import { Footer } from './Footer';
import { GroupSelection } from './GroupSelection';

import { AdvancedScopeSelection } from '../../../../../components';
import styles from './styles';

type Form = {
  name: string;
  accessOption?: string;
  selectedEntities: BasicEntity[];
  advancedSelectionScope?: AdvancedSelectionScope;
};

type CreateUpdateAccessGroupProps = {
  onLeave?: () => void;
};

export function CreateUpdateAccessGroup({ onLeave }: CreateUpdateAccessGroupProps) {
  const intl = useIntl();
  const isModal = !!onLeave;
  const { goUp } = useRouteUtils();
  const { accessGroupId } = useParams();
  const { selectedGroups, setSelectedGroups } = useScopeStore();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [validated, setValidated] = useState(false);
  const { data: accessGroup, isFetching: isLoadingAccessGroup } =
    useAccessGroupDetails(accessGroupId);
  const scopeAdditionalEntityIds =
    (!isLoadingAccessGroup && accessGroup?.mel?.additional_entities) || [];

  const { data: scopeAdditionalEntities = [] } = useBasicEntities(
    scopeAdditionalEntityIds,
    !!scopeAdditionalEntityIds.length
  );

  const editMode = accessGroupId !== undefined;

  const methods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      selectedEntities: [],
    },
  });

  const {
    trigger,
    getValues,
    setValue,
    formState: { isDirty, errors },
    reset,
    watch,
  } = methods;

  const [accessOption, selectedEntities, advancedSelectionScope] = watch([
    'accessOption',
    'selectedEntities',
    'advancedSelectionScope',
  ]);

  useEffect(() => {
    if (!isLoadingAccessGroup && accessGroup) {
      const defaultValues: Form = {
        name: accessGroup.name,
        selectedEntities: accessGroup.entities || [],
      };
      if (accessGroup?.entities.length) {
        defaultValues.accessOption = 'entities';
      } else if (accessGroup?.groups.length) {
        defaultValues.accessOption = 'segments';
      } else if (accessGroup?.mel) {
        defaultValues.accessOption = 'rules';
        defaultValues.advancedSelectionScope = {
          rules: getParsedScopeRules(accessGroup.mel.rule),
          additional_entities: accessGroup.mel.additional_entities.map((id) => ({
            id,
            //These values will be filled with scopeAdditionalEntities data
            name: '',
            country_name: '',
            city: '',
          })),
        };
      }
      reset(defaultValues);
      setSelectedGroups(accessGroup?.groups || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGroup, isLoadingAccessGroup]);

  useEffect(() => {
    if (accessGroup?.mel?.additional_entities.length && scopeAdditionalEntities.length) {
      setValue('advancedSelectionScope', {
        rules: getParsedScopeRules(accessGroup.mel.rule),
        additional_entities: scopeAdditionalEntities,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeAdditionalEntities]);

  const createUpdateAccessGroup = useCreateUpdateAccessGroup();
  const isSaving = createUpdateAccessGroup.isPending;

  const validateForm = async () => {
    let isFormValid = !Object.keys(errors).length;
    if (isFormValid) {
      isFormValid = await trigger();
    }
    if (
      !isFormValid ||
      !accessOption ||
      (accessOption === 'entities' && !selectedEntities.length) ||
      (accessOption === 'segments' && !selectedGroups.length) ||
      (accessOption === 'rules' && !isValidAdvancedSelectionScope(advancedSelectionScope))
    ) {
      return false;
    }
    return true;
  };

  const onSave = async () => {
    const isFormValid = await validateForm();
    setValidated(true);
    if (!isFormValid) {
      return;
    }
    const savingAccessGroup: AccessGroupCreateUpdatePayload = {
      all: false,
      name: getValues('name'),
      entity_ids: accessOption === 'entities' ? selectedEntities.map((entity) => entity.id) : [],
      group_ids: accessOption === 'segments' ? selectedGroups.map((group) => group.id) : [],
      mel:
        accessOption === 'rules' ? getSavingAdvancedSelectionScope(advancedSelectionScope) : null,
    };
    if (accessGroupId) {
      savingAccessGroup.id = accessGroupId;
    }
    createUpdateAccessGroup.mutate(savingAccessGroup, {
      onSuccess: () => {
        cancelSave();
        snackbar.success(
          intl.formatMessage({
            id: 'organization.users.changesSaved',
            defaultMessage: 'Changes saved',
          })
        );
      },
    });
  };

  const handleCancel = () => {
    if (isDirty) {
      setShowCancelConfirmation(true);
    } else {
      cancelSave();
    }
  };

  const cancelSave = () => {
    if (isModal) {
      onLeave();
    } else {
      goUp(editMode ? 2 : 1);
    }
  };

  return (
    <ContentPane
      headers={[
        <Typography variant="h6">
          {editMode ? (
            <FormattedMessage id="access-group.edit-details" defaultMessage="Edit details" />
          ) : (
            <FormattedMessage
              id="access-group.add-access-group"
              defaultMessage="Add access group"
            />
          )}
        </Typography>,
      ]}
      navigationHandle={!isModal ? handleCancel : undefined}
      headerStyles={isModal ? { pl: 3 } : {}}
      sx={styles.contentPane}
      bodyStyles={styles.contentPaneBody}
    >
      <Box sx={styles.body}>
        <FormProvider {...methods}>
          <AccessGroupName />
          <AccessOptionSelection validated={validated} />
          {accessOption === 'entities' && <EntitySelection validated={validated} />}
          {accessOption === 'segments' && <GroupSelection validated={validated} />}
          {accessOption === 'rules' && (
            <AdvancedScopeSelection
              validated={validated}
              scope={advancedSelectionScope}
              setScope={(value) => setValue('advancedSelectionScope', value, { shouldDirty: true })}
            />
          )}
        </FormProvider>
      </Box>
      <Footer actionMode={editMode ? 'update' : 'create'} onCancel={handleCancel} onSave={onSave} />
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={cancelSave}
        open={showCancelConfirmation}
      />
      <BackdropSpinner isLoading={isSaving || isLoadingAccessGroup} />
    </ContentPane>
  );
}
