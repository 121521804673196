import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { EMAIL_VALIDATION, validationErrors } from '@trustyou/shared';
import { Box, FormControl, TextField, Typography } from '@trustyou/ui';

import styles from './styles';

export type UserEmailForm = {
  email: string;
};

const UserEmail = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  return (
    <Box sx={styles.emailContainer}>
      <Typography variant="subtitle1">
        <FormattedMessage
          id="organization.users.enterEmailAddress"
          defaultMessage="Enter email address"
        />
      </Typography>
      <FormControl>
        <Controller
          name="email"
          control={control}
          rules={{
            required: intl.formatMessage(validationErrors.invalidEmail),
            pattern: {
              value: EMAIL_VALIDATION,
              message: intl.formatMessage(validationErrors.invalidEmail),
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={intl.formatMessage({
                id: 'organization.users.emailAddress',
                defaultMessage: 'Email address',
              })}
              id="email"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              type="email"
              disabled={!!control._defaultValues.email}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default UserEmail;
