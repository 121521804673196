import React from 'react';

import { faEllipsis } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, StyledFontAwesomeIcon, TrustYouLogo } from '@trustyou/ui';

import styles from './styles';

export interface ConnectToExternalAPIProps {
  srcExt: string;
  altExt: string;
}

export const ConnectToExternalAPI = ({ srcExt, altExt }: ConnectToExternalAPIProps) => (
  <Box sx={styles.wrapper}>
    <TrustYouLogo variant="bubbles" color="blue" />
    <StyledFontAwesomeIcon size="xl" icon={faEllipsis} />
    <Box component="img" src={srcExt} alt={altExt} />
  </Box>
);
