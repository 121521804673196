import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useOrgCenter } from '../../../../hooks';
import type { PaginationRequest } from '../../../../types/model/shared';
import type {
  InviteOwner,
  InviteServiceUser,
  InviteUser,
  ResendInvitation,
} from '../../../../types/model/user';
import {
  CREATE_USER,
  DELETE_INVITATION,
  DELETE_USER,
  FETCH_COMBINED_ORGANIZATION_USERS,
  FETCH_GLOBAL_ORGANIZATION_USERS,
  FETCH_GLOBAL_SERVICE_USERS,
  FETCH_GLOBAL_USERS,
  FETCH_SPECIFIC_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_MEMBERS,
  FETCH_USERS_BY_ORGANIZATION,
  FETCH_USER_SETTINGS,
  RESEND_INVITATION,
  UPDATE_INVITATION,
  UPDATE_USER,
  UPDATE_USER_SETTINGS,
} from '../../../constants/queryKeys';
import {
  deleteInvitation,
  deleteServiceUser,
  deleteUser,
  fetchCombinedUsersByOrganization,
  fetchCombinedUsersByOrganizationAdmin,
  fetchGlobalOrganizationUsers,
  fetchGlobalServiceUsers,
  fetchGlobalUsers,
  fetchUserSettings,
  fetchUsersByOrganization,
  inviteOwner,
  inviteServiceUser,
  inviteUser,
  resendInvitation,
  updateInvitation,
  updateUser,
  updateUsersSettings,
} from '../../../queries';
import { isOperationsRealm } from '../../../utils/misc';

export function useDeleteUser({
  shouldRefetchSubscriptionMembers,
}: {
  shouldRefetchSubscriptionMembers?: boolean;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteUser,
    mutationKey: [DELETE_USER],
    onSuccess: (data: string) => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
      if (shouldRefetchSubscriptionMembers) {
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_MEMBERS] });
        queryClient.refetchQueries({ queryKey: [FETCH_SPECIFIC_SUBSCRIPTION] });
      }
    },
  });
}

export function useDeleteServiceUser(
  onSuccess: (data: string) => void,
  onError?: (error: Error) => void,
  onSettled?: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: string) => deleteServiceUser(userId),
    mutationKey: [DELETE_USER],
    onSuccess: (data: string) => {
      queryClient.refetchQueries({ queryKey: [FETCH_GLOBAL_SERVICE_USERS] });
      onSuccess(data);
    },
    onError,
    onSettled,
  });
}

export function useInviteUser({
  shouldRefetchSubscriptionMembers,
}: {
  shouldRefetchSubscriptionMembers?: boolean;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: InviteUser) => inviteUser(user),
    mutationKey: [CREATE_USER],
    onSuccess: (data: InviteUser) => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
      if (shouldRefetchSubscriptionMembers) {
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_MEMBERS] });
      }
    },
  });
}

export function useInviteOwner() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: InviteOwner) => inviteOwner(user),
    mutationKey: [CREATE_USER],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
    },
  });
}

export function useInviteServiceUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: InviteServiceUser) => inviteServiceUser(user),
    mutationKey: [CREATE_USER],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_GLOBAL_SERVICE_USERS] });
    },
  });
}

export function useUpdateUser({
  shouldRefetchSubscriptionMembers,
}: {
  shouldRefetchSubscriptionMembers?: boolean;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateUser,
    mutationKey: [UPDATE_USER],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
      if (shouldRefetchSubscriptionMembers) {
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_MEMBERS] });
      }
    },
  });
}

// TODO: I'm not used, remove me?
export function useGlobalUsers() {
  return useQuery({
    queryKey: [FETCH_GLOBAL_USERS],
    queryFn: fetchGlobalUsers,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useUsersByOrganization(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
  // options: UseQueryOptions<UsersResponse, unknown, UsersResponse, QueryKey> = {}
) {
  return useQuery({
    queryKey: [FETCH_USERS_BY_ORGANIZATION, { pagination, sortKey, searchKey, organizationId }],
    queryFn: () => fetchUsersByOrganization(pagination, sortKey, searchKey, organizationId),
    refetchOnWindowFocus: false,
    enabled: true,
    // ...options,
  });
}

export function useUpdateUserSettings() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUsersSettings,
    mutationKey: [UPDATE_USER_SETTINGS],
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
}

export function useFetchUserSettings(enabled = true) {
  const orgCenter = useOrgCenter();

  return useQuery({
    queryKey: [FETCH_USER_SETTINGS],
    queryFn: fetchUserSettings,
    refetchOnWindowFocus: false,
    enabled: enabled && !!orgCenter,
  });
}

// TODO: I'm not used, remove me?
export function useTeamMembers(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string,
  organizationId?: string
) {
  return useQuery({
    queryKey: [FETCH_USERS_BY_ORGANIZATION, { pagination, sortKey, searchKey, organizationId }],
    queryFn: () => fetchGlobalOrganizationUsers(pagination, sortKey, searchKey, organizationId),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useGlobalOrganizationUsers(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_GLOBAL_ORGANIZATION_USERS, { pagination, sortKey, searchKey }],
    queryFn: () => fetchGlobalOrganizationUsers(pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useGlobalServiceUsers(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_GLOBAL_SERVICE_USERS, { pagination, sortKey, searchKey }],
    queryFn: () => fetchGlobalServiceUsers(pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useCombinedUsersByOrganization(
  pagination: PaginationRequest,
  sortKey: string,
  searchFields?: Record<string, string | undefined>,
  organizationId?: string
) {
  return useQuery({
    queryKey: [
      FETCH_COMBINED_ORGANIZATION_USERS,
      { organizationId, pagination, sortKey, searchFields },
    ],
    queryFn: () => {
      if (organizationId == null) {
        // Throw an error or return a default value. This code won't be executed if `enabled` is false.
        throw new Error('Entity ID is missing');
      }
      return isOperationsRealm()
        ? fetchCombinedUsersByOrganizationAdmin(
            organizationId,
            pagination,
            sortKey,
            searchFields?.searchKey
          )
        : fetchCombinedUsersByOrganization(organizationId, pagination, sortKey, searchFields);
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(organizationId),
  });
}

export function useUpdateInvitation({
  shouldRefetchSubscriptionMembers,
}: {
  shouldRefetchSubscriptionMembers?: boolean;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateInvitation,
    mutationKey: [UPDATE_INVITATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
      if (shouldRefetchSubscriptionMembers) {
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_MEMBERS] });
      }
    },
  });
}

export function useResendInvitation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ResendInvitation) => resendInvitation(payload.invitation_id),
    mutationKey: [RESEND_INVITATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
    },
  });
}

export function useDeleteInvitation({
  shouldRefetchSubscriptionMembers,
}: {
  shouldRefetchSubscriptionMembers?: boolean;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteInvitation,
    mutationKey: [DELETE_INVITATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_COMBINED_ORGANIZATION_USERS] });
      if (shouldRefetchSubscriptionMembers) {
        queryClient.refetchQueries({ queryKey: [FETCH_SUBSCRIPTION_MEMBERS] });
      }
    },
  });
}
