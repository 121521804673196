import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { type Subscription, commonFields, useEditSubscription } from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  snackbar,
} from '@trustyou/ui';
import * as yup from 'yup';

import { styles } from './styles';

type EditSegmentsProps = {
  onClose: () => void;
  subscription?: Subscription;
};

export default function EditSubscription({ subscription, onClose }: EditSegmentsProps) {
  const intl = useIntl();
  const editSubscription = useEditSubscription();

  const schema = yup.object().shape({
    name: yup.string().required(),
  });
  const { register, handleSubmit, formState, setValue, reset } = useForm<Partial<Subscription>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: subscription,
  });

  useEffect(() => {
    if (subscription) {
      reset();
      setValue('name', subscription.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  const onSubmit = (data: Partial<Subscription>) => {
    editSubscription.mutate(
      {
        subscription_id: subscription?.id as string,
        name: data.name as string,
      },
      {
        onSuccess: () => {
          snackbar.success(intl.formatMessage(commonFields.changesSaved));
          onClose();
        },
      }
    );
  };

  return (
    <Dialog open={!!subscription} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="h6">
          <FormattedMessage
            id="subscription.edit-subscription"
            defaultMessage="Edit subscription"
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="subscription.name-of-subscription"
            defaultMessage="Name of subscription"
          >
            {(message) => (
              <TextField
                sx={styles.nameField}
                data-testid="subscription-name"
                label={message}
                variant="standard"
                {...register('name')}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message}
              />
            )}
          </FormattedMessage>
          <BackdropSpinner isLoading={editSubscription.isPending} />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-edit-subscription" onClick={onClose} color="inherit">
            <FormattedMessage {...commonFields.cancel} />
          </Button>
          <Button
            data-testid="accept-edit-segment"
            disabled={!formState.isValid}
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="subscription.save-button" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
