import { type IntlShape, useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  DELETE_ENTITY_PERMISSION,
  ENTITY_GLOBAL_ATTRIBUTES,
  type Entity,
  commonFields,
  useAttributes,
  usePermissionStore,
} from '@trustyou/shared';
import { Typography } from '@trustyou/ui';

import EntityActionCell from './EntityActionCell';
import NameCell from './NameCell';

import styles from './styles';

const renderCell = ({ field, row }: GridCellParams) => (
  <Typography variant="body2" sx={styles.renderCell}>
    {row[field]}
  </Typography>
);

const columns = (intl: IntlShape): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      renderCell: ({ row }: GridCellParams) => <NameCell name={row.name} sector={row.sector} />,
      hideable: false,
      disableColumnMenu: true,
      width: 350,
    },
    {
      field: 'brand',
      headerName: intl.formatMessage(commonFields.brand),
      renderCell,
      minWidth: 300,
    },
    {
      field: 'continent',
      headerName: intl.formatMessage(commonFields.continent),
      renderCell,
      minWidth: 200,
    },
    {
      field: 'country_name',
      headerName: intl.formatMessage(commonFields.country),
      renderCell,
      minWidth: 200,
    },
    {
      field: 'zip',
      headerName: intl.formatMessage(commonFields.zip),
      renderCell,
      minWidth: 100,
    },
    {
      field: 'city',
      headerName: intl.formatMessage(commonFields.city),
      renderCell,
      minWidth: 100,
    },
    {
      field: 'address',
      headerName: intl.formatMessage(commonFields.address),
      renderCell,
      minWidth: 200,
    },
  ];
};

export interface EntitiesColumnsProps {
  handleDelete?: (entity: Entity) => void;
  actionsDisabled?: boolean;
}

const useEntitiesTableColumns = ({
  handleDelete,
  actionsDisabled,
}: EntitiesColumnsProps): GridColDef[] => {
  const intl = useIntl();
  const { hasPermissions } = usePermissionStore();
  const isDeleteEntityAllowed = hasPermissions(DELETE_ENTITY_PERMISSION);
  const { data: attributes } = useAttributes();

  const localAttributeColumns = (attributes || [])
    .filter((attribute) => !ENTITY_GLOBAL_ATTRIBUTES.includes(attribute))
    .map((field) => ({
      field,
      headerName: field,
      minWidth: 200,
      renderCell: ({ row }: GridCellParams) => {
        const value = row.custom_attributes[field];
        return (
          <Typography variant="body2" sx={styles.renderCell}>
            {Array.isArray(value) ? value.join(', ') : value}
          </Typography>
        );
      },
    }));

  if (isDeleteEntityAllowed && handleDelete && !actionsDisabled) {
    const actionColumn: GridColDef = {
      field: 'action',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams) => (
        <EntityActionCell entity={row} handleDelete={handleDelete} />
      ),
    };

    return [...columns(intl), ...localAttributeColumns, actionColumn];
  }

  return [...columns(intl), ...localAttributeColumns];
};

export default useEntitiesTableColumns;
