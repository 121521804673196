import type { StackProps } from '@mui/material';
import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  IconButton,
  type IconButtonProps,
  StyledFontAwesomeIcon,
  TrustYouLogo,
  type TrustYouLogoProps,
  Typography,
} from '@trustyou/ui';

export interface DrawerHeaderProps {
  logoColor: TrustYouLogoProps['color'];
  onClick: () => void;
  onClose?: IconButtonProps['onClick'];
  sx?: StackProps['sx'];
}

export function DrawerHeader({ logoColor, onClose, onClick, sx }: DrawerHeaderProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: 2, ...sx }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer' }}
        onClick={onClick}
      >
        <TrustYouLogo variant="bubbles" color={logoColor} />
        <Typography fontWeight={500}>TrustYou</Typography>
      </Box>
      <Box flexGrow={1} />
      {onClose && (
        <IconButton title="close drawer" onClick={onClose}>
          <StyledFontAwesomeIcon icon={faXmark} size="sm" />
        </IconButton>
      )}
    </Box>
  );
}
