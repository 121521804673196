import type { Benchmark } from '@trustyou/shared';
import { create } from 'zustand';

interface BenchmarkStore {
  selectedBenchmark?: Benchmark;
  setSelectedBenchmark: (benchmark?: Benchmark) => void;
  editableBenchmark?: Benchmark;
  setEditableBenchmark: (benchmark?: Benchmark) => void;
  deletableBenchmark?: Benchmark;
  setDeletableBenchmark: (benchmark?: Benchmark) => void;
  isCreateBenchmarkDrawerOpen?: boolean;
  showCreateBenchmarkDrawer: () => void;
  closeManageDrawer: () => void;
  closeDeleteConfirmation: () => void;
}

const useBenchmarkStore = create<BenchmarkStore>((set) => ({
  setSelectedBenchmark: (selectedBenchmark) => set(() => ({ selectedBenchmark })),
  setEditableBenchmark: (editableBenchmark) => set(() => ({ editableBenchmark })),
  setDeletableBenchmark: (deletableBenchmark) => set(() => ({ deletableBenchmark })),
  showCreateBenchmarkDrawer: () => set(() => ({ isCreateBenchmarkDrawerOpen: true })),
  closeManageDrawer: () =>
    set(() => ({ isCreateBenchmarkDrawerOpen: false, editableBenchmark: undefined })),
  closeDeleteConfirmation: () => set(() => ({ deletableBenchmark: undefined })),
}));

export default useBenchmarkStore;
