import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormControlProps, SxProps, Theme } from '@mui/material';
import {
  Asterisk,
  FormHelperText,
  InputLabel,
  MenuItem,
  StyledFormControl,
  TextField,
} from '@trustyou/ui';

type Option = {
  value: string;
  label: string;
};

export type DropdownFormProps = {
  name: string;
  options: Option[];
  label?: ReactNode;
  required?: boolean;
  placeholder?: string;
  size?: FormControlProps['size'];
  fullWidth?: FormControlProps['fullWidth'];
  error?: FormControlProps['error'];
  helperText?: ReactNode;
  sx?: SxProps<Theme>;
};

export const DropdownFormField = ({
  name,
  options,
  label,
  required = false,
  placeholder,
  size,
  fullWidth,
  error,
  helperText,
  sx,
}: DropdownFormProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <StyledFormControl fullWidth>
          {label && (
            <InputLabel shrink htmlFor={name}>
              {label}
              {required && <Asterisk />}
            </InputLabel>
          )}
          <TextField
            select
            aria-labelledby={name}
            placeholder={placeholder}
            id={name}
            size={size}
            fullWidth={fullWidth}
            value={value}
            onChange={onChange}
            error={error}
            sx={sx}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                data-gtm-class={`inbox_settings_${name}`}
                data-gtm-id={option.label.toLowerCase()}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </StyledFormControl>
      )}
    />
  );
};
