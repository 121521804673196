import { FormattedMessage } from 'react-intl';

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, Spacer, StyledFontAwesomeIcon } from '@trustyou/ui';

export function CustomToolbar({ newDataPointClick }: { newDataPointClick: () => void }) {
  return (
    <GridToolbarContainer sx={{ alignItems: 'start', padding: 1 }}>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <Spacer />
      <Button
        variant="outlined"
        startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
        onClick={newDataPointClick}
      >
        <FormattedMessage id="visit-data.new-data-point" defaultMessage="New data point" />
      </Button>
    </GridToolbarContainer>
  );
}
