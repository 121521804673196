import { useNavigate } from 'react-router-dom';

import { useCustomerLogout } from '@trustyou/shared';
import { ForbiddenRoute, TopBarLayout } from '@trustyou/ui';

export const ForbiddenPage = () => {
  const navigate = useNavigate();
  const customerLogout = useCustomerLogout();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <TopBarLayout hasLogo backgroundColor="background.paper" onLogout={customerLogout}>
      <ForbiddenRoute onGoBack={handleGoBack} />
    </TopBarLayout>
  );
};
