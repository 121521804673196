import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { faCopy, faEdit, faTrashCan } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Spacer, Stack } from '@trustyou/ui';

import { IconButtonWithTooltip } from '../../../icon-button-with-tooltip';
import { ResponseAIRatingMobile } from '../../../response-ai/response-ai-rating/response-ai-rating-mobile';

export type ResponseFieldActionWrapperProps = {
  children: ReactNode;
  isDisabled?: boolean;
  isRatingMobileVisible: boolean;
  onCopy: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

export function ResponseFieldActionWrapper({
  children,
  isDisabled,
  isRatingMobileVisible,
  onCopy,
  onEdit,
  onDelete,
}: ResponseFieldActionWrapperProps) {
  const intl = useIntl();

  return (
    <Stack spacing={0.5}>
      {children}
      <Stack direction="row" justifyContent="space-between">
        <IconButtonWithTooltip
          aria-label="textbox copy button"
          data-gtm-class="inbox_response_textbox_action"
          data-gtm-id="copy"
          tooltip={intl.formatMessage({
            id: 'inbox.action.copy',
            defaultMessage: 'Copy',
          })}
          icon={faCopy}
          onClick={onCopy}
          disabled={isDisabled}
        />
        <IconButtonWithTooltip
          aria-label="textbox edit button"
          data-gtm-class="inbox_response_textbox_action"
          data-gtm-id="edit"
          tooltip={intl.formatMessage({
            id: 'inbox.edit',
            defaultMessage: 'Edit',
          })}
          icon={faEdit}
          onClick={onEdit}
          disabled={isDisabled}
        />
        {isRatingMobileVisible && <ResponseAIRatingMobile isDisabled={isDisabled} />}
        <Spacer />
        <IconButtonWithTooltip
          aria-label="textbox delete button"
          data-gtm-class="inbox_response_textbox_action"
          data-gtm-id="delete"
          tooltip={intl.formatMessage({
            id: 'inbox.response.info.delete',
            defaultMessage: 'Delete text',
          })}
          icon={faTrashCan}
          onClick={onDelete}
          disabled={isDisabled}
        />
      </Stack>
    </Stack>
  );
}
