import { FormattedMessage } from 'react-intl';

import type { UploadError } from '@trustyou/shared';
import { Alert, AlertTitle } from '@trustyou/ui';

const WrongFormattingError = ({ columns }: UploadError) => (
  <Alert severity="error">
    <AlertTitle>
      <FormattedMessage
        id="entitiesImporter.uploadSpreadsheet.wrongFormatting"
        defaultMessage="Wrong formatting"
      />
    </AlertTitle>
    <FormattedMessage
      id="entitiesImporter.uploadSpreadsheet.wrongFormattingErrorDescription"
      defaultMessage="The following columns have wrong formatting: {columns}. Please correct them and try again"
      values={{ columns: columns?.join(', ') }}
    />
  </Alert>
);

export default WrongFormattingError;
