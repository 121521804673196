import { green, grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';
import { CONTENT_MAX_WIDTH } from '@trustyou/ui';

const styles: IStyles = {
  pageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: 'none',
    '& > div': {
      maxWidth: '500px',
      paddingX: 0,
      justifyContent: 'center',
    },
  },
  dialogBodyWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  whereCanIFindWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    width: '320px',
    padding: 3,
    backgroundColor: grey[50],
    height: 'fit-content',
    '& > h6': {
      fontWeight: 500,
    },
  },
  formOuterWrapper: {
    display: 'flex',
    gap: 10,
    maxWidth: CONTENT_MAX_WIDTH,
    height: 'auto',
    minHeight: '450px',
    alignSelf: 'center',
  },
  formInnerWrapper: {
    display: 'flex',
    maxWidth: '600px',
    flexDirection: 'column',
    gap: 1,
    '& button': {
      width: 'fit-content',
    },
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    color: 'success.main',
    backgroundColor: green[50],
  },
  stepperBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    paddingX: 5,
    pt: 4,
  },
  footerContainer: {
    mt: 'auto',
    paddingY: 2,
    paddingX: 5,
    alignItems: 'center',
    display: 'flex',
    position: 'sticky',
    bottom: '0',
    background: 'rgba(255, 255, 255, 0.85)',
    backdropFilter: 'blur(2px)',
    zIndex: '1',
  },
  footerBackBtn: {
    marginRight: 'auto',
  },
  authoriseDescription: {
    maxWidth: '600px',
    textAlign: 'center',
  },
  authoriseFooterMessage: {
    mr: 3,
    color: 'text.secondary',
  },
  legalEntityIDFormField: {
    mt: 2,
    width: '200px',
  },
  contactEmailFormField: {
    mt: 2,
    width: '320px',
  },
  connectInstructionsTitle: {
    mt: 1,
    mb: -2,
  },
  connectInstructionsDescription: {
    mb: 1,
    textAlign: 'center',
  },
};

export default styles;
