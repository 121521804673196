import { FormattedMessage } from 'react-intl';

import {
  type AccessGroup,
  USER_SCOPES,
  type UserScopeDetails,
  commonFields,
  detectUserScope,
  usersMessages,
} from '@trustyou/shared';
import { Box, Typography } from '@trustyou/ui';

import styles from './styles';

interface UserScopeCellProps {
  scope: UserScopeDetails | AccessGroup;
}

export const UserScopeCell = ({ scope }: UserScopeCellProps) => {
  const userScope = detectUserScope(scope);

  return (
    <Box sx={styles.userScopeCell}>
      <Typography variant="body2">
        {userScope === USER_SCOPES.ALL_ENTITIES && (
          <FormattedMessage id="scope.all-entities" defaultMessage="All entities" />
        )}
        {userScope === USER_SCOPES.BY_ENTITIES && (
          <FormattedMessage
            {...usersMessages.scopesEntityCount}
            values={{ count: scope.entities.length }}
          />
        )}
        {userScope === USER_SCOPES.ACCESS_GROUP && 'name' in scope && scope.name}
        {userScope === USER_SCOPES.BY_GROUPS && (
          <FormattedMessage
            id="tyDesign.user.countGroups"
            defaultMessage="{count} groups"
            values={{ count: scope.groups.length }}
          />
        )}
        {userScope === USER_SCOPES.ADVANCED_SELECTION && (
          <FormattedMessage
            {...usersMessages.scopesEntityCount}
            values={{ count: scope.entities_count }}
          />
        )}
        {userScope === USER_SCOPES.NONE && (
          <FormattedMessage id="tyDesign.user.none" defaultMessage="None" />
        )}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {userScope === USER_SCOPES.ALL_ENTITIES && scope.subscription_id && (
          <FormattedMessage id="scope.in-subscription" defaultMessage="In subscription" />
        )}
        {userScope === USER_SCOPES.ALL_ENTITIES && !scope.subscription_id && (
          <FormattedMessage id="scope.in-organization" defaultMessage="In organization" />
        )}
        {userScope === USER_SCOPES.BY_ENTITIES &&
          scope.entities.map((entity) => entity.name).join(', ')}
        {userScope === USER_SCOPES.ACCESS_GROUP && (
          <FormattedMessage {...commonFields.accessGroup} />
        )}
        {userScope === USER_SCOPES.ADVANCED_SELECTION && (
          <FormattedMessage {...usersMessages.scopesAdvancedRules} />
        )}
        {userScope === USER_SCOPES.BY_GROUPS && scope.groups.map((group) => group.name).join(', ')}
      </Typography>
    </Box>
  );
};
