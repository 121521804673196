import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getEmailNotification,
  getResponseTemplates,
  getSignature,
  setEmailNotification,
  setSignature,
} from './fetchers';

import type { EmailNotificationSettings_Input } from '../../../client';
import { EMAIL_NOTIFICATION, RESPONSE_TEMPLATES, SIGNATURE } from '../../../constants/query-keys';

export function useEmailSettings() {
  return useQuery({
    queryKey: [EMAIL_NOTIFICATION],
    queryFn: getEmailNotification,
    refetchOnWindowFocus: false,
  });
}

export function useUpdateEmailSettings() {
  return useMutation({
    mutationFn: (payload: EmailNotificationSettings_Input) => setEmailNotification(payload),
  });
}

export function useGetResponseTemplates() {
  return useQuery({
    queryKey: [RESPONSE_TEMPLATES],
    queryFn: getResponseTemplates,
    refetchOnWindowFocus: false,
  });
}

export function useGetSignature() {
  return useQuery({
    queryKey: [SIGNATURE],
    queryFn: getSignature,
    refetchOnWindowFocus: false,
  });
}

export function useSetSignature() {
  return useMutation({
    mutationFn: (signature: string) => setSignature(signature),
  });
}
