import { defineMessages } from 'react-intl';

export const tableHeaders = defineMessages({
  google: {
    id: 'reviewProviderDetails.table.header.google',
    defaultMessage: 'Google Business Profile',
  },
  cxpEquivalent: {
    id: 'reviewProviderDetails.table.header.cxpEquivalent',
    defaultMessage: 'CXP equivalent',
  },
  lastSynced: {
    id: 'reviewProviderDetails.table.header.lastSynced',
    defaultMessage: 'Last synced',
  },
  propertyId: {
    id: 'reviewProviderDetails.table.propertyId',
    defaultMessage: 'Property ID',
  },
  placeId: {
    id: 'reviewProviderDetails.table.placeId',
    defaultMessage: 'Place ID',
  },
});

export const actionMessages = defineMessages({
  disconnectAPI: {
    id: 'review-providers.disconnect-api.success',
    defaultMessage:
      'APIs disconnected. It might take {delay} for the changes to reflect in your inbox.',
  },
  deleteAccount: {
    id: 'review-providers.delete-account.success',
    defaultMessage:
      'Account removed. It might take {delay} for the changes to reflect in your inbox.',
  },
});
