import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, InputLabel, Stack, Typography } from '@trustyou/ui';

import { useUnregisterUnmountedQuestion } from '../../../../../service/hooks/use-unregister-unmounted-question';
import { QUESTION_TYPES_BY_CATEGORY } from '../../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../../shared';

function isValid32BitInteger(number: number) {
  if (number) {
    return Number.isInteger(number) && number >= -2147483648 && number <= 2147483647;
  }
  return true;
}

export function QuestionEditorNumberType() {
  const { register, watch } = useFormContext();
  const isMinNumberValid = isValid32BitInteger(Number(watch('minNumber')));
  const isMaxNumberValid = isValid32BitInteger(Number(watch('maxNumber')));

  useUnregisterUnmountedQuestion({
    questionTypes: QUESTION_TYPES_BY_CATEGORY.number,
    fieldsToUnregister: ['minNumber', 'maxNumber', 'number'],
  });

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        <FormattedMessage id="survey.common.range" defaultMessage="Range" />
      </Typography>
      <Stack direction="row" spacing={2}>
        <FormControl variant="standard">
          <InputLabel shrink>
            <FormattedMessage id="survey.common.minimum" defaultMessage="Minimum" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            sx={{ width: 151 }}
            error={!isMinNumberValid}
            helperText={
              !isMinNumberValid && (
                <FormattedMessage
                  id="survey.common.max-char-error"
                  defaultMessage="Max characters limit"
                />
              )
            }
            {...register('minNumber')}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink>
            <FormattedMessage id="survey.common.maximum" defaultMessage="Maximum" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            sx={{ width: 151 }}
            error={!isMaxNumberValid}
            helperText={
              !isMaxNumberValid && (
                <FormattedMessage
                  id="survey.common.max-char-error"
                  defaultMessage="Max characters limit"
                />
              )
            }
            {...register('maxNumber')}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
}
