import { create } from 'zustand';

export type FeedbackPaginationState = {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};

export const useFeedbackPaginationStore = create<FeedbackPaginationState>((set) => ({
  currentPage: 1,
  setCurrentPage: (currentPage) => set({ currentPage }),
}));
