import type { AccordionProps, AccordionSummaryProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { faChevronDown } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Accordion, AccordionSummary, StyledFontAwesomeIcon, styled } from '@trustyou/ui';

export const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  marginTop: theme.spacing(1),
  backgroundColor: grey[50],
}));

export const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<StyledFontAwesomeIcon icon={faChevronDown} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  marginLeft: theme.spacing(2),
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: theme.spacing(2),
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));
