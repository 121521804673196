import type { UploadError } from '@trustyou/shared';
import { Stack } from '@trustyou/ui';

import errorsMap from './ErrorTypes';

import styles from './styles';

export type UploadErrorsProps = {
  errors: UploadError[];
};

const Errors = ({ errors }: UploadErrorsProps) => (
  <Stack sx={styles.errorStack} spacing={2}>
    {errors.map((error) => (errorsMap[error.type] || errorsMap['undefined-error'])(error))}
  </Stack>
);

export default Errors;
