import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useHelpLinks } from '@trustyou/shared';
import { IconButtonWithTooltip, ListItemIcon, ListItemText, Menu, MenuItem } from '@trustyou/ui';

export function HelpMenu() {
  const intl = useIntl();
  const helpLinks = useHelpLinks();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButtonWithTooltip
        icon={faQuestionCircle}
        tooltip={intl.formatMessage({ id: 'topbar.action.help', defaultMessage: 'Help' })}
        IconProps={{ size: 'sm' }}
        data-testid="help-nav-icon"
        data-gtm-id="help-nav-icon"
        id="help-button"
        aria-controls={open ? 'help-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="help-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'help-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {helpLinks.map(({ id, icon, name, onClick }) => (
          <MenuItem key={id} onClick={onClick}>
            <ListItemIcon>
              <FontAwesomeIcon icon={icon} />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 14,
                },
              }}
            >
              {name}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
