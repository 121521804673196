import type { DashboardFilter } from '../types';

export const getDashboardExcludedFilters = (filters: DashboardFilter[]) => {
  const excludedFilters = filters
    .filter(
      (row) =>
        row.jaql && (row.psHideFilter || /^segment\d$/.test(row.jaql.column || row.jaql.title))
    )
    .map((row) => row.jaql.column || row.jaql.title);

  filters.forEach((row) => {
    if (row.levels) {
      row.levels.forEach((level) => {
        if (/^\$hide_me_custom_survey_\d+\$$/.test(level.title)) {
          excludedFilters.push(level.title);
        }
      });
    }
  });

  return JSON.stringify(excludedFilters);
};
