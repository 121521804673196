import { FormattedMessage } from 'react-intl';

import { USER_SCOPES, useScopeStore } from '@trustyou/shared';
import { Box, EntitySelector, ScopeSelector, Stack, Typography } from '@trustyou/ui';

type EditorScopeContentProps = {
  variant: 'survey' | 'theme';
};
const RESTRICTED_SCOPES = [
  USER_SCOPES.BY_GROUPS,
  USER_SCOPES.ADVANCED_SELECTION,
  USER_SCOPES.ACCESS_GROUP,
  USER_SCOPES.ALL_ENTITIES,
];
const isAllowedScope = (scope: USER_SCOPES) => !RESTRICTED_SCOPES.includes(scope);
const allowedScopes = Object.values(USER_SCOPES).filter(isAllowedScope);

export function EditorScopeContent({ variant }: EditorScopeContentProps) {
  const { selectedScope, selectedEntities, setSelectedEntities } = useScopeStore();

  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.editor.step-scope.title"
            defaultMessage="Assign to entities"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {variant === 'survey' ? (
            <FormattedMessage
              id="survey.survey-editor.step-scope.description"
              defaultMessage="Decide which entities will have this questionnaire assigned."
            />
          ) : (
            <FormattedMessage
              id="survey.theme-editor.step-scope.description"
              defaultMessage="Decide which entities will have this Theme or Theme version assigned."
            />
          )}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          <FormattedMessage id="survey.editor.step-scope.subtitle" defaultMessage="Scope" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {variant === 'survey' ? (
            <FormattedMessage
              id="survey.survey-editor.step-scope.subdescription"
              defaultMessage="Which entities will have access to this Survey?"
            />
          ) : (
            <FormattedMessage
              id="survey.theme-editor.step-scope.subdescription"
              defaultMessage="Which entities will have access to this Theme?"
            />
          )}
        </Typography>
      </Box>
      <ScopeSelector scopes={allowedScopes} />
      {selectedScope === USER_SCOPES.BY_ENTITIES && (
        <EntitySelector
          selectedEntities={selectedEntities}
          setSelectedEntities={setSelectedEntities}
        />
      )}
      {/* {selectedScope === USER_SCOPES.BY_GROUPS && <GroupSelector />} */}
    </Stack>
  );
}
