export const ORGANIZATION_BASE_PATH = '/api/org_center/{organization_id}/organizations';
export const SEGMENTS_BASE_PATH = '/api/org_center/{organization_id}/segments';
export const USERS_BASE_PATH = '/api/org_center/{organization_id}/users';
export const SCOPES_BASE_PATH = '/api/org_center/{organization_id}/scopes';
export const ENTITIES_BASE_PATH = '/api/org_center/{organization_id}/entities';
export const CONNECTION_BASE_PATH = '/api/org_center/{organization_id}/connection';
export const SUBSCRIPTIONS_BASE_PATH = '/api/org_center/{organization_id}/subscriptions';
export const BENCHMARKS_BASE_PATH = '/api/org_center/{organization_id}/benchmarks';
export const CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH =
  '/api/org_center/{organization_id}/custom_attribute_definitions';
export const ENTITY_CONNECTIONS_BASE_PATH = '/api/org_center/{organization_id}/entity_connections';
export const ORG_BASE_PATH = '/api/org_center/{organization_id}';
export const AUTH_TOKEN_SERVICE_PATH = '/api/token_service';
export const AUTH_CENTER_PATH = '/api/auth_center';
export const ANALYTICS_BASE_PATH = '/api/analytics';
export const INBOX_BASE_PATH = '/api/inbox/{organization_id}';
export const SURVEY_BASE_PATH = '/api/survey/{organization_id}';
export const SURVEY_SUBMISSION_BASE_PATH = '/api';
export const ADMIN_ORGANIZATION_BASE_PATH = '/admin-api/org_center/organizations';
export const ADMIN_ENTITIES_BASE_PATH = '/admin-api/org_center/entities';
export const ADMIN_USERS_BASE_PATH = '/admin-api/org_center/users';
export const TOKEN_INFO_PATH = '/token_info';
