import React from 'react';

import { BrandedAvatar, Stack, Typography } from '@trustyou/ui';

import type { Recipient } from '../../types/recipient';

export const RecipientOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Recipient) => (
  <li {...props} key={option.id}>
    <Stack direction="row" alignItems="flex-start">
      <BrandedAvatar name={option.name} />
      <Stack direction="column" ml={2} alignItems="flex-start">
        <Typography variant="body2" color="text.primary">
          {option.name}
        </Typography>
        <Typography variant="body2" style={{ wordBreak: 'break-word' }} color="text.secondary">
          {option.email}
        </Typography>
      </Stack>
    </Stack>
  </li>
);
