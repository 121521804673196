import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  type CustomAttributeDefinition,
  commonFields,
  useCanDeleteCustomAttributeDefinition,
  useDeleteCustomAttributeDefinition,
} from '@trustyou/shared';
import { BackdropSpinner, Button, DeleteModal, snackbar } from '@trustyou/ui';

import { DeleteProhibitionDialog } from './DeleteProhibitionDialog';

import { manageDrawer } from '../../constants/messages/customAttributes';
import { useDashboardDelayMessage } from '../../hooks';
import { styles } from './styles';

type Props = {
  onDelete: () => void;
  data: CustomAttributeDefinition;
};

export const DeleteAction = ({ data, onDelete }: Props) => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [impactedSegments, setImpactedSegments] = useState<string[]>([]);

  const canDeleteCustomAttributeDefinition = useCanDeleteCustomAttributeDefinition();
  const isValidating = canDeleteCustomAttributeDefinition.isPending;

  const deleteCustomAttribute = useDeleteCustomAttributeDefinition();
  const isDeleting = deleteCustomAttribute.isPending;

  const handleValidate = () => {
    canDeleteCustomAttributeDefinition.mutate(data.id, {
      onSuccess: (response) => {
        if (!response.valid && response.segment_names && response.segment_names.length > 0) {
          setImpactedSegments(response.segment_names);
        } else {
          setIsDeleteModalOpen(true);
        }
      },
    });
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(false);
    deleteCustomAttribute.mutate(data.id, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(manageDrawer.deleteSuccess, {
            name: data?.name,
            delay: dashboardDelayMessage,
          })
        );
        onDelete();
      },
    });
  };

  return (
    <>
      <Button onClick={handleValidate} color="error" sx={styles.deleteButton}>
        {intl.formatMessage(manageDrawer.deleteButton)}
      </Button>
      <DeleteModal
        fullWidth
        maxWidth="xs"
        cancelText={intl.formatMessage(commonFields.cancel)}
        content={intl.formatMessage(manageDrawer.deleteContent)}
        dataName="custom-attribute-delete"
        deleteText={intl.formatMessage(manageDrawer.confirmedDeleteButton)}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        title={intl.formatMessage(manageDrawer.deleteTitle, { name: data?.name })}
        open={isDeleteModalOpen}
      />
      <DeleteProhibitionDialog
        segments={impactedSegments}
        onClose={() => setImpactedSegments([])}
      />
      <BackdropSpinner isLoading={isValidating || isDeleting} />
    </>
  );
};
