import { blue, grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  providerAccountWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '320px',
    paddingY: 1,
    paddingX: 2,
    gap: 1,
    borderRadius: '4px',
    backgroundColor: grey[50],
  },
  providerAccountAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: blue[300],
  },
  providerAccountInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '192px',
    paddingY: 1,
    '& > span': {
      color: 'text.secondary',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  providerAccountDeleteIcon: {
    margin: 'auto 0 auto auto',
  },
  providerAccountSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  providerAccountList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 2,
    mb: 5,
  },
};

export default styles;
