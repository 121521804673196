import { useEffect } from 'react';

import { useTokenInfo } from '../service/hooks/domains/ass/auth';
import { usePermissionStore } from '../store';

export const usePermissions = (accessToken?: string | null) => {
  const { permissions, setPermissions, hasPermissions } = usePermissionStore();
  const { data: tokenInfoData } = useTokenInfo(accessToken);

  useEffect(() => {
    if (!tokenInfoData || !accessToken) return;

    const newPermissions = Object.entries(tokenInfoData).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value?.permissions || [],
      }),
      { ...permissions }
    );

    setPermissions(newPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInfoData]);

  return {
    permissions,
    hasPermissions,
  };
};
