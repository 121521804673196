import { FormattedMessage } from 'react-intl';

import { useMembershipStore } from '@trustyou/shared';
import { ContentPane, MembershipSelector, Typography } from '@trustyou/ui';

export function MembershipSwitcherPage() {
  const { setMembership, organizations } = useMembershipStore();

  if (!organizations || !organizations.length) {
    return undefined;
  }

  return (
    <ContentPane sx={{ margin: 'auto', border: 'none', maxWidth: 360 }}>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage
          id="hallway.select-membership.title"
          defaultMessage="Select a membership to continue"
        />
      </Typography>
      <MembershipSelector organizations={organizations} onSelect={setMembership} />
    </ContentPane>
  );
}
