import { defineMessages } from 'react-intl';

export const entityMessage = defineMessages({
  detailsExpandIcon: {
    id: 'entities.details.expand-icon',
    defaultMessage: 'Expand to view all entities',
  },
  connectionsHeader: {
    id: 'entities.details.connections.header',
    defaultMessage:
      'These integrations collect reviews directly from providers and let you respond to them from your Inbox.',
  },
});
