import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  contentPane: { height: '100%' },
  contentPaneBody: {
    height: 'calc(100% - 72px)',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    maxWidth: '1000px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxWidth: '480px',
  },
  footer: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  accessOptionSelectionCard: { maxWidth: '320px', minHeight: '150px' },
  accessOptionSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  accessOptionSelectionCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
};

export default styles;
