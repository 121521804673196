import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { lightBlue } from '@mui/material/colors';
import { faMagnifyingGlass } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { FiltersForm } from '@trustyou/inbox';
import { useInboxStore } from '@trustyou/shared';
import {
  Autocomplete,
  BackdropSpinner,
  Box,
  Chip,
  InputAdornment,
  InputLabel,
  Stack,
  StyledFontAwesomeIcon,
  TextField,
  Typography,
} from '@trustyou/ui';

import { commonMessages, inboxDetailsStepMessages } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import { schema } from '../inbox-filter-step/inbox-filter-step';
import styles from '../styles';

export const InboxDetailsStep = () => {
  const methods = useForm<FiltersForm>({ resolver: yupResolver(schema) });
  const intl = useIntl();
  const currentView = useInboxStore((state) => state.currentView);
  const { prevStep, nextStep } = useNewReportStore();
  const [value, setValue] = useState<string[]>(currentView.filters?.search_terms ?? []);

  return (
    <FormProvider {...methods}>
      <Box sx={styles.stepBody}>
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <Stack spacing={3}>
            <InputLabel htmlFor="keywords">
              <Typography
                component="h1"
                variant="h6"
                color="text.primary"
                gutterBottom
                sx={{ display: 'inline-block' }}
              >
                {intl.formatMessage(commonMessages.keywords)}
              </Typography>
              <Chip
                label={intl.formatMessage(commonMessages.optional)}
                sx={{ color: 'info.main', backgroundColor: lightBlue[50], marginLeft: 1 }}
              />
            </InputLabel>
            <Box>
              <Typography variant="body1" gutterBottom>
                {intl.formatMessage(inboxDetailsStepMessages.descriptionOne)}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {intl.formatMessage(inboxDetailsStepMessages.descriptionTwo)}
              </Typography>
            </Box>
            <Autocomplete
              multiple
              id="keywords"
              options={[]}
              freeSolo
              value={value}
              onChange={(_event, newValue) => {
                setValue(newValue);
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip variant="filled" label={option} color="info" key={key} {...tagProps} />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <StyledFontAwesomeIcon
                            icon={faMagnifyingGlass}
                            size="lg"
                            sx={{
                              color: (theme) => theme.palette.action.active,
                              marginInline: 1,
                            }}
                          />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  placeholder={intl.formatMessage(commonMessages.enterKeywords)}
                />
              )}
            />
          </Stack>
        </Stack>
      </Box>
      <Footer onClickBack={prevStep} onClickNext={nextStep} />
      <BackdropSpinner isLoading={false} />
    </FormProvider>
  );
};
