import { useState } from 'react';

import { FlagIconButton } from './flag-icon-button';
import { ModerationFormDrawer } from './form-drawer';

import { SurveyModerationDiscardDialog } from './survey-moderation-discard-dialog';

export function SurveyModeration() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleDiscard = () => {
    closeDialog();
    closeDrawer();
  };

  const handleConfirm = () => {
    closeDrawer();
  };

  return (
    <>
      <FlagIconButton onClick={openDrawer} />
      <ModerationFormDrawer
        isOpen={isDrawerOpen}
        onClose={openDialog}
        onCancel={openDialog}
        onConfirm={handleConfirm}
      />
      <SurveyModerationDiscardDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onCancel={closeDialog}
        onDiscard={handleDiscard}
      />
    </>
  );
}
