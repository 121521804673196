import type { SxProps, Theme } from '@mui/material';
import { type Node, useSidebarStore } from '@trustyou/shared';
import { Box, DRAWER_WIDTH_DESKTOP, Drawer, Stack } from '@trustyou/ui';

import { DrawerHeader } from './DrawerHeader';
import { DrawerNodeList } from './DrawerNodeList';
import { ToggleSidebarButton } from './ToggleButton';

import type { TrustYouLogoProps } from '../TrustYouLogo/TrustYouLogo';

const expansionTransition: SxProps<Theme> = {
  width: (theme) => theme.spacing(32),
  transition: (theme) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  overflowX: 'hidden',
};

const collapseTransition: SxProps<Theme> = {
  width: (theme) => theme.spacing(8),
  transition: (theme) =>
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  overflowX: 'hidden',
};

export const styles = {
  expanded: {
    ...expansionTransition,
    '& .MuiDrawer-paper': expansionTransition,
  },
  collapsed: {
    ...collapseTransition,
    '& .MuiDrawer-paper': collapseTransition,
  },
};

type CollapsibleDrawerProps = {
  logoColor: TrustYouLogoProps['color'];
  primaryNodes?: Node[];
  secondaryNodes?: Node[];
  isChangeling?: boolean;
  onHeaderClick: () => void;
};

export function CollapsibleDrawer({
  logoColor,
  primaryNodes = [],
  secondaryNodes = [],
  isChangeling,
  onHeaderClick,
}: CollapsibleDrawerProps) {
  const { isCollapsed } = useSidebarStore();

  return (
    <Drawer
      open={!isCollapsed}
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH_DESKTOP,
        // FIXME: find the reason why zIndex value 1200 is needed to avoid breaking the styles of DrawerHeader.
        zIndex: 1200,
        '& .MuiPaper-root': {
          borderRight: 0,
          boxShadow: 1,
          ...(isChangeling && { paddingTop: 8 }),
        },
        ...(isCollapsed ? styles.collapsed : styles.expanded),
      }}
    >
      <DrawerHeader logoColor={logoColor} onClick={onHeaderClick} />
      <Stack sx={{ height: '100%' }}>
        <DrawerNodeList nodes={primaryNodes} />
        <Box sx={{ flexGrow: 1 }} />
        <DrawerNodeList nodes={secondaryNodes} />
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            background: 'white',
          }}
        >
          <ToggleSidebarButton />
        </Box>
      </Stack>
    </Drawer>
  );
}
