import { useIntl } from 'react-intl';

import { type SxProps, type Theme } from '@mui/material';
import type { SelectProps } from '@trustyou/ui';

import { LoadingDropdown } from './loading-dropdown';

import { useFetchSources } from '../../../hooks';
import type { Option } from '../../../types';
import { DropdownMultiple } from '../dropdown-multiple';

export type DropdownSourceProps = {
  fieldName: string;
  initialValue?: string[];
  size?: SelectProps['size'];
  fullWidth?: SelectProps['fullWidth'];
  sx?: SxProps<Theme>;
};

export const DropdownSource = ({
  fieldName,
  initialValue,
  size = 'small',
  fullWidth = false,
  sx = {},
}: DropdownSourceProps) => {
  const intl = useIntl();
  const { data, isPending: isLoading } = useFetchSources();

  if (isLoading) return <LoadingDropdown size={size} sx={sx} />;

  // TODO: method `toSorted()` can be used instead of the in-place `sort()` when bumping Node to v20+
  const sortedSourceOptions: Option[] = Object.entries(data ?? {})
    .map(([name, uuid]) => ({ label: name, value: uuid }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <DropdownMultiple
      fieldName={fieldName}
      options={sortedSourceOptions}
      allValuesLabel={intl.formatMessage({
        id: 'inbox.filter.source.all',
        defaultMessage: 'All Sources',
      })}
      dataTestId="filterFieldSource"
      label={intl.formatMessage({
        id: 'inbox.filter.source.label',
        defaultMessage: 'Source',
      })}
      initialSelectedValues={initialValue}
      fullWidth={fullWidth}
      sx={sx}
      showLabelAsResult
    />
  );
};
