import { useRef } from 'react';

import { useDashboardId } from '@trustyou/shared';

import { BaseDashboard } from './BaseDashboard';

import { useSendImpactScore } from '../../hooks';
import { Reviews } from '../../reviews';

export const SentimentDashboard = () => {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const { data: dashboardIdResponse } = useDashboardId();
  useSendImpactScore(iFrameRef);

  if (!dashboardIdResponse?.sentiment_dashboard_id) return null;
  return (
    <>
      <BaseDashboard
        dashboardId={dashboardIdResponse?.sentiment_dashboard_id}
        iFrameRef={iFrameRef}
      />
      <Reviews />
    </>
  );
};
