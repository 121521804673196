import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, StyledFontAwesomeIcon as Icon } from '@trustyou/ui';

import { manageDrawer } from '../../../constants/messages/customAttributes';
import { styles } from './styles';

type Props = {
  isDisabled?: boolean;
  onClick: () => void;
};

export const AddButton = ({ onClick, isDisabled = false }: Props) => (
  <Button
    data-testid="add-option-button"
    variant="text"
    sx={styles.addButton}
    onClick={onClick}
    startIcon={<Icon icon={faPlus} />}
    disabled={isDisabled}
  >
    <FormattedMessage {...manageDrawer.optionSelectorAddOption} />
  </Button>
);
