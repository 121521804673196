import { useIntl } from 'react-intl';

import {
  faArrowRightToArc,
  faBrush,
  faEnvelope,
  faGear,
  faHandWave,
  faShuffle,
  faSquareQuestion,
  faTextSize,
  faUserGear,
} from '@trustyou/fortawesome/pro-regular-svg-icons';

import { EditorSurveyContent } from './content/content';
import { EditorEmailInviteContent } from './content/email-invite';
import { EditorRulesContent } from './content/rules';
import { EditorSettingsContent } from './content/settings';
import { EditorSubmitSectionContent } from './content/submit';
import { EditorThankYouContent } from './content/thank-you';
import { EditorThemeContent } from './content/theme';
import { EditorWelcomeMessageContent } from './content/welcome';

import type { Question_Output } from '../../../client';
import { useContents, useFetchThemeList, useQuestions } from '../../../service/hooks/use-questions';
import { EditorScopeContent } from '../../shared/editor-scope-content';
import type { Step } from '../../shared/editor-side-nav';

export function useSurveyQuestionnaireEditorSteps() {
  const intl = useIntl();
  const { data: welcomeMessages } = useContents(['welcome_message']);
  const { data: questions } = useQuestions();
  const { data: sections } = useContents(['section']);
  const { data: thankyouMessages } = useContents(['thankyou_message']);
  const { data: emailInviteMessages } = useContents(['email_invitation']);
  const { data: themeList } = useFetchThemeList();

  const submitSectionHandlingTypes = [
    'contact_info_name',
    'contact_info_email',
    'contact_info_room_number',
    'contact_info_phone',
    'data_privacy_information',
    'terms_and_conditions',
  ];

  const steps: Step[] = [
    {
      icon: faGear,
      label: intl.formatMessage({ id: 'survey.common.settings', defaultMessage: 'Settings' }),
      content: <EditorSettingsContent />,
    },
    {
      icon: faHandWave,
      label: intl.formatMessage({ id: 'survey.common.welcome', defaultMessage: 'Welcome' }),
      content: <EditorWelcomeMessageContent contents={welcomeMessages || []} />,
    },
    {
      icon: faSquareQuestion,
      label: intl.formatMessage({ id: 'survey.common.content', defaultMessage: 'Content' }),
      content: (
        <EditorSurveyContent
          questions={
            (questions as Question_Output[])?.filter(
              (item) => !submitSectionHandlingTypes.includes(item.handling_type)
            ) || []
          }
          sections={sections || []}
        />
      ),
    },
    {
      icon: faArrowRightToArc,
      label: intl.formatMessage({ id: 'survey.common.submit', defaultMessage: 'Submit' }),
      content: (
        <EditorSubmitSectionContent
          questions={
            (questions as Question_Output[])?.filter((item) =>
              submitSectionHandlingTypes.includes(item.handling_type)
            ) || []
          }
          sections={sections || []}
        />
      ),
    },
    {
      icon: faShuffle,
      label: intl.formatMessage({ id: 'survey.common.rules', defaultMessage: 'Rules' }),
      content: <EditorRulesContent questions={(questions as Question_Output[]) || []} />,
    },
    {
      icon: faTextSize,
      label: intl.formatMessage({
        id: 'survey.common.thankyou-page',
        defaultMessage: 'Thank you page',
      }),
      content: <EditorThankYouContent contents={thankyouMessages || []} />,
    },
    {
      icon: faEnvelope,
      label: intl.formatMessage({
        id: 'survey.common.email-invitiation',
        defaultMessage: 'Email invitation',
      }),
      content: <EditorEmailInviteContent contents={emailInviteMessages || []} />,
    },
    {
      icon: faBrush,
      label: intl.formatMessage({ id: 'survey.common.theme', defaultMessage: 'Theme' }),
      content: <EditorThemeContent themeList={themeList ?? []} />,
    },
    {
      icon: faUserGear,
      label: intl.formatMessage({ id: 'survey.common.scope', defaultMessage: 'Scope' }),
      content: <EditorScopeContent variant="survey" />,
    },
  ];

  return steps;
}
