import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  formHeader: { paddingInline: 3 },
  formContent: { paddingX: 3, paddingY: 2 },
  formFooter: { boxShadow: 5 },
  deleteButton: { marginRight: 'auto' },
  checkboxControllerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  checkboxFormControlLabel: { marginX: 0, gap: 1 },
  checkboxHelperText: { marginTop: 0 },
  nameField: {
    '& .MuiInputBase-root': { maxWidth: '300px' },
    '& .MuiInputBase-input::placeholder': {
      color: 'text.secondary',
      opacity: 1,
    },
  },
  descriptionField: {
    '& .MuiInputBase-input::placeholder': {
      color: 'text.secondary',
      opacity: 1,
    },
  },
};
