import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { actionMessages } from '@trustyou/shared';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Selector,
  type SelectorItem,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

export interface EntitySelectorModalProps<T> {
  items: (T & SelectorItem)[];
  defaultItems?: (T & SelectorItem)[];
  isLoading?: boolean;
  title: ReactNode;
  selectedHeaderTitle: (count: number) => ReactNode;
  subHeader?: ReactNode;
  searchPlaceholder?: string;
  maxItems?: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedItems: (T & SelectorItem)[]) => void;
  onSearch: (value: string) => void;
  renderRowContent: (item: T & SelectorItem) => ReactNode;
  onFetchNextPage?: () => void;
}

export const EntitySelectorModal = <T,>({
  items,
  isLoading,
  title,
  subHeader,
  selectedHeaderTitle,
  searchPlaceholder,
  maxItems,
  isOpen,
  defaultItems = [],
  onClose,
  onSave,
  renderRowContent,
  onFetchNextPage,
  onSearch,
}: EntitySelectorModalProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<(T & SelectorItem)[]>([]);

  useEffect(() => {
    setSelectedItems(isOpen ? defaultItems : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', paddingBottom: 4 }}>
        <Box>
          {title}
          <Typography variant="body2" color="text.secondary">
            {subHeader}
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(2),
          }}
        >
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 2 }}>
        <Selector
          searchPlaceholder={searchPlaceholder}
          items={items}
          selectedHeaderTitle={selectedHeaderTitle(selectedItems.length)}
          onSelectItems={setSelectedItems}
          selectedItems={selectedItems}
          renderRowContent={renderRowContent}
          isLoading={isLoading}
          onFetchNextPage={onFetchNextPage}
          onSearch={onSearch}
          isListDisabled={(maxItems && selectedItems.length >= maxItems) || false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage {...actionMessages.cancel} />
        </Button>
        <Button onClick={() => onSave(selectedItems)} variant="contained" color="primary">
          <FormattedMessage {...actionMessages.select} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
