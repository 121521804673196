import { useRef } from 'react';

import { useChangelingStore } from '@trustyou/shared';
import { ComposableDrawerWithStickyFooter } from '@trustyou/ui';

import { Form, type FormRef } from './Form';

import { useBenchmarkStore } from '../../../store';

const SLIDE_ANIMATION_DURATION = 250;
const DRAWER_WIDTH_PX = 600;

export const BenchmarkManageDrawer = () => {
  const childRef = useRef<FormRef>(null);
  const { isChangeling } = useChangelingStore();
  const { isCreateBenchmarkDrawerOpen, editableBenchmark, closeManageDrawer } = useBenchmarkStore();

  const handleClose = () => {
    if (childRef.current) {
      childRef.current.onCancel();
    }
  };

  return (
    <ComposableDrawerWithStickyFooter
      open={isCreateBenchmarkDrawerOpen || !!editableBenchmark}
      anchor="right"
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: { xs: '90vw', sm: DRAWER_WIDTH_PX },
          paddingTop: isChangeling ? 8 : 0,
        },
      }}
      SlideProps={{ appear: true, timeout: SLIDE_ANIMATION_DURATION }}
    >
      {(isCreateBenchmarkDrawerOpen || !!editableBenchmark) && (
        <Form ref={childRef} data={editableBenchmark} onClose={closeManageDrawer} />
      )}
    </ComposableDrawerWithStickyFooter>
  );
};
