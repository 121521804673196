import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import type { ButtonProps } from '@mui/material';
import { faFlag } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { messages } from './messages';

import { useReview } from '../../hooks';
import useModerationPermission from '../../hooks/permissions/use-moderation-permission';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

type FlagIconButtonProps = {
  onClick: ButtonProps['onClick'];
};

export function FlagIconButton({ onClick }: FlagIconButtonProps) {
  const intl = useIntl();
  const hasModerationPermission = useModerationPermission();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { can_be_moderated, moderation } = reviewRoot ?? {};

  const isModerationProcessStarted = ['requested', 'propagating'].includes(
    moderation?.status ?? ''
  );
  const isModerationProcessFinished = ['approved', 'rejected'].includes(moderation?.status ?? '');

  const isDisabled =
    !hasModerationPermission ||
    !can_be_moderated ||
    isModerationProcessStarted ||
    isModerationProcessFinished;

  const tooltipMessage =
    moderation?.status === 'rejected'
      ? messages.cannotBeMarkedAgainAsInappropriate
      : messages.markAsInappropriate;

  return (
    <IconButtonWithTooltip
      data-gtm-class="review_action_on_review"
      data-gtm-id="flag"
      color="primary"
      icon={faFlag}
      disabled={isDisabled}
      onClick={onClick}
      tooltip={intl.formatMessage(tooltipMessage)}
    />
  );
}
