export function toTerms(input: string) {
  const regex = /"([^"]+)"|(\S+)/g;
  const results = [];
  let match;

  while ((match = regex.exec(input)) !== null) {
    if (match[1]) {
      // Phrase in quotes
      results.push(match[1]);
    } else if (match[2]) {
      // Individual word
      results.push(match[2]);
    }
  }

  return results;
}

export function toInput(terms: string[]) {
  return terms
    .map((item) => (item.includes(' ') ? `"${item}"` : item)) // Enclose phrases in quotes
    .join(' '); // Join all items with a space
}
