import { FormattedMessage } from 'react-intl';

import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@trustyou/ui';

type ToneComparisonProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ToneComparisonDialog({ isOpen, onClose }: ToneComparisonProps) {
  return (
    <Dialog data-testid="tone-comparison" open={isOpen} onClose={onClose}>
      <DialogContent sx={{ display: 'flex', gap: 4 }}>
        <Box>
          <Typography variant="h6">
            <FormattedMessage
              id="inbox.settings.toneComparison.formalToneHeader"
              defaultMessage="Formal tone"
            />
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <FormattedMessage
              id="inbox.settings.toneComparison.formalToneAppeal"
              defaultMessage="Dear Customer,"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="inbox.settings.toneComparison.formalToneContent"
              defaultMessage="Wonderful to know that you enjoyed the view from your room. However, we were sorry to hear that the room did not meet your expectations. Your feedback is invaluable to us and we hope to serve you even better in the future."
            />
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <FormattedMessage
              id="inbox.settings.toneComparison.formalToneRegards"
              defaultMessage="Best regards,"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="inbox.settings.toneComparison.formalToneName"
              defaultMessage="[Your Name]"
            />
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            <FormattedMessage
              id="inbox.settings.toneComparison.casualToneHeader"
              defaultMessage="Casual tone"
            />
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <FormattedMessage
              id="inbox.settings.toneComparison.casualToneContent"
              defaultMessage="Welcome! We are sorry to hear that the room did not meet your expectations. We are glad you enjoyed the view. We are working to provide you with a unique and unforgettable experience and hope to see you again soon!"
            />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
