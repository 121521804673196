// Copied and adapted from `libs/modules/survey-manager/src/lib/components/ui/text-field.tsx`
import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  type FormLabelProps,
  TextField,
  type TextFieldProps,
} from '@mui/material';

export function CustomTextField({
  id,
  nonFloatingLabel,
  nonFloatingLabelProps,
  mainFullWidth = false,
  ...props
}: {
  id: string;
  nonFloatingLabel: ReactNode;
  nonFloatingLabelProps?: FormLabelProps;
  mainFullWidth?: boolean;
} & TextFieldProps) {
  const { register } = useFormContext();

  return (
    <FormControl fullWidth={mainFullWidth}>
      {nonFloatingLabel && (
        <FormLabel
          htmlFor={id}
          {...nonFloatingLabelProps}
          sx={{
            color: 'text.primary',
            marginBottom: 1,
            ...nonFloatingLabelProps?.sx,
          }}
        >
          {nonFloatingLabel}
        </FormLabel>
      )}
      <TextField id={id} {...props} {...register(id)} />
    </FormControl>
  );
}
