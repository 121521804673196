import { useState } from 'react';
import { useParams } from 'react-router-dom';

import type { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { faEye } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { DEFAULT_PAGE_SIZE, useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import { Box, DataGridPro, IconButton, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { useQuestionnairesByEntity } from '../../../../service/hooks/use-questionnaires';
import type { SurveyRowByEntity } from '../../../../types/survey';
import { CustomToolbar } from '../../../shared/custom-toolbar';
import styles from '../../../shared/styles';
import { Distribution } from '../distribution';

function useColumns() {
  const columns: GridColDef<SurveyRowByEntity>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'entity',
      headerName: 'Entity',
      flex: 1,
      renderCell: ({ value }) => <Box fontWeight={500}>{value}</Box>,
    },
    {
      field: 'version',
      headerName: 'Questionnaire',
      flex: 1,
      renderCell: ({ value }) => (
        <Typography variant="body2" color="text.secondary">
          {value}
        </Typography>
      ),
    },
    {
      field: 'distribution',
      headerName: 'Distribution',
      flex: 1,
      renderCell: ({ row }) => <Distribution surveyRowByEntity={row} />,
    },
    {
      field: 'preview',
      headerName: 'Preview',
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => window.open(row.url, '_blank')}
          size="small"
          sx={{ aspectRatio: '1 / 1' }}
        >
          <StyledFontAwesomeIcon icon={faEye} fixedWidth />
        </IconButton>
      ),
    },
  ];

  return columns;
}

export function DataGrid() {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const { surveyId = '' } = useParams();
  const columns = useColumns();
  const { data: rows = [] } = useQuestionnairesByEntity({ surveyId });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      pageSizeOptions={[15, 50, 100]}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      ignoreDiacritics
      disableRowSelectionOnClick
      slots={{
        toolbar: CustomToolbar,
      }}
      sx={styles.disableCellOutline}
      density="standard"
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
