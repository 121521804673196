import { useMutation, useQuery } from '@tanstack/react-query';

import {
  editProfile,
  fetchLanguagesForResponseAI,
  fetchResponseAIProfile,
  generateResponseAI,
  saveResponseAIFeedback,
  translateViaResponseAI,
} from './fetchers';

import type { Profile_Input, TranslateIn } from '../../../client';
import {
  RESPONSE_AI_LANGUAGES,
  RESPONSE_AI_PROFILE,
  RESPONSE_AI_RESULT,
} from '../../../constants/query-keys';
import type { ResponseAIFeedback } from '../../../types';

export function useTranslateViaResponseAI() {
  return useMutation({
    mutationFn: (data: TranslateIn) => translateViaResponseAI(data),
  });
}

export function useLanguagesForResponseAI({ enabled }: { enabled?: boolean }) {
  return useQuery({
    queryKey: [RESPONSE_AI_LANGUAGES],
    queryFn: fetchLanguagesForResponseAI,
    enabled,
    refetchOnWindowFocus: false,
  });
}

export function useResponseAIProfile() {
  return useQuery({
    queryKey: [RESPONSE_AI_PROFILE],
    queryFn: fetchResponseAIProfile,
    refetchOnWindowFocus: false,
  });
}

export function useEditProfile() {
  return useMutation({
    mutationFn: (profileData: Profile_Input) => editProfile(profileData),
  });
}

export function useGenerateResponseAI() {
  return useMutation({
    mutationFn: generateResponseAI,
    mutationKey: [RESPONSE_AI_RESULT],
  });
}

export function useSendFeedback() {
  return useMutation({
    mutationFn: (payload: ResponseAIFeedback) => saveResponseAIFeedback(payload),
  });
}
