import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { MenuItem, Stack, Typography } from '@trustyou/ui';

import type { Content } from '../../../../types/content';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorThankYouContent({ contents = [] }: { contents: Content[] }) {
  const { register, setValue, watch } = useFormContext();

  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const thankyouMessageId =
    watch('surveyQuestionnaireEditorThankyouMessage')?.id || contents?.[0].id || '';

  useEffect(() => {
    register('surveyQuestionnaireEditorThankyouMessage');
    if (!watch('surveyQuestionnaireEditorThankyouMessage')) {
      setValue(
        'surveyQuestionnaireEditorThankyouMessage',
        contents?.filter((item) => item.is_default)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.thank-you-message.title"
            defaultMessage="Thank you page"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.thank-you-message.description"
            defaultMessage="Shown after respondents submit their review. To create a custom one, visit the Content library."
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        value={thankyouMessageId}
        onChange={(e) => {
          const selectedMessage = contents.find((item) => item.id === e.target.value);
          setValue('surveyQuestionnaireEditorThankyouMessage', selectedMessage);
        }}
      >
        {contents.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title?.[defaultSurveyLanguage] ??
              item.title?.[item.default_language ?? 'en'] ??
              'Thank you text'}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
    </Stack>
  );
}
