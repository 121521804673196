import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';

const ImportingEntities = () => (
  <LoadingPlaceholder
    img={personSelectingData}
    title={<FormattedMessage id="importingEntities.almostThere" defaultMessage="Almost there!" />}
    description={
      <FormattedMessage
        id="importingEntities.configureOrganization"
        defaultMessage="We're configuring your organization. This may take a few minutes"
      />
    }
    loaderContainer={{ marginTop: 6 }}
    loaderHeader={{ marginTop: 3 }}
    loaderIcon={{ marginTop: 5 }}
  />
);

export default ImportingEntities;
