export const BROWSER_LANGUAGES: Record<string, string> = {
  en: 'en-US',
  es: 'es-ES',
  de: 'de-DE',
  it: 'it-IT',
  pt: 'pt-PT',
  fr: 'fr-FR',
  zh: 'zh-CN',
  ja: 'ja-JP',
} as const;
