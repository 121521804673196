import { useIntl } from 'react-intl';

import { deepPurple, indigo, lightBlue, lime, orange, teal } from '@mui/material/colors';
import {
  faChartBullet,
  faClipboardListCheck as faClipboardListCheckRegular,
  faFileArrowDown,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  faChartPie,
  faClipboardListCheck,
  faFaceSmile,
  faStar,
  faUsers,
} from '@trustyou/fortawesome/pro-solid-svg-icons';
import {
  type HomeElementType,
  homeHeadings,
  navigationNodes,
  useMembershipStore,
  usePermissionStore,
  useResponsive,
} from '@trustyou/shared';
import { personManagingFeedback } from '@trustyou/ui';

export function useHomeElements() {
  const intl = useIntl();
  const { membership } = useMembershipStore();
  const { hasRoutePermissions } = usePermissionStore();
  const { isMobile } = useResponsive();

  const basePath = `/org/${membership?.id}`;

  const homeElements: HomeElementType[] = [
    {
      id: 'inbox',
      path: `${basePath}/inbox`,
      type: 'card',
      category: 'dashboards',
      featured: true,
      imageUrl: personManagingFeedback,
      name: intl.formatMessage(navigationNodes.reviewsInbox),
      description: intl.formatMessage(navigationNodes.reviewsInboxDescription),
    },
    {
      id: 'dashboards-heading',
      type: 'heading',
      category: 'heading',
      name: intl.formatMessage(homeHeadings.dashboards),
    },
    {
      id: 'review-performance',
      path: `${basePath}/insights/reviews`,
      type: 'card',
      category: 'dashboards',
      icon: faStar,
      color: lightBlue[300],
      name: intl.formatMessage(navigationNodes.reviewPerformance),
      description: intl.formatMessage(navigationNodes.reviewPerformanceDescription),
    },
    {
      id: 'sentiment',
      path: `${basePath}/insights/sentiment`,
      type: 'card',
      category: 'dashboards',
      icon: faFaceSmile,
      color: teal[300],
      name: intl.formatMessage(navigationNodes.insightsSentiment),
      description: intl.formatMessage(navigationNodes.insightsSentimentDescription),
    },
    {
      id: 'competitors',
      path: `${basePath}/insights/competitors`,
      type: 'card',
      category: 'dashboards',
      icon: faUsers,
      color: orange[500],
      name: intl.formatMessage(navigationNodes.insightsCompetitors),
      description: intl.formatMessage(navigationNodes.insightsCompetitorsDescription),
    },
    {
      id: 'survey-reviews',
      path: `${basePath}/insights/survey-reviews`,
      type: 'card',
      category: 'dashboards',
      icon: faClipboardListCheck,
      color: deepPurple[300],
      name: intl.formatMessage(navigationNodes.insightsSurveyReviews),
      description: intl.formatMessage(navigationNodes.insightsSurveyReviewsDescription),
    },
    {
      id: 'survey-statistics',
      path: `${basePath}/insights/survey-statistics`,
      type: 'card',
      category: 'dashboards',
      icon: faChartPie,
      color: indigo[300],
      name: intl.formatMessage(navigationNodes.insightsSurveyStatistics),
      description: intl.formatMessage(navigationNodes.insightsSurveyStatisticsDescription),
    },
    {
      id: 'benchmarks',
      path: `${basePath}/insights/benchmarks`,
      type: 'card',
      category: 'dashboards',
      icon: faChartBullet,
      color: lime[600],
      name: intl.formatMessage(navigationNodes.benchmarks),
      description: intl.formatMessage(navigationNodes.benchmarksDescription),
    },
    {
      id: 'reports-heading',
      type: 'heading',
      category: 'heading',
      name: intl.formatMessage(homeHeadings.reports),
      isHiddenOnMobile: true,
    },
    {
      id: 'reports',
      path: `${basePath}/reports`,
      type: 'button',
      category: 'reports',
      icon: faFileArrowDown,
      name: intl.formatMessage(navigationNodes.reports),
      isHiddenOnMobile: true,
    },
    {
      id: 'managers-heading',
      type: 'heading',
      category: 'heading',
      name: intl.formatMessage(homeHeadings.managers),
    },
    {
      id: 'benchmarks-button',
      path: `${basePath}/benchmarks`,
      type: 'button',
      category: 'managers',
      icon: faChartBullet,
      name: intl.formatMessage(navigationNodes.benchmarks),
    },
    {
      id: 'survey-manager',
      path: `${basePath}/survey-manager`,
      type: 'button',
      category: 'managers',
      icon: faClipboardListCheckRegular,
      name: intl.formatMessage(navigationNodes.surveyManager),
    },
  ];

  const isVisible = (element: HomeElementType) =>
    hasRoutePermissions(element.path ?? '') &&
    (!element.isHiddenOnMobile || !isMobile) &&
    !element.isHidden;

  return homeElements.filter(isVisible);
}
