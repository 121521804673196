import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { DataGrid as ContentLibraryTextsDataGrid } from '../../../components/content-library/texts-detail/data-grid';

export const ContentLibraryTexts = () => {
  const navigate = useNavigate();
  const { membershipId } = useParams();

  const navigateToContentLibraryList = () => {
    navigate(`/org/${membershipId}/survey-manager/content-library/overview`);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <IconButton onClick={navigateToContentLibraryList}>
          <StyledFontAwesomeIcon icon={faArrowLeft} size="sm" />
        </IconButton>
        <Typography variant="h6">
          <FormattedMessage id="survey.common.texts" defaultMessage="Texts" />
        </Typography>
      </Stack>
      <Stack marginBottom={2}>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.content-library.texts.description"
            defaultMessage="Customize the messages to display in various parts of surveys and survey invitations."
          />
        </Typography>
      </Stack>
      <ContentLibraryTextsDataGrid />
    </Stack>
  );
};
