import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonGroup, IconButton } from '@mui/material';
import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SURVEY_QUESTIONNAIRE_CREATE_PERMISSION, usePermissionStore } from '@trustyou/shared';
import { Button, Spacer, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { DataGrid as SurveyDetailByEntityDataGrid } from '../../../components/survey/detail/by-entity/data-grid';
import { DataGrid as SurveyDetailByQuestionnaireDataGrid } from '../../../components/survey/detail/by-questionnaire/data-grid';
import { NewSurveyQuestionnaireButton } from '../../../components/survey/new-survey-questionnaire-button';
import { SurveyQuestionnaireEmptyState } from '../../../components/survey/survey-questionnaire-empty-state';
import { useGetContentDefaults } from '../../../service/hooks/use-questions';
import { useSurveys } from '../../../service/hooks/use-surveys';

type SurveyDetailView = 'by-questionnaire' | 'by-entity';

export const SurveyDetail = () => {
  const navigate = useNavigate();
  const { data: defaults } = useGetContentDefaults();
  const { hasPermissions } = usePermissionStore();
  const hasSurveyQuestionnaireCreatePermission = hasPermissions(
    SURVEY_QUESTIONNAIRE_CREATE_PERMISSION
  );
  const { surveyId, membershipId } = useParams();
  const [view, setView] = useState<SurveyDetailView>('by-entity');
  const { data: surveys = [] } = useSurveys();
  const { name, questionnaire_count } = surveys.find((survey) => survey.id === surveyId) || {};

  const navigateToSurveyList = () => {
    navigate(`/org/${membershipId}/survey-manager/survey/overview`);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={3}>
        <IconButton onClick={navigateToSurveyList}>
          <StyledFontAwesomeIcon icon={faArrowLeft} size="sm" />
        </IconButton>
        <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            {name}
          </Typography>
          <Button variant="text" disabled>
            <FormattedMessage id="survey.common.edit-name" defaultMessage="Edit name" />
          </Button>
        </Stack>
      </Stack>
      {questionnaire_count && questionnaire_count > 0 ? (
        <>
          <Stack direction="row" spacing={5} alignItems="center" marginBottom={1}>
            <ButtonGroup>
              <Button
                variant={view === 'by-entity' ? 'contained' : 'outlined'}
                onClick={() => setView('by-entity')}
              >
                <FormattedMessage id="survey.common.by-entity" defaultMessage="By entity" />
              </Button>
              <Button
                variant={view === 'by-questionnaire' ? 'contained' : 'outlined'}
                onClick={() => setView('by-questionnaire')}
              >
                <FormattedMessage
                  id="survey.common.by-questionnaire"
                  defaultMessage="By questionnaire"
                />
              </Button>
            </ButtonGroup>
            {/* TODO: Hiding this for now enable it when needed */}
            {/* <Alert severity="warning">
          <FormattedMessage
            id="survey.survey-detail.missing-entities"
            defaultMessage="{missingEntityCount} entities are missing a version"
            values={{ missingEntityCount: 10 }}
          />
        </Alert> */}
            <Spacer />
            {hasSurveyQuestionnaireCreatePermission && <NewSurveyQuestionnaireButton />}
          </Stack>
          {view === 'by-entity' && <SurveyDetailByEntityDataGrid />}
          {view === 'by-questionnaire' && <SurveyDetailByQuestionnaireDataGrid />}
        </>
      ) : (
        <SurveyQuestionnaireEmptyState />
      )}
    </Stack>
  );
};
