import type { PreviewEntitiesSummary } from '@trustyou/shared';
import { Box, Stack, Typography } from '@trustyou/ui';

import { STATUS_CHIP_MAP } from './StatusChips';

import styles from './styles';

type SummaryProps = {
  summary: PreviewEntitiesSummary;
};

const Summary = ({ summary }: SummaryProps) => (
  <Stack direction="row" sx={styles.statusCounts}>
    {Object.entries(summary)
      .filter(([_, value]) => value)
      .map(([key, value]) => (
        <Box key={key} sx={styles.statusCountBox}>
          <Typography sx={styles.statusCountText} variant="subtitle2">
            {value}
          </Typography>
          {STATUS_CHIP_MAP[key]}
        </Box>
      ))}
  </Stack>
);

export default Summary;
