import { type SyntheticEvent, useState } from 'react';
import { type IntlShape, useIntl } from 'react-intl';

import { useChangelingStore } from '@trustyou/shared';
import { Box, SecondaryDrawer, Tab, Tabs } from '@trustyou/ui';

import BenchmarkCompetitorEntities from './BenchmarkCompetitorEntities';
import BenchmarkOwnEntities from './BenchmarkOwnEntities';

import { useBenchmarkStore } from '../../store';
import { styles } from './styles';

interface BenchmarkDetailsTabStructure {
  ownEntities: string;
  competitorEntities: string;
}

const getDetailsTabs = (intl: IntlShape): BenchmarkDetailsTabStructure => ({
  ownEntities: intl.formatMessage({
    id: 'benchmark.details.own-entities',
    defaultMessage: 'Own entities',
  }),
  competitorEntities: intl.formatMessage({
    id: 'benchmark.details.competitor-entities',
    defaultMessage: 'Competitor entities',
  }),
});

export const BenchmarkDetailView = () => {
  const { isChangeling } = useChangelingStore();
  const { selectedBenchmark, setSelectedBenchmark } = useBenchmarkStore();
  const [value, setValue] = useState(0);
  const intl = useIntl();
  const tabs = getDetailsTabs(intl);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <SecondaryDrawer
      header={selectedBenchmark?.name}
      open={!!selectedBenchmark}
      onClose={() => setSelectedBenchmark(undefined)}
      changelingMode={isChangeling}
    >
      {selectedBenchmark && (
        <Box sx={styles.container}>
          <Tabs sx={styles.barContainer} value={value} onChange={handleChange}>
            {Object.entries(tabs).map(([id, message]) => (
              <Tab data-testid={`benchmark-detail-tab-${message}`} key={id} label={message} />
            ))}
          </Tabs>
          <Box sx={styles.body}>
            {value === 0 && <BenchmarkOwnEntities benchmarkId={selectedBenchmark.id} />}
            {value === 1 && <BenchmarkCompetitorEntities benchmarkId={selectedBenchmark.id} />}
          </Box>
        </Box>
      )}
    </SecondaryDrawer>
  );
};
