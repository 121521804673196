import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Chip, PopperMenu } from '@trustyou/ui';

import { USER_PLACEHOLDER } from '../../../../constants';
import { commonMessages } from '../../../../constants/messages';
import { useReview } from '../../../../hooks';
import { getHumanDate } from '../../../../utils/date';
import { messages } from '../../../review-deletion/messages';
import { StatusList } from './status-list';

export function MarkedAsDeletedChip() {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });

  return (
    <PopperMenu
      disablePortal={false}
      placement="bottom"
      Trigger={({ onClick }) => (
        <Chip
          label={intl.formatMessage(messages.markedDeleted)}
          size="small"
          variant="pastelWarning"
          onClick={onClick}
        />
      )}
      Content={() => (
        <StatusList
          items={[
            {
              concept: intl.formatMessage(messages.markedAsDeleted),
              content: getHumanDate(reviewRoot?.deletion?.requested_at ?? ''),
            },
            {
              concept: intl.formatMessage(commonMessages.by),
              content: reviewRoot?.deletion?.username ?? USER_PLACEHOLDER,
            },
            {
              concept: '',
              content: intl.formatMessage(messages.removingFromAnalyticsDisclaimer),
            },
          ]}
        />
      )}
    />
  );
}
