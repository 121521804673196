import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Masonry } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@trustyou/ui';

import { QUESTIONS } from './constants/questions';
import { QuestionTypologyList } from './question-typology-list';

import { useStore } from '../../store/store';
import type { ListItem, Typology } from '../../types/question';

type NewQuestionDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function NewQuestionDialog({ open, onClose: close }: NewQuestionDialogProps) {
  const navigate = useNavigate();
  // const initialQuestionType = useStore.use.initialQuestionType();
  const { setInitialQuestionType, initialQuestionValueItem, setInitialQuestionValueItem } =
    useStore();

  // TODO: we're avoiding using `Object.groupBy` because currently when running `nx test inbox` we get "TypeError: Object.groupBy is not a function"
  // const CATEGORIES = Object.groupBy(QUESTIONS, ({ typology }) => typology);
  const CATEGORIES = {
    Choice: QUESTIONS.filter((q) => q.typology === 'Choice'),
    Rating: QUESTIONS.filter((q) => q.typology === 'Rating'),
    'Contact information': QUESTIONS.filter((q) => q.typology === 'Contact information'),
    Text: QUESTIONS.filter((q) => q.typology === 'Text'),
    Date: QUESTIONS.filter((q) => q.typology === 'Date'),
  };

  const navigateToQuestionEditor = () => {
    setInitialQuestionType('');
    setInitialQuestionValueItem({} as ListItem);

    navigate('question/editor', { state: initialQuestionValueItem });

    close();
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <DialogTitle>
        <FormattedMessage
          id="survey.new-question-dialog.title"
          defaultMessage="Select question type"
        />
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.new-question-dialog.content-text"
            defaultMessage="Can be changed during editing"
          />
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ '&.MuiDialogContent-root': { py: 2, px: 5 } }}>
        <Masonry columns={{ xs: 1, sm: 3 }} spacing={2}>
          {Object.entries(CATEGORIES).map(([typology, items]) => (
            <QuestionTypologyList
              key={typology}
              typology={typology as Typology}
              items={items as ListItem[]}
            />
          ))}
        </Masonry>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={close}>
          <FormattedMessage id="survey.common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="contained"
          disabled={Object.keys(initialQuestionValueItem).length === 0}
          onClick={navigateToQuestionEditor}
        >
          <FormattedMessage id="survey.common.next" defaultMessage="Next" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
