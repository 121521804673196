import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ReviewProviderStore {
  googleConnectEntityId?: string;
  setGoogleConnectEntityId: (googleConnectEntityId?: string) => void;
}

const useReviewProviderStore = create<ReviewProviderStore>()(
  persist<ReviewProviderStore>(
    (set) => ({
      setGoogleConnectEntityId: (googleConnectEntityId) => set(() => ({ googleConnectEntityId })),
    }),
    {
      name: 'review-provider-state',
    }
  )
);

export default useReviewProviderStore;
