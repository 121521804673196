import { defineMessages } from 'react-intl';

export const submissionMessages = defineMessages({
  paginationPage: {
    id: 'survey.submission.pagination.page',
    defaultMessage: 'Page {naturalPage} of {pageCount}',
  },
  paginationBack: {
    id: 'survey.submission.common.back',
    defaultMessage: 'Back',
  },
  paginationNext: {
    id: 'survey.submission.common.next',
    defaultMessage: 'Next',
  },
  questionDropdownPlaceholder: {
    id: 'survey.submission.question.dropdown.placeholder',
    defaultMessage: 'Select',
  },
  shortOrLongHelperText: {
    id: 'survey.submission.question.text.short-or-long.helper-text',
    defaultMessage: 'Remaining characters: {remainingCharacterCount}',
  },
  emailErrorHelperText: {
    id: 'survey.submission.question.text.email-error.helper-text',
    defaultMessage: 'Enter a valid email address',
  },
  phoneNumberErrorHelperText: {
    id: 'survey.submission.question.text.phone-number-error.helper-text',
    defaultMessage: 'Enter a valid phone number',
  },
  requiredFieldErrorHelperText: {
    id: 'survey.submission.question.text.required-field-error.helper-text',
    defaultMessage: 'This field is required',
  },
  mandatoryQuestion: {
    id: 'survey.submission.common.mandatory-question',
    defaultMessage: 'Mandatory question',
  },
  invalidFormWarning: {
    id: 'survey.submission.snackbar.invalid-form',
    defaultMessage: 'Check the questions with warnings.',
  },
  submit: {
    id: 'survey.submission.submit.button',
    defaultMessage: 'Submit',
  },
  submitting: {
    id: 'survey.submission.submitting.button',
    defaultMessage: 'Submitting...',
  },
  mandatoryQuestionsDisclaimer: {
    id: 'survey.submission.mandatory-questions.disclaimer',
    defaultMessage: 'Fields marked with * are mandatory.',
  },
  by: {
    id: 'survey.submission.footer.by',
    defaultMessage: 'by',
  },
});
