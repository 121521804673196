import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faFingerprint, faTrash, faUser } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type BookingConnectionRequestCredentials,
  CONNECT_PROVIDER_NAMES,
  type GoogleAccountCredentials,
  type ProviderAccount,
  defineProvider,
  getDisplayedProviderName,
} from '@trustyou/shared';
import { Avatar, Box, StyledFontAwesomeIcon as Icon, IconButton, Typography } from '@trustyou/ui';

import styles from './styles';

type AccountViewProps = {
  account: ProviderAccount;
  onDelete: (id: string) => void;
};

export function AccountView({ account, onDelete }: AccountViewProps) {
  const provider: CONNECT_PROVIDER_NAMES = defineProvider(account);

  const displayedProviderName = getDisplayedProviderName(provider);

  const isGoogleAccount = provider === CONNECT_PROVIDER_NAMES.GOOGLE;
  const isBookingAccount = provider === CONNECT_PROVIDER_NAMES.BOOKING;

  const getProviderAvatarIcon = () => {
    if (isGoogleAccount) return faUser;
    return faFingerprint;
  };

  const avatarIcon = getProviderAvatarIcon();

  const renderProviderAccountInfo = () => {
    if (isGoogleAccount)
      return (
        <>
          <Typography variant="body2">
            {(account.credential as GoogleAccountCredentials).name}
          </Typography>
          <Typography variant="caption">
            {(account.credential as GoogleAccountCredentials).email}
          </Typography>
        </>
      );
    if (isBookingAccount) {
      const credential: BookingConnectionRequestCredentials =
        account.credential as BookingConnectionRequestCredentials;
      if (credential.legal_entity_id)
        return (
          <>
            <Typography variant="body2">{credential.legal_entity_id}</Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="review-providers.provider-account.legal-entity-id"
                defaultMessage="Legal Entity ID"
              />
            </Typography>
          </>
        );
      if (credential.contact_email)
        return (
          <>
            <Typography variant="body2">{credential.contact_email}</Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="review-providers.provider-account.contact-email"
                defaultMessage="Contact Email"
              />
            </Typography>
          </>
        );
    }
    throw new Error('Provider cannot be determined or is not supported.');
  };

  return (
    <Box sx={styles.providerAccountWrapper}>
      <Avatar sx={styles.providerAccountAvatar}>
        <Icon icon={avatarIcon} />
      </Avatar>
      <Box sx={styles.providerAccountInfo}>{renderProviderAccountInfo()}</Box>
      <IconButton
        data-testid="delete-account-button"
        data-gtm-id={`${displayedProviderName}-delete-account-button`}
        size="medium"
        sx={styles.providerAccountDeleteIcon}
        onClick={() => onDelete(account.provider_account_id)}
      >
        <Icon icon={faTrash} />
      </IconButton>
    </Box>
  );
}
