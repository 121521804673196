import { FormattedMessage } from 'react-intl';

import { EmptyStatePlaceholder, personFillingAForm } from '@trustyou/ui';

export const EmptyContentPlaceholder = ({ actionHandler }: { actionHandler: () => void }) => {
  return (
    <EmptyStatePlaceholder
      img={personFillingAForm}
      title={
        <FormattedMessage
          id="survey.survey-editor.content.empty-state.title"
          defaultMessage="No content yet"
        />
      }
      description={
        <FormattedMessage
          id="survey.survey-editor.content.empty-state.description"
          defaultMessage="Click to add questions or messages from the Question and Content library"
        />
      }
      emptyStateContainer={{ height: 450 }}
      action={{
        testId: 'survey-empty-state',
        message: <FormattedMessage id="survey.common.add-content" defaultMessage="Add content" />,
        handler: actionHandler,
        styles: { marginTop: 3 },
      }}
    />
  );
};
