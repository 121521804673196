import { useMutation, useQuery } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';

import type {
  CreateQuestionIn,
  CreateQuestionnaireSchema,
  CreateSurveySchema,
  SurveyWithCount,
} from '../../client';
import {
  QUESTIONNAIRE_CREATE,
  QUESTION_CREATE,
  SURVEY_CREATE,
  SURVEY_LIST,
} from '../constants/query-keys';

async function fetchSurveyList(): Promise<SurveyWithCount[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/survey/list`);
  return data;
}

async function createSurvey({ name }: CreateSurveySchema): Promise<{ id: string }> {
  const { data } = await $assApi.post(`${SURVEY_BASE_PATH}/survey/create`, { name });
  return data;
}

async function createSurveyQuestionnaire(body: CreateQuestionnaireSchema) {
  const { data } = await $assApi.post(`${SURVEY_BASE_PATH}/questionnaire/create`, body);
  return data;
}

async function createSurveyQuestion(body: CreateQuestionIn) {
  const { data } = await $assApi.post(`${SURVEY_BASE_PATH}/question/create`, body);
  return data;
}

export function useSurveys() {
  return useQuery({
    queryKey: [SURVEY_LIST],
    queryFn: fetchSurveyList,
  });
}

export function useCreateSurvey() {
  return useMutation({
    mutationFn: ({ name }: CreateSurveySchema) => createSurvey({ name }),
    mutationKey: [SURVEY_CREATE],
  });
}

export function useCreateSurveyQuestionnaire() {
  return useMutation({
    mutationFn: (body: CreateQuestionnaireSchema) => createSurveyQuestionnaire(body),
    mutationKey: [QUESTIONNAIRE_CREATE],
  });
}

export function useCreateSurveyQuestion() {
  return useMutation({
    mutationFn: (body: CreateQuestionIn) => createSurveyQuestion(body),
    mutationKey: [QUESTION_CREATE],
  });
}
