import type { ReviewEnvelopeDetailSchema } from '@trustyou/inbox';
import { type BasicEntity, isDefined } from '@trustyou/shared';
import { uniq } from 'lodash';

import { SENTIMENTS_MAP } from './constants';

export const processData = (
  rows: ReviewEnvelopeDetailSchema[],
  categoryId: string,
  categoryType: string,
  categoryMap: Record<string, Record<string, string[]>>,
  subCategoryMap: Record<string, Record<string, string[]>>,
  selectedSentiment: string,
  page: number,
  rowsPerPage: number
) => {
  let subCategoryIds: string[] = [];
  if (categoryType === 'top-level') {
    Object.values(categoryMap).forEach((row) => {
      subCategoryIds = subCategoryIds.concat(row[categoryId] || []);
    });
  } else {
    subCategoryIds = [categoryId];
  }

  let mappedTopics: string[] = [];
  Object.values(subCategoryMap).forEach((categoryTopics) => {
    subCategoryIds.forEach((subCat) => {
      mappedTopics = mappedTopics.concat(categoryTopics[subCat] || []);
    });
  });

  // Filter data by matches sentiments
  const filteredData = rows
    .map((row, _id) => {
      const opinions = (row.sema_matches?.opinions || []).filter((opinion) =>
        mappedTopics.includes(opinion.topic_id)
      );
      const sentiments = uniq(opinions.map((opinion) => SENTIMENTS_MAP[opinion.sentiment]));
      return {
        _id,
        ...row,
        sema_matches: {
          ...row.sema_matches,
          opinions,
        },
        sentiments,
      };
    })
    .filter((row) => selectedSentiment === 'all' || row.sentiments.includes(selectedSentiment));

  // handle ui side pagination
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);
  const entityIds = paginatedData.map((row) => row.meta.entity_id);
  return {
    paginatedData,
    entityIds,
    total: filteredData.length,
  };
};

export const getEntityNamesMap = (entities: BasicEntity[]) =>
  entities.reduce(
    (prev, cur) => ({
      ...prev,
      [cur.id]: cur.name,
    }),
    {} as { [key: string]: string }
  );

export const getSentimentByImpactScore = (impactScore?: string) => {
  if (!isDefined(impactScore)) return 'all';
  if (Number(impactScore) < 0) return 'negative';
  if (Number(impactScore) > 0) return 'positive';
  return 'neutral';
};
