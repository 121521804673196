import { useFetchReviewsInfo } from './api-query';

export function useReviewsInfo() {
  const { data: reviewsInfo } = useFetchReviewsInfo();

  const lastProducedAt = reviewsInfo?.last_produced_at ?? '';
  const hasReviews = lastProducedAt !== undefined;

  return { lastProducedAt, hasReviews };
}
