import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { stringToDateAndTime, useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import { Box, DataGridPro, Stack, Typography } from '@trustyou/ui';

import type { SurveyWithCount } from '../../../client';
import { CustomToolbar } from '../../../components/shared/custom-toolbar';
import styles from '../../../components/shared/styles';
import { SurveyEmptyState } from '../../../components/survey/survey-empty-state';
import { useSurveys } from '../../../service/hooks/use-surveys';

function useSurveyListColumns({ intl }: { intl: IntlShape }) {
  const columns: GridColDef<SurveyWithCount>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'name',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.survey-name',
        defaultMessage: 'Survey name',
      }),
      flex: 1,
    },
    {
      field: 'questionnaire_count',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.questionnaires',
        defaultMessage: 'Questionnaires',
      }),
      flex: 1,
    },
    {
      field: 'entity_count',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.entities',
        defaultMessage: 'Entities',
      }),
      flex: 1,
    },
    {
      field: 'last_updated',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.last-updated',
        defaultMessage: 'Last updated',
      }),
      flex: 1,
      valueGetter: ({ row }) => stringToDateAndTime(row.metadata.updated_at as string),
    },
  ];

  return columns;
}

function SurveyListHeader() {
  return (
    <Box sx={{ marginBottom: 2.5 }}>
      <Typography variant="h6" component="h2">
        <FormattedMessage id="survey.common.overview" defaultMessage="Overview" />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          id="survey.survey-editor.subtitle"
          defaultMessage="Create and manage all your surveys here"
        />
      </Typography>
    </Box>
  );
}

export function SurveyList() {
  const navigate = useNavigate();
  const { data: surveys = [] } = useSurveys();
  const { locale } = useLanguageStore();
  const intl = useIntl();
  const dataGridIntl = useDataGridIntl();
  const columns = useSurveyListColumns({ intl });

  if (surveys.length === 0)
    return (
      <Stack spacing={1}>
        <SurveyListHeader />
        <Box padding={10}>
          <SurveyEmptyState />
        </Box>
      </Stack>
    );

  return (
    <Stack spacing={1}>
      <SurveyListHeader />
      <DataGridPro
        columns={columns}
        rows={surveys || []}
        rowCount={surveys.length}
        onCellClick={(params) => navigate(`survey/detail/${params.id}`)}
        pagination
        paginationMode="client"
        pageSizeOptions={[10, 30, 100]}
        // paginationModel={paginationModel}
        // onPaginationModelChange={setPaginationModel}
        // filterMode="server"
        // onFilterModelChange={updateFilter}
        disableRowSelectionOnClick
        ignoreDiacritics
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        sx={styles.disableCellOutline}
        localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
        density="standard"
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              duplicate: false,
            },
          },
        }}
      />
    </Stack>
  );
}
