import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrown, faFaceMeh, faFaceSmile } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Stack, Typography } from '@trustyou/ui';

import { useSemanticCategoryItems } from '../../hooks';

const styleMap = {
  positive: { icon: faFaceSmile, color: 'success.main' },
  neutral: { icon: faFaceMeh, color: 'warning.main' },
  negative: { icon: faFaceFrown, color: 'error.main' },
};

const SEPARATOR = '/';

export type Category = 'positive' | 'neutral' | 'negative';

export type SemanticGroupProps = {
  category: Category;
  items: string[];
};

export const SemanticGroup = ({ category, items }: SemanticGroupProps) => {
  const icon = styleMap[category].icon;
  const color = styleMap[category].color;

  if (items.length === 0) {
    return null;
  }

  return (
    <Typography component="span" color={color} data-testid={`${category}-sema-category`}>
      <FontAwesomeIcon icon={icon} style={{ marginInlineEnd: '10px' }} />
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        return (
          <span key={index} style={{ fontSize: '14px' }}>
            <span
              style={{
                marginInline: '8px',
                marginInlineStart: isFirst ? 0 : '8px',
                marginInlineEnd: isLast ? '10px' : '8px',
              }}
            >
              {item}
            </span>
            {!isLast && <span>{SEPARATOR}</span>}
          </span>
        );
      })}
    </Typography>
  );
};

export const SemanticAnalysis = () => {
  const { getSemanticCategoryItems } = useSemanticCategoryItems();

  return (
    <Stack>
      <SemanticGroup category="positive" items={getSemanticCategoryItems('p')} />
      <SemanticGroup category="neutral" items={getSemanticCategoryItems('o')} />
      <SemanticGroup category="negative" items={getSemanticCategoryItems('n')} />
    </Stack>
  );
};
