import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from '@trustyou/ui';

import {
  RenderEmailInvitation,
  RenderSubmitSection,
  RenderThankyouPage,
  RenderWelcomeMessageAndContent,
} from './render-questions';

import { Question } from '../../../components/feedback/components/question';
import type {
  PaginatedSurveyProps,
  SurveyQuestionnaireEditorContentOutput,
} from '../../../types/survey';
import { findOverallScoreQuestion } from '../../../utils/survey-questionnaire-editor';

const PaginationKeytoName: { [key: string]: JSX.Element } = {
  'survey-content': (
    <FormattedMessage
      id="survey.survey-editor.page.survey-content"
      defaultMessage="Survey content"
    />
  ),
  'submit-section': (
    <FormattedMessage
      id="survey.survey-editor.page.submit-section"
      defaultMessage="Submit section"
    />
  ),
  'thank-you-page': (
    <FormattedMessage id="survey.survey-editor.page.thank-you-page" defaultMessage="Thank you" />
  ),
  'email-invitation': (
    <FormattedMessage
      id="survey.survey-editor.page.email-invitation"
      defaultMessage="Email invitation"
    />
  ),
};

export const SurveyQuestionnaireEditorPreview = ({ activeStep }: { activeStep: number }) => {
  const { watch } = useFormContext();
  const intl = useIntl();

  const editorWelcomeMessage = watch('surveyQuestionnaireEditorWelcomeMessage');
  const surveyContent = watch('surveyContent') as SurveyQuestionnaireEditorContentOutput[];
  const submitSection = watch('submitSection');
  const editorThankYouMessage = watch('surveyQuestionnaireEditorThankyouMessage');
  const emailInvitation = watch('surveyQuestionnaireEditorEmailInvitation');

  const [paginatedSurvey, setPaginatedSurvey] = useState<PaginatedSurveyProps[]>([
    { 'survey-content': [editorWelcomeMessage, surveyContent] },
    { 'submit-section': [submitSection] },
    { 'thank-you-page': [editorThankYouMessage] },
    { 'email-invitation': [emailInvitation] },
  ]);
  const [selectedPreviewPage, setSelectedPreviewPage] = useState<string>('survey-content');

  const isContentExist =
    (editorWelcomeMessage && editorWelcomeMessage.id !== 'no-welcome-message') ||
    surveyContent?.length > 0 ||
    submitSection?.length > 0 ||
    editorThankYouMessage ||
    emailInvitation;

  useEffect(() => {
    if (activeStep === 6) {
      setSelectedPreviewPage('email-invitation');
    } else if (activeStep === 5) {
      setSelectedPreviewPage('thank-you-page');
    } else if (activeStep === 3) {
      setSelectedPreviewPage('submit-section');
    } else if ([0, 1, 2, 4, 7].includes(activeStep)) {
      setSelectedPreviewPage('survey-content');
    }
  }, [activeStep]);

  useEffect(() => {
    setPaginatedSurvey([
      { 'survey-content': [editorWelcomeMessage, surveyContent] },
      { 'submit-section': [submitSection] },
      { 'thank-you-page': [editorThankYouMessage] },
      { 'email-invitation': [emailInvitation] },
    ]);
  }, [editorWelcomeMessage, surveyContent, submitSection, editorThankYouMessage, emailInvitation]);

  return (
    <Stack spacing={2} sx={{ marginInline: 5 }}>
      <FormControl sx={{ width: 160, marginTop: 2 }}>
        <InputLabel>
          <FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />
        </InputLabel>
        <Select
          label={<FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
          value={selectedPreviewPage}
          onChange={(event) => setSelectedPreviewPage(event.target.value as string)}
        >
          {paginatedSurvey.map((page, index) => {
            const pageName = Object.keys(page)[0];
            return (
              <MenuItem key={index} value={pageName}>
                {PaginationKeytoName[pageName]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {!isContentExist && (
        <Question
          name="initial-preview"
          title={intl.formatMessage({
            id: 'survey.survey-editor.initial-preview-card.title',
            defaultMessage: 'This is a preview of your Survey',
          })}
          description={intl.formatMessage({
            id: 'survey.survey-editor.initial-preview-card.description',
            defaultMessage: 'Start adding content',
          })}
        />
      )}
      <Box sx={{ maxHeight: '600px', overflow: 'auto' }}>
        {selectedPreviewPage === 'survey-content' && <RenderWelcomeMessageAndContent />}
        {selectedPreviewPage === 'submit-section' && <RenderSubmitSection />}
        {selectedPreviewPage === 'thank-you-page' && <RenderThankyouPage />}
        {selectedPreviewPage === 'email-invitation' && (
          <RenderEmailInvitation
            emailInvite={emailInvitation}
            overallScoreQuestion={findOverallScoreQuestion(surveyContent)}
          />
        )}
      </Box>
    </Stack>
  );
};
