import { FormattedMessage } from 'react-intl';

import { faPlus, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { AdvancedSelectionScopeRule } from '@trustyou/shared';
import { Button, StyledFontAwesomeIcon as Icon, IconButton, Stack, Typography } from '@trustyou/ui';

import { AttributeSelector } from './AttributeSelector';
import { AttributeValuesSelector } from './AttributeValuesSelector';

import { advancedScopeMessages } from '../../../constants/messages/users';
import { styles } from './styles';

type Props = {
  rules: AdvancedSelectionScopeRule[];
  onChange: (rules: AdvancedSelectionScopeRule[]) => void;
};

export const DEFAULT_DEFINITION_RULE = {
  values: [],
};

const MAX_FILTERS_COUNT = 10;

export const RuleSelector = ({ rules, onChange }: Props) => {
  const addRule = () => {
    onChange([...rules, DEFAULT_DEFINITION_RULE]);
  };

  const deleteRule = (id: number) => {
    onChange(rules.filter((_, index) => index !== id));
  };

  const handleAttributeValuesChange = (id: number, values: string[]) => {
    onChange(rules.map((rule, index) => (index === id ? { ...rule, values } : rule)));
  };

  const handleAttributeChange = (id: number, attribute: string) => {
    onChange(rules.map((rule, index) => (index === id ? { attribute, values: [] } : rule)));
  };

  return (
    <Stack marginTop={1}>
      {rules.length > 0 && (
        <Stack gap={1} marginBottom={2}>
          {rules.map((rule, index) => (
            <Stack key={`definition-rule-${index}`} sx={styles.ruleRow}>
              <AttributeSelector
                value={rule.attribute}
                onChange={(value) => handleAttributeChange(index, value)}
              />
              <Typography variant="body1">
                <FormattedMessage {...advancedScopeMessages.rulesIs} />
              </Typography>
              <AttributeValuesSelector
                key={rule.attribute}
                attribute={rule.attribute}
                defaultValues={rule.values}
                onChange={(values) => handleAttributeValuesChange(index, values)}
              />
              <IconButton onClick={() => deleteRule(index)} sx={{ marginLeft: 'auto' }}>
                <Icon size="xs" icon={faTrash} />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      )}
      <Button
        sx={styles.addRuleButton}
        onClick={addRule}
        disabled={rules.length >= MAX_FILTERS_COUNT}
      >
        <Icon icon={faPlus} />
        <FormattedMessage {...advancedScopeMessages.rulesAdd} />
      </Button>
    </Stack>
  );
};
