import { FormattedMessage, useIntl } from 'react-intl';

import { actionMessages, useDeleteBenchmark } from '@trustyou/shared';
import { BackdropSpinner, DeleteModal, snackbar } from '@trustyou/ui';

import { deleteConfirmation } from '../../constants/messages/benchmarks';
import { useDashboardDelayMessage } from '../../hooks';
import { useBenchmarkStore } from '../../store';

export const DeleteConfirmation = () => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();
  const deleteBenchmark = useDeleteBenchmark();
  const { deletableBenchmark, closeDeleteConfirmation, closeManageDrawer } = useBenchmarkStore();

  const handleDelete = () => {
    closeDeleteConfirmation();
    deleteBenchmark.mutate(deletableBenchmark?.id ?? '', {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(deleteConfirmation.deleteSuccess, {
            name: deletableBenchmark?.name,
            delay: dashboardDelayMessage,
          })
        );
        closeManageDrawer();
      },
    });
  };

  return (
    <>
      <DeleteModal
        fullWidth
        maxWidth="xs"
        cancelText={<FormattedMessage {...actionMessages.cancel} />}
        content={<FormattedMessage {...deleteConfirmation.deleteContent} />}
        dataName="benchmark-delete"
        deleteText={<FormattedMessage {...actionMessages.delete} />}
        onClose={closeDeleteConfirmation}
        onDelete={handleDelete}
        title={intl.formatMessage(deleteConfirmation.deleteTitle, {
          name: deletableBenchmark?.name,
        })}
        open={!!deletableBenchmark}
      />
      <BackdropSpinner isLoading={deleteBenchmark.isPending} />
    </>
  );
};
