import { useLocation } from 'react-router-dom';

import type { Node } from '../types';

export const useActiveNode = (nodes: Node[], footerNodes: Node[] = []) => {
  const { pathname } = useLocation();
  let activeNode: Node | undefined;
  nodes.concat(footerNodes).forEach((node) => {
    if (node.path && pathname.includes(node.path)) {
      activeNode = node;
    } else if (!activeNode && node.childNodes) {
      activeNode = node.childNodes.find(
        (childNode) => childNode.path && pathname.includes(childNode.path)
      );
    }
  });
  return activeNode;
};
