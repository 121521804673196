import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useOrgCenter } from '../../../../hooks';
import type { CreateOrganization, EditOrganization } from '../../../../types/model/organization';
import type { PaginationRequest } from '../../../../types/model/shared';
import {
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  EDIT_ORGANIZATION,
  FETCH_MEMBERSHIPS_AND_INVITATIONS,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION_ADMIN,
  FETCH_ORGANIZATION_LOGO,
  FETCH_USER_ORGANIZATION,
  IS_VALID_ORGANIZATION,
} from '../../../constants/queryKeys';
import {
  createOrganization,
  deleteOrganization,
  editOrganization,
  fetchOrganizations,
  isValidOrganizationName,
} from '../../../queries';
import {
  editUserOrganization,
  fetchOrganizationAdmin,
  fetchOrganizationLogo,
  fetchUserOrganization,
} from '../../../queries/domains/ass/organization';

export function useCreateOrganization() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (organization: CreateOrganization) => createOrganization(organization),
    mutationKey: [CREATE_ORGANIZATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ORGANIZATIONS] });
    },
  });
}

export function useEditOrganization() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (organization: EditOrganization) => editOrganization(organization),
    mutationKey: [EDIT_ORGANIZATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ORGANIZATIONS] });
      queryClient.refetchQueries({ queryKey: [FETCH_ORGANIZATION_ADMIN] });
    },
  });
}

export function useEditUserOrganization() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editUserOrganization,
    mutationKey: [EDIT_ORGANIZATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_MEMBERSHIPS_AND_INVITATIONS] });
      queryClient.refetchQueries({ queryKey: [FETCH_USER_ORGANIZATION] });
      queryClient.refetchQueries({ queryKey: [FETCH_ORGANIZATION_LOGO] });
    },
  });
}

export function useOrganizations(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_ORGANIZATIONS, { pagination, sortKey, searchKey }],
    queryFn: () => fetchOrganizations(pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useIsValidOrganizationName() {
  return useMutation({
    mutationFn: (name: string) => isValidOrganizationName(name),
    mutationKey: [IS_VALID_ORGANIZATION],
  });
}

export function useDeleteOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (organizationId: string) => deleteOrganization(organizationId),
    mutationKey: [DELETE_ORGANIZATION],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ORGANIZATIONS] });
    },
  });
}

export function useUserOrganization(withSummary?: boolean) {
  return useQuery({
    queryKey: [FETCH_USER_ORGANIZATION, { withSummary }],
    queryFn: () => fetchUserOrganization(withSummary),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useOrganizationLogo() {
  const orgCenter = useOrgCenter();
  return useQuery({
    queryKey: [FETCH_ORGANIZATION_LOGO],
    queryFn: fetchOrganizationLogo,
    refetchOnWindowFocus: false,
    enabled: !!orgCenter,
  });
}

export function useOrganizationAdmin(organizationId?: string) {
  return useQuery({
    queryKey: [FETCH_ORGANIZATION_ADMIN],
    queryFn: () => {
      if (organizationId == null) {
        // Throw an error or return a default value. This code won't be executed if `enabled` is false.
        throw new Error('Entity ID is missing');
      }
      return fetchOrganizationAdmin(organizationId);
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
