import dayjs from 'dayjs';

import { DEFAULT_TIME_RANGE_OPTION_ENGLISH } from './constants';

import type { ReviewFilters, ReviewView, ScoreRange, ViewScoreEnum } from '../client';
import {
  MAX_ENTITIES,
  SCORE_RANGE_MAP,
  SORTING_API_NAMES_MAP,
  SORTING_SEMANTIC_NAMES_MAP,
  SURVEY_TYPE_KEYS_MAP,
  TIME_RANGE_IN_SECONDS_MAP,
  TIME_RANGE_SEMANTIC_MAP,
  TIME_RANGE_SHORT_NAME_OR_STRINGIFIED_JSON_MAP,
} from '../constants/filters';
import type { FiltersForm, GetSourcesReturnType, Range, UserFilters } from '../types';

export const getSurveyTypesSemantic = (surveyTypes: string[]) =>
  surveyTypes.map((surveyType) => SURVEY_TYPE_KEYS_MAP[surveyType]);

export const getTimeRangeSemanticName = (shortnameOrStringifiedJson: string): string =>
  TIME_RANGE_SEMANTIC_MAP[shortnameOrStringifiedJson];

export const getTimeRangeShortnameOrStringifiedJson = (semanticName: string): string =>
  TIME_RANGE_SHORT_NAME_OR_STRINGIFIED_JSON_MAP[semanticName];

const getTimeRange = (shortname: string): Range => {
  const timeRangeInSeconds = TIME_RANGE_IN_SECONDS_MAP[shortname];
  if (!timeRangeInSeconds) {
    const [start, end] = shortname.split('-');
    return {
      start: new Date(start).valueOf(),
      end: new Date(end).valueOf(),
    };
  }
  return {
    start: Math.floor(Date.now() / 1000) - timeRangeInSeconds,
    end: 0,
  };
};

export const getScoreRanges = (scoreNames: ViewScoreEnum[]): ScoreRange[] => {
  return scoreNames.map((scoreName) => {
    const range = SCORE_RANGE_MAP[scoreName];
    if (typeof range === 'boolean') {
      return { no_score: true };
    }
    return { start: range[0], end: range[1] };
  });
};

export const getSorting = (semanticName: string) => SORTING_API_NAMES_MAP[semanticName];

export const getSortingSemanticName = (sorting: string) => SORTING_SEMANTIC_NAMES_MAP[sorting];

export const mapViewFilters = (
  filters?: UserFilters,
  sources?: GetSourcesReturnType
): UserFilters => {
  if (!filters) return {};
  const definedData = Object.keys(filters).reduce((acc, key) => {
    const value = filters[key as keyof ReviewFilters];
    const isExcludedKey = ['relative_timerange'].includes(key);
    const isEmptyArray = Array.isArray(value) && !value.length;
    // skip this key when
    if (!value || isEmptyArray || isExcludedKey) return acc;
    return { ...acc, [key]: value };
  }, {});

  const score = filters.score ? { score: getScoreRanges(filters.score) } : {};

  const sourcesList =
    sources && Object.values(sources).filter((value) => filters.source?.includes(value));

  let timeRange = {};
  if (filters.relative_timerange) {
    timeRange = { timerange: getTimeRange(filters.relative_timerange) as Range };
  }
  if (filters.timerange) {
    timeRange = { timerange: filters.timerange as Range };
  }

  return {
    ...definedData,
    ...(sourcesList && { source: sourcesList }),
    ...timeRange,
    ...score,
  } as UserFilters;
};

export const getViewFilters = (
  data: FiltersForm,
  sourcesLength?: number,
  languagesLength?: number,
  surveyNamesLength?: number
): ReviewView => {
  const filters: ReviewView = {};

  filters.status = data.status;
  filters.score = data.score;
  filters.source = data.source?.length === sourcesLength ? ['all'] : data.source;
  filters.sema_categories = data.categories;
  filters.sema_subcategories = data.subcategories;
  filters.sema_topics = data.semaTopics;
  filters.entity_ids = data.entities?.slice(0, MAX_ENTITIES);
  filters.respondable = data.respondable;
  filters.language = data.language?.length === languagesLength ? ['all'] : data.language;

  if (!filters.survey) {
    filters.survey = {};
  }
  filters.survey.ids = data.survey_name?.length === surveyNamesLength ? ['all'] : data.survey_name;

  // FIXME: messed up logic for time range field (backend involved in defining `timerange` vs `relative_timerange` vs `preset_timerange`)
  // https://app-api.cxp-integration.trustyou.com/api/inbox/docs#/Review/filter_reviews_api_inbox__organization_id__reviews_filter_post
  // `ReviewFilters` schema
  const CUSTOM_TIME_RANGE_REGEX = /^\d{2}\.\d{2}\.\d{4} - \d{2}\.\d{2}\.\d{4}$/;
  const hasTimeRangeFilter = ![DEFAULT_TIME_RANGE_OPTION_ENGLISH, ''].includes(data.rangename);
  if (CUSTOM_TIME_RANGE_REGEX.test(data.rangename)) {
    const [start, end] = data.rangename.split('-');
    const startDate = dayjs(start, 'DD.MM.YYYY');
    const endDate = dayjs(end, 'DD.MM.YYYY');
    filters.timerange = {
      start: startDate.unix(),
      end: endDate.endOf('day').unix(),
    };
  } else if (getTimeRangeShortnameOrStringifiedJson(data.rangename)?.[0] === '{') {
    const { start, end } = JSON.parse(getTimeRangeShortnameOrStringifiedJson(data.rangename));
    filters.preset_timerange = {
      start,
      end,
    };
  } else if (hasTimeRangeFilter) {
    filters.relative_timerange = getTimeRangeShortnameOrStringifiedJson(data.rangename);
  }

  return filters;
};
