import { FormattedMessage, useIntl } from 'react-intl';

import { type ButtonProps, Chip } from '@mui/material';
import { Button, CardActions, CardContent, Stack, TextField, Typography } from '@trustyou/ui';

import type { Issue } from '../../../types';

type FeedbackFormStepProps = {
  issueOptions: {
    label: string;
    value: Issue;
  }[];
  selectedIssues: Issue[];
  isOtherSelected?: boolean;
  feedbackText: string;
  setFeedbackText: (text: string) => void;
  onSkip: ButtonProps['onClick'];
  toggleSelectedIssue: (issue: Issue) => void;
  onSend: ButtonProps['onClick'];
};

export function FeedbackFormStep({
  issueOptions,
  selectedIssues,
  isOtherSelected,
  toggleSelectedIssue,
  feedbackText,
  setFeedbackText,
  onSkip,
  onSend,
}: FeedbackFormStepProps) {
  const intl = useIntl();

  return (
    <>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage
            id="inbox.response.ai.rating.dialog.header"
            defaultMessage="What went wrong?"
          />
        </Typography>
      </CardContent>
      <CardContent>
        <Stack
          component="ul"
          direction="row"
          sx={{
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            gap: 1.5,
            flexWrap: 'wrap',
          }}
        >
          {issueOptions.map((option) => (
            <Chip
              component="li"
              key={option.value}
              label={option.label}
              color="secondary"
              variant={selectedIssues.includes(option.value) ? 'filled' : 'outlined'}
              sx={{ '&.MuiChip-filled': { border: `1px solid transparent` } }}
              onClick={() => toggleSelectedIssue(option.value)}
            />
          ))}
        </Stack>
        {isOtherSelected && (
          <TextField
            placeholder={intl.formatMessage({
              id: 'inbox.response.ai.rating.dialog.message.placeholder',
              defaultMessage: 'Tell us more (optional)',
            })}
            value={feedbackText}
            onChange={(event) => setFeedbackText(event.target.value)}
            fullWidth
            multiline
            minRows="3"
            sx={{ marginTop: 2 }}
          />
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button onClick={onSkip}>
          <FormattedMessage id="inbox.action.skip" defaultMessage="Skip" />
        </Button>
        <Button variant="contained" onClick={onSend}>
          <FormattedMessage id="inbox.action.send" defaultMessage="Send" />
        </Button>
      </CardActions>
    </>
  );
}
