import { Skeleton, type SxProps, type Theme } from '@mui/material';
import type { SelectProps } from '@trustyou/ui';

export const LoadingDropdown = ({
  size = 'small',
  sx = {},
}: {
  size?: SelectProps['size'];
  sx?: SxProps<Theme>;
}) => (
  // @ts-expect-error sx?.width: Property 'width' does not exist on type 'CSSPseudoSelectorProps<Theme>'.
  <Skeleton variant="rounded" height={size === 'small' ? 41 : 57} width={sx?.width ?? 220} />
);
