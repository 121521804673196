import { type ReactNode, useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { TextFieldProps } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import {
  Asterisk,
  FormControl,
  InputLabel,
  Stack,
  StyledFormControl,
  TextField,
} from '@trustyou/ui';
import { isInteger } from 'lodash';

import { submissionMessages } from '../../../../i18n-submission-ui-subset/messages';
import { CountrySelect } from '../../components/country-select';
import { Question, type QuestionProps } from '../../components/question';

export type TextQuestionVariant = 'short_text' | 'long_text' | 'email' | 'phone';

export type TextQuestionProps = QuestionProps & {
  variant?: TextQuestionVariant;
  minCharacters?: number;
  maxCharacters?: number;
  initialValue?: string;
  label?: ReactNode;
  showMandatoryAsteriskForLabel?: boolean;
  isContactInfo?: boolean;
};

export function TextQuestion({
  name,
  control,
  variant = 'short_text',
  minCharacters = 0,
  maxCharacters = 255,
  label,
  showMandatoryAsteriskForLabel,
  isContactInfo,
  ...props
}: TextQuestionProps) {
  const intl = useIntl();
  const { isSmallPhone } = useResponsive();
  const FormControlWrapper = isContactInfo ? StyledFormControl : FormControl;

  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [value, setValue] = useState(field.value ?? '');

  const errorMessage = error?.message ?? '';
  const remainingCharacterCount = maxCharacters - value.length;

  const isText = variant === 'short_text' || variant === 'long_text';
  const isEmail = variant === 'email';
  const isPhone = variant === 'phone';

  const isPhoneError = isPhone && !isInteger(Number(value.replaceAll(' ', '')));
  const isError = !!errorMessage || isPhoneError;

  const handleChange: TextFieldProps['onChange'] = (event) => {
    field.onChange(event.target.value);
    setValue(event.target.value);
  };

  const getPlaceholder = () => {
    if (isEmail) {
      return 'name@example.com';
    }
    if (isPhone) {
      return '123456798';
    }
    return undefined;
  };

  const getHelperText = () => {
    if (value.length === 0) {
      return errorMessage ?? '';
    }
    if (isText) {
      return intl.formatMessage(submissionMessages.shortOrLongHelperText, {
        remainingCharacterCount,
      });
    }
    if (isEmail) {
      if (isError) {
        return intl.formatMessage(submissionMessages.emailErrorHelperText);
      } else {
        return errorMessage ?? '';
      }
    }
    if (isPhone) {
      if (isPhoneError) {
        return intl.formatMessage(submissionMessages.phoneNumberErrorHelperText);
      } else {
        return errorMessage ?? '';
      }
    }
  };

  return (
    <Question name={name} {...props}>
      <Stack direction={isSmallPhone ? 'column' : 'row'} spacing={2}>
        {isPhone && <CountrySelect isMandatory={showMandatoryAsteriskForLabel} />}
        <FormControlWrapper
          fullWidth
          sx={{ ...(isPhone && !isSmallPhone && { paddingBlockStart: 3.5 }) }}
        >
          {label && (
            <InputLabel shrink htmlFor={name}>
              {!isPhone && label}
              {showMandatoryAsteriskForLabel && <Asterisk />}
            </InputLabel>
          )}
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline={isText}
                minRows={variant === 'long_text' ? 5 : 1}
                value={value}
                onChange={handleChange}
                placeholder={getPlaceholder()}
                helperText={getHelperText()}
                error={isError}
                FormHelperTextProps={{ sx: { marginInline: 0 } }}
              />
            )}
          />
        </FormControlWrapper>
      </Stack>
    </Question>
  );
}
