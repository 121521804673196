import { type IntlShape, useIntl } from 'react-intl';

import { commonFields, isTemporaryHidden } from '@trustyou/shared';

import { useShowSegments } from '../../../../hooks';

interface TabItem {
  key: number;
  label: string;
  path: string;
}

const getDefaultTabs = (intl: IntlShape, isShowSegmentsAllowed: boolean): TabItem[] => {
  const tabs: TabItem[] = [
    {
      key: 0,
      label: intl.formatMessage({ id: 'common.entities.all', defaultMessage: 'All entities' }),
      path: '',
    },
  ];

  if (isShowSegmentsAllowed) {
    tabs.push({
      key: 1,
      label: intl.formatMessage(commonFields.segments),
      path: 'segments',
    });
  }

  if (!isTemporaryHidden('CXP-customers-visit-data')) {
    tabs.push({
      key: 2,
      label: intl.formatMessage({ id: 'common.visit-data', defaultMessage: 'Visit data' }),
      path: 'visit-data',
    });
  }

  return tabs;
};

const useEntityPageTabs = () => {
  const intl = useIntl();
  const isShowSegmentsAllowed = useShowSegments();
  const entityPageTabs = getDefaultTabs(intl, isShowSegmentsAllowed);
  //TODO: Add visitData tab checking here
  return entityPageTabs;
};

export default useEntityPageTabs;
