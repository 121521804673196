import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingButton } from '@mui/lab';
import { snackbar } from '@trustyou/ui';

import { DeleteReportModal } from './DeleteReportModal';
import { useDeleteReportById } from './hooks/reports';
import { commonMessages } from './messages';

export const DeleteReport = ({
  reportId,
  onDelete,
}: {
  reportId: string;
  onDelete: () => void;
}) => {
  const intl = useIntl();
  const [shouldDelete, setShouldDelete] = useState(false);
  const deleteReportById = useDeleteReportById();

  return (
    <>
      <LoadingButton
        data-testId="analytics-delete-report"
        data-gtm-class="analytics-delete-report"
        data-gtm-id="analytics-delete-report"
        loading={deleteReportById.isPending}
        onClick={() => {
          setShouldDelete(true);
        }}
        variant="text"
        color="error"
      >
        <FormattedMessage {...commonMessages.deleteReportButton} />
      </LoadingButton>
      <DeleteReportModal
        open={shouldDelete}
        onClose={() => {
          setShouldDelete(false);
        }}
        onDelete={() => {
          setShouldDelete(false);
          deleteReportById.mutate(reportId, {
            onSuccess: () => {
              onDelete();
              snackbar.info(intl.formatMessage(commonMessages.deleteReportSuccess));
            },
          });
        }}
      />
    </>
  );
};
