import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';

const ImportingAdditionalEntities = () => (
  <LoadingPlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage
        id="importAdditionalEntities.importingYourEntities"
        defaultMessage="Importing your entities..."
      />
    }
    description={
      <FormattedMessage
        id="importAdditionalEntities.addingNewEntitiesToYourOrganization"
        defaultMessage="We're adding the new entities to your organization. This may take a few minutes."
      />
    }
    loaderContainer={{ marginTop: 6 }}
    loaderHeader={{ marginTop: 3 }}
    loaderIcon={{ marginTop: 5 }}
  />
);

export default ImportingAdditionalEntities;
