import type { ReactNode } from 'react';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { StyledFontAwesomeIcon as Icon, Stack, Tooltip, Typography } from '@trustyou/ui';

type InputLabelProps = {
  children: ReactNode;
  required?: boolean;
  info?: string;
};

// TODO: Use this in reporting module drawer.
export const FormFieldLabel = ({ children, required, info }: InputLabelProps) => (
  <Stack direction="row" gap={1} marginBottom={1} alignItems="center">
    <Stack direction="row" gap={0.5}>
      <Typography variant="body2" color="text.secondary">
        {children}
      </Typography>
      {required && (
        <Typography variant="body2" color="error.main">
          *
        </Typography>
      )}
    </Stack>
    {info && (
      <Tooltip placement="top" arrow title={info}>
        <Icon icon={faInfoCircle} size="sm" sx={{ color: 'text.secondary' }} />
      </Tooltip>
    )}
  </Stack>
);
