import type { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, ChangelingAlert, Stack } from '@trustyou/ui';

import styles from './styles';

export type TopBarLayoutProps = {
  hideTopBar?: boolean;
  children?: ReactElement;
};

export const FullScreenLayout = ({ children }: TopBarLayoutProps) => {
  return (
    <Stack sx={styles.container}>
      <ChangelingAlert />
      <Box component="main" sx={styles.main}>
        {children || <Outlet />}
      </Box>
    </Stack>
  );
};
