import { useState } from 'react';

import { useGridParams } from '@trustyou/shared';
import { DataGrid } from '@trustyou/ui';

import { EditReportDrawer } from '../../../components';
import { ROW_HEIGHT } from '../../../constants/data-grid';
import { REPORT_LIST } from '../../../constants/query-keys';
import { useReportsGridStore } from '../../../hooks';
import { useReports } from '../../../service/hooks';
import { EmptyState } from '../components/empty-state';
import { useReportsTableColumns } from '../components/use-reports-table-columns';

export const DashboardReportList = () => {
  const [selectedReport, setSelectedReport] = useState<string>();

  const columns = useReportsTableColumns(setSelectedReport);

  const gridState = useReportsGridStore();

  const { paginationModel, sortModel, searchKey, columnVisibilityModel, setColumnVisibilityModel } =
    gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    REPORT_LIST,
    gridState
  );

  const { data, isFetching } = useReports();

  if (data && data?.meta?.totalRecords === 0 && !searchKey) return <EmptyState />;

  return (
    <>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowHeight={ROW_HEIGHT}
        columns={columns}
        rows={data?.data || []}
        rowCount={data?.meta?.totalRecords}
        loading={isFetching}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        enableToolbarColumnsButton
        enableToolbarDensityButton
        defaultSearchValue={searchKey}
        onSearch={onSearch}
      />
      {selectedReport && (
        <EditReportDrawer reportId={selectedReport} onClose={() => setSelectedReport(undefined)} />
      )}
    </>
  );
};
