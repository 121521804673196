import { useIntl } from 'react-intl';

import {
  faLink,
  faMobileScreen,
  faMobileSignal,
  faQrcode,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { snackbar } from '@trustyou/ui';
import QRCode from 'qrcode';

import type { DistributionElementProps } from './distribution';

import type { SurveyRowByEntity } from '../../../types/survey';

export function useDistributionElements(survey: SurveyRowByEntity) {
  const intl = useIntl();

  const copyUrlToClipboard = (url?: string) => {
    if (!url) {
      snackbar.warning('Missing submit_url');
      return;
    }
    navigator.clipboard.writeText(url);
    snackbar.success(
      intl.formatMessage({
        id: 'survey.survey-detail.distribution.alert.url-copied-to-clipboard',
        defaultMessage: 'Survey URL copied to clipboard',
      })
    );
  };

  const generateAndDownloadQRCode = async (url: string) => {
    try {
      const dataUrl = await QRCode.toDataURL(url, { width: 256 });
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'SurveyQR.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
    }
  };

  const elements: DistributionElementProps[] = [
    {
      icon: faLink,
      title: intl.formatMessage({
        defaultMessage: 'Survey link',
        id: 'survey.survey-detail.distribution.survey-link.title',
      }),
      description: intl.formatMessage({
        defaultMessage: 'Copy and paste a public link to direct guests to your Survey',
        id: 'survey.survey-detail.distribution.survey-link.description',
      }),
      buttonText: intl.formatMessage({
        defaultMessage: 'Copy link',
        id: 'survey.survey-detail.distribution.button.copy-link',
      }),
      onButtonClick: () => copyUrlToClipboard(survey.url),
    },
    {
      icon: faMobileScreen,
      title: intl.formatMessage({
        defaultMessage: 'Mobile app link',
        id: 'survey.survey-detail.distribution.mobile-app-link.title',
      }),
      description: intl.formatMessage({
        defaultMessage:
          'Copy and paste a public link to direct guests to your Survey in your mobile app',
        id: 'survey.survey-detail.distribution.mobile-app-link.description',
      }),
      buttonText: intl.formatMessage({
        defaultMessage: 'Copy link',
        id: 'survey.survey-detail.distribution.button.copy-link',
      }),
      onButtonClick: () => copyUrlToClipboard(`${survey.url}?source=mobile`),
    },
    {
      icon: faQrcode,
      title: intl.formatMessage({
        defaultMessage: 'QR code',
        id: 'survey.survey-detail.distribution.qr-code.title',
      }),
      description: intl.formatMessage({
        defaultMessage: 'Use a QR code that can be scanned to access your Survey',
        id: 'survey.survey-detail.distribution.qr-code.description',
      }),
      buttonText: intl.formatMessage({
        defaultMessage: 'Download code',
        id: 'survey.survey-detail.distribution.button.download-code',
      }),
      onButtonClick: () => generateAndDownloadQRCode(`${survey.url}?source=qr_code`),
    },
    {
      icon: faMobileSignal,
      title: intl.formatMessage({
        defaultMessage: 'Wi-Fi link',
        id: 'survey.survey-detail.distribution.wifi-link.title',
      }),
      description: intl.formatMessage({
        defaultMessage:
          'Show your guests the Survey right after they connect to your Wi-Fi network',
        id: 'survey.survey-detail.distribution.wifi-link.description',
      }),
      buttonText: intl.formatMessage({
        defaultMessage: 'Copy link for provider',
        id: 'survey.survey-detail.distribution.button.copy-link-provider',
      }),
      onButtonClick: () => copyUrlToClipboard(`${survey.url}?source=wifi`),
    },
    // TODO: Frontend feature
    // This `auto-reload` option is for the use of tablet/iPad in the front desks of hotels:
    // After submitting, the ThankYou page appears, and finally, after some seconds,
    // the URL should go back to the survey to be ready to be submitted by another guest.
    // {
    //   icon: faRotateRight,
    //   title: intl.formatMessage({
    //     defaultMessage: 'Shared device link',
    //     id: 'survey.survey-detail.distribution.auto-reload-link.title',
    //   }),
    //   description: intl.formatMessage(
    //     {
    //       defaultMessage:
    //         'This URL reloads the survey upon completion which allows multiple respondents to take it from the same device.{br}Useful for shared spaces such as lobbies or information points',
    //       id: 'survey.survey-detail.distribution.auto-reload-link.description',
    //     },
    //     {
    //       br: <br />,
    //     }
    //   ),
    //   buttonText: intl.formatMessage({
    //     defaultMessage: 'Copy link',
    //     id: 'survey.survey-detail.distribution.button.copy-link',
    //   }),
    //   onButtonClick: () => copyUrlToClipboard(`${survey.url}?auto-reload=true`),
    // },
  ];

  return elements;
}
