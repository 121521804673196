import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Stack } from '@mui/system';
import { commonFields, useConfirm } from '@trustyou/shared';
import {
  Box,
  Button,
  ConfirmDialog,
  ContentPane,
  TextField,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { useGetSignature, useSetSignature } from '../../../../hooks';
import type { ResponseAISignatureSettingsFormData } from '../../../../types';
import { messages } from '../messages';

export const SignatureSettingsForm = () => {
  const intl = useIntl();
  const { data: signature, isPending: isLoading } = useGetSignature();
  const setSignature = useSetSignature();

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<ResponseAISignatureSettingsFormData>({
    mode: 'onChange',
    defaultValues: {
      signature: '',
    },
  });

  const { isDialogOpen, handleAction, handleConfirm, handleCancel } = useConfirm(isDirty);

  const onSubmit = handleSubmit((data) => {
    setSignature.mutate(data.signature.trim(), {
      onSuccess: () => {
        snackbar.success(intl.formatMessage(commonFields.changesSaved));
      },
    });
  });

  useEffect(() => {
    if (signature) {
      reset({
        signature: signature ?? '',
      });
    }
  }, [reset, signature]);

  const onCancel = async () => {
    handleAction(() => {
      reset();
    });
  };

  return (
    <ContentPane bodyStyles={{ justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6">
          <FormattedMessage id="inbox.settings.signature.title" defaultMessage="Signature" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="inbox.settings.signature.description"
            defaultMessage="Your signature will be appended at the end of your responses to reviews from inbox"
          />
        </Typography>
      </Box>
      <Stack spacing={5} sx={{ marginTop: 3 }}>
        <Controller
          name="signature"
          control={control}
          disabled={isLoading || setSignature.isPending}
          render={({ field }) => (
            <TextField
              {...field}
              // label={intl.formatMessage({
              //   id: 'inbox.settings.signature.title',
              //   defaultMessage: 'Signature',
              // })}
              multiline
              rows={4}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Button
            disabled={!isDirty}
            onClick={onCancel}
            data-testid="inbox_settings_cancel"
            sx={{ alignSelf: 'end' }}
          >
            <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={isLoading || setSignature.isPending}
            onClick={onSubmit}
            variant="contained"
            data-testid="inbox_settings_save"
            sx={{ alignSelf: 'end' }}
          >
            <FormattedMessage id="inbox.action.save-changes" defaultMessage="Save changes" />
          </Button>
        </Stack>
      </Stack>
      <ConfirmDialog
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={intl.formatMessage(messages.leavingDialogHeader)}
        content={intl.formatMessage(messages.leavingDialogContent)}
      />
    </ContentPane>
  );
};
