import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faCircleExclamation } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { BookingConnectionRequestCredentials } from '@trustyou/shared';
import { Box, Button, Link, StyledFontAwesomeIcon, TextField, Typography } from '@trustyou/ui';

import WhereCanIFind from './WhereCanIFind';

import leidInstructionScreenshot from '../../assets/screenshots/leid-instruction.png';
import styles from './styles';

const bookingExtranetHref =
  'https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1608';

const BookingExtranetLink = () => (
  <Typography variant="body2" component="span" sx={{ color: 'primary.main' }}>
    <Link href={bookingExtranetHref} target="_blank" underline="hover">
      <FormattedMessage
        id="review-providers.booking.gotoExtranet"
        defaultMessage="go to Booking.com extranet"
      />
    </Link>
  </Typography>
);

export interface LegalEntityIDFormProps {
  formData: UseFormReturn<BookingConnectionRequestCredentials>;
  toggleForm: () => void;
}

const LegalEntityIDForm = ({ formData, toggleForm }: LegalEntityIDFormProps) => {
  const { register, formState } = formData;

  return (
    <Box sx={styles.formOuterWrapper}>
      <form>
        <Box sx={styles.formInnerWrapper}>
          <Typography variant="h6">
            <FormattedMessage
              id="review-providers.booking.legalEntityIDForm.title"
              defaultMessage="Enter your Legal Entity ID from Booking.com"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="review-providers.booking.legalEntityIDForm.description"
              defaultMessage="Let us know your Legal Entity ID (LEID) so that we can match your entities. If you have more than 1 LEID, you can add more after the initial setup."
            />
          </Typography>
          <FormattedMessage
            id="review-providers.booking.legalEntityId"
            defaultMessage="Legal Entity ID"
          >
            {(message) => (
              <TextField
                sx={styles.legalEntityIDFormField}
                data-testid="leid"
                label={message}
                variant="outlined"
                {...register('legal_entity_id')}
                error={!!formState.errors.legal_entity_id}
                // We need to keep the same height of the form no matter have we error or not
                helperText={formState.errors.legal_entity_id?.message || ' '}
              />
            )}
          </FormattedMessage>
          <Button
            data-testid="dont-have-leid"
            onClick={toggleForm}
            variant="text"
            startIcon={<StyledFontAwesomeIcon icon={faCircleExclamation} />}
          >
            <FormattedMessage
              id="review-providers.booking.legalEntityIDForm.toggleToContactEmail"
              defaultMessage="I don’t have an LEID"
            />
          </Button>
        </Box>
      </form>
      <WhereCanIFind
        title={
          <FormattedMessage
            id="review-providers.booking.leidFindGuide.title"
            defaultMessage="Where can I find my Legal Entity ID?"
          />
        }
        description={
          <FormattedMessage
            id="review-providers.booking.leidFindGuide.description"
            defaultMessage="To find your legal Entity ID (LEID), you’ll need to {extranet}. Click on your account name next to the Booking.com logo, and you will see it in the dropdown."
            values={{ extranet: <BookingExtranetLink /> }}
          />
        }
        src={leidInstructionScreenshot}
      />
    </Box>
  );
};

export default LegalEntityIDForm;
