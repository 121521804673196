import { useGridParams } from '@trustyou/shared';
import { DataGrid } from '@trustyou/ui';

import { ROW_HEIGHT } from '../../../constants/data-grid';
import { REPORTS_HISTORY } from '../../../constants/query-keys';
import { useReportsHistoryGridStore } from '../../../hooks';
import { useReportsHistory } from '../../../service/hooks';
import { EmptyState } from '../components/empty-state';
import { useReportsHistoryTableColumns } from '../components/use-reports-history-table-columns';

export const DashboardReportsHistory = () => {
  const columns = useReportsHistoryTableColumns();

  const gridState = useReportsHistoryGridStore();

  const { paginationModel, sortModel, searchKey, columnVisibilityModel, setColumnVisibilityModel } =
    gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    REPORTS_HISTORY,
    gridState
  );

  const { data, isFetching } = useReportsHistory();

  if (data && data?.meta?.totalRecords === 0 && !searchKey) return <EmptyState />;

  const rows = (data?.data || []).map((row, index) => ({ ...row, id: `${row.id}_${index}` }));

  return (
    <DataGrid
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      rowHeight={ROW_HEIGHT}
      columns={columns}
      rows={rows}
      rowCount={data?.meta?.totalRecords}
      loading={isFetching}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      enableToolbarColumnsButton
      enableToolbarDensityButton
      defaultSearchValue={searchKey}
      onSearch={onSearch}
    />
  );
};
