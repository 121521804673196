import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';

import type { View } from '../../../types';

export async function fetchViews() {
  const { data } = await $assApi.get<View[]>(`${INBOX_BASE_PATH}/views/get-views`);
  return data;
}

export async function saveViews(newViews: View[]) {
  const { data } = await $assApi.post<View[]>(`${INBOX_BASE_PATH}/views/set-views`, newViews);
  return data;
}
