import { FormattedMessage } from 'react-intl';

import { faGlasses, faPenToSquare, faUserGear } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { USER_ROLES, roleDefinitions, roleDescriptions } from '@trustyou/shared';
import { Alert, Box, SelectionCard, Typography } from '@trustyou/ui';

import { useCheckEntityManager } from '../../../../../hooks';
import styles from './styles';

const roles = [
  {
    id: USER_ROLES.ADMIN,
    titleId: roleDefinitions[USER_ROLES.ADMIN].id,
    title: roleDefinitions[USER_ROLES.ADMIN].defaultMessage,
    contentId: roleDescriptions[USER_ROLES.ADMIN].id,
    content: roleDescriptions[USER_ROLES.ADMIN].defaultMessage,
    icon: faUserGear,
  },
  {
    id: USER_ROLES.EDITOR,
    titleId: roleDefinitions[USER_ROLES.EDITOR].id,
    title: roleDefinitions[USER_ROLES.EDITOR].defaultMessage,
    contentId: roleDescriptions[USER_ROLES.EDITOR].id,
    content: roleDescriptions[USER_ROLES.EDITOR].defaultMessage,
    icon: faPenToSquare,
  },
  {
    id: USER_ROLES.READ_ONLY,
    titleId: roleDefinitions[USER_ROLES.READ_ONLY].id,
    title: roleDefinitions[USER_ROLES.READ_ONLY].defaultMessage,
    contentId: roleDescriptions[USER_ROLES.READ_ONLY].id,
    content: roleDescriptions[USER_ROLES.READ_ONLY].defaultMessage,
    icon: faGlasses,
  },
];

type RoleSelectionProps = {
  validated?: boolean;
  role?: string;
  setRole: (role: string) => void;
};

export const RoleSelection = ({ validated, role, setRole }: RoleSelectionProps) => {
  const isEntityManager = useCheckEntityManager();

  return (
    <Box sx={styles.container}>
      <Typography variant="subtitle1">
        <FormattedMessage
          id="organization.users.chooseRoleForThisMember"
          defaultMessage="Choose a role for this member"
        />
      </Typography>
      <Box sx={styles.cardContainer}>
        {roles
          .filter((row) => !isEntityManager || row.id !== USER_ROLES.ADMIN)
          .map((row) => (
            <SelectionCard
              {...row}
              key={row.id}
              title={<FormattedMessage id={row.titleId} defaultMessage={row.title} />}
              content={<FormattedMessage id={row.contentId} defaultMessage={row.content} />}
              onClick={() => setRole(row.id)}
              selected={role === row.id}
              iconAltText={row.title}
            />
          ))}
      </Box>
      {validated && !role && (
        <Alert severity="error">
          <FormattedMessage id="organization.users.roleSelect" defaultMessage="Select a role" />
        </Alert>
      )}
    </Box>
  );
};
