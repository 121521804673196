import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box, Dialog, DiscardDialog, snackbar } from '@trustyou/ui';

import { QuestionLibraryEditorPreview } from './question-library-editor-preview';

import type { SurveyManagerProps } from '../..';
import type { CreateQuestionIn } from '../../../client';
import { useQuestionLibraryEditorSteps } from '../../../components/question-library/editor/use-steps';
import { EditorContent } from '../../../components/shared/editor-content';
import { EditorFooter } from '../../../components/shared/editor-footer';
import { EditorHeader } from '../../../components/shared/editor-header';
import { EditorSideNav } from '../../../components/shared/editor-side-nav';
import styles from '../../../components/shared/styles';
import { useCreateSurveyQuestion } from '../../../service/hooks';
import { useStore } from '../../../store/store';
import type { SurveyQuestionEditorProps } from '../../../types/question';
import { convertQuestionEditorData } from '../../../utils/survey-question-editor';

export function QuestionLibraryEditor({ isChangeling }: SurveyManagerProps) {
  const navigate = useNavigate();
  const intl = useIntl();
  const editorStartingPoint = useStore.use.questionLibraryEditorStartingPoint();
  const questionEditorFormHook = useForm<SurveyQuestionEditorProps>();
  const { handleSubmit, watch, control } = questionEditorFormHook;
  const createSurveyQuestion = useCreateSurveyQuestion();

  //Discard dialog
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  // dialog
  const [open, setOpen] = useState(true);
  const closeEditor = () => {
    setOpenDiscardDialog(false);
    setOpen(false);
    navigate('question/overview');
  };

  const openDiscardDialogHandler = () => {
    setOpenDiscardDialog(true);
  };

  // stepper
  const steps = useQuestionLibraryEditorSteps();
  const [activeStep, setActiveStep] = useState(0);
  const hasPreviousStep = steps[activeStep - 1] !== undefined;
  const hasNextStep = steps[activeStep + 1] !== undefined;

  const goToNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // global state
  // const { selectedEntities, selectedGroups } = useScopeStore();

  const saveDraft = (data: SurveyQuestionEditorProps) => {
    if (activeStep === 0) {
      goToNextStep();
    } else {
      publish(data);
    }
  };

  const publish = (data: SurveyQuestionEditorProps) => {
    createSurveyQuestion.mutate(convertQuestionEditorData(data) as CreateQuestionIn, {
      onSuccess: () => {
        closeEditor();
        snackbar.success(
          intl.formatMessage({
            id: 'survey.survey-question.editor.success',
            defaultMessage: 'Question created',
          })
        );
      },
      onError: () => {
        snackbar.error(
          intl.formatMessage({
            id: 'survey.survey-editor.error',
            defaultMessage: 'Error creating survey',
          })
        );
      },
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={openDiscardDialogHandler}
      sx={{ marginTop: isChangeling ? '65px' : null }}
    >
      <FormProvider {...questionEditorFormHook}>
        <Box sx={styles.editorMain}>
          <EditorHeader
            title={
              editorStartingPoint === 'new-question' ? (
                <FormattedMessage
                  id="survey.question-editor.dialog.title"
                  defaultMessage="New custom question"
                />
              ) : editorStartingPoint === 'edit-question' ? (
                <FormattedMessage
                  id="survey.question-editor.edit.title"
                  defaultMessage="Editing {customQuestionName}"
                  values={{ customQuestionName: '[Name of question]' }}
                />
              ) : (
                ''
              )
            }
            close={openDiscardDialogHandler}
            save={handleSubmit(saveDraft)}
          />
          <EditorContent
            sideNavigator={
              <EditorSideNav
                activeStep={activeStep}
                setActiveStep={(step) => setActiveStep(step)}
                steps={steps}
              />
            }
            content={steps[activeStep].content}
            contentExtraStyles={{ ...(activeStep === 3 && styles.editorContentExtraStyles) }}
            preview={<QuestionLibraryEditorPreview watch={watch} control={control} />}
            previewExtraStyles={{
              ...(activeStep === 3 && styles.editorPreviewExtraStyles),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <EditorFooter
            hasPreviousStep={hasPreviousStep}
            hasNextStep={hasNextStep}
            nextStepLabel={steps[activeStep + 1]?.label}
            previousStepLabel={steps[activeStep - 1]?.label}
            goToNextStep={handleSubmit(goToNextStep)}
            goToPreviousStep={goToPreviousStep}
            publish={handleSubmit(publish)}
            extraMainStyles={{ ...(activeStep === 3 && styles.editorFooterExtraStyles) }}
          />
        </Box>
        <DiscardDialog
          title={intl.formatMessage({
            id: 'survey.question-editor.discard-editor.title',
            defaultMessage: 'Discard changes and leave editor?',
          })}
          content={intl.formatMessage({
            id: 'survey.question-editor.discard-editor.description',
            defaultMessage: 'Changes won’t be saved if you leave the editor',
          })}
          open={openDiscardDialog}
          onCancel={() => setOpenDiscardDialog(false)}
          onDiscard={closeEditor}
        />
      </FormProvider>
    </Dialog>
  );
}
