import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type AdvancedSelectionScope,
  type AdvancedSelectionScopeRule,
  type BasicEntity,
  isValidAdvancedSelectionScope,
} from '@trustyou/shared';
import {
  Alert,
  Button,
  Chip,
  StyledFontAwesomeIcon as Icon,
  Stack,
  Typography,
} from '@trustyou/ui';

import { AdditionalEntitiesModal } from './AdditionalEntitiesModal';
import { DEFAULT_DEFINITION_RULE, RuleSelector } from './RuleSelector';

import { advancedScopeMessages } from '../../constants/messages/users';

type Props = {
  validated?: boolean;
  scope?: AdvancedSelectionScope;
  setScope: (scope?: AdvancedSelectionScope) => void;
};

export const AdvancedScopeSelection = ({ validated, scope, setScope }: Props) => {
  const [isEntitySelectorOpen, setIsEntitySelectorOpen] = useState(false);
  const rules = scope?.rules || [DEFAULT_DEFINITION_RULE];
  const selectedEntities = scope?.additional_entities || [];

  const toggleIsEntitySelectorOpen = () => {
    setIsEntitySelectorOpen((state) => !state);
  };

  const onAdditionalEntitiesModalSave = (values: BasicEntity[]) => {
    setScope({
      rules: scope?.rules || [],
      additional_entities: values,
    });
    toggleIsEntitySelectorOpen();
  };

  const onRulesChange = (rules: AdvancedSelectionScopeRule[]) => {
    setScope({
      additional_entities: scope?.additional_entities || [],
      rules,
    });
  };

  return (
    <Stack>
      <Typography variant="subtitle1">
        <FormattedMessage {...advancedScopeMessages.rulesTitle} />
      </Typography>
      <Typography variant="body2" marginBottom={1}>
        <FormattedMessage {...advancedScopeMessages.rulesSubtitle} />
      </Typography>

      {validated && !isValidAdvancedSelectionScope(scope) && (
        <Alert severity="error">
          <FormattedMessage
            {...(selectedEntities.length
              ? advancedScopeMessages.errorWithSelectedEntities
              : advancedScopeMessages.error)}
          />
        </Alert>
      )}

      <RuleSelector rules={rules} onChange={onRulesChange} />

      <Stack marginTop={5} direction="row" gap={2}>
        <Typography variant="body1">
          <FormattedMessage {...advancedScopeMessages.additionalEntitiesHeader} />
        </Typography>
        {!!selectedEntities.length && (
          <Chip
            label={
              <FormattedMessage
                {...advancedScopeMessages.additionalEntitiesCount}
                values={{ count: selectedEntities.length }}
              />
            }
            size="small"
            variant="pastelInfo"
            onClick={toggleIsEntitySelectorOpen}
          />
        )}
      </Stack>
      <Button
        sx={{
          gap: 1,
          width: 'fit-content',
          marginTop: 1,
        }}
        onClick={toggleIsEntitySelectorOpen}
      >
        <Icon icon={faPlus} />
        <FormattedMessage {...advancedScopeMessages.additionalEntitiesSelectButton} />
      </Button>
      {isEntitySelectorOpen && (
        <AdditionalEntitiesModal
          defaultItems={selectedEntities}
          onClose={toggleIsEntitySelectorOpen}
          onSave={onAdditionalEntitiesModalSave}
        />
      )}
    </Stack>
  );
};
