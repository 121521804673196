import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder } from '@trustyou/ui';

import personHoldingACoffeeCup from '../../assets/illustrations/person-holding-a-coffee-cup.svg';

export default function LoggingOutState() {
  return (
    <LoadingPlaceholder
      img={personHoldingACoffeeCup}
      title={<FormattedMessage id="action.loggingOut" defaultMessage="Logging out" />}
      description={
        <FormattedMessage
          id="action.logoutDescription"
          defaultMessage="Please wait while redirecting to login form"
        />
      }
      loaderContainer={{ marginTop: 6 }}
      loaderHeader={{ marginTop: 3 }}
      loaderIcon={{ marginTop: 5 }}
    />
  );
}
