import type { CardProps } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Card } from '@trustyou/ui';

export function CustomCard({ children, ...props }: CardProps) {
  const { isPhone } = useResponsive();
  const { sx, ...cardProps } = props;
  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 5,
        ...(isPhone && {
          padding: 2,
          borderRadius: 0,
          borderInline: 'none',
        }),
        ...sx,
      }}
      {...cardProps}
    >
      {children}
    </Card>
  );
}
