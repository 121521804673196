import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box, Dialog, DiscardDialog, snackbar } from '@trustyou/ui';

import { EditorContent } from '../../../components/shared/editor-content';
import { EditorFooter } from '../../../components/shared/editor-footer';
import { EditorHeader } from '../../../components/shared/editor-header';
import { EditorSideNav } from '../../../components/shared/editor-side-nav';
import styles from '../../../components/shared/styles';
import { useSurveyEditorSteps } from '../../../components/survey/editor/use-steps';
import { useCreateSurvey } from '../../../service/hooks';
import { useStore } from '../../../store/store';
import type { SurveyEditorDataProps } from '../../../types/survey';

export function SurveyEditor() {
  const navigate = useNavigate();
  const intl = useIntl();
  const surveyEditorStartingPoint = useStore.use.surveyEditorStartingPoint();
  const surveyEditorFormHook = useForm<SurveyEditorDataProps>();
  const { handleSubmit, trigger, getValues } = surveyEditorFormHook;
  const createSurvey = useCreateSurvey();

  //Discard dialog
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  // dialog
  const [open, setOpen] = useState(true);
  const closeEditor = () => {
    setOpenDiscardDialog(false);
    setOpen(false);
    navigate('');
  };

  const openDiscardDialogHandler = () => {
    setOpenDiscardDialog(true);
  };

  // stepper
  const steps = useSurveyEditorSteps();
  const [activeStep, setActiveStep] = useState(0);

  const goToNextStep = (data: SurveyEditorDataProps) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goToPreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goToSideBarChosenStep = (index: number) => {
    const onfulfilled = (value: boolean) => {
      if (value) setActiveStep(index);
      else {
        snackbar.error('Check the fields with warnings.');
      }
    };
    const onrejected = (reason: unknown) => console.error('onrejected', reason);
    trigger?.().then(onfulfilled, onrejected);
  };
  const hasPreviousStep = steps[activeStep - 1] !== undefined;
  const hasNextStep = steps[activeStep + 1] !== undefined;

  const publish = () => {
    createSurvey.mutate(
      { name: getValues('surveyEditorInternalName') as string },
      {
        onSuccess: (data) => {
          navigate(`survey/detail/${data?.id}`);
          snackbar.success(
            intl.formatMessage({
              id: 'survey.survey-editor.success',
              defaultMessage: 'Survey created',
            })
          );
        },
        onError: () => {
          snackbar.error(
            intl.formatMessage({
              id: 'survey.survey-editor.error',
              defaultMessage: 'Error creating survey',
            })
          );
        },
      }
    );
  };

  const saveDraft = () => {
    publish();
  };

  return (
    <Dialog fullScreen open={open} onClose={openDiscardDialogHandler}>
      <FormProvider {...surveyEditorFormHook}>
        <Box sx={styles.editorMain}>
          <EditorHeader
            title={
              surveyEditorStartingPoint === 'new-survey' ? (
                <FormattedMessage id="survey.common.new-survey" defaultMessage="New Survey" />
              ) : surveyEditorStartingPoint === 'edit-survey' ? (
                <FormattedMessage
                  id="survey.survey-editor.edit.title"
                  defaultMessage="Editing {surveyName}"
                  values={{ surveyName: '[Name of survey]' }}
                />
              ) : (
                ''
              )
            }
            close={openDiscardDialogHandler}
            save={saveDraft}
          />
          <EditorContent
            sideNavigator={
              <EditorSideNav
                activeStep={activeStep}
                setActiveStep={goToSideBarChosenStep}
                steps={steps}
              />
            }
            content={steps[activeStep].content}
            contentExtraStyles={{ ...(activeStep === 0 && styles.editorContentExtraStyles) }}
            previewExtraStyles={{ ...(activeStep === 0 && styles.editorPreviewExtraStyles) }}
          />
          <EditorFooter
            hasNextStep={hasNextStep}
            hasPreviousStep={hasPreviousStep}
            nextStepLabel={steps[activeStep + 1]?.label}
            previousStepLabel={steps[activeStep - 1]?.label}
            goToNextStep={handleSubmit(goToNextStep)}
            goToPreviousStep={goToPreviousStep}
            publish={handleSubmit(publish)}
            extraMainStyles={{ ...(activeStep === 0 && styles.editorFooterExtraStyles) }}
          />
        </Box>
        <DiscardDialog
          // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
          title={
            <FormattedMessage
              id="survey.survey-editor.discard-editor.title"
              defaultMessage="Leave without saving changes?"
            />
          }
          // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
          content={
            <FormattedMessage
              id="survey.survey-editor.discard-editor.description"
              defaultMessage="All changes will be lost."
            />
          }
          open={openDiscardDialog}
          onCancel={() => setOpenDiscardDialog(false)}
          onDiscard={closeEditor}
        />
      </FormProvider>
    </Dialog>
  );
}
