import { type ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { type Node, useChangelingStore, useRouteUtils } from '@trustyou/shared';
import { Box } from '@trustyou/ui';

import { MobileDrawer } from '../Drawer/Drawer';
import { MobileTopBar } from '../TopBar/TopBar';
import styles from './styles';

type MobileLayoutProps = {
  pageName: ReactNode;
  nodes: Node[];
  footerNodes: Node[];
  children?: ReactNode;
  isRootNode?: boolean;
};

export const MobileLayout = ({
  pageName,
  children,
  nodes,
  footerNodes,
  isRootNode,
}: MobileLayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();
  const { isChangeling } = useChangelingStore();
  const { goUp } = useRouteUtils();

  const topBarClick = () => {
    if (isRootNode) {
      setDrawerOpen(true);
    } else {
      goUp();
    }
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  return (
    <Box sx={{ ...(isChangeling && styles.changelingMode) }}>
      <MobileTopBar pageName={pageName} isRoot={isRootNode} onClick={topBarClick} />
      <MobileDrawer
        primaryNodes={nodes}
        secondaryNodes={footerNodes}
        isOpen={drawerOpen}
        setIsOpen={setDrawerOpen}
      />
      <Box sx={{ ...styles.container, ...(isChangeling && styles.changelingModeContainer) }}>
        {children}
        <Outlet />
      </Box>
    </Box>
  );
};
