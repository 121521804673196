import { useEffect, useRef, useState } from 'react';
import ReactCrop, { type Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

import type { DropzoneProps } from '../Dropzone';

function centerAspectCrop({
  mediaWidth,
  mediaHeight,
  aspect,
}: {
  mediaWidth: number;
  mediaHeight: number;
  aspect: number;
}) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

type CropToolProps = DropzoneProps & {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  variant: string | null;
};

const CropTool = ({ file, canvasRef, setFile, variant }: CropToolProps) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();

  useEffect(() => {
    if (file) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(file);
      setFile(file);
    }
  }, [file, setFile]);

  useEffect(() => {
    if (imgRef.current) {
      const { width, height } = imgRef.current;
      setCrop(
        centerAspectCrop({
          mediaWidth: width,
          mediaHeight: height,
          aspect: variant === 'Banner' ? 4 : 1,
        })
      );
    }
  }, [variant]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    const { width, height } = e.currentTarget;
    setCrop(
      centerAspectCrop({
        mediaWidth: width,
        mediaHeight: height,
        aspect: variant === 'Banner' ? 4 : 1,
      })
    );
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && canvasRef.current) {
        canvasPreview(imgRef.current, canvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={variant === 'Banner' ? 4 : 1}
        >
          <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
        </ReactCrop>
      )}
      <canvas
        ref={canvasRef}
        style={{
          display: 'none',
        }}
      />
    </>
  );
};

export default CropTool;
