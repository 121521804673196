import { defineMessages } from 'react-intl';

export const common = defineMessages({
  addBenchmark: {
    id: 'benchmark.add-benchmark',
    defaultMessage: 'Add benchmark',
  },
  viewDashboard: {
    id: 'benchmark.tooltip.dashboard',
    defaultMessage: 'View dashboard',
  },
  organizationBenchmarks: {
    id: 'benchmark.organization-benchmarks',
    defaultMessage: 'Organization benchmarks',
  },
  userBenchmarks: {
    id: 'benchmark.user-benchmarks',
    defaultMessage: 'Personal benchmarks',
  },
  organizationBenchmarksInfo: {
    id: 'benchmark.organization-benchmarks.info',
    defaultMessage:
      'These benchmarks are shared and any user with access to all entities can view and update them.',
  },
  userBenchmarksInfo: {
    id: 'benchmark.user-benchmarks.info',
    defaultMessage: 'These benchmarks are only visible to you.',
  },
});

export const manageDrawer = defineMessages({
  createHeader: {
    id: 'benchmark.manage-drawer.create.header',
    defaultMessage: 'New benchmark',
  },
  editHeader: {
    id: 'benchmark.manage-drawer.edit.header',
    defaultMessage: 'Edit benchmark',
  },
  unmanagedBenchmarkInfo: {
    id: 'benchmark.manage-drawer.unmanaged-benchmark-info',
    defaultMessage: 'This benchmark is created via API and cannot be changed here.',
  },
  nameTitle: {
    id: 'benchmark.manage-drawer.name.title',
    defaultMessage: 'Name of benchmark',
  },
  nameRequired: {
    id: 'benchmark.manage-drawer.name.required',
    defaultMessage: 'Name is required',
  },
  nameExist: {
    id: 'benchmark.manage-drawer.name.exist',
    defaultMessage: 'Name already exists',
  },
  namePlaceholder: {
    id: 'benchmark.manage-drawer.name.place-holder',
    defaultMessage: 'Type name',
  },
  nameHelper: {
    id: 'benchmark.manage-drawer.name.helper',
    defaultMessage: 'e.g. EMEA',
  },
  setNameTitle: {
    id: 'benchmark.manage-drawer.set-name.title',
    defaultMessage: 'Assign a set',
  },
  setNameInfo: {
    id: 'benchmark.manage-drawer.set-name.info',
    defaultMessage:
      'A set helps you organize your benchmarks and later identify them on the Dashboard',
  },
  setNamePlaceholder: {
    id: 'benchmark.manage-drawer.set-name.place-holder',
    defaultMessage: 'Type set name',
  },
  setNameLoading: {
    id: 'benchmark.manage-drawer.set-name.loading',
    defaultMessage: 'Loading...',
  },
  setNameAdd: {
    id: 'benchmark.manage-drawer.set-name.add',
    defaultMessage: 'Add "{name}"',
  },
  entitiesRulesHeader: {
    id: 'benchmark.manage-drawer.entities.rules-header',
    defaultMessage: 'Which entities belong to this benchmark?',
  },
  entitiesRulesSecondaryHeader: {
    id: 'benchmark.manage-drawer.entities.rules-secondary-header',
    defaultMessage: 'Add entities based on conditions',
  },
  additionalEntitiesHeader: {
    id: 'benchmark.manage-drawer.entities.additional-entities.header',
    defaultMessage: 'Add entities manually',
  },
  additionalEntitiesCount: {
    id: 'benchmark.manage-drawer.entities.additional-entities.count',
    defaultMessage: '{count} Added',
  },
  additionalEntitiesSelectButton: {
    id: 'benchmark.manage-drawer.entities.additional-entities.select-button',
    defaultMessage: 'Select entities',
  },
  additionalEntitiesModalHeader: {
    id: 'benchmark.manage-drawer.entities.additional-entities.modal.header',
    defaultMessage: 'Manually select entities',
  },
  additionalEntitiesModalSubHeader: {
    id: 'benchmark.manage-drawer.entities.additional-entities.modal.sub-header',
    defaultMessage: 'Choose up to {count} additional entities',
  },
  additionalEntitiesModalSelectedCount: {
    id: 'benchmark.manage-drawer.entities.additional-entities.modal.selected-count',
    defaultMessage: 'Selected entities ({count})',
  },
  competitorsRulesHeader: {
    id: 'benchmark.manage-drawer.competitors.rules-header',
    defaultMessage: 'Which competitors belong to this benchmark?',
  },
  competitorsRulesSecondaryHeader: {
    id: 'benchmark.manage-drawer.competitors.rules-secondary-header',
    defaultMessage: 'Add competitors based on conditions',
  },
  additionalCompetitorsHeader: {
    id: 'benchmark.manage-drawer.competitors.additional-competitors.header',
    defaultMessage: 'Add competitors manually',
  },
  additionalCompetitorsSelectButton: {
    id: 'benchmark.manage-drawer.competitors.additional-competitors.select-button',
    defaultMessage: 'Select competitors',
  },
  additionalCompetitorsModalHeader: {
    id: 'benchmark.manage-drawer.competitors.additional-competitors.modal.header',
    defaultMessage: 'Manually select competitors',
  },
  additionalCompetitorsModalSubHeader: {
    id: 'benchmark.manage-drawer.competitors.additional-competitors.modal.sub-header',
    defaultMessage: 'Choose up to {count} additional competitors',
  },
  additionalCompetitorsModalSelectedCount: {
    id: 'benchmark.manage-drawer.competitors.additional-competitors.modal.selected-count',
    defaultMessage: 'Selected competitors ({count})',
  },
  definitionRulesIs: {
    id: 'benchmark.manage-drawer.definition-rules.is',
    defaultMessage: 'is one of',
  },
  definitionRulesAdd: {
    id: 'benchmark.manage-drawer.definition-rules.add',
    defaultMessage: 'Add condition',
  },
  definitionRulesSelectPlaceholder: {
    id: 'benchmark.manage-drawer.definition-rules.select.placeholder',
    defaultMessage: 'Select',
  },
  definitionRulesSelectNoValues: {
    id: 'benchmark.manage-drawer.definition-rules.select.no-values',
    defaultMessage: 'No Values',
  },
  definitionRulesLoading: {
    id: 'benchmark.manage-drawer.definition-rules.select.loading',
    defaultMessage: 'Loading...',
  },
  noEntitiesError: {
    id: 'benchmark.manage-drawer.no-entities-error',
    defaultMessage: 'Add at least one condition or entity to the benchmark',
  },
  noCompetitorsError: {
    id: 'benchmark.manage-drawer.no-competitors-error',
    defaultMessage: 'Add at least one condition or competitor to the benchmark',
  },
  saveButton: {
    id: 'benchmark.manage-drawer.save-button',
    defaultMessage: 'Save',
  },
  deleteButton: {
    id: 'benchmark.manage-drawer.delete-button',
    defaultMessage: 'Delete benchmark',
  },
  addSuccess: {
    id: 'benchmark.manage-drawer.add.success',
    defaultMessage: '{name} added. {delay}',
  },
  updateSuccess: {
    id: 'benchmark.manage-drawer.update.success',
    defaultMessage: 'Changes saved. {delay}',
  },
});

export const deleteConfirmation = defineMessages({
  deleteTitle: {
    id: 'benchmark.delete-confirmation.delete-title',
    defaultMessage: 'Delete "{name}"?',
  },
  deleteContent: {
    id: 'benchmark.delete-confirmation.delete-content',
    defaultMessage: 'This action cannot be undone.',
  },
  deleteSuccess: {
    id: 'benchmark.delete-confirmation.delete.success',
    defaultMessage: '"{name}" deleted. {delay}',
  },
});
