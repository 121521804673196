import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  formHeader: { paddingInline: 3 },
  formContent: { paddingX: 3, paddingY: 2 },
  formFooter: { boxShadow: 5 },
  deleteButton: { marginRight: 'auto' },
  setField: {
    maxWidth: '220px',
    '& .MuiInputBase-root': { padding: 0 },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '8.5px 14px',
    },
  },
  tabsBar: {
    marginTop: 2,
  },
  selectEntitiesButton: {
    gap: 1,
    width: 'fit-content',
    marginTop: 1,
  },
};
