import { FormattedMessage } from 'react-intl';

import type { SubmitButtonProps } from '../submit-region';
import { SubmitButton } from './submit-button';

export function MarkAsRespondedButton({ onSubmit, isDisabled }: SubmitButtonProps) {
  return (
    <SubmitButton
      color="success"
      onClick={onSubmit}
      data-gtm-id="mark_responded"
      disabled={isDisabled}
    >
      <FormattedMessage id="inbox.action.mark-as-responded" defaultMessage="Mark as responded" />
    </SubmitButton>
  );
}
