import { useEffect, useMemo } from 'react';
import { Controller, type FormState, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, FormHelperText, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import {
  capitalizeFirstLetter,
  getDashboardExcludedFilters,
  range,
  useDashboard,
  useLanguageStore,
} from '@trustyou/shared';
import { Alert, Button, FormControl, MenuItem, Select, Stack, TextField } from '@trustyou/ui';
import { DateTime, Info } from 'luxon';

import { SelectLabel, TextLabel } from './CustomLabels';
import { DeleteReport } from './DeleteReport';
import { InfoBannerReportsValue } from './InfoBannerReportsValue';
// import { FormatSelector } from './FormatSelector';
import { RecipientsAutocomplete } from './RecipientsAutocomplete';
import { TabActions } from './components/TabActions';
import { NARROW_FIELD_WIDTH } from './constants';
import { useFetchReportByIdLazy } from './hooks/reports';
import {
  formHelperTextMessages,
  formLabelMessages,
  formPlaceholderMessages,
  validationErrorsMessages,
} from './messages';
import { type SchedulingForm, schedulingSchema } from './reportValidationSchema';

export const ReportForm = ({
  reportId,
  dashboardId,
  onCancel,
  onSubmit,
  onFormStateChange,
  isLoading = false,
  // enabledFormats,
}: {
  isLoading?: boolean;
  onSubmit: (form: SchedulingForm) => void;
  onCancel: () => void;
  onFormStateChange?: (formState: FormState<SchedulingForm>) => void;
  dashboardId: string;
  // NOTE: Provided when existing report is edited
  reportId?: string;
  enabledFormats?: string[];
}) => {
  const intl = useIntl();
  const { locale } = useLanguageStore();

  const localeWeekdays = useMemo(
    () => Info.weekdays('long', { locale: intl.locale }).map(capitalizeFirstLetter),
    [intl.locale]
  );

  const {
    data: report,
    refetch: fetchReport,
    isError: isFetchReportError,
  } = useFetchReportByIdLazy(reportId);

  const { refetch: fetchDashboard } = useDashboard(
    reportId ? report?.dashboard_id : dashboardId,
    false
  );

  const { register, formState, watch, control, getValues, setValue, trigger, handleSubmit } =
    useForm<SchedulingForm>({
      mode: 'onChange',
      resolver: yupResolver(schedulingSchema),
      defaultValues: async () => {
        if (!reportId) {
          const { data: dashboard } = await fetchDashboard();
          if (dashboard) {
            const filters = dashboard.filters || [];
            return Object.assign(schedulingSchema.getDefault(), {
              dayOfWeek: schedulingSchema.getDefault().frequency === 'weekly' ? 1 : null,
              dashboardId,
              dashboardFilters: filters.length ? JSON.stringify(filters) : '',
              excludedFilters: getDashboardExcludedFilters(filters),
              language: locale.split('-')[0],
            });
          }
        }
        const report = await fetchReport();
        if (report.data) {
          const emails = report.data.emails.split(',');
          const userIds = report.data.user_ids.split(',');
          return Object.assign(schedulingSchema.getDefault(), {
            name: report.data.report_name,
            frequency: report.data.period,
            dayOfWeek: report.data.day_of_week ? report.data.day_of_week + 1 : undefined,
            dashboardId: report.data.dashboard_id,
            dayOfMonth: report.data.day,
            timeOfDay: DateTime.fromObject(
              {
                hour: report.data.hour,
                minute: 0,
                second: 0,
                millisecond: 0,
              },
              { zone: report.data.timezone }
            ),
            // emails[index] should be safe since emails and userIds should always have the same length
            recipients: userIds.map((id, index) => ({ label: emails[index], value: id })),
            fileFormat: report.data.file_format,
            dashboardFilters: report.data.dashboard_filters,
            excludedFilters: report.data.excluded_filters,
            language: report.data.language,
          });
        } else {
          return Object.assign(schedulingSchema.getDefault(), { dashboardId });
        }
      },
    });

  const frequencyOptions = [
    {
      value: 'daily',
      label: intl.formatMessage(formLabelMessages.daily),
    },
    {
      value: 'weekly',
      label: intl.formatMessage(formLabelMessages.weekly),
    },
    {
      value: 'monthly',
      label: intl.formatMessage(formLabelMessages.monthly),
    },
  ];
  const frequencyWatched = watch(
    'frequency',
    reportId ? undefined : schedulingSchema.getDefault().frequency
  );
  const isWeekly = frequencyWatched === 'weekly';
  const isMonthly = frequencyWatched === 'monthly';

  useEffect(() => {
    onFormStateChange?.(formState);
  }, [formState, onFormStateChange]);

  useEffect(() => {
    if (!isWeekly) {
      setValue('dayOfWeek', undefined);
    } else if (!getValues('dayOfWeek')) {
      setValue('dayOfWeek', 1);
      if (reportId) {
        trigger();
      }
    }
    if (!isMonthly) {
      setValue('dayOfMonth', undefined);
    } else if (!getValues('dayOfMonth')) {
      setValue('dayOfMonth', 1);
      if (reportId) {
        trigger();
      }
    }
  }, [isWeekly, isMonthly, setValue, getValues, trigger, reportId]);

  if (reportId && !getValues('frequency')) {
    return (
      <Stack
        style={{
          height: 'calc(100vh - 72px)',
          width: '100%',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Stack>
    );
  } else if (reportId && isFetchReportError) {
    return (
      <Alert severity="error">
        <FormattedMessage
          id="analytics.reports.details.loading-error"
          defaultMessage="There was an error listing the report, please try again a bit later."
        />
      </Alert>
    );
  }

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      gap={5.5}
      // Hack to work around the position absolute of TabActions (rendered at the end of this component, ReportForm).
      sx={{ paddingBlockEnd: 8 }}
    >
      {!reportId && (
        <Alert severity="info">
          <FormattedMessage
            id="analytics.reports.new.info-banner"
            defaultMessage="Export the currently selected Dashboard, as currently shown."
          />
          <br />
          <FormattedMessage
            id="analytics.reports.new.info-banner.line-two"
            defaultMessage="If you prefer an Excel report, go to the new “{reports}” menu."
            values={{
              reports: <InfoBannerReportsValue />,
            }}
          />
        </Alert>
      )}
      <FormControl>
        <TextLabel required htmlFor="reportName" shrink>
          <FormattedMessage
            id="analytics.reports.new.name-input-label"
            defaultMessage="Name your report"
          />
        </TextLabel>
        <TextField
          placeholder={intl.formatMessage(formPlaceholderMessages.name)}
          autoFocus={!reportId}
          error={!!formState.errors.name}
          helperText={
            !!formState.errors.name &&
            intl.formatMessage(validationErrorsMessages.missingReportName)
          }
          margin="dense"
          id="reportName"
          {...register('name')}
          style={{ marginTop: 10 }}
        />
      </FormControl>
      <FormControl>
        <SelectLabel required htmlFor="recipients" shrink>
          <FormattedMessage
            id="analytics.reports.new.recipients-input-label"
            defaultMessage="Recipients"
          />
        </SelectLabel>
        <RecipientsAutocomplete control={control} formState={formState} watch={watch} />
      </FormControl>
      {/* <FormatSelector control={control} enabledFormats={enabledFormats} /> */}
      <Typography variant="subtitle1" sx={{ marginBlock: -1 }}>
        <FormattedMessage id="analytics.reports.new.frequency-title" defaultMessage="Frequency" />
      </Typography>
      <FormControl sx={{ alignSelf: 'start', width: NARROW_FIELD_WIDTH }}>
        <SelectLabel htmlFor="frequency" shrink>
          <FormattedMessage
            id="analytics.reports.new.frequency-input-label"
            defaultMessage="Frequency"
          />
        </SelectLabel>
        <Controller
          control={control}
          name="frequency"
          render={({ field: { value, onChange } }) => (
            <Select
              aria-labelledby="frequency"
              value={value || schedulingSchema.getDefault().frequency}
              id="frequency"
              onChange={(event) => {
                onChange(event.target.value);
              }}
            >
              {frequencyOptions.map((option) => (
                <MenuItem
                  data-testid="analytics-new-report-frequency"
                  data-gtm-id={option.value}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {isWeekly && (
        <FormControl sx={{ width: NARROW_FIELD_WIDTH }}>
          <SelectLabel htmlFor="dayOfWeek" shrink>
            <FormattedMessage
              id="analytics.reports.new.day-of-week-input-label"
              defaultMessage="Day of the week"
            />
          </SelectLabel>
          <Controller
            control={control}
            name="dayOfWeek"
            render={({ field: { value, onChange } }) => (
              <Select
                aria-labelledby="dayOfWeek"
                id="dayOfWeek"
                value={value || 1}
                onChange={(event) => {
                  onChange(event.target.value);
                }}
              >
                {Info.weekdays('long')
                  .map((day) => ({ value: day, label: day }))
                  .map((item, index) => (
                    <MenuItem key={item.value} value={index + 1}>
                      {localeWeekdays[index]}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </FormControl>
      )}
      {isMonthly && (
        <FormControl sx={{ width: NARROW_FIELD_WIDTH }}>
          <SelectLabel htmlFor="dayOfMonth" shrink>
            <FormattedMessage
              id="analytics.reports.new.day-of-month-input-label"
              defaultMessage="Day of the month"
            />
          </SelectLabel>
          <Controller
            control={control}
            name="dayOfMonth"
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  aria-labelledby="dayOfMonth"
                  value={value ? value : 1}
                  id="dayOfMonth"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 210 } } }}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  sx={{
                    '& ~ .MuiFormHelperText-root': {
                      marginInlineStart: 0,
                    },
                  }}
                >
                  {range(1, 31).map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText>{intl.formatMessage(formHelperTextMessages.dayOfMonth)}</FormHelperText>
        </FormControl>
      )}
      <FormControl sx={{ alignSelf: 'start', width: NARROW_FIELD_WIDTH }}>
        <SelectLabel htmlFor="timeOfDay" shrink>
          <FormattedMessage
            id="analytics.reports.new.time-of-day-input-label"
            defaultMessage="Time of the day"
          />
        </SelectLabel>
        <Controller
          control={control}
          name="timeOfDay"
          render={({ field: { value, onChange } }) => (
            <Stack gap={0.5}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <TimePicker
                  views={[
                    'hours',
                    // 'minutes'
                  ]}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    // minutes: renderTimeViewClock,
                  }}
                  format="HH:00"
                  defaultValue={DateTime.fromISO(
                    DateTime.local().set({ hour: 11, minute: 0 }).toISO()
                  )}
                  value={DateTime.fromISO(value)}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '41px',
                    },
                  }}
                />
              </LocalizationProvider>
              <Typography variant="caption" color="text.secondary">
                {intl.formatMessage(formHelperTextMessages.timeOfDay)}
              </Typography>
            </Stack>
          )}
        />
      </FormControl>
      {!!formState.errors.timeOfDay && (
        <Alert severity="error">{intl.formatMessage(validationErrorsMessages.timeAfter)}</Alert>
      )}
      <TabActions
        left={reportId ? <DeleteReport reportId={reportId} onDelete={onCancel} /> : null}
        right={
          <>
            <Button color="inherit" variant="text" onClick={onCancel}>
              <FormattedMessage id="analytics.reports.common.cancel" defaultMessage="Cancel" />
            </Button>
            <LoadingButton
              data-testid="analytics-save-report"
              data-gtm-class="analytics-save-report"
              data-gtm-id={reportId ? 'analytics-update-report' : 'analytics-create-report'}
              disabled={!formState.isValid}
              loading={isLoading}
              variant="contained"
              type="submit"
            >
              <FormattedMessage id="analytics.reports.common.save" defaultMessage="Save" />
            </LoadingButton>
          </>
        }
      />
    </Stack>
  );
};
