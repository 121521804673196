import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  type BasicEntity,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_ENTITIES,
  type SelectionMode,
  searchPlaceholders,
  useInfiniteEntities,
} from '@trustyou/shared';
import { Box, ListItemText } from '@trustyou/ui';

import { Selector } from '../../Selector/Selector';

interface EntitySelectorProps {
  selectedEntities?: Entity[] | BasicEntity[];
  isListDisabled?: boolean;
  selectedEntitiesHeader?: ReactNode;
  hideSelectResults?: boolean;
  mode?: SelectionMode;
  setSelectedEntities: (entities: Entity[] | BasicEntity[]) => void;
}

export const EntitySelector = ({
  selectedEntities = [],
  isListDisabled = false,
  selectedEntitiesHeader,
  hideSelectResults,
  mode,
  setSelectedEntities,
}: EntitySelectorProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [searchKey, setSearchKey] = useState('');

  const { data, fetchNextPage, hasNextPage, isPending, isFetchingNextPage } = useInfiniteEntities(
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_KEY,
    searchKey
  );
  const [entities, setEntities] = useState<Entity[]>([]);

  const isLoading = isPending || isFetchingNextPage;

  useEffect(() => {
    setEntities(data?.pages.map((page) => page.data).flat() || []);
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
  };

  const renderRowContent = (entity: Entity | BasicEntity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  return (
    <Box>
      <Selector
        searchPlaceholder={intl.formatMessage(searchPlaceholders.searchEntities)}
        isLoading={isLoading}
        items={entities}
        onSelectItems={setSelectedEntities}
        onSearch={onSearch}
        renderRowContent={renderRowContent}
        selectedItems={selectedEntities}
        selectedHeaderTitle={
          selectedEntitiesHeader ?? (
            <FormattedMessage
              id="organization.users.selectedEntities"
              defaultMessage="Selected entities ({count})"
              values={{ count: selectedEntities.length }}
            />
          )
        }
        onFetchNextPage={onFetchNextPage}
        isListDisabled={isListDisabled}
        hideSelectResults={hideSelectResults}
        mode={mode}
      />
    </Box>
  );
};
