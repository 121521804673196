import { getEnvironment } from '../service';

const openAssistant = () => {
  // @ts-expect-error Property 'Userlane' does not exist on type 'Window & typeof globalThis'.
  if (!window.Userlane) {
    const environment = getEnvironment();
    alert(`Userlane is not available in "${environment}" environment`);
    return;
  }
  // @ts-expect-error Cannot find name 'Userlane'.
  Userlane('openAssistant');
};

type UserData = {
  userRole?: string;
  productPlan?: string;
  breakpoint: string;
};

export const initUserlane = (userId: string, userData: UserData, lang: string) => {
  // @ts-expect-error Property 'Userlane' does not exist on type 'Window & typeof globalThis'.
  if (!window.Userlane) return;
  sendUserlaneData(userId, userData);
  // @ts-expect-error Cannot find name 'Userlane'.
  Userlane('init', process.env.REACT_APP_USERLANE_ID);
  changeUserlaneLanguage(lang);
};

export const openFeatureTour = () => {
  openAssistant();
  setTimeout(() => {
    document.getElementById('usln-p-assistant-nav-tab__chapters')?.click();
  }, 10);
};

export const openNotifications = () => {
  openAssistant();
  setTimeout(() => {
    document.getElementById('usln-p-assistant-nav-tab__announcement-list')?.click();
  }, 10);
};

export const changeUserlaneLanguage = (lang: string) => {
  if (!lang) return;
  // @ts-expect-error Property 'Userlane' does not exist on type 'Window & typeof globalThis'.
  if (!window.Userlane) return;
  // @ts-expect-error Cannot find name 'Userlane'.
  Userlane('lang', lang.toLowerCase());
};

export const sendUserlaneData = (userId: string, userData: UserData) => {
  // @ts-expect-error Property 'Userlane' does not exist on type 'Window & typeof globalThis'.
  if (!window.Userlane) return;
  // @ts-expect-error Cannot find name 'Userlane'.
  Userlane('identify', userId, {
    user_role: userData.userRole,
    product_plan: userData.productPlan,
    breakpoint: userData.breakpoint,
  });
};
