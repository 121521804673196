import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { iconMap } from '@trustyou/shared';
import { Box, Button, Stack, Typography } from '@trustyou/ui';

import type { EmailInvitation, NPSPrimitive_Input, ScalePrimitive_Input } from '../../../client';
import {
  BooleanQuestion,
  type BooleanQuestionVariant,
  DateQuestion,
  type DateQuestionVariant,
  NPSQuestion,
  NumberQuestion,
  OneToFiveOptions,
  OneToTenOptions,
  ScaleQuestion,
  SelectQuestion,
  TextQuestion,
  type TextQuestionVariant,
  ZeroToTenOptions,
} from '../../../components/feedback/question-types';
import {
  SurveyMessageContent,
  SurveySection,
  SurveySectionTitle,
} from '../../../components/feedback/static-elements';
import type { SurveyQuestionnaireEditorContentOutput } from '../../../types/survey';
import { generateCustomTheme } from '../../../utils/survey-questionnaire-editor';

type RenderQuestionsProps = {
  question: SurveyQuestionnaireEditorContentOutput;
};

type RenderEmailInvitationProps = {
  overallScoreQuestion?: SurveyQuestionnaireEditorContentOutput;
  emailInvite: EmailInvitation & { embedOverallScore?: boolean };
};

export const RenderQuestions = ({ question }: RenderQuestionsProps) => {
  const intl = useIntl();
  const { control, watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const questionLang = question.default_language ?? 'en';
  const name = question.id ?? '';
  const title = question?.title?.[defaultSurveyLanguage] ?? question?.title?.[questionLang] ?? '';
  const description =
    question?.description?.[defaultSurveyLanguage] ?? question?.description?.[questionLang] ?? '';
  const variant = question.primitive.repr;
  const isMandatory = question.mandatory;
  const notApplicable = question.not_applicable;
  const notApplicableLabel =
    question.not_applicable_label?.[defaultSurveyLanguage] ??
    intl.formatMessage({
      id: 'survey.question.checkbox.not-applicable.label',
      defaultMessage: 'Not applicable',
    });

  switch (question.primitive.type) {
    case 'text': {
      return (
        <TextQuestion
          key={name}
          name={name}
          control={control}
          variant={variant as TextQuestionVariant}
          title={title}
          description={description}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
          // label={label}
        />
      );
    }
    case 'select': {
      return (
        <SelectQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          variant={'dropdown_single'}
          options={question.primitive.options.map((option) => ({
            value: option.value,
            label: option.label[defaultSurveyLanguage] ?? option.label[questionLang],
            icon: iconMap[option.icon ?? ''],
            color: option.color,
          }))}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'scale': {
      return (
        <ScaleQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          options={
            question.primitive.options?.map((option) => ({
              value: option.value,
              label: option.label[defaultSurveyLanguage] ?? option.label[questionLang],
              icon: iconMap[option.icon ?? ''],
              color:
                (question.primitive as ScalePrimitive_Input | NPSPrimitive_Input).options
                  ?.length === 5
                  ? OneToFiveOptions.find((o) => o.value === option.value)?.color
                  : (question.primitive as ScalePrimitive_Input | NPSPrimitive_Input).options
                        ?.length === 10
                    ? OneToTenOptions.find((o) => o.value === option.value)?.color
                    : ZeroToTenOptions.find((o) => o.value === option.value)?.color,
            })) ?? []
          }
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'nps': {
      return (
        <NPSQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'number': {
      return (
        <NumberQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'boolean': {
      if (variant === 'checkbox') {
        const isLegalOrPushToCheckbox = [
          'data_privacy_information',
          'terms_and_conditions',
          'push_to_google',
        ].includes(question.handling_type);
        if (isLegalOrPushToCheckbox) {
          return (
            <BooleanQuestion
              key={name}
              name={name}
              control={control}
              variant={variant}
              isLegalCheckbox={isLegalOrPushToCheckbox}
              options={[
                {
                  value: 'accept',
                  label: `${question.title[defaultSurveyLanguage] ?? question.title[questionLang]}${isMandatory ? ' *' : ''}`,
                },
              ]}
            />
          );
        }
        return (
          <BooleanQuestion
            key={name}
            name={name}
            control={control}
            title={title}
            description={description}
            notApplicableCheckbox={
              notApplicable
                ? {
                    checked: false,
                    label: notApplicableLabel,
                    onChange: () => {},
                  }
                : undefined
            }
            variant={variant}
            options={[
              {
                label: question.title[defaultSurveyLanguage] ?? question.title[questionLang],
                value: question.title[defaultSurveyLanguage] ?? question.title[questionLang],
              },
            ]}
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      // Default `boolean` render. It includes the variant `button_group` for the Yes/No question use case.
      return (
        <BooleanQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          variant={variant as BooleanQuestionVariant}
          options={
            question.primitive.options?.map((option) => ({
              value: option.value,
              label: option.label[defaultSurveyLanguage] ?? option.label[questionLang],
              icon: iconMap[option.icon ?? ''],
              color: option.color,
            })) ?? []
          }
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'date': {
      const variantMap: Record<string, DateQuestionVariant> = {
        datepicker: 'day-month-year',
        year_month_picker: 'month-year',
      };
      return (
        <DateQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          variant={variantMap[variant as DateQuestionVariant] ?? variant}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    default:
      return null;
  }
};

export const RenderWelcomeMessageAndContent = () => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const editorWelcomeMessage = watch('surveyQuestionnaireEditorWelcomeMessage');
  const surveyContent = watch('surveyContent') as SurveyQuestionnaireEditorContentOutput[];
  const surveyTheme = watch('surveyQuestionnaireEditorTheme');
  const generatedTheme = surveyTheme && generateCustomTheme(surveyTheme);
  return (
    <Box>
      {editorWelcomeMessage && editorWelcomeMessage.id !== 'no-welcome-message' && (
        <SurveyMessageContent
          title={
            editorWelcomeMessage?.title?.[defaultSurveyLanguage] ??
            editorWelcomeMessage?.title?.[editorWelcomeMessage.default_language ?? 'en'] ??
            ''
          }
          description={
            editorWelcomeMessage?.description?.[defaultSurveyLanguage] ??
            editorWelcomeMessage?.description?.[editorWelcomeMessage.default_language ?? 'en'] ??
            ''
          }
        />
      )}
      {surveyContent && surveyContent.length > 0 && (
        <Stack spacing={2}>
          {surveyContent.map((content, index) => {
            const contentLang = content.default_language ?? 'en';
            if (content.type === 'section') {
              return (
                <Stack key={content.id}>
                  <SurveySectionTitle>
                    {content.title[defaultSurveyLanguage] ?? content.title[contentLang]}
                  </SurveySectionTitle>
                  {content.questions?.map(
                    (question: SurveyQuestionnaireEditorContentOutput, index: number) => (
                      <RenderQuestions key={index} question={question} />
                    )
                  )}
                </Stack>
              );
            }
            return <RenderQuestions key={index} question={content} />;
          })}
        </Stack>
      )}
    </Box>
  );
};

export const RenderSubmitSection = () => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const submitSection = watch('submitSection') as SurveyQuestionnaireEditorContentOutput[];
  const title = submitSection[0]?.title?.[defaultSurveyLanguage] ?? '';

  return (
    <Box>
      {submitSection && submitSection.length > 0 && (
        <SurveySection>
          <SurveySectionTitle>{title}</SurveySectionTitle>
          <Stack spacing={3}>
            {submitSection[0]?.questions?.map((question, index) => (
              <RenderQuestions key={index} question={question} />
            ))}
          </Stack>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ width: '100%', maxWidth: 384, alignSelf: 'center' }}
          >
            <FormattedMessage id="survey.submit.button" defaultMessage="Submit" />
          </Button>
        </SurveySection>
      )}
    </Box>
  );
};

export const RenderThankyouPage = () => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const editorThankYouMessage = watch('surveyQuestionnaireEditorThankyouMessage');
  return (
    <Box>
      {editorThankYouMessage && (
        <SurveyMessageContent
          title={
            editorThankYouMessage?.title?.[defaultSurveyLanguage] ??
            editorThankYouMessage?.title?.[editorThankYouMessage.default_language ?? 'en'] ??
            ''
          }
          description={
            editorThankYouMessage?.description?.[defaultSurveyLanguage] ??
            editorThankYouMessage?.description?.[editorThankYouMessage.default_language ?? 'en'] ??
            ''
          }
        />
      )}
    </Box>
  );
};

export const RenderEmailInvitation = ({
  emailInvite,
  overallScoreQuestion,
}: RenderEmailInvitationProps) => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');

  return (
    <Stack spacing={3} sx={{ width: 531, backgroundColor: 'white', padding: 5, borderRadius: 1 }}>
      <Typography variant="body1">
        {emailInvite?.invitation_email?.body?.[defaultSurveyLanguage] ??
          emailInvite?.invitation_email?.body?.[emailInvite.default_language ?? 'en'] ??
          ''}
      </Typography>
      <Box>
        {emailInvite?.embedOverallScore ? (
          <RenderQuestions
            question={overallScoreQuestion as SurveyQuestionnaireEditorContentOutput}
          />
        ) : (
          <Button variant="contained" size="large" sx={{ width: 150 }}>
            <FormattedMessage
              id="survey.survey-editor.email-invite.write-review-button"
              defaultMessage="Write a review"
            />
          </Button>
        )}
      </Box>
      <Typography variant="body1">
        {emailInvite?.invitation_email?.footer?.[defaultSurveyLanguage] ??
          emailInvite?.invitation_email?.footer?.[emailInvite.default_language ?? 'en'] ??
          ''}
      </Typography>
    </Stack>
  );
};
