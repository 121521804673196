import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  type CustomAttributeDefinition,
  CustomAttributeType,
  useCanUpdateCustomAttributeDefinition,
  useCreateCustomAttributeDefinition,
  useUpdateCustomAttributeDefinition,
} from '@trustyou/shared';
import { BackdropSpinner, Button, snackbar } from '@trustyou/ui';

import type { CustomAttributeFormData } from './Form';
import { type UpdateConfirmationData, UpdateConfirmationDialog } from './UpdateConfirmationDialog';
import { UpdateProhibitionDialog } from './UpdateProhibitionDialog';

import { manageDrawer } from '../../constants/messages/customAttributes';

type Props = {
  onSave: () => void;
  data?: CustomAttributeDefinition;
};

export const SaveAction = ({ data, onSave }: Props) => {
  const intl = useIntl();
  const [impactedSegments, setImpactedSegments] = useState<Record<string, string[]>>();
  const [updateConfirmationData, setUpdateConfirmationData] = useState<UpdateConfirmationData>();

  const createCustomAttribute = useCreateCustomAttributeDefinition();
  const updateCustomAttribute = useUpdateCustomAttributeDefinition();
  const validateUpdate = useCanUpdateCustomAttributeDefinition();

  const { formState, watch, getValues } = useFormContext<CustomAttributeFormData>();
  const { type, options = [] } = watch();

  const hasOptions =
    type === CustomAttributeType.SELECT || type === CustomAttributeType.MULTI_SELECT;

  const saveDisabled =
    !formState.isDirty ||
    !formState.isValid ||
    !!Object.keys(formState.errors).length ||
    (hasOptions && options.length === 0);

  const getSavingValues = () => {
    const values = getValues();
    const options = [...(values.options || [])];
    options.forEach((option) => {
      delete option._id;
      if (option.id?.startsWith('NEW_')) {
        delete option.id;
      }
    });
    const savingValues = {
      ...values,
      type: values.type as CustomAttributeType,
      default: options.filter((option) => option.isDefault).map(({ isDefault, ...rest }) => rest),
      unique: hasOptions ? false : values.unique,
      options: options.map(({ isDefault, ...rest }) => rest),
    };
    delete savingValues.id;
    return savingValues;
  };

  const onSaveConfirmed = () => {
    if (data?.id) {
      updateCustomAttribute.mutate(
        {
          ...getSavingValues(),
          id: data.id,
        },
        {
          onSuccess: (response) => {
            onSave();
            snackbar.success(
              intl.formatMessage(manageDrawer.updateSuccess, { name: response.name })
            );
          },
        }
      );
    } else {
      createCustomAttribute.mutate(getSavingValues(), {
        onSuccess: (response) => {
          onSave();
          snackbar.success(intl.formatMessage(manageDrawer.addSuccess, { name: response.name }));
        },
      });
    }
    setUpdateConfirmationData(undefined);
  };

  const onSubmit = () => {
    if (data?.id) {
      validateUpdate.mutate(
        {
          ...getSavingValues(),
          id: data.id,
        },
        {
          onSuccess: (response, payload) => {
            if (!response.valid && response.segment_names_by_option) {
              setImpactedSegments(response.segment_names_by_option);
            } else {
              if (payload.type === CustomAttributeType.TEXT) {
                if (response.number_of_affected_owned_entities) {
                  setUpdateConfirmationData({
                    entitiesCount: response.number_of_affected_owned_entities,
                  });
                } else {
                  onSaveConfirmed();
                }
              } else {
                const oldOptions = data?.options || [];
                const newOptions = payload.options.filter((row) => !row.id).map((row) => row.name);
                const oldIds = payload.options.map((row) => row.id);
                const deleted = oldOptions
                  .filter((row) => !oldIds.includes(row.id))
                  .map((row) => row.name);
                const renamed = payload.options
                  .filter(
                    (row) =>
                      row.id && oldOptions.find((option) => option.id === row.id)?.name !== row.name
                  )
                  .map((row) => row.name);
                if (
                  response.number_of_affected_owned_entities ||
                  renamed.length ||
                  newOptions.length ||
                  deleted.length
                ) {
                  setUpdateConfirmationData({
                    entitiesCount: response.number_of_affected_owned_entities,
                    renamed,
                    new: newOptions,
                    deleted: deleted,
                  });
                } else {
                  onSaveConfirmed();
                }
              }
            }
          },
          onError: snackbar.genericError,
        }
      );
    } else {
      onSaveConfirmed();
    }
  };

  return (
    <>
      <Button onClick={onSubmit} variant="contained" disabled={saveDisabled}>
        <FormattedMessage {...manageDrawer.save} />
      </Button>
      <UpdateProhibitionDialog
        segmentsByAttribute={impactedSegments}
        onClose={() => setImpactedSegments(undefined)}
      />
      <UpdateConfirmationDialog
        data={updateConfirmationData}
        onCancel={() => setUpdateConfirmationData(undefined)}
        onSave={onSaveConfirmed}
      />
      <BackdropSpinner
        isLoading={
          createCustomAttribute.isPending ||
          updateCustomAttribute.isPending ||
          validateUpdate.isPending
        }
      />
    </>
  );
};
