import { useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { isRouteEndsWith, useEntityCount, useResponsive } from '@trustyou/shared';
import { Box, ContentPane, Stack } from '@trustyou/ui';

import { InboxEmptyState } from '../../components/inbox-empty-state';
import { InboxHeader } from '../../components/inbox-header';
import { LastUpdateTimestamp } from '../../components/last-update-timestamp';
import { ReviewsDataGrid } from '../../components/reviews-data-grid/reviews-data-grid';
import { useComponentHeight, useReviewsInfo } from '../../hooks';

export function Inbox() {
  const location = useLocation();
  const isParentRoute = isRouteEndsWith(location.pathname, 'inbox');

  const { lastProducedAt } = useReviewsInfo();
  const { isMobile } = useResponsive();

  const { data: entityCount } = useEntityCount();
  const noEntitiesYet = entityCount === 0;

  const headerRef = useRef<HTMLDivElement>(null);
  const headerHeight = useComponentHeight(headerRef);
  const MANUAL_ADJUSTMENT_FOR_NEGATIVE_MARGIN = 16;
  const dataGridContainerHeight = `calc(100% - ${headerHeight}px + ${MANUAL_ADJUSTMENT_FOR_NEGATIVE_MARGIN}px)`;

  return (
    <ContentPane sx={{ height: '100%' }}>
      <Outlet />
      {noEntitiesYet ? (
        <InboxEmptyState />
      ) : (
        isParentRoute && (
          <Stack sx={{ height: '100%', gap: 6 }}>
            <Stack ref={headerRef} sx={{ margin: { md: -1 }, gap: 2 }}>
              {lastProducedAt && !isMobile && (
                <LastUpdateTimestamp lastProducedAt={lastProducedAt} />
              )}
              <InboxHeader />
            </Stack>
            <Box sx={{ height: dataGridContainerHeight, margin: { xs: -2, md: -3 } }}>
              <ReviewsDataGrid />
            </Box>
          </Stack>
        )
      )}
    </ContentPane>
  );
}
