import { FormattedMessage, useIntl } from 'react-intl';

import { faFlag } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faUserTie } from '@trustyou/fortawesome/pro-solid-svg-icons';

import { commonMessages } from '../../../constants/messages';
import type { SpecificStampProps } from '../../../types';
import { Stamp } from './stamp';
import { StampPair } from './stamp-pair';

export function MarkedAsInappropriateStampPair({
  date,
  requestor,
}: Omit<SpecificStampProps, 'author'> & {
  requestor: string;
}) {
  const intl = useIntl();

  return (
    <StampPair>
      <Stamp
        status={
          <FormattedMessage
            id="inbox.response.status.date.marked-as-inappropriate-at"
            defaultMessage="Marked as inappropriate: {moderationRequestDate}"
            values={{ moderationRequestDate: date }}
          />
        }
        icon={faFlag}
      />
      <Stamp
        status={intl.formatMessage(commonMessages.byUsername, { username: requestor })}
        icon={faUserTie}
      />
    </StampPair>
  );
}
