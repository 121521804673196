import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { BookingConnectionRequestCredentials } from '@trustyou/shared';
import { Box, Button, Link, TextField, Typography } from '@trustyou/ui';

import WhereCanIFind from './WhereCanIFind';

import contactEmailInstructionScreenshot from '../../assets/screenshots/contact-email-instruction.png';
import styles from './styles';

const bookingExtranetHref =
  'https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1608';

const BookingExtranetLink = () => (
  <Typography variant="body2" component="span" sx={{ color: 'primary.main' }}>
    <Link href={bookingExtranetHref} target="_blank" underline="hover">
      <FormattedMessage
        id="review-providers.booking.gotoExtranet"
        defaultMessage="go to Booking.com extranet"
      />
    </Link>
  </Typography>
);

export interface ContactEmailFormProps {
  formData: UseFormReturn<BookingConnectionRequestCredentials>;
  toggleForm: () => void;
}

const ContactEmailForm = ({ formData, toggleForm }: ContactEmailFormProps) => {
  const { register, formState } = formData;

  return (
    <Box sx={styles.formOuterWrapper}>
      <form>
        <Box sx={styles.formInnerWrapper}>
          <Typography variant="h6">
            <FormattedMessage
              id="review-providers.booking.contactEmailForm.title"
              defaultMessage="Enter your primary contact email on Booking.com"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="review-providers.booking.contactEmailForm.description"
              defaultMessage="Let us know your primary contact email so that we can match your entities. If you have more than 1 primary contact emails, you can add more after the initial setup.."
            />
          </Typography>
          <FormattedMessage
            id="review-providers.booking.contactEmail"
            defaultMessage="Primary contact email"
          >
            {(message) => (
              <TextField
                sx={styles.contactEmailFormField}
                data-testid="contact-email"
                label={message}
                variant="outlined"
                {...register('contact_email')}
                error={!!formState.errors.contact_email}
                // We need to keep the same height of the form no matter have we error or not
                helperText={formState.errors.contact_email?.message || ' '}
              />
            )}
          </FormattedMessage>
          <Button data-testid="found-my-leid" onClick={toggleForm} variant="text">
            <FormattedMessage
              id="review-providers.booking.contactEmailForm.toggleToLeid"
              defaultMessage="I found my LEID"
            />
          </Button>
        </Box>
      </form>
      <WhereCanIFind
        title={
          <FormattedMessage
            id="review-providers.booking.contactEmailFindGuide.title"
            defaultMessage="Where can I find my contact email?"
          />
        }
        description={
          <FormattedMessage
            id="review-providers.booking.contactEmailFindGuide.description"
            defaultMessage="To find your contact email, you’ll need to {extranet}. Click on your account avatar, select Contacts in the dropdown menu, and you will see the primary point of contact."
            values={{ extranet: <BookingExtranetLink /> }}
          />
        }
        src={contactEmailInstructionScreenshot}
      />
    </Box>
  );
};

export default ContactEmailForm;
