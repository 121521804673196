import { useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  FETCH_BENCHMARK_OWNED_ENTITIES,
  commonFields,
  getPaginationRequest,
  getSortRequest,
  useBenchmarkOwnedEntities,
  useBenchmarkOwnedEntitiesGridStore,
  useGridParams,
} from '@trustyou/shared';
import {
  Box,
  CircularProgress,
  DataGrid,
  DataGridCell,
  EmptyBenchmarkEntityState,
} from '@trustyou/ui';

import { styles } from './styles';

export interface BenchmarkOwnEntitiesProps {
  benchmarkId: string;
}

const BenchmarkOwnEntities = ({ benchmarkId }: BenchmarkOwnEntitiesProps) => {
  const intl = useIntl();

  const gridState = useBenchmarkOwnedEntitiesGridStore();

  const { paginationModel, sortModel } = gridState;

  const { onPaginationModelChange, onSortModelChange } = useGridParams(
    FETCH_BENCHMARK_OWNED_ENTITIES,
    gridState
  );

  const { data, isPending: isLoading } = useBenchmarkOwnedEntities(
    benchmarkId,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel)
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      minWidth: 350,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => (
        <DataGridCell primaryValue={row.name} secondaryValue={`${row.city}, ${row.country_name}`} />
      ),
    },
    {
      field: 'brand',
      headerName: intl.formatMessage(commonFields.brand),
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => <DataGridCell primaryValue={row.brand?.name} />,
    },
  ];

  if (isLoading)
    return (
      <Box sx={styles.loader}>
        <CircularProgress />
      </Box>
    );

  if (data?.pagination?.total === 0) return <EmptyBenchmarkEntityState />;

  const rows = (data?.data || []).map((row, rowId) => ({ ...row, rowId }));

  return (
    <DataGrid
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      rowHeight={64}
      columns={columns}
      rows={rows}
      getRowId={(row) => row.rowId}
      rowCount={data?.pagination.total}
    />
  );
};

export default BenchmarkOwnEntities;
