import {
  type AccessGroup,
  type AdvancedSelectionScope,
  type BasicEntity,
  type Entity,
  type SegmentGroup,
  USER_SCOPES,
  isValidAdvancedSelectionScope,
} from '@trustyou/shared';
import { create } from 'zustand';

type ScopeState = {
  isDirty: boolean;

  selectedScope: USER_SCOPES;
  setSelectedScope: (scope: USER_SCOPES, shouldDirty?: boolean) => void;

  selectedEntities: Entity[] | BasicEntity[];
  setSelectedEntities: (entities: Entity[] | BasicEntity[], shouldDirty?: boolean) => void;

  selectedGroups: SegmentGroup[];
  setSelectedGroups: (groups: SegmentGroup[]) => void;

  selectedAccessGroup?: AccessGroup;
  setSelectedAccessGroup: (accessGroup?: AccessGroup, shouldDirty?: boolean) => void;

  advancedSelectionScope?: AdvancedSelectionScope;
  setAdvancedSelectionScope: (
    advancedSelectionScope?: AdvancedSelectionScope,
    shouldDirty?: boolean
  ) => void;

  isValid: () => boolean;

  reset: () => void;
};

export const useScopeStore = create<ScopeState>((set, get) => ({
  isDirty: false,

  selectedScope: 'none' as USER_SCOPES.NONE,
  setSelectedScope: (selectedScope, shouldDirty) =>
    set((state) => ({ selectedScope, isDirty: shouldDirty || state.isDirty })),

  selectedEntities: [],
  setSelectedEntities: (selectedEntities, shouldDirty) =>
    set((state) => ({ selectedEntities, isDirty: shouldDirty || state.isDirty })),

  selectedGroups: [],
  setSelectedGroups: (selectedGroups) => set(() => ({ selectedGroups })),

  setSelectedAccessGroup: (selectedAccessGroup, shouldDirty) =>
    set((state) => ({ selectedAccessGroup, isDirty: shouldDirty || state.isDirty })),

  setAdvancedSelectionScope: (advancedSelectionScope, shouldDirty) =>
    set((state) => ({
      advancedSelectionScope,
      isDirty: shouldDirty || state.isDirty,
    })),

  isValid: () =>
    get().selectedScope === USER_SCOPES.ALL_ENTITIES ||
    (get().selectedScope === USER_SCOPES.BY_ENTITIES && get().selectedEntities.length > 0) ||
    (get().selectedScope === USER_SCOPES.ACCESS_GROUP && !!get().selectedAccessGroup) ||
    (get().selectedScope === USER_SCOPES.BY_GROUPS && get().selectedGroups.length > 0) ||
    (get().selectedScope === USER_SCOPES.ADVANCED_SELECTION &&
      isValidAdvancedSelectionScope(get().advancedSelectionScope)) ||
    get().selectedScope === USER_SCOPES.NONE,

  reset: () =>
    set(() => ({
      isDirty: false,
      selectedEntities: [],
      selectedGroups: [],
      advancedSelectionScope: undefined,
      selectedAccessGroup: undefined,
      selectedScope: USER_SCOPES.NONE,
    })),
}));
