import { useState } from 'react';
import { useController } from 'react-hook-form';

import type { DateView } from '@mui/x-date-pickers-pro';
import { useDatePickersIntl, useLanguageStore } from '@trustyou/shared';
import { AdapterDayjs, DatePicker, LocalizationProvider } from '@trustyou/ui';
import dayjs, { type Dayjs } from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/be';
import 'dayjs/locale/bg';
import 'dayjs/locale/ca';
import 'dayjs/locale/cs';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/he';
import 'dayjs/locale/hr';
import 'dayjs/locale/hu';
import 'dayjs/locale/id';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/ms';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/sl';
import 'dayjs/locale/sr';
import 'dayjs/locale/sv';
import 'dayjs/locale/th';
import 'dayjs/locale/tr';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh';

import { Question, type QuestionProps } from '../../components/question';

export type DateQuestionVariant = 'day-month-year' | 'month-year';

export type DateQuestionProps = QuestionProps & {
  variant?: DateQuestionVariant;
};

const DATE_VARIANT_SETTINGS = {
  'day-month-year': {
    views: ['year', 'month', 'day'],
    formatFn: (yearMonthDay: Dayjs | null) => dayjs(yearMonthDay).format('YYYY-MM-DD'),
  },
  'month-year': {
    views: ['year', 'month'],
    // Submit API expects the first day of the selected month, for example 2024-04-01.
    formatFn: (yearMonth: Dayjs | null) => dayjs(yearMonth).startOf('month').format('YYYY-MM-DD'),
  },
};

export const DateQuestion = ({
  name,
  control,
  variant = 'day-month-year',
  ...props
}: DateQuestionProps) => {
  const { locale } = useLanguageStore();
  const shortLocale = locale.substring(0, 2);
  const datePickersIntl = useDatePickersIntl();
  const { field } = useController({ name, control });
  const [value, setValue] = useState<Dayjs | null>(null);

  const handleChange = (newValue: Dayjs | null) => {
    field.onChange(DATE_VARIANT_SETTINGS[variant]?.formatFn(newValue));
    setValue(newValue);
  };

  return (
    <Question name={name} control={control} {...props}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={shortLocale}
        localeText={
          datePickersIntl[shortLocale]?.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          views={DATE_VARIANT_SETTINGS[variant]?.views as DateView[]}
          value={value}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </Question>
  );
};
