import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  Avatar,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from '@trustyou/ui';

import { useUnregisterUnmountedQuestion } from '../../../../../service/hooks/use-unregister-unmounted-question';
import {
  MAX_QUESTION_EDITOR_FIELD_WIDTH_PX,
  QUESTION_TYPES_BY_CATEGORY,
} from '../../../../feedback/constants';
import type { Color } from '../../../../feedback/types';
import { CustomTextFieldWithSeparatedLabel } from '../../../../shared';
import {
  QuestionEditorScaleOneToFiveOptions,
  QuestionEditorScaleOneToTenOptions,
  QuestionEditorScaleZeroToTenOptions,
} from '../../../constants/editor-scale-options';

const CustomColorField = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '100px',
    height: '30px',
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: 4,
  },
}));

const QUESTION_LIBRARY_EDITOR_SCALE_RATING_COLOR: Color[] = ['error', 'warning', 'success'];

export function QuestionEditorScaleRatingType() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { fields, replace, update } = useFieldArray({
    control,
    name: 'ratingOptions',
  });

  useUnregisterUnmountedQuestion({
    questionTypes: QUESTION_TYPES_BY_CATEGORY.scale,
    fieldsToUnregister: ['ratingOptions', 'rating', 'nps'],
  });

  useEffect(() => {
    if (watch('questionType') === 'nps') {
      if (fields.length !== QuestionEditorScaleZeroToTenOptions.length) {
        replace([...QuestionEditorScaleZeroToTenOptions]);
      }
    } else {
      if (watch('questionTypeVariation') === 'numbers-one-to-five') {
        if (fields.length !== QuestionEditorScaleOneToFiveOptions.length) {
          replace([...QuestionEditorScaleOneToFiveOptions]);
        }
      }
      if (watch('questionTypeVariation') === 'numbers-one-to-ten') {
        if (fields.length !== QuestionEditorScaleOneToTenOptions.length) {
          replace([...QuestionEditorScaleOneToTenOptions]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('questionTypeVariation'), watch('questionType')]);

  return (
    <Stack spacing={2} style={{ marginTop: 40 }}>
      <Typography variant="body2">
        <FormattedMessage
          id="survey.question-editor.scale-rating-options.title"
          defaultMessage="Labels are optional. If left blank, nothing will be shown"
        />
      </Typography>
      {fields?.map((scaleOptionField, index) => (
        <Stack key={index} sx={{ gap: 2, flexDirection: 'row' }}>
          <FormControl
            variant="standard"
            fullWidth
            sx={{ maxWidth: MAX_QUESTION_EDITOR_FIELD_WIDTH_PX }}
          >
            <InputLabel shrink>
              <FormattedMessage id="survey.common.rating-label" defaultMessage="Rating label" />{' '}
              {watch('questionType') === 'nps' ? index : index + 1}
            </InputLabel>
            <Controller
              name={`ratingOptions[${index}].label`}
              control={control}
              render={({ field }) => (
                <CustomTextFieldWithSeparatedLabel
                  variant="outlined"
                  error={
                    errors?.ratingOptions &&
                    Array.isArray(errors.ratingOptions) &&
                    !!errors.ratingOptions[index]?.label
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={scaleOptionField.label}
                  onChange={(e) => {
                    update(index, { ...scaleOptionField, label: e.target.value });
                  }}
                  onBlur={(e) => {
                    update(index, { ...scaleOptionField, label: e.target.value });
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              {index === 0 && (
                <FormattedMessage
                  id="survey.question-editor.scale-rating.color-field.label"
                  defaultMessage="Color of selected option"
                />
              )}
            </InputLabel>
            <Controller
              name={`ratingOptions[${index}].color`}
              control={control}
              render={({ field }) => (
                <CustomColorField
                  fullWidth
                  select
                  sx={{ width: 60 }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={scaleOptionField.color}
                  onChange={(e) => {
                    update(index, { ...scaleOptionField, color: e.target.value });
                  }}
                >
                  {QUESTION_LIBRARY_EDITOR_SCALE_RATING_COLOR.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Avatar
                        sx={{ bgcolor: `${option}.main`, width: 24, height: 24, marginRight: 2 }}
                      >
                        <Chip color={option} />
                      </Avatar>
                    </MenuItem>
                  ))}
                </CustomColorField>
              )}
            />
          </FormControl>
        </Stack>
      ))}
    </Stack>
  );
}
