import { FormattedMessage } from 'react-intl';

import { Alert, AlertTitle } from '@trustyou/ui';

const InvalidFileSizeError = () => (
  <Alert severity="error">
    <AlertTitle>
      <FormattedMessage
        id="entitiesImporter.uploadSpreadsheet.wrongFileSize"
        defaultMessage="Wrong file size"
      />
    </AlertTitle>
    <FormattedMessage
      id="entitiesImporter.uploadSpreadsheet.wrongFileSizeErrorDescription"
      defaultMessage="The size limit for uploaded files is 20 MB. Please reduce the size of your file."
    />
  </Alert>
);

export default InvalidFileSizeError;
