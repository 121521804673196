import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import type { GridRowParams } from '@mui/x-data-grid-pro';
import {
  type PreviewEntityData,
  type PreviewEntityRow,
  getEntitiesPayload,
  getPreviewEntitiesSummary,
  useAddEntities,
  useChangelingStore,
  useRouteUtils,
} from '@trustyou/shared';
import {
  Button,
  SecondaryDrawer,
  SectorAvatarIcon,
  Stack,
  Typography,
  snackbar,
} from '@trustyou/ui';

import Summary from './Summary';
import Table from './Table';

import { EntityDetailView } from '../../../components';
import ImportingAdditionalEntitiesLoader from '../../../components/LoadingScreen/ImportingAdditionalEntities';
import ImportingEntitiesLoader from '../../../components/LoadingScreen/ImportingEntities';
import { Footer } from '../Footer';
import styles from './styles';

type PreviewEntitiesProps = {
  additionalEntities?: boolean;
  onClose?: () => void;
  onClickBack: () => void;
  previewEntityData: PreviewEntityData;
};

export const PreviewEntities = ({
  additionalEntities,
  previewEntityData,
  onClickBack,
  onClose,
}: PreviewEntitiesProps) => {
  const intl = useIntl();
  const { navigateWithBase } = useRouteUtils();
  const { isChangeling } = useChangelingStore();
  const { chosenSubscriptionId } = useParams();
  const addEntities = useAddEntities({ shouldRefetchSubscriptionEntities: !!chosenSubscriptionId });

  const [selectedEntity, setSelectedEntity] = useState<PreviewEntityRow>();

  const { rows, summary } = useMemo(
    () => getPreviewEntitiesSummary(previewEntityData),
    [previewEntityData]
  );

  const handleRowClick = (params: GridRowParams) => {
    if (params.row.entityId) {
      setSelectedEntity(params.row);
    }
  };

  const goBack = () => {
    if (chosenSubscriptionId) {
      navigateWithBase(`subscriptions/${chosenSubscriptionId}/entities`);
    } else {
      navigateWithBase(`organization/entities`);
    }
  };

  const handleProceed = () => {
    const payload = getEntitiesPayload(previewEntityData.matched, chosenSubscriptionId);
    addEntities.mutate(payload, {
      onSuccess: () => {
        if (onClose) {
          snackbar.success(
            intl.formatMessage({
              id: 'entitiesImporter.entitiesImported',
              defaultMessage: 'Entities imported',
            })
          );
          onClose();
        } else {
          snackbar.success(
            intl.formatMessage({
              id: 'entitiesImporter.entities.added',
              defaultMessage: 'Entities added successfully',
            })
          );
          goBack();
        }
      },
    });
  };

  if (addEntities.isPending && !additionalEntities) {
    return <ImportingEntitiesLoader />;
  }

  if (addEntities.isPending && additionalEntities) {
    return <ImportingAdditionalEntitiesLoader />;
  }

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id="entitiesImporter.previewEntities" defaultMessage="Preview entities" />
      </Typography>
      <Summary summary={summary} />
      <Table rows={rows} onRowClick={handleRowClick} />
      <SecondaryDrawer
        header={
          selectedEntity && (
            <Stack direction="row" gap={1} alignItems="center">
              <SectorAvatarIcon sector={selectedEntity.sector} />
              {selectedEntity.name}
            </Stack>
          )
        }
        open={!!selectedEntity}
        onClose={() => setSelectedEntity(undefined)}
        changelingMode={isChangeling}
      >
        {selectedEntity?.entityId && (
          <EntityDetailView
            entityId={selectedEntity.entityId}
            presetEntity={selectedEntity}
            showEditCustomAttributes={false}
          />
        )}
      </SecondaryDrawer>
      <Footer onClickBack={onClickBack}>
        <Typography variant="body1" sx={styles.footerMessage}>
          {additionalEntities ? (
            <FormattedMessage
              id="entitiesImporter.onlyMatchedEntitiesWillBeSavedExistingEntitiesWontBeChanged"
              defaultMessage="Only matched entities will be saved. Existing entities won't be changed."
            />
          ) : (
            <FormattedMessage
              id="entitiesImporter.onlyMatchedEntitiesWillBeSaved"
              defaultMessage="Only matched entities will be saved"
            />
          )}
        </Typography>
        <Button data-testid="next-button" variant="contained" size="large" onClick={handleProceed}>
          <FormattedMessage id="entitiesImporter.action.proceed" defaultMessage="Proceed" />
        </Button>
      </Footer>
    </>
  );
};
