import type { BenchmarkDefinitionRule } from '../types';

export const getStringifiedBenchmarkRules = (rules: BenchmarkDefinitionRule[]) => {
  const formattedRules = rules.reduce(
    (cur, item) => {
      if (item.attribute && item.values.length > 0) {
        cur[item.attribute] = item.values;
      }
      return cur;
    },
    {} as Record<string, string[]>
  );
  if (Object.keys(formattedRules).length) {
    return JSON.stringify(formattedRules);
  }
  return '';
};

export const getParsedBenchmarkRules = (stringifiedRules: string): BenchmarkDefinitionRule[] => {
  if (!stringifiedRules) return [];
  const parsedRules = JSON.parse(stringifiedRules) as Record<string, string[]>;
  return Object.entries(parsedRules).map(([attribute, values]) => ({
    attribute,
    values,
  }));
};
