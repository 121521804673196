import {
  ADD_OWNED_ENTITIES_PERMISSION,
  SEARCH_ADDABLE_ENTITIES_PERMISSION,
  isTemporaryHidden,
  usePermissionStore,
} from '@trustyou/shared';

const useShowAddEntities = () => {
  const { hasPermissions } = usePermissionStore();

  const isSearchAddableEntitiesAllowed = hasPermissions(SEARCH_ADDABLE_ENTITIES_PERMISSION);
  const isAddOwnedEntitiesAllowed = hasPermissions(ADD_OWNED_ENTITIES_PERMISSION);
  const isAddEntityEnabled = !isTemporaryHidden('CXP-customers-add-entity');

  return isSearchAddableEntitiesAllowed && isAddOwnedEntitiesAllowed && isAddEntityEnabled;
};

export default useShowAddEntities;
