import { type MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { faArrowRightFromBracket, faUserCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  navigationNodes,
  useChangelingStore,
  useOrganizationLogo,
  useRouteUtils,
  userActions,
} from '@trustyou/shared';
import {
  BrandedAvatar,
  Divider,
  ListItemIcon,
  ListItemText,
  MENU_MAX_WIDTH,
  Menu,
  MenuItem,
  MenuList,
  OrganizationsMenu,
  Typography,
} from '@trustyou/ui';

import { CustomMenuItem } from './CustomMenuItem';
import { UserButton } from './UserButton';

type UserMenuProps = {
  onLogout: () => void;
};

export function UserMenu({ onLogout }: UserMenuProps) {
  const intl = useIntl();
  const { navigateWithBase } = useRouteUtils();
  const { accessTokenPayload } = useOidcAccessToken();
  const { isChangeling, userInfo } = useChangelingStore();
  const { data: organizationLogo } = useOrganizationLogo();

  const name = isChangeling ? userInfo?.name : accessTokenPayload.name;
  const email = isChangeling ? userInfo?.email : accessTokenPayload.email;

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateToProfileSettings = () => {
    handleCloseUserMenu();
    navigateWithBase('settings/user');
  };

  return (
    <>
      <UserButton
        logo={organizationLogo?.logo_uri}
        name={isChangeling ? userInfo?.name : accessTokenPayload.name}
        onClick={handleOpenUserMenu}
      />
      <Menu
        sx={{
          marginBlockStart: '3rem',
          '& .MuiPaper-root': {
            maxWidth: MENU_MAX_WIDTH,
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          disableRipple
          sx={{
            userSelect: 'text',
            pointerEvents: 'none',
            '& span': {
              display: 'block',
              overflow: 'hidden',
              textWrap: 'wrap',
              overflowWrap: 'break-word',
            },
          }}
        >
          <ListItemIcon sx={{ marginInlineEnd: 2 }}>
            <BrandedAvatar name={name} />
          </ListItemIcon>
          <ListItemText>
            <Typography component="span">{name || email}</Typography>
            {name && email && (
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {email}
              </Typography>
            )}
          </ListItemText>
        </MenuItem>
        <OrganizationsMenu onClick={handleCloseUserMenu} />
        <Divider />
        <MenuList sx={{ paddingBlockEnd: 0 }}>
          <CustomMenuItem
            id="profile-menu-item"
            icon={faUserCircle}
            text={intl.formatMessage(navigationNodes.userSettings)}
            onClick={navigateToProfileSettings}
          />
          <CustomMenuItem
            id="logout-menu-item"
            icon={faArrowRightFromBracket}
            text={intl.formatMessage(userActions.logout)}
            onClick={onLogout}
          />
        </MenuList>
      </Menu>
    </>
  );
}
