import { type ReactNode, type UIEventHandler, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faCircleCheck } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { SEARCH_DEBOUNCE_TIME_MS, type SelectionMode, isScrollEnd } from '@trustyou/shared';
import {
  Box,
  Checkbox,
  CircularProgress,
  StyledFontAwesomeIcon as Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SearchBar,
  type SelectorItem,
  Stack,
  Typography,
} from '@trustyou/ui';

import styles from './SelectionList.styles';

interface SelectionListProps<T> {
  selected: string[];
  items: (T & SelectorItem)[];
  disabled?: boolean;
  itemsDisabled?: boolean;
  isLoading?: boolean;
  listState: ReactNode;
  hideSelectResults: boolean;
  defaultValue?: string;
  searchPlaceholder?: string;
  mode?: SelectionMode;
  onSelect: (items: (T & SelectorItem)[]) => void;
  onSearch: (value: string) => void;
  fetchNextPage?: () => void;
  renderRowContent: (item: T & SelectorItem) => ReactNode;
}

export const SelectionList = <T,>({
  items,
  disabled,
  itemsDisabled,
  selected,
  isLoading,
  listState,
  hideSelectResults,
  defaultValue,
  searchPlaceholder,
  mode,
  onSelect,
  onSearch,
  fetchNextPage,
  renderRowContent,
}: SelectionListProps<T>) => {
  const [showSelectResults, setShowSelectResults] = useState(false);

  const onScrollReact: UIEventHandler<HTMLUListElement> = (event) => {
    if (isScrollEnd(event.target as HTMLElement)) {
      fetchNextPage?.();
    }
  };

  const handleSearch = (value: string) => {
    onSearch(value);
    if (value) {
      setShowSelectResults(true);
    } else {
      setShowSelectResults(false);
    }
  };

  const renderSelectResults = () => (
    <ListItemButton
      onClick={() => onSelect(items)}
      data-testid="selection-item-select-all-search-results"
      sx={styles.listItemButton}
      disabled={disabled}
    >
      <ListItemText
        primary={
          <Typography variant="subtitle1" fontWeight="500">
            <FormattedMessage
              id="tyDesign.selector.selectSearchResults"
              defaultMessage={'Select all results ({count})'}
              values={{ count: items.length }}
            />
          </Typography>
        }
      />
      <ListItemIcon sx={styles.listItemIcon}>
        <Checkbox
          edge="start"
          checked={
            selected.length > 0 && items.map((row) => row.id).every((id) => selected.includes(id))
          }
          disableRipple
        />
      </ListItemIcon>
    </ListItemButton>
  );

  return (
    <Stack gap={2} width="100%" position="relative">
      <SearchBar
        defaultValue={defaultValue}
        placeholder={searchPlaceholder}
        onSearch={handleSearch}
        debounceTime={SEARCH_DEBOUNCE_TIME_MS}
        id="selectorSearchBar"
        disabled={disabled}
      />
      {!items.length && !isLoading && listState}
      {!!items.length && (
        <List
          dense
          disablePadding
          sx={{
            ...styles.list,
            ...(disabled && styles.disabledList),
          }}
          onScroll={onScrollReact}
        >
          {showSelectResults && !hideSelectResults && renderSelectResults()}
          {items.map((item) => (
            <ListItemButton
              key={item.id}
              onClick={() => onSelect([item])}
              data-testid="selection-item"
              sx={styles.listItemButton}
              disabled={disabled || (itemsDisabled && !selected.includes(item.id))}
            >
              {renderRowContent(item)}
              <ListItemIcon sx={styles.listItemIcon}>
                {mode === 'multiple' ? (
                  <Checkbox checked={selected.includes(item.id)} disableRipple />
                ) : (
                  selected.includes(item.id) && (
                    <Icon size="lg" sx={styles.checkedIcon} icon={faCircleCheck} />
                  )
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      )}
      {isLoading && (
        <Box sx={styles.loader}>
          <CircularProgress />
        </Box>
      )}
    </Stack>
  );
};
