import type { StateCreator } from 'zustand';

import { LANGUAGE_ITEM_AUTO } from '../../hooks';
import { type LanguageItem, ResponseTextField } from '../../types';

export interface ResponseSliceState {
  isResponseFormDirty: boolean;
  updateIsResponseFormDirty: (isResponseFormDirty: boolean) => void;

  hasGeneratedResponse: boolean;
  updateHasGeneratedResponse: (hasGeneratedResponse: boolean) => void;

  hasExistingResponse: boolean;
  updateHasExistingResponse: (hasExistingResponse: boolean) => void;

  generationLanguage: LanguageItem;
  updateGenerationLanguage: (generationLanguage: LanguageItem) => void;

  selectedResponse: ResponseTextField;
  updateSelectedResponse: (selectedResponse: ResponseTextField) => void;

  isSubmitDisabled: boolean;
  updateIsSubmitDisabled: (isSubmitDisabled: boolean) => void;

  acceptsDirectResponse: boolean;
  updateAcceptsDirectResponse: (acceptsDirectResponse: boolean) => void;
}

export const createResponseSlice: StateCreator<ResponseSliceState, [], []> = (set) => ({
  isResponseFormDirty: false,
  updateIsResponseFormDirty: (isResponseFormDirty) => set(() => ({ isResponseFormDirty })),

  hasGeneratedResponse: false,
  updateHasGeneratedResponse: (hasGeneratedResponse) => set(() => ({ hasGeneratedResponse })),

  hasExistingResponse: false,
  updateHasExistingResponse: (hasExistingResponse) => set(() => ({ hasExistingResponse })),

  generationLanguage: LANGUAGE_ITEM_AUTO,
  updateGenerationLanguage: (generationLanguage) => set(() => ({ generationLanguage })),

  selectedResponse: ResponseTextField.ORIGINAL,
  updateSelectedResponse: (selectedResponse) => set(() => ({ selectedResponse })),

  isSubmitDisabled: true,
  updateIsSubmitDisabled: (isSubmitDisabled) => set(() => ({ isSubmitDisabled })),

  acceptsDirectResponse: false,
  updateAcceptsDirectResponse: (acceptsDirectResponse) => set(() => ({ acceptsDirectResponse })),
});
