import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  faBallot,
  faBuildings,
  faGrid2,
  faListTree,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Subscription,
  USER_SCOPES,
  useAssignableEntityCount,
  useEntityCount,
  useScopeStore,
} from '@trustyou/shared';
import { SelectionCard, Stack } from '@trustyou/ui';

type ScopeSelectorProps = {
  subscription?: Subscription;
  scopes?: USER_SCOPES[];
};

export function ScopeSelector({ subscription, scopes }: ScopeSelectorProps) {
  const intl = useIntl();
  const { data: orgEntityCount } = useEntityCount(undefined, !subscription);
  const { data: assignableEntityCount } = useAssignableEntityCount(subscription?.id);
  const { selectedScope, setSelectedScope } = useScopeStore();

  const entityCount = subscription ? assignableEntityCount : orgEntityCount;

  const handleCardClick = (scope: USER_SCOPES) => {
    if (selectedScope !== scope) {
      setSelectedScope(scope, true);
    }
  };

  useEffect(() => {
    // we need to drop scope on unMount instead of did mount otherwise strange values could exist
    return () => {
      setSelectedScope(USER_SCOPES.NONE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scopeList = [
    {
      id: USER_SCOPES.ALL_ENTITIES,
      icon: faBallot,
      title: intl.formatMessage(
        {
          id: 'user.scopes.all.entities',
          defaultMessage: 'All entities ({entityCount})',
        },
        { entityCount }
      ),
      description: intl.formatMessage({
        id: 'user.scopes.descriptions.all.entities',
        defaultMessage: 'Access to all entities in your organization',
      }),
    },
    {
      id: USER_SCOPES.BY_ENTITIES,
      icon: faBuildings,
      title: intl.formatMessage({
        id: 'user.scopes.by.entities',
        defaultMessage: 'By entities',
      }),
      description: intl.formatMessage({
        id: 'user.scopes.descriptions.by.entities',
        defaultMessage: 'Access to selected entities in your organization',
      }),
    },
    {
      id: USER_SCOPES.ACCESS_GROUP,
      icon: faGrid2,
      title: intl.formatMessage({
        id: 'user.scopes.by.access-group',
        defaultMessage: 'By access group',
      }),
      description: intl.formatMessage({
        id: 'user.scopes.descriptions.by.access-group',
        defaultMessage: 'Access to entities in an access group',
      }),
    },
    {
      id: USER_SCOPES.BY_GROUPS,
      icon: faListTree,
      title: intl.formatMessage({
        id: 'user.scopes.by.segment',
        defaultMessage: 'By segment',
      }),
      description: intl.formatMessage({
        id: 'user.scopes.descriptions.by.groups',
        defaultMessage:
          'Access to selected groups of entities (e.g. brand, city) in your organization',
      }),
    },
    {
      id: USER_SCOPES.ADVANCED_SELECTION,
      icon: faListTree,
      title: intl.formatMessage({
        id: 'user.scopes.advanced-selection',
        defaultMessage: 'Advanced selection',
      }),
      description: intl.formatMessage({
        id: 'user.scopes.descriptions.advanced-selection',
        defaultMessage: 'Access to entities based on rules',
      }),
    },
  ];

  return (
    <Stack direction="row" spacing={2}>
      {(scopes ? scopeList.filter((row) => scopes.includes(row.id)) : scopeList).map((scope) => (
        <SelectionCard
          {...scope}
          key={scope.id}
          title={scope.title}
          content={scope.description}
          onClick={() => handleCardClick(scope.id)}
          selected={selectedScope === scope.id}
          iconAltText={scope.title}
        />
      ))}
    </Stack>
  );
}
