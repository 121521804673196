import arabic from './compiled/ar.json';
import bulgarian from './compiled/bg.json';
import czech from './compiled/cs.json';
import danish from './compiled/da.json';
import german from './compiled/de.json';
import greek from './compiled/el.json';
import english from './compiled/en.json';
import spanish from './compiled/es.json';
import finnish from './compiled/fi.json';
import french from './compiled/fr.json';
import hebrew from './compiled/he.json';
import croatian from './compiled/hr.json';
import hungarian from './compiled/hu.json';
import indonesian from './compiled/id.json';
import italian from './compiled/it.json';
import japanese from './compiled/ja.json';
import korean from './compiled/ko.json';
import malay from './compiled/ms.json';
import dutch from './compiled/nl.json';
import polish from './compiled/pl.json';
import portugueseOfBrazil from './compiled/pt-BR.json';
import portuguese from './compiled/pt.json';
import romanian from './compiled/ro.json';
import russian from './compiled/ru.json';
import slovak from './compiled/sk.json';
import slovenian from './compiled/sl.json';
import serbian from './compiled/sr.json';
import swedish from './compiled/sv.json';
import thai from './compiled/th.json';
import turkish from './compiled/tr.json';
import vietnamese from './compiled/vi.json';
import traditionalChinese from './compiled/zf.json';
import cantonese from './compiled/zh-yue.json';
import chinese from './compiled/zh.json';

export const submissionUiSubsetMessages = {
  'de-DE': german,
  'en-US': english,
  'es-ES': spanish,
  'fr-FR': french,
  'it-IT': italian,
  'ja-JP': japanese,
  'pt-BR': portugueseOfBrazil,
  'pt-PT': portuguese,
  'ru-RU': russian,
  'zh-CN': chinese,
  'zh-yue': cantonese,
  ar: arabic,
  bg: bulgarian,
  cs: czech,
  da: danish,
  el: greek,
  fi: finnish,
  he: hebrew,
  hr: croatian,
  hu: hungarian,
  id: indonesian,
  ko: korean,
  ms: malay,
  nl: dutch,
  pl: polish,
  ro: romanian,
  sk: slovak,
  sl: slovenian,
  sr: serbian,
  sv: swedish,
  th: thai,
  tr: turkish,
  vi: vietnamese,
  zf: traditionalChinese,
};
