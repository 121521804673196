import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { snackbar } from '@trustyou/ui';

import type { ResponseResult } from '../../../client';
import { RESPONSE_AI_RESULT } from '../../../constants/query-keys';
import { useResponseAIIssueOptions, useSendFeedback } from '../../../hooks';
import type { Issue, Score } from '../../../types';

export function useResponseAIRating() {
  const intl = useIntl();

  const queryClient = useQueryClient();
  const issueOptions = useResponseAIIssueOptions();
  const sendFeedback = useSendFeedback();

  const [score, setScore] = useState<Score | null>(null);
  const [showFeedbackStep, setShowFeedbackStep] = useState(false);
  const [showThankYouStep, setShowThankYouStep] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState<Issue[]>([]);
  const [feedbackText, setFeedbackText] = useState('');

  const isOtherSelected = selectedIssues.includes('other');
  const payload = {
    consumption_log_id: (
      queryClient.getMutationCache().find({ mutationKey: [RESPONSE_AI_RESULT] })?.state
        ?.data as ResponseResult
    )?.consumption_log_id,
    score: score as Score,
    issues: selectedIssues,
    description: isOtherSelected ? feedbackText : '',
  };

  useEffect(() => {
    setShowThankYouStep(false);
    if (!score) return;

    if (score > 3) {
      sendFeedback.mutate({
        ...payload,
        issues: [],
        description: '',
      });
      if (sendFeedback.isError) {
        handleRatingError();
      } else {
        snackbar.success(
          intl.formatMessage({
            id: 'inbox.response.ai.rating.snackbar.success',
            defaultMessage: 'Thank you for your feedback!',
          })
        );
      }
    } else {
      sendFeedback.mutate(payload);
      setShowFeedbackStep(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  useEffect(() => {
    !showFeedbackStep && setShowFeedbackStep(false);
  }, [showFeedbackStep]);

  const handleRatingError = () => {
    snackbar.error(
      intl.formatMessage({
        id: 'inbox.response.ai.rating.snackbar.error',
        defaultMessage: "Couldn't send rating, please try again.",
      })
    );
  };

  const closeFeedbackStep = () => {
    setShowFeedbackStep(false);
    setShowThankYouStep(false);
  };

  const toggleSelectedIssue = (clickedIssue: Issue) => {
    if (selectedIssues.includes(clickedIssue)) {
      setSelectedIssues(selectedIssues.filter((issue) => issue !== clickedIssue));
    } else {
      setSelectedIssues([...selectedIssues, clickedIssue]);
    }
  };

  const submit = () => {
    if (!score) return;
    sendFeedback.mutate(payload);
    if (sendFeedback.isError) {
      handleRatingError();
      return;
    }
    setShowThankYouStep(true);
  };

  return {
    issueOptions,
    score,
    setScore,
    showFeedbackStep,
    setShowFeedbackStep,
    showThankYouStep,
    setShowThankYouStep,
    selectedIssues,
    isOtherSelected,
    feedbackText,
    setFeedbackText,
    closeFeedbackStep,
    toggleSelectedIssue,
    submit,
  };
}
