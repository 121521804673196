import type { MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CardMediaProps, CardProps } from '@mui/material';
import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type HomeCardType, useResponsive } from '@trustyou/shared';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  StyledFontAwesomeIcon,
  Typography,
  styled,
} from '@trustyou/ui';

type CustomResponsiveProps = {
  orientation?: 'horizontal' | 'vertical';
};

const StyledCard = styled(Card)<CardProps & CustomResponsiveProps>(
  ({ theme, orientation = 'vertical' }) => ({
    display: orientation === 'horizontal' ? 'flex' : 'block',
    flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    justifyContent: orientation === 'horizontal' ? 'space-between' : 'start',
    gap: orientation === 'horizontal' ? 0 : theme.spacing(2),
    cursor: 'pointer',
    transition: 'all 150ms ease-in-out',
    height: '100%',
    width: '100%',
  })
);

const StyledCardMedia = styled(CardMedia)<CardMediaProps & CustomResponsiveProps>(
  ({ orientation = 'vertical' }) => ({
    flexShrink: orientation === 'horizontal' ? 0 : undefined,
    width: orientation === 'horizontal' ? 320 : '100%',
    height: 240,
  })
);

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export function HomeCard({
  id,
  path,
  name,
  description,
  icon,
  color,
  imageUrl,
  featured,
}: HomeCardType) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isTablet } = useResponsive();

  const orientation = isTablet ? 'horizontal' : 'vertical';

  const onClick = (event: MouseEvent) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(`${process.env.PUBLIC_URL}${path}`, '_blank');
    } else {
      navigate(path ?? '.', { state: { path: pathname } });
    }
  };

  return (
    <StyledCard
      orientation={orientation}
      variant="outlined"
      sx={{
        ':hover': {
          translate: '0 -4px',
          boxShadow: 4,
        },
        '& .MuiCardContent-root:last-child': {
          paddingBlockEnd: 2,
        },
      }}
      onClick={onClick}
      data-gtm-id={`home-nav-card-${id}`}
    >
      {featured && <StyledCardMedia orientation={orientation} image={imageUrl} title={name} />}
      <StyledCardContent>
        <Box>
          {icon && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBlockEnd: 1 }}>
              <Avatar
                variant="rounded"
                sx={{ height: 44, width: 44, backgroundColor: color, color: 'common.white' }}
              >
                <FontAwesomeIcon icon={icon} />
              </Avatar>
            </Box>
          )}
          <Box>
            <Typography component="h3" variant="subtitle1">
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Box>
        </Box>
        <StyledFontAwesomeIcon icon={faArrowRight} size="lg" sx={{ color: 'primary.main' }} />
      </StyledCardContent>
    </StyledCard>
  );
}
