import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FormControlLabel, FormGroup, Switch } from '@trustyou/ui';

import type { EmailNotificationSettings_Input } from '../../../client';

export function SurveyReviewModerationSection() {
  const intl = useIntl();
  const { control } = useFormContext<EmailNotificationSettings_Input>();

  return (
    <FormGroup>
      <Controller
        control={control}
        name="survey_moderation.enabled"
        render={({ field }) => (
          <FormControlLabel
            control={<Switch {...field} checked={field.value} />}
            label={intl.formatMessage({
              id: 'inbox.settings.notifications.survey-moderation.label',
              defaultMessage: 'Receive survey review moderation requests via email',
            })}
          />
        )}
      />
    </FormGroup>
  );
}
