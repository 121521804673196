import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  emptyStateContentPane: {
    height: '100%',
    margin: 'auto',
  },
  emptyStateBodyStyles: {
    justifyContent: 'center',
  },
  emptyStateHeader: {
    marginTop: 3,
  },
};

export default styles;
