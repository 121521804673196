import { useIntl } from 'react-intl';

import {
  type Segment,
  type SegmentGroup,
  type SegmentGroupCreated,
  type SegmentGroupPayload,
  useCreateGroup,
  useUpdateGroup,
} from '@trustyou/shared';
import { BackdropSpinner, Box, FullScreenModal, snackbar } from '@trustyou/ui';
import type { AxiosError } from 'axios';

import UpdateGroupsForm from './UpdateGroupsForm';

import { messages } from '../../../../../../constants/messages/segments';
import { useDashboardDelayMessage } from '../../../../../../hooks';
import styles from './styles';

type UpdateSegmentsProps = {
  onClose: () => void;
  segment: Segment;
  group?: SegmentGroup;
  onGroupEdit?: (group: SegmentGroup) => void;
};

const UpdateGroups = ({ segment, group, onGroupEdit, onClose }: UpdateSegmentsProps) => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();

  const createGroup = useCreateGroup();
  const updateGroup = useUpdateGroup();

  const handleError = (error: AxiosError) => {
    if (JSON.stringify(error.response?.data).includes('"code":"mece_rule"')) {
      snackbar.error(
        intl.formatMessage({
          id: 'organization.segments.groupCreateFailed',
          defaultMessage:
            'The entities matching the selected rule are already assigned to another group within this segment',
        })
      );
    } else {
      snackbar.genericError();
    }
  };

  const onSave = (data: SegmentGroupPayload) => {
    if (data.id) {
      updateGroup.mutate(data, {
        onSuccess: (data: SegmentGroupCreated) => {
          snackbar.success(
            intl.formatMessage(messages.changesSaved, { delay: dashboardDelayMessage })
          );
          onGroupEdit?.({ ...(group as SegmentGroup), ...data });
          onClose();
        },
        onError: handleError,
      });
    } else {
      createGroup.mutate(
        {
          ...data,
          segment_id: segment.id,
        },
        {
          onSuccess: (data: SegmentGroupCreated) => {
            snackbar.success(
              intl.formatMessage(
                {
                  id: 'organization.segments.groupCreateSuccess',
                  defaultMessage: '{group} added. {delay}.',
                },
                { group: data.name, delay: dashboardDelayMessage }
              )
            );
            onClose();
          },
          onError: handleError,
        }
      );
    }
  };

  return (
    <FullScreenModal open onClose={onClose}>
      <Box sx={styles.modalBody}>
        <UpdateGroupsForm
          segment={segment}
          group={group}
          dataName={group ? 'update-group' : 'add-group'}
          onSave={onSave}
        />
      </Box>
      <BackdropSpinner isLoading={createGroup.isPending || updateGroup.isPending} />
    </FullScreenModal>
  );
};

export default UpdateGroups;
