import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  type FormErrorResponseData,
  useCreateEntityGoogleConnection,
  useCreateGoogleConnection,
  useRouteUtils,
} from '@trustyou/shared';
import { BackdropSpinner, FullScreenModal } from '@trustyou/ui';
import type { AxiosError } from 'axios';

import FailedConnect from './FailedConnect';
import SuccessConnect from './SuccessConnect';

import { useReviewProviderStore } from '../..//store';
import { GOOGLE_REDIRECT_URL } from '../../constants/reviewProviders';

export default function GoogleStaticConnect() {
  const { navigateWithBase } = useRouteUtils();
  const { googleConnectEntityId } = useReviewProviderStore();

  const [searchParams] = useSearchParams();

  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [isUniquenessError, setIsUniquenessError] = useState<boolean>(false);

  const onClose = () => {
    if (googleConnectEntityId) {
      navigateWithBase(
        `organization/entities/${googleConnectEntityId}/connections${providerId ? `/${providerId}` : ''}`
      );
    } else {
      navigateWithBase(
        `settings/application/review-providers${providerId ? `/${providerId}` : ''}`
      );
    }
  };

  const code = searchParams.get('code');

  const handleGoogleConnectionError = (error: AxiosError) => {
    const responseData = error.response?.data as FormErrorResponseData;
    const bookingValidationErrors = responseData.error.validation_errors;
    if (!bookingValidationErrors) return;
    if (!bookingValidationErrors.some((item) => item.code === 'uniqueness_error')) return;
    setIsUniquenessError(true);
  };

  const createGoogleConnection = useCreateGoogleConnection();
  const createEntityGoogleConnection = useCreateEntityGoogleConnection();

  const getQueryProvider = () => {
    if (googleConnectEntityId) return createEntityGoogleConnection;
    return createGoogleConnection;
  };

  useEffect(() => {
    if (!code) return;
    // prevent cycle of requests cause mutation is fired automatically
    if (
      getQueryProvider().isPending ||
      getQueryProvider().isError ||
      getQueryProvider().isSuccess
    ) {
      return;
    }
    const payload = { redirect_url: GOOGLE_REDIRECT_URL, code };
    if (googleConnectEntityId) {
      createEntityGoogleConnection.mutate(
        { ...payload, entity_id: googleConnectEntityId },
        {
          onSuccess: (data) => {
            setProviderId(data.review_provider_id);
          },
          onError: (err) => {
            handleGoogleConnectionError(err as AxiosError);
          },
        }
      );
    } else {
      createGoogleConnection.mutate(payload, {
        onSuccess: (data) => {
          setProviderId(data.review_provider_id);
        },
        onError: (err) => {
          handleGoogleConnectionError(err as AxiosError);
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (!code || getQueryProvider().isError)
      return <FailedConnect isUniquenessError={isUniquenessError} onClose={onClose} />;
    if (getQueryProvider().isPending) return <BackdropSpinner isLoading />;
    if (getQueryProvider().isSuccess) return <SuccessConnect onClose={onClose} />;
    return null;
  };

  const getCloseButtonGTMID = () => {
    if (!code || getQueryProvider().isError) return 'google-static-error';
    if (getQueryProvider().isSuccess) return 'google-static-success';
    return undefined;
  };

  return (
    <FullScreenModal closeButtonGTMID={getCloseButtonGTMID()} open onClose={onClose}>
      {renderContent()}
    </FullScreenModal>
  );
}
