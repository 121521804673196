import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faGlobe, faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DiscardDialog,
  FormControl,
  StyledFontAwesomeIcon as Icon,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { mergeAndUpdateScaleArray } from '../../../../utils/survey-question-editor';
import { LANGUAGE_OPTIONS, MAX_QUESTION_EDITOR_FIELD_WIDTH_PX } from '../../../feedback/constants';
import type { Option } from '../../../feedback/types';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

type TranslationDialogProps = {
  open: boolean;
  close: () => void;
  isEditMode: boolean;
};

type TranslatedFields = {
  internalName: string;
  questionText: string;
  additionalText: string;
  notApplicable: string;
  selectOptions: Option[];
  ratingOptions: Option[];
  binaryOptions: Option[];
};

type SavedTranslations = {
  [key: string]: TranslatedFields;
};

function TranslationDialog({ open, close, isEditMode }: TranslationDialogProps) {
  const {
    watch,
    register,
    control,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = useFormContext();
  const defaultLanguage = watch('defaultLanguage');
  const savedTranslations = watch('savedTranslations');
  const selectOptions = watch('selectOptions')?.length > 0 ? watch('selectOptions') : null;
  const ratingOptions = watch('ratingOptions')?.length > 0 ? watch('ratingOptions') : null;
  const binaryOptions = watch('binaryOptions')?.length > 0 ? watch('binaryOptions') : null;

  const isOptionsAvailable =
    savedTranslations?.[defaultLanguage]?.selectOptions?.length > 0 ||
    savedTranslations?.[defaultLanguage]?.ratingOptions?.length > 0 ||
    savedTranslations?.[defaultLanguage]?.binaryOptions?.length > 0;

  function updateTranslations(languageKey: string) {
    const currentTranslations = structuredClone(savedTranslations) || {};
    const updatedTranslations = {
      ...currentTranslations,
      [languageKey]: {
        internalName: structuredClone(watch('internalName')),
        questionText: structuredClone(watch('questionText')),
        additionalText: structuredClone(watch('additionalText') ?? ''),
        notApplicable: structuredClone(watch('notApplicable') ?? ''),
        selectOptions: structuredClone(selectOptions),
        ratingOptions: savedTranslations
          ? mergeAndUpdateScaleArray(
              savedTranslations?.[defaultLanguage]?.ratingOptions,
              structuredClone(ratingOptions)
            )
          : structuredClone(ratingOptions),
        binaryOptions: structuredClone(binaryOptions),
      },
    };

    setValue('savedTranslations', updatedTranslations, { shouldValidate: true });

    // Call sync with the updated translations
    selectOptions && syncSelectTranslationsWithDefault(updatedTranslations);
  }

  const syncSelectTranslationsWithDefault = (translations: SavedTranslations) => {
    const updatedTranslations = { ...translations };
    const defaultOptions = translations[defaultLanguage].selectOptions || [];

    Object.keys(translations).forEach((languageKey) => {
      if (languageKey !== defaultLanguage) {
        const targetOptionsMap = new Map(
          (translations[languageKey].selectOptions || []).map((option) => [option.id, option])
        );

        updatedTranslations[languageKey] = {
          ...updatedTranslations[languageKey],
          selectOptions: defaultOptions.map((defaultOption: Option) => {
            const existingOption = targetOptionsMap.get(defaultOption.id);
            return {
              ...defaultOption,
              value: existingOption?.value || defaultOption.value,
              label: existingOption?.label || defaultOption.label,
              id: defaultOption.id, // Keep the ID from the default language
            };
          }),
        };
      }
    });

    setValue('savedTranslations', updatedTranslations, { shouldValidate: true });
  };

  useEffect(() => {
    updateTranslations(defaultLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTranslations = () => {
    trigger();
    if (isValid) {
      updateTranslations(watch('translatedLanguage'));
      close();
      snackbar.success(
        <FormattedMessage
          id="survey.question-editor.translations-added"
          defaultMessage="Translations added"
        />
      );
    }
  };

  return (
    <Dialog onClose={close} open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="survey.question-editor.translations.new-translation.title"
          defaultMessage="Add translation"
        />
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.question-editor.translations.new-translation.subtitle"
            defaultMessage="Section titles and their translations can be edited in the Content library"
          />
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 3, flexDirection: 'row' }}>
        {/* Language field */}
        <FormControl
          variant="standard"
          sx={{ maxWidth: MAX_QUESTION_EDITOR_FIELD_WIDTH_PX }}
          fullWidth
        >
          <InputLabel shrink>
            <FormattedMessage id="common.language" defaultMessage="Language" />
          </InputLabel>
          <CustomTextFieldWithSeparatedLabel
            fullWidth
            select
            error={!!errors?.translatedLanguage && !watch('translatedLanguage')}
            helperText={
              !!errors?.translatedLanguage && !watch('translatedLanguage') ? (
                <FormattedMessage
                  id="survey.common.mandatory-field"
                  defaultMessage="Mandatory field"
                />
              ) : null
            }
            value={watch('translatedLanguage') ?? ''}
            {...register('translatedLanguage', { required: open })}
          >
            {(!isEditMode
              ? LANGUAGE_OPTIONS.filter(
                  (option) => !Object.hasOwn(savedTranslations ?? {}, option.value)
                )
              : LANGUAGE_OPTIONS
            ).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomTextFieldWithSeparatedLabel>
        </FormControl>
        {/* Internal name field */}
        <Stack spacing={3} sx={{ marginTop: 4 }}>
          <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage
                id="survey.question-editor.translations.internal-name"
                defaultMessage="Internal name"
              />
            </Typography>
            <Typography variant="body1">
              {savedTranslations?.[defaultLanguage]?.internalName} *
            </Typography>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink>
                <FormattedMessage id="common.translation" defaultMessage="Translation" />
              </InputLabel>
              <CustomTextFieldWithSeparatedLabel
                fullWidth
                error={!!errors?.internalName}
                helperText={
                  !!errors?.internalName && (
                    <FormattedMessage
                      id="survey.common.mandatory-field"
                      defaultMessage="Mandatory field"
                    />
                  )
                }
                {...register('internalName', { required: true })}
              />
            </FormControl>
          </Stack>
          {/* Question text field */}
          {watch('questionTypeVariation') !== 'checkbox' && (
            <Stack spacing={1}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                <FormattedMessage
                  id="survey.question-editor.translations.question-text"
                  defaultMessage="Question text"
                />
              </Typography>
              <Typography variant="body1">
                {savedTranslations?.[defaultLanguage]?.questionText} *
              </Typography>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>
                  <FormattedMessage id="common.translation" defaultMessage="Translation" />
                </InputLabel>
                <CustomTextFieldWithSeparatedLabel
                  fullWidth
                  error={!!errors?.questionText}
                  helperText={
                    !!errors?.questionText && (
                      <FormattedMessage
                        id="survey.common.mandatory-field"
                        defaultMessage="Mandatory field"
                      />
                    )
                  }
                  {...register('questionText', { required: true })}
                />
              </FormControl>
            </Stack>
          )}
          {/* Additional text field if exist*/}
          {savedTranslations?.[defaultLanguage]?.additionalText.length > 0 && (
            <Stack spacing={1}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                <FormattedMessage
                  id="survey.question-editor.translations.additional-text"
                  defaultMessage="Additional text"
                />
              </Typography>
              <Typography variant="body1">
                {savedTranslations?.[defaultLanguage]?.additionalText} *
              </Typography>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>
                  <FormattedMessage id="common.translation" defaultMessage="Translation" />
                </InputLabel>
                <CustomTextFieldWithSeparatedLabel
                  fullWidth
                  error={!!errors?.additionalText}
                  helperText={
                    !!errors?.additionalText && (
                      <FormattedMessage
                        id="survey.common.mandatory-field"
                        defaultMessage="Mandatory field"
                      />
                    )
                  }
                  {...register('additionalText', { required: true })}
                />
              </FormControl>
            </Stack>
          )}
          {/* No Applicable*/}
          <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage
                id="survey.question-editor.translations.not-applicable"
                defaultMessage="Not applicable"
              />
            </Typography>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink>
                <FormattedMessage id="common.translation" defaultMessage="Translation" />
              </InputLabel>
              <CustomTextFieldWithSeparatedLabel
                fullWidth
                helperText={
                  <FormattedMessage
                    id="survey.question-editor.option-hidden"
                    defaultMessage="This option is hidden by default"
                  />
                }
                {...register('notApplicable')}
              />
            </FormControl>
          </Stack>
          {/* Answer options based on question type chosen */}
          {isOptionsAvailable && (
            <Stack spacing={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                <FormattedMessage
                  id="survey.question-editor.translations.answer-options"
                  defaultMessage="Answer options"
                />
              </Typography>
              {/* Select question type options */}
              {savedTranslations?.[defaultLanguage]?.selectOptions?.map(
                (option: Option, index: number) => (
                  <Stack spacing={1} key={index}>
                    <Typography variant="body1">{option.label} *</Typography>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink>
                        <FormattedMessage id="common.translation" defaultMessage="Translation" />
                      </InputLabel>
                      <Controller
                        name={`selectOptions[${index}]`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomTextFieldWithSeparatedLabel
                            fullWidth
                            error={!!(errors?.selectOptions as any)?.[index]}
                            helperText={
                              !!(errors?.selectOptions as any)?.[index] && (
                                <FormattedMessage
                                  id="survey.common.mandatory-field"
                                  defaultMessage="Mandatory field"
                                />
                              )
                            }
                            value={field?.value?.value ?? ''}
                            onChange={(e) => {
                              field.onChange({
                                id: option.id,
                                value: e.target.value,
                                label: e.target.value,
                              });
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )
              )}
              {/* Scale/NPS question type options */}
              {savedTranslations?.[defaultLanguage]?.ratingOptions
                ?.filter((option: Option) => option.label !== '')
                ?.map((option: Option, index: number) => (
                  <Stack spacing={1} key={option.value as string}>
                    <Typography variant="body1">{option.label} *</Typography>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink>
                        <FormattedMessage id="common.translation" defaultMessage="Translation" />
                      </InputLabel>
                      <Controller
                        name={`ratingOptions[${option.value}]`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomTextFieldWithSeparatedLabel
                            fullWidth
                            error={!!(errors?.ratingOptions as any)?.[option.value as number]}
                            helperText={
                              !!(errors?.ratingOptions as any)?.[option.value as number] && (
                                <FormattedMessage
                                  id="survey.common.mandatory-field"
                                  defaultMessage="Mandatory field"
                                />
                              )
                            }
                            value={field?.value?.label ?? ''}
                            onChange={(e) => {
                              field.onChange({ ...option, label: e.target.value });
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                ))}
              {/* Binary question type options */}
              {savedTranslations?.[defaultLanguage]?.binaryOptions?.map(
                (option: Option, index: number) => (
                  <Stack spacing={1} key={index}>
                    <Typography variant="body1">{option.label} *</Typography>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink>
                        <FormattedMessage id="common.translation" defaultMessage="Translation" />
                      </InputLabel>
                      <Controller
                        name={`binaryOptions[${index}]`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomTextFieldWithSeparatedLabel
                            fullWidth
                            error={!!(errors?.binaryOptions as any)?.[index]}
                            helperText={
                              !!(errors?.binaryOptions as any)?.[index] && (
                                <FormattedMessage
                                  id="survey.common.mandatory-field"
                                  defaultMessage="Mandatory field"
                                />
                              )
                            }
                            value={field?.value?.label ?? ''}
                            onChange={(e) => {
                              field.onChange({ ...option, label: e.target.value });
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )
              )}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close}>
          <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="contained" onClick={saveTranslations}>
          <FormattedMessage id="common.save-translation" defaultMessage="Save translation" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function QuestionLibraryEditorTranslations() {
  const isMounted = useRef(false);
  const { watch, setValue, resetField } = useFormContext();
  const defaultLanguage = watch('defaultLanguage');
  const [openTranslationDialog, setOpenTranslationDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [chosenLanguage, setChosenLanguage] = useState(defaultLanguage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const savedTranslations: SavedTranslations = watch('savedTranslations') ?? [];
  const savedTranslationsRef = useRef(savedTranslations);

  useEffect(() => {
    savedTranslationsRef.current = savedTranslations;
  }, [savedTranslations]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (!isMounted.current) {
        setTranslationFields(defaultLanguage, savedTranslationsRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTranslationDialog = () => {
    setOpenTranslationDialog(false);
    setIsEditMode(false);
    setTranslationFields(defaultLanguage);
    changeBetweenTranslatedLanguages(defaultLanguage);
  };

  const removeTranslation = (key: string) => {
    const { [key]: _, ...newData } = savedTranslations;
    setValue('savedTranslations', newData, { shouldValidate: true });
    setOpenDiscardDialog(false);
    setTranslationFields(defaultLanguage);
    changeBetweenTranslatedLanguages(defaultLanguage);
  };

  const setTranslationFields = (key: string, translations?: SavedTranslations) => {
    const translation = translations || savedTranslations;
    if (!translation[key]) {
      return;
    }
    setValue('internalName', translation[key].internalName);
    setValue('questionText', translation[key].questionText);
    setValue('additionalText', translation[key].additionalText);
    setValue('notApplicable', translation[key].notApplicable);
    setValue('selectOptions', translation[key].selectOptions);
    setValue('ratingOptions', translation[key].ratingOptions);
    setValue('binaryOptions', translation[key].binaryOptions);
  };

  const resetFields = () => {
    resetField('internalName');
    resetField('questionText');
    resetField('additionalText');
    resetField('notApplicable');
    resetField('selectOptions');
    resetField('ratingOptions');
    resetField('binaryOptions');
    setOpenTranslationDialog(true);
  };

  const handleEdit = (key: string) => {
    setOpenTranslationDialog(true);
    setValue('translatedLanguage', key);
    setTranslationFields(key);
    setIsEditMode(true);
  };

  const changeBetweenTranslatedLanguages = (key: string) => {
    setChosenLanguage(key);
    setTranslationFields(key);
  };

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage
          id="survey.question-editor.translations.title"
          defaultMessage="Translations"
        />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage
          id="survey.question-editor.translations.subtitle"
          defaultMessage="This question is available in {numberOfTranslations} languages"
          values={{ numberOfTranslations: Object.keys(savedTranslations).length }}
        />
      </Typography>
      <Button
        variant="contained"
        size="medium"
        color="secondary"
        sx={{ width: 112, marginTop: 2 }}
        startIcon={<Icon icon={faPlus} width={18} />}
        onClick={resetFields}
      >
        <FormattedMessage id="common.add-new" defaultMessage="Add new" />
      </Button>

      <List sx={{ marginTop: 2 }}>
        {Object.entries(savedTranslations)?.map(([locale, fields], index) => (
          <ListItemButton
            key={locale}
            sx={{ height: 50 }}
            selected={chosenLanguage === locale}
            onClick={() => changeBetweenTranslatedLanguages(locale)}
          >
            <ListItem
              key={locale}
              secondaryAction={
                locale !== defaultLanguage && (
                  <Stack flexDirection="row">
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        setChosenLanguage(locale);
                        setOpenDiscardDialog(true);
                      }}
                    >
                      <FormattedMessage id="common.delete" defaultMessage="Delete" />
                    </Button>
                    <Button variant="text" color="secondary" onClick={() => handleEdit(locale)}>
                      <FormattedMessage id="common.edit" defaultMessage="Edit" />
                    </Button>
                  </Stack>
                )
              }
            >
              <ListItemIcon sx={{ minWidth: 26 }}>
                <Icon icon={faGlobe} />
              </ListItemIcon>
              <ListItemText
                primary={`${LANGUAGE_OPTIONS.find((value) => value.value === locale)?.label} ${locale === defaultLanguage ? '(default)' : ''}`}
              />
            </ListItem>
          </ListItemButton>
        ))}
      </List>

      <TranslationDialog
        open={openTranslationDialog}
        close={handleCloseTranslationDialog}
        isEditMode={isEditMode}
      />

      <DiscardDialog
        // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
        title={
          <FormattedMessage
            id="survey.question-editor.translations.delete-translation.title"
            defaultMessage="Delete translation?"
          />
        }
        // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
        content={
          <FormattedMessage
            id="survey.question-editor.translations.delete-translation.description"
            defaultMessage="You won’t be able to recover it."
          />
        }
        open={openDiscardDialog}
        onCancel={() => setOpenDiscardDialog(false)}
        onDiscard={() => removeTranslation(chosenLanguage)}
      />
    </>
  );
}
