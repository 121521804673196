import { matchPath } from 'react-router-dom';

import { checkUserPermission } from './permissions';

import type { Node, Permissions, RoutePermissions } from '../types';

export const isRouteAllowed = (
  path: string,
  routePermissions: RoutePermissions,
  userPermissions: Permissions
): boolean => {
  const routeKey = Object.keys(routePermissions).find((key) => matchPath(key, path));
  // if routeKey is not defined, route is allowed for everyone
  if (!routeKey) return true;
  const permissions = routePermissions[routeKey];
  return permissions.every((permission) => checkUserPermission(permission, userPermissions));
};

export const getAllowedNodes = (
  nodes: Node[],
  routePermissions: RoutePermissions,
  userPermissions: Permissions | null
): Node[] => {
  if (!userPermissions) return nodes;
  const allowedNodes = [...nodes];
  allowedNodes.forEach((node) => {
    if (node.path) {
      const isNodeAllowed = isRouteAllowed(node.path, routePermissions, userPermissions);
      // eslint-disable-next-line no-param-reassign
      node.isHidden = node.isHidden || !isNodeAllowed;
    }
    if (node.childNodes) {
      // eslint-disable-next-line no-param-reassign
      node.childNodes = getAllowedNodes(node.childNodes, routePermissions, userPermissions);
    }
  });
  return allowedNodes;
};

export const isRouteMatched = (pattern: string, path: string) =>
  !!matchPath({ path: pattern }, path);

export const isRouteEndsWith = (location: string, route: string) =>
  location.endsWith(route) || location.endsWith(`${route}/`);
