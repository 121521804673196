import { useIntl } from 'react-intl';

import { useManageBenchmarksPermission } from '@trustyou/shared';
import { Button } from '@trustyou/ui';

import { common as benchmarkMessages } from '../../constants/messages/benchmarks';
import { useBenchmarkStore } from '../../store';

export const AddBenchmarkButton = () => {
  const intl = useIntl();
  const isManageBenchmarksAllowed = useManageBenchmarksPermission();

  const { showCreateBenchmarkDrawer } = useBenchmarkStore();

  if (!isManageBenchmarksAllowed) return null;

  return (
    <Button
      data-testid="create-benchmark-button"
      variant="outlined"
      onClick={showCreateBenchmarkDrawer}
    >
      {intl.formatMessage(benchmarkMessages.addBenchmark)}
    </Button>
  );
};
