import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type EntityCustomAttributes, actionMessages, commonFields } from '@trustyou/shared';
import { Button, StyledFontAwesomeIcon as Icon, Stack, Tooltip, Typography } from '@trustyou/ui';

import { AttributeValue } from './AttributeValue';

type Props = {
  attributes: EntityCustomAttributes;
  descriptions?: Record<string, string>;
  onEdit?: () => void;
};

export const EntityCustomAttributesView = ({ attributes, descriptions, onEdit }: Props) => {
  if (!attributes || Object.keys(attributes).length === 0) return null;
  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="subtitle2">
          <FormattedMessage {...commonFields.customAttributes} />
        </Typography>
        {onEdit && (
          <Button data-testid="edit-custom-attributes-values" onClick={onEdit} variant="text">
            <FormattedMessage {...actionMessages.edit} />
          </Button>
        )}
      </Stack>
      <Stack gap={3}>
        {Object.entries(attributes).map(([key, values]) => (
          <Stack key={key} direction="row" gap={2}>
            <Stack direction="row" gap={1} width="150px">
              <Typography
                variant="body2"
                sx={{
                  overflowWrap: 'break-word',
                  maxWidth: '128px',
                  color: 'text.hint',
                }}
              >
                {key}
              </Typography>
              {descriptions?.[key] && (
                <Tooltip placement="top" arrow title={descriptions?.[key]}>
                  <Icon
                    size="sm"
                    icon={faInfoCircle}
                    sx={{ color: 'text.secondary', paddingTop: '4px' }}
                  />
                </Tooltip>
              )}
            </Stack>
            <Stack gap={0.5}>
              <AttributeValue values={values} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
