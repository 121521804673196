import type { StateCreator } from 'zustand';

import type {
  QuestionLibraryEditorStartingPoint,
  SurveyEditorStartingPoint,
  SurveyQuestionnaireEditorStartingPoint,
  ThemeEditorStartingPoint,
} from '../../types/editor';

export interface EditorSliceState {
  isSurveyQuestionnaireEditorOpen: boolean;
  isSurveyEditorOpen: boolean;
  isThemeEditorOpen: boolean;
  isQuestionLibraryEditorOpen: boolean;
  setIsSurveyQuestionnaireEditorOpen: (isSurveyQuestionnaireEditorOpen: boolean) => void;
  setIsSurveyEditorOpen: (isSurveyEditorOpen: boolean) => void;
  setIsThemeEditorOpen: (isThemeEditorOpen: boolean) => void;
  setIsQuestionLibraryEditorOpen: (isQuestionLibraryEditorOpen: boolean) => void;

  surveyQuestionnaireEditorStartingPoint: SurveyQuestionnaireEditorStartingPoint;
  surveyEditorStartingPoint: SurveyEditorStartingPoint;
  themeEditorStartingPoint: ThemeEditorStartingPoint;
  questionLibraryEditorStartingPoint: QuestionLibraryEditorStartingPoint;
  setSurveyQuestionnaireEditorStartingPoint: (
    surveyQuestionnaireEditorStartingPoint: SurveyQuestionnaireEditorStartingPoint
  ) => void;
  setSurveyEditorStartingPoint: (surveyEditorStartingPoint: SurveyEditorStartingPoint) => void;
  setThemeEditorStartingPoint: (themeEditorStartingPoint: ThemeEditorStartingPoint) => void;
  setQuestionLibraryEditorStartingPoint: (
    questionLibraryEditorStartingPoint: QuestionLibraryEditorStartingPoint
  ) => void;
}

export const createEditorSlice: StateCreator<EditorSliceState, [], []> = (set) => ({
  isSurveyQuestionnaireEditorOpen: false,
  isSurveyEditorOpen: false,
  isThemeEditorOpen: false,
  isQuestionLibraryEditorOpen: false,
  setIsSurveyQuestionnaireEditorOpen: (isSurveyQuestionnaireEditorOpen) =>
    set(() => ({ isSurveyQuestionnaireEditorOpen })),
  setIsSurveyEditorOpen: (isSurveyEditorOpen) => set(() => ({ isSurveyEditorOpen })),
  setIsThemeEditorOpen: (isThemeEditorOpen) => set(() => ({ isThemeEditorOpen })),
  setIsQuestionLibraryEditorOpen: (isQuestionLibraryEditorOpen) =>
    set(() => ({ isQuestionLibraryEditorOpen })),

  surveyQuestionnaireEditorStartingPoint: 'new-survey-questionnaire',
  surveyEditorStartingPoint: 'new-survey',
  themeEditorStartingPoint: 'new-theme',
  questionLibraryEditorStartingPoint: 'new-question',
  setSurveyQuestionnaireEditorStartingPoint: (surveyQuestionnaireEditorStartingPoint) =>
    set(() => ({ surveyQuestionnaireEditorStartingPoint })),
  setSurveyEditorStartingPoint: (surveyEditorStartingPoint) =>
    set(() => ({ surveyEditorStartingPoint })),
  setThemeEditorStartingPoint: (themeEditorStartingPoint) =>
    set(() => ({ themeEditorStartingPoint })),
  setQuestionLibraryEditorStartingPoint: (questionLibraryEditorStartingPoint) =>
    set(() => ({ questionLibraryEditorStartingPoint })),
});
