import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useBenchmarkSets } from '@trustyou/shared';
import {
  Autocomplete,
  FormControl,
  FormFieldLabel,
  StyledFontAwesomeIcon,
  TextField,
  Tooltip,
} from '@trustyou/ui';

import { DEFAULT_SET_NAME } from '../../../constants/benchmarks';
import { manageDrawer } from '../../../constants/messages/benchmarks';
import { styles } from './styles';

export const SetController = () => {
  const { control, setValue } = useFormContext();
  const intl = useIntl();
  const { data = [], isPending: isSearching } = useBenchmarkSets();
  const benchmarkSets = useMemo(
    () => (data.includes(DEFAULT_SET_NAME) ? data : [...data, DEFAULT_SET_NAME]).sort(),
    [data]
  );

  const getMatchedValue = (newValue: string) =>
    benchmarkSets.find((value) => value.toLowerCase() === newValue.trim().toLowerCase()) ||
    newValue;

  const handleValueSet = (value?: string) => {
    setValue('set_name', value, { shouldDirty: true });
  };

  return (
    <FormControl>
      <FormFieldLabel>
        {intl.formatMessage(manageDrawer.setNameTitle)}
        <Tooltip placement="top" arrow title={intl.formatMessage(manageDrawer.setNameInfo)}>
          <StyledFontAwesomeIcon icon={faInfoCircle} sx={{ marginLeft: 1 }} />
        </Tooltip>
      </FormFieldLabel>
      <Controller
        name="set_name"
        control={control}
        render={({ field }) => (
          <Autocomplete
            value={field.value}
            loading={isSearching}
            loadingText={intl.formatMessage(manageDrawer.setNameLoading)}
            sx={styles.setField}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              handleValueSet(
                event.target.value ? getMatchedValue(event.target.value) : DEFAULT_SET_NAME
              );
            }}
            onChange={(_, newValue) => {
              if (typeof newValue === 'string') {
                handleValueSet(getMatchedValue(newValue));
              } else if (!newValue) {
                handleValueSet();
              } else {
                handleValueSet(newValue.inputValue || newValue.title);
              }
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const filteredOptions = options.filter((option) =>
                option.title.toLowerCase().includes(inputValue.toLowerCase())
              );
              if (inputValue && !filteredOptions.length) {
                return [
                  {
                    inputValue,
                    title: intl.formatMessage(manageDrawer.setNameAdd, { name: inputValue }),
                  },
                ];
              }
              return filteredOptions;
            }}
            openOnFocus
            selectOnFocus
            handleHomeEndKeys
            id="benchmark-set"
            options={benchmarkSets.map((title) => ({ title }))}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.inputValue || option.title
            }
            renderOption={(props, option) => (
              <li {...props} key={props.id}>
                {option.title}
              </li>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={intl.formatMessage(manageDrawer.setNamePlaceholder)}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
};
