import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { SxProps, Theme } from '@mui/material';
import type { SelectProps } from '@trustyou/ui';

import { LoadingDropdown } from './loading-dropdown';

import { TRUSTYOU_SURVEY_DOMAIN_ID } from '../../../constants';
import { useFetchSources, useFetchSurveyNames } from '../../../hooks';
import { Option } from '../../../types';
import { DropdownMultiple } from '../dropdown-multiple';

export type DropdownSurveyNameProps = {
  fieldName: string;
  initialValue?: string[];
  fullWidth?: SelectProps['fullWidth'];
  sx?: SxProps<Theme>;
};

/**
 * DynamicDropdownSurveyName component is used to render the survey name dropdown
 * based on the selected sources. The dropdown will only be visible when the TrustYou survey
 * domain is among the filtered sources.
 * @param param0
 * @returns
 */
export const DynamicDropdownSurveyName = (props: DropdownSurveyNameProps) => {
  const { watch } = useFormContext();
  const sources = watch('source');
  const [showDropDown, setShowDropDown] = useState(false);
  const { data: fetchedSources, isPending: isLoadingSources } = useFetchSources();

  useEffect(() => {
    // check wether the sources even contain the TrustYou survey domain if not don't show the dropdown
    if (
      !fetchedSources ||
      !Object.values(fetchedSources)?.includes(TRUSTYOU_SURVEY_DOMAIN_ID) ||
      !isTrustYouSurveySelected()
    ) {
      // hide when no TrustYou survey domain is present or not set as filtered source
      setShowDropDown(false);
      return;
    }

    setShowDropDown(true);
  }, [sources]);

  const isTrustYouSurveySelected = () =>
    !sources ||
    sources.includes(TRUSTYOU_SURVEY_DOMAIN_ID) ||
    sources.includes(undefined) ||
    sources.includes('all');

  if (!showDropDown || isLoadingSources) return null;
  return <DropdownSurveyName {...props} />;
};

export const DropdownSurveyName = ({
  fieldName,
  initialValue,
  fullWidth = false,
  sx = {},
}: DropdownSurveyNameProps) => {
  const intl = useIntl();
  const { data: surveyNames, isPending: isLoadingSurveys } = useFetchSurveyNames();
  if (isLoadingSurveys) return <LoadingDropdown sx={sx} />;

  // TODO: method `toSorted()` can be used instead of the in-place `sort()` when bumping Node to v20+
  const sortedSurveyOptions: Option[] = (surveyNames ?? [])
    .map(({ name, id }) => ({ label: name, value: id }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <DropdownMultiple
      fieldName={fieldName}
      options={sortedSurveyOptions}
      allValuesLabel={intl.formatMessage({
        id: 'inbox.filter.surveyName.all',
        defaultMessage: 'All surveys',
      })}
      dataTestId="filterFieldSurveyName"
      label={intl.formatMessage({
        id: 'inbox.filter.surveyName.label',
        defaultMessage: 'Survey Name',
      })}
      initialSelectedValues={initialValue}
      fullWidth={fullWidth}
      sx={sx}
      showLabelAsResult
    />
  );
};
