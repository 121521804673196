import { useIntl } from 'react-intl';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBookBlank, faEnvelope, faSignsPost } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { openFeatureTour, useResponsive } from '@trustyou/shared';

import useZendeskLinks from './useZendeskLinks';

type HelpLink = {
  id: string;
  icon: IconDefinition;
  name: string;
  onClick: () => void;
  isHidden?: boolean;
};

export function useHelpLinks() {
  const intl = useIntl();
  const { isMobile } = useResponsive();
  const { openHelpManual, openSupportAndFeedback } = useZendeskLinks();

  const helpLinks: HelpLink[] = [
    {
      id: 'help-manual',
      icon: faBookBlank,
      name: intl.formatMessage({
        id: 'help.help-manual',
        defaultMessage: 'Help manual',
      }),
      onClick: openHelpManual,
    },
    {
      id: 'support-and-feedback',
      icon: faEnvelope,
      name: intl.formatMessage({
        id: 'help.support-and-feedback',
        defaultMessage: 'Support & feedback',
      }),
      onClick: openSupportAndFeedback,
    },
    {
      id: 'feature-tour',
      icon: faSignsPost,
      name: intl.formatMessage({
        id: 'help.feature-tour',
        defaultMessage: 'Feature tour',
      }),
      onClick: openFeatureTour,
      isHidden: isMobile,
    },
  ];

  return helpLinks;
}
