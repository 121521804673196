import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  markedAsResponded: {
    id: 'inbox.response.status.card.marked-as-responded',
    defaultMessage: 'Marked as responded',
  },
  responded: {
    id: 'inbox.response.status.chip.responded',
    defaultMessage: 'Responded',
  },
  responseConfirmed: {
    id: 'inbox.response.status.card.confirmed',
    defaultMessage: 'Response confirmed',
  },
  respondedBy: {
    id: 'inbox.response.status.card.responded-by',
    defaultMessage: 'Responded by',
  },
});
