import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type HomeButtonType,
  type HomeCardType,
  type HomeElementType,
  type HomeHeadingType,
  getAllowedNodes,
  routePermissions,
  usePermissionStore,
  useResponsive,
} from '@trustyou/shared';
import { Box, Button, CONTENT_MAX_WIDTH, ContentPane, Stack, Typography } from '@trustyou/ui';

import { GreetingBanner } from './GreetingBanner';
import { HomeCard } from './HomeCard';
import { useHomeElements } from './useHomeElements';

export function Home() {
  const { isTablet, isDesktop } = useResponsive();
  const { permissions } = usePermissionStore();
  const elements = useHomeElements();
  const allowedElements = getAllowedNodes(
    elements,
    routePermissions,
    permissions
  ) as HomeElementType[];

  const featured = allowedElements.find(
    (element) => (element as HomeCardType).featured
  ) as HomeCardType;
  const headings = allowedElements.filter((element) => element.category === 'heading');
  const dashboards = allowedElements.filter(
    (element) => element.category === 'dashboards' && !element.featured
  ) as HomeCardType[];
  const reports = allowedElements.filter((element) => element.category === 'reports');
  const managers = allowedElements.filter((element) => element.category === 'managers');

  const dashboardHeading = headings.find((heading) => heading.id === 'dashboards-heading');
  const reportsHeading = headings.find((heading) => heading.id === 'reports-heading');
  const managersHeading = headings.find((heading) => heading.id === 'managers-heading');

  return (
    <ContentPane
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
      }}
      bodyStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 2, sm: 3 },
        padding: { xs: 2, md: 0 },
        maxWidth: CONTENT_MAX_WIDTH,
      }}
    >
      <GreetingBanner />
      <Stack
        sx={{
          gap: 2,
          ...(isTablet && {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }),
          ...(isDesktop && {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
          }),
        }}
      >
        <Box
          sx={{
            ...(isTablet && { gridColumn: 'span 3' }),
            ...(isDesktop && { gridRow: 'span 3' }),
          }}
        >
          {featured && <HomeCard key={featured.id} {...featured} />}
        </Box>
        <Box sx={{ gridColumn: 'span 3' }}>
          {dashboardHeading && <HomeHeading {...dashboardHeading} />}
        </Box>
        {dashboards.map((dashboardLink) => (
          <HomeCard key={dashboardLink.id} {...dashboardLink} />
        ))}
      </Stack>
      <Stack
        sx={{
          gap: 2,
          width: '100%',
          ...(isTablet && {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }),
          ...(isDesktop && {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
          }),
        }}
      >
        {reportsHeading && <HomeHeading {...reportsHeading} />}
        {managers.length > 0 && (
          <Box sx={{ gridColumn: 'span 3' }}>
            {managersHeading && <HomeHeading {...managersHeading} />}
          </Box>
        )}
        {(reports as HomeButtonType[]).map((reportLink) => (
          <HomeButton key={reportLink.id} {...reportLink} />
        ))}
        {(managers as HomeButtonType[]).map((managerLink) => (
          <HomeButton key={managerLink.id} {...managerLink} />
        ))}
      </Stack>
    </ContentPane>
  );
}

function HomeHeading({ name }: HomeHeadingType) {
  return (
    <Typography component="h2" variant="subtitle2">
      {name}
    </Typography>
  );
}

function HomeButton({ path, icon, name }: HomeButtonType) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigateToPath = () => {
    navigate(path ?? '.', { state: { path: pathname } });
  };

  return (
    <Button
      variant="outlined"
      startIcon={icon && <FontAwesomeIcon icon={icon} />}
      onClick={handleNavigateToPath}
    >
      {name}
    </Button>
  );
}
