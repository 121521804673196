import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useChangelingStore } from '@trustyou/shared';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StyledFontAwesomeIcon as Icon,
  TrustYouLogo,
} from '@trustyou/ui';

import styles from './FullScreenModal.styles';

type FullScreenModalProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
  noHeaderBorder?: boolean;
  disableEscapeKeyDown?: boolean;
  closeButtonGTMID?: string;
};

export const FullScreenModal = ({
  open,
  children,
  footer,
  onClose,
  noHeaderBorder,
  disableEscapeKeyDown,
  closeButtonGTMID,
}: FullScreenModalProps) => {
  const { isChangeling } = useChangelingStore();
  return (
    <Dialog disableEscapeKeyDown={!!disableEscapeKeyDown} fullScreen open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          ...styles.title,
          ...(isChangeling ? styles.changelingMode : {}),
          ...(noHeaderBorder ? styles.noHeaderBorder : {}),
        }}
      >
        <TrustYouLogo variant="bubbles" color="blue" />
        <Button
          data-testid="close-button"
          data-gtm-id={closeButtonGTMID ? `${closeButtonGTMID}-close-button` : undefined}
          data-gtm-class={undefined}
          size="medium"
          variant="text"
          onClick={onClose}
          sx={styles.closeButton}
        >
          <Icon icon={faXmark} sx={styles.closeIcon} />
          <FormattedMessage id="action.close" defaultMessage="Close" />
        </Button>
      </DialogTitle>
      <DialogContent sx={styles.container}>{children}</DialogContent>
      {footer && <DialogActions sx={styles.footer}>{footer}</DialogActions>}
    </Dialog>
  );
};
