import { useEffect, useState } from 'react';

import { Box, FormControl, MenuItem, Select, Stack } from '@trustyou/ui';

import { useHasSchedulePermission } from '../../../hooks';
import { reportType } from '../dashboard';
import { DashboardReportList } from '../dashboard/dashboard-report-list';
import { DashboardReportsHistory } from '../dashboard/dashboard-reports-history';

export const InboxReport = () => {
  const hasSchedulePermission = useHasSchedulePermission();
  const [type, setType] = useState(hasSchedulePermission ? 'list' : 'sent');

  useEffect(() => {
    if (window.location.href.includes('one-time')) {
      setType('sent');
    } else if (window.location.href.includes('scheduled')) {
      setType('list');
    }
  }, []);

  return (
    <Stack spacing={3}>
      {hasSchedulePermission && (
        <Box sx={{ width: 220 }}>
          <FormControl fullWidth>
            <Select value={type} onChange={(value) => setType(value.target.value)}>
              {reportType.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {/* TODO: fix these */}
      <Stack>{type === 'list' ? <DashboardReportList /> : <DashboardReportsHistory />}</Stack>
    </Stack>
  );
};
