import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SURVEY_QUESTIONNAIRE_CREATE_PERMISSION, usePermissionStore } from '@trustyou/shared';
import { EmptyStatePlaceholder } from '@trustyou/ui';

// eslint-disable-next-line @nx/enforce-module-boundaries
import personSelectingData from '../../../../../../ui/src/lib/assets/illustrations/person-selecting-data.svg';
import { useStore } from '../../store/store';

export function SurveyQuestionnaireEmptyState() {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionStore();
  const hasSurveyQuestionnaireCreatePermission = hasPermissions(
    SURVEY_QUESTIONNAIRE_CREATE_PERMISSION
  );
  const { surveyId } = useParams();
  const isSurveyQuestionnaireEditorOpen = useStore.use.setIsSurveyQuestionnaireEditorOpen();

  const navigateToSurveyQuestionnaireEditor = () => {
    isSurveyQuestionnaireEditorOpen(true);
    navigate('survey/questionnaire-editor?new', { state: { surveyId } });
  };

  return (
    <EmptyStatePlaceholder
      img={personSelectingData}
      title={
        <FormattedMessage
          id="survey.questionnaire-editor.empty-state.title"
          defaultMessage="No Questionnaires yet"
        />
      }
      description={
        <FormattedMessage
          id="survey.questionnaire-editor.empty-state.description"
          defaultMessage="You can have multiple questionnaires within a Survey, each with different content and theme."
        />
      }
      emptyStateHeader={{ marginTop: 3 }}
      action={
        hasSurveyQuestionnaireCreatePermission
          ? {
              testId: 'survey-questionnaire-empty-state',
              startIcon: <FontAwesomeIcon icon={faPlus} />,
              message: (
                <FormattedMessage
                  id="survey.common.new-questionnaire"
                  defaultMessage="New Questionnaire"
                />
              ),
              handler: navigateToSurveyQuestionnaireEditor,
              styles: { marginTop: 3 },
            }
          : undefined
      }
    />
  );
}
