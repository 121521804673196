import { useEffect } from 'react';

import { SISENSE_SERVER_PATH } from '@trustyou/shared';

import { useHasImpactScorePermission } from './use-has-impact-score-permission';

import { useImpactScore } from '../services';
import type { ImpactScoreResponse } from '../types';

const REQUEST_KEY = 'impact-score-calculate';
const RESPOND_KEY = 'impact-score-receive';

export const useSendImpactScore = (iFrameRef: React.RefObject<HTMLIFrameElement>) => {
  const hasImpactScorePermission = useHasImpactScorePermission();
  const impactScore = useImpactScore();

  const postImpactScore = (response?: ImpactScoreResponse) => {
    iFrameRef.current?.contentWindow?.postMessage(
      {
        action: RESPOND_KEY,
        response: response || null,
      },
      SISENSE_SERVER_PATH as string
    );
  };

  // NOTE: Use this if multiple requests fired
  // const getImpactScoreThrottled = useCallback(throttle(getImpactScore, 1000), []);
  const sendImpactScore = (event: MessageEvent) => {
    if (typeof event.data === 'string') return;
    if (!hasImpactScorePermission) return;
    if (event.data.action && event.data.action === REQUEST_KEY) {
      impactScore.mutate(event.data.request, {
        onSuccess: (data) => {
          postImpactScore(data);
        },
        onError: () => {
          postImpactScore();
        },
      });
    }
  };

  const messageEventHandler = (event: MessageEvent) => {
    if (event.origin !== SISENSE_SERVER_PATH) return;
    sendImpactScore(event);
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sendImpactScore,
  };
};
