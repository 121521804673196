import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  type AccessGroup,
  FETCH_ACCESS_GROUPS,
  getPaginationRequest,
  useAccessGroups,
  useAccessGroupsGridStore,
  useDeleteAccessGroup,
  useGridParams,
  useResponsive,
} from '@trustyou/shared';
import { BackdropSpinner, DataGrid, EmptyAccessGroupState, snackbar } from '@trustyou/ui';

import DeleteAccessGroupModal from './DeleteAccessGroupModal/DeleteAccessGroupModal';
import useAccessGroupTableColumns from './hooks/useAccessGroupTableColumns';

import styles from './styles';

export const AccessGroups = () => {
  const intl = useIntl();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const [deletableAccessGroup, setDeletableAccessGroup] = useState<AccessGroup>();
  const gridState = useAccessGroupsGridStore();
  const { paginationModel } = gridState;
  const { onPaginationModelChange } = useGridParams(FETCH_ACCESS_GROUPS, gridState);
  const { data, isPending: isLoading } = useAccessGroups(getPaginationRequest(paginationModel));

  const deleteAccessGroup = useDeleteAccessGroup();

  const handleDelete = () => {
    deleteAccessGroup.mutate(deletableAccessGroup?.id as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(
            {
              id: 'access-group.alert.deleted',
              defaultMessage: '{accessGroup} deleted',
            },
            { accessGroup: deletableAccessGroup?.name }
          )
        );
      },
      onSettled: () => {
        setDeletableAccessGroup(undefined);
      },
    });
  };

  const columns = useAccessGroupTableColumns({
    onDelete: setDeletableAccessGroup,
    onEdit: (accessGroup) => navigate(`${accessGroup.id}/update`),
  });

  const rows = data?.data || [];

  if (!isLoading && !rows.length) return <EmptyAccessGroupState />;

  return (
    <>
      <DataGrid
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowHeight={64}
        columns={columns}
        rows={rows}
        rowCount={data?.pagination.total}
        onRowClick={(params) => navigate(`${params.row.id}/members`)}
        loading={isLoading}
        pinnedColumns={!isMobile ? { right: ['action'] } : undefined}
        autoHeight={false}
        containerStyle={styles.tableContainer}
      />
      <DeleteAccessGroupModal
        accessGroup={deletableAccessGroup}
        onClose={() => {
          setDeletableAccessGroup(undefined);
        }}
        onDelete={handleDelete}
      />
      <BackdropSpinner isLoading={deleteAccessGroup.isPending} />
    </>
  );
};
