import { faChevronDown, faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useResponsive } from '@trustyou/shared';
import { Box, StyledFontAwesomeIcon as Icon } from '@trustyou/ui';

import styles from './styles';

export function ListSeparator() {
  const { isMobile } = useResponsive();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.arrow}>
        <Icon size="lg" icon={isMobile ? faChevronDown : faChevronRight} />
      </Box>
    </Box>
  );
}
