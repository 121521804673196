import { useEffect } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  faGripDotsVertical,
  faPlus,
  faTrashCan,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Button,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

import { useUnregisterUnmountedQuestion } from '../../../../../service/hooks/use-unregister-unmounted-question';
import { QUESTION_TYPES_BY_CATEGORY } from '../../../../feedback/constants';

export function QuestionEditorSelectType() {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, move, update } = useFieldArray({
    control,
    name: 'selectOptions',
  });

  useUnregisterUnmountedQuestion({
    questionTypes: QUESTION_TYPES_BY_CATEGORY.select,
    fieldsToUnregister: ['selectOptions', 'multiple_select', 'single_select'],
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ value: '', label: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    move(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="survey.common.answer-options" defaultMessage="Answer options" />
      </Typography>
      <Droppable droppableId="droppable-options">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            spacing={3}
            sx={{ alignItems: 'flex-start' }}
          >
            {fields.map((field, index) => (
              <Draggable
                key={`${field.id}-${index}`}
                draggableId={field.id.toString()}
                index={index}
              >
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={field.id}
                    sx={{ flexDirection: 'row', width: '100%' }}
                  >
                    <IconButton size="medium">
                      <Icon icon={faGripDotsVertical} />
                    </IconButton>
                    <Controller
                      name={`selectOptions[${index}].value`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: controllerField }) => (
                        <TextField
                          variant="filled"
                          fullWidth
                          hiddenLabel
                          value={controllerField.value}
                          onChange={(e) => {
                            controllerField.onChange(e);
                          }}
                          onBlur={(e) => {
                            update(index, {
                              ...field,
                              value: e.target.value,
                              label: e.target.value,
                            });
                          }}
                          error={
                            errors?.selectOptions &&
                            Array.isArray(errors.selectOptions) &&
                            !!errors.selectOptions[index]?.value
                          }
                        />
                      )}
                    />
                    {fields.length > 1 && (
                      <IconButton size="medium" onClick={() => remove(index)}>
                        <Icon icon={faTrashCan} />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </Draggable>
            ))}
            <Button
              variant="text"
              startIcon={<Icon icon={faPlus} />}
              onClick={() => append({ value: '', label: '' })}
            >
              <FormattedMessage id="survey.common.add-option" defaultMessage="Add option" />
            </Button>
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
}
