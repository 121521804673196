import { type Control, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, MenuItem, Select } from '@trustyou/ui';

import { frequencySelectorMessages } from '../../constants/messages';
import { useLocaleWeekdays } from '../../hooks';
import { FormControlLabel } from '../form-control-label/form-control-label';
import styles from './styles';

type Props = {
  control: Control;
};

export const DayOfWeekFormControl = ({ control }: Props) => {
  const localeWeekdays = useLocaleWeekdays();
  return (
    <FormControl sx={styles.formControl}>
      <FormControlLabel>
        <FormattedMessage {...frequencySelectorMessages.dayOfWeek} />
      </FormControlLabel>
      <Controller
        control={control}
        name="dayOfWeek"
        render={({ field: { value, onChange } }) => (
          <Select
            aria-labelledby="dayOfWeek"
            id="dayOfWeek"
            value={value}
            defaultValue={control._defaultValues.dayOfWeek}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          >
            {localeWeekdays.map((item, index) => (
              <MenuItem key={item} value={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};
