import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  leavingDialogHeader: {
    id: 'inbox.settings.dialog.header',
    defaultMessage: 'Discard changes?',
  },
  leavingDialogContent: {
    id: 'inbox.settings.dialog.message',
    defaultMessage: 'You have unsaved changes on this page. If you leave, they will be lost.',
  },
});
