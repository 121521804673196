/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { lightBlue } from '@mui/material/colors';
import { faPlus, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { capitalizeFirstLetter } from '@trustyou/shared';
import {
  AccordionDetails,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@trustyou/ui';

import { CustomAccordion, CustomAccordionSummary } from './components/rules-shared';

import type {
  BooleanPrimitive_Output,
  NPSPrimitive_Output,
  Question_Output,
  ScalePrimitive_Output,
  SelectPrimitive_Output,
} from '../../../../client';
import type { SurveyQuestionnaireEditorContentOutput } from '../../../../types/survey';
import { flattenQuestionsArray } from '../../../../utils/survey-questionnaire-editor';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

type RulesConditionsProps = {
  questionType: number;
  questionId: string;
  operator: number;
  answer: string;
};
type FieldArrayComponentProps = {
  questions: SurveyQuestionnaireEditorContentOutput[];
  filteredParentQuestions: SurveyQuestionnaireEditorContentOutput[];
  contentIndex: number;
  defaultSurveyLanguage: string;
};

const FieldArrayComponent = ({
  questions,
  filteredParentQuestions,
  contentIndex,
  defaultSurveyLanguage,
}: FieldArrayComponentProps) => {
  const { watch, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `surveyContentWithRule.${contentIndex}.conditions`,
  });

  return (
    <>
      {fields.map((field, index) => (
        <Stack key={field.id} flexDirection="row">
          <Controller
            name={`surveyContentWithRule.${contentIndex}.conditions.${index}.questionType`}
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth sx={{ maxWidth: 242 }}>
                <InputLabel shrink>
                  <FormattedMessage
                    id="survey.survey-editor.rules.condition"
                    defaultMessage="condition"
                  />
                </InputLabel>
                <CustomTextFieldWithSeparatedLabel fullWidth select defaultValue={1} {...field}>
                  <MenuItem key={1} value={1}>
                    <FormattedMessage id="survey.common.question" defaultMessage="Question" />
                  </MenuItem>
                </CustomTextFieldWithSeparatedLabel>
              </FormControl>
            )}
          />

          <Controller
            name={`surveyContentWithRule.${contentIndex}.conditions.${index}.questionId`}
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth sx={{ maxWidth: 242 }}>
                <InputLabel shrink>
                  <FormattedMessage
                    id="survey.survey-editor.rules.question"
                    defaultMessage="question"
                  />
                </InputLabel>
                <CustomTextFieldWithSeparatedLabel select {...field}>
                  {filteredParentQuestions.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title?.[defaultSurveyLanguage] ??
                        item.title?.[item.default_language ?? 'en'] ??
                        'No title'}
                    </MenuItem>
                  ))}
                </CustomTextFieldWithSeparatedLabel>
              </FormControl>
            )}
          />

          <Controller
            name={`surveyContentWithRule.${contentIndex}.conditions.${index}.operator`}
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth sx={{ maxWidth: 110 }}>
                <InputLabel shrink />
                <CustomTextFieldWithSeparatedLabel fullWidth select defaultValue={1} {...field}>
                  <MenuItem key={1} value={1}>
                    <FormattedMessage id="survey.common.is" defaultMessage="Is" />
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    <FormattedMessage id="survey.common.isnt" defaultMessage="Isn't" />
                  </MenuItem>
                </CustomTextFieldWithSeparatedLabel>
              </FormControl>
            )}
          />

          <Controller
            name={`surveyContentWithRule.${contentIndex}.conditions.${index}.answer`}
            control={control}
            render={({ field }) => {
              const selectedQuestion: SurveyQuestionnaireEditorContentOutput | undefined =
                questions.find(
                  (item) =>
                    item.id ===
                    (
                      watch(`surveyContentWithRule.${contentIndex}.conditions`)[
                        index
                      ] as unknown as RulesConditionsProps
                    )?.questionId
                );

              return (
                <FormControl variant="standard" fullWidth sx={{ maxWidth: 242 }}>
                  <InputLabel shrink>
                    <FormattedMessage
                      id="survey.survey-editor.rules.answered-with"
                      defaultMessage="answered with"
                    />
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    variant="outlined"
                    sx={{
                      'label + &': {
                        marginTop: 3,
                      },
                    }}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {(
                      selectedQuestion?.primitive as
                        | SelectPrimitive_Output
                        | BooleanPrimitive_Output
                        | ScalePrimitive_Output
                        | NPSPrimitive_Output
                    )?.options?.map((option, idx) => {
                      const optionLabel =
                        option?.label?.[defaultSurveyLanguage] ??
                        option?.label?.[selectedQuestion?.default_language ?? 'en'] ??
                        'No label';
                      return (
                        <MenuItem key={idx} value={optionLabel}>
                          {optionLabel}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            }}
          />
          <Stack
            sx={{
              flexDirection: 'row',
              marginTop: 2,
              marginLeft: 2,
              alignItems: 'center',
            }}
          >
            {fields.length > 1 && (
              <Typography variant="body1">
                <FormattedMessage id="survey.common.and" defaultMessage="and" />
              </Typography>
            )}
            <IconButton onClick={() => remove(index)}>
              <Icon icon={faTrash} />
            </IconButton>
          </Stack>
        </Stack>
      ))}
      <Box sx={{ width: 160, marginTop: 2 }}>
        <Button
          variant="outlined"
          startIcon={<Icon icon={faPlus} />}
          onClick={() =>
            append({
              questionType: 1,
              questionId: '',
              operator: 1,
              answer: [],
            })
          }
        >
          <FormattedMessage id="survey.common.add-more" defaultMessage="Add more" />
        </Button>
      </Box>
    </>
  );
};

export function EditorRulesContent({ questions }: { questions: Question_Output[] }) {
  const intl = useIntl();
  const { watch, setValue, register } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const surveyContent = flattenQuestionsArray(watch('surveyContent') ?? []);
  const submitSection = watch('submitSection');
  const surveyContentWithRule = watch('surveyContentWithRule');

  useEffect(() => {
    register('surveyContentWithRule');
    setValue('surveyContentWithRule', surveyContent);
  }, []);

  const isRuleAdded = (content: SurveyQuestionnaireEditorContentOutput) => {
    return content.mandatory || content.not_applicable || content.conditions?.length > 0;
  };

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id="survey.common.question-rules" defaultMessage="Question rules" />
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 3 }}>
        <FormattedMessage
          id="survey.survey-editor.survey-rules.description"
          defaultMessage="Set specific rules for each question"
        />
      </Typography>
      {surveyContentWithRule &&
        surveyContentWithRule.map(
          (content: SurveyQuestionnaireEditorContentOutput, contentIndex: number) => (
            <Box key={content.id}>
              {content.type !== 'section' && (
                <CustomAccordion key={content.id}>
                  <CustomAccordionSummary>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Stack>
                        <Typography variant="subtitle1">
                          {capitalizeFirstLetter(
                            content?.internal_name?.[defaultSurveyLanguage] ??
                              content?.internal_name?.[content.default_language ?? 'en'] ??
                              ''
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {content?.title?.[defaultSurveyLanguage] ??
                            content?.title?.[content.default_language ?? 'en'] ??
                            ''}
                        </Typography>
                      </Stack>
                      {isRuleAdded(content) && (
                        <Chip
                          label={intl
                            .formatMessage({
                              id: 'survey.survey-editor.rule-added',
                              defaultMessage: 'Rule added',
                            })
                            .toUpperCase()}
                          sx={{ color: 'info.main', backgroundColor: lightBlue[50], marginLeft: 1 }}
                        />
                      )}
                    </Stack>
                  </CustomAccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: 'white' }}>
                    <Stack spacing={3} sx={{ marginLeft: 7 }}>
                      <Stack>
                        <Typography variant="subtitle1">
                          <FormattedMessage
                            id="survey.survey-editor.rules.question-settings"
                            defaultMessage="Question settings"
                          />
                        </Typography>
                        <FormGroup sx={{ paddingInline: 3 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Boolean(content.mandatory)}
                                onChange={(e) => {
                                  content.mandatory = e.target.checked;
                                  setValue('surveyContentWithRule', watch('surveyContentWithRule'));
                                }}
                              />
                            }
                            label={
                              <FormattedMessage
                                id="survey.survey-editor.rules.mandatory"
                                defaultMessage="This question is mandatory"
                              />
                            }
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Boolean(content.not_applicable)}
                                onChange={(e) => {
                                  content.not_applicable = e.target.checked;
                                  setValue('surveyContentWithRule', watch('surveyContentWithRule'));
                                }}
                              />
                            }
                            label={
                              <FormattedMessage
                                id="survey.survey-editor.rules.not-applicable"
                                defaultMessage="Allow respondents to say this question doesn’t apply to them"
                              />
                            }
                          />
                        </FormGroup>
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle1">
                          <FormattedMessage
                            id="survey.survey-editor.rules.show-question-if"
                            defaultMessage="Show this question only if"
                          />
                        </Typography>
                        <Stack spacing={2}>
                          <FieldArrayComponent
                            questions={questions as SurveyQuestionnaireEditorContentOutput[]}
                            filteredParentQuestions={surveyContent.filter(
                              (item: any) =>
                                ['scale', 'select', 'boolean', 'nps'].includes(
                                  item?.primitive?.type
                                ) && item.id !== content.id
                            )}
                            contentIndex={contentIndex}
                            defaultSurveyLanguage={defaultSurveyLanguage}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </CustomAccordion>
              )}
            </Box>
          )
        )}
      {submitSection &&
        submitSection[0].questions.map(
          (sectionContent: SurveyQuestionnaireEditorContentOutput, sectionContentIndex: number) => (
            <Box key={sectionContent.id}>
              <CustomAccordion key={sectionContent.id}>
                <CustomAccordionSummary>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Stack>
                      <Typography variant="subtitle1">
                        {capitalizeFirstLetter(
                          sectionContent?.internal_name?.[defaultSurveyLanguage] ??
                            sectionContent?.internal_name?.[
                              sectionContent.default_language ?? 'en'
                            ] ??
                            ''
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {sectionContent?.title?.[defaultSurveyLanguage] ??
                          sectionContent?.title?.[sectionContent.default_language ?? 'en'] ??
                          ''}
                      </Typography>
                    </Stack>
                    {isRuleAdded(sectionContent) && (
                      <Chip
                        label={intl
                          .formatMessage({
                            id: 'survey.survey-editor.rule-added',
                            defaultMessage: 'Rule added',
                          })
                          .toUpperCase()}
                        sx={{ color: 'info.main', backgroundColor: lightBlue[50], marginLeft: 1 }}
                      />
                    )}
                  </Stack>
                </CustomAccordionSummary>
                <AccordionDetails sx={{ backgroundColor: 'white' }}>
                  <Stack spacing={3} sx={{ marginLeft: 7 }}>
                    <Stack>
                      <Typography variant="subtitle1">
                        <FormattedMessage
                          id="survey.survey-editor.rules.question-settings"
                          defaultMessage="Question settings"
                        />
                      </Typography>
                      <FormGroup sx={{ paddingInline: 3 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={Boolean(sectionContent.mandatory)}
                              onChange={(e) => {
                                sectionContent.mandatory = e.target.checked;
                                setValue('submitSection', watch('submitSection'));
                              }}
                              disabled={[
                                'data_privacy_information',
                                'terms_and_conditions',
                              ].includes(sectionContent.handling_type)}
                            />
                          }
                          label={
                            <FormattedMessage
                              id="survey.survey-editor.rules.mandatory"
                              defaultMessage="This question is mandatory"
                            />
                          }
                        />
                      </FormGroup>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </CustomAccordion>
            </Box>
          )
        )}
    </>
  );
}
