import {
  type GridRowSelectionModel,
  type GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import { useResponsive } from '@trustyou/shared';
import { Box, Spacer } from '@trustyou/ui';

import { CustomBulkActions } from './custom-bulk-actions';
import { CustomExportButton } from './custom-export-button';
import { CustomGridToolbarQuickFilter } from './custom-grid-toolbar-quick-filter';
import { InfoIcon } from './info-icon';
import { RefreshButton } from './refresh-button';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    selectedRows: GridRowSelectionModel;
    resetSelectedRows: () => void;
  }
}

export function CustomToolbar({
  selectedRows,
  resetSelectedRows,
}: NonNullable<GridSlotsComponentsProps['toolbar']>) {
  const { isPhone, isMobile } = useResponsive();

  return (
    <GridToolbarContainer
      sx={{
        paddingBlock: 1,
        paddingInline: 2,
        ...(isPhone && {
          flexDirection: 'column-reverse',
          alignItems: 'start',
          paddingBlockStart: 2,
        }),
      }}
    >
      {selectedRows?.length && selectedRows?.length > 0 ? (
        <CustomBulkActions
          selectedRows={selectedRows}
          resetSelectedRows={() => resetSelectedRows}
        />
      ) : (
        <>
          {!isMobile && <RefreshButton />}
          <GridToolbarColumnsButton />
          {!isMobile && <CustomExportButton />}
        </>
      )}
      {!isPhone && <Spacer />}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          ...(isPhone && {
            width: '100%',
          }),
        }}
      >
        <CustomGridToolbarQuickFilter />
        <InfoIcon />
      </Box>
    </GridToolbarContainer>
  );
}
