import type { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { type Node, useSidebarStore } from '@trustyou/shared';
import { ListItem, NavIcon, NavListItem } from '@trustyou/ui';

export type DrawerNodeProps = {
  node: Node;
  isPermanentlyExpanded?: boolean;
};

export function DrawerNode({ node, isPermanentlyExpanded }: DrawerNodeProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    isCollapsed,
    isTemporarilyExpanded,
    expandedNode,
    toggleSidebar,
    expandTemporarily,
    setExpandedNode,
  } = useSidebarStore();

  const nodeName = intl.formatMessage({ id: node.id, defaultMessage: node.name });
  const isParentNode = node.childNodes && node.childNodes.length > 0;
  const isNodeExpanded = expandedNode === node.id;

  const getSelectedState = () => {
    if (isParentNode) {
      const selectedChildNode = node.childNodes?.find(
        (childNode) => !!(childNode.path && pathname.includes(childNode.path))
      );
      return isCollapsed || !isNodeExpanded ? !!selectedChildNode : false;
    }
    return !!(node.path && pathname.includes(node.path));
  };

  const navProps = {
    id: node.id,
    icon: node.icon,
    selected: getSelectedState(),
    isNodeExpanded: isParentNode ? isNodeExpanded : undefined,
    onClick: (event: MouseEvent) => {
      if (isParentNode) {
        if (isCollapsed) {
          expandTemporarily();
          if (!isNodeExpanded) setExpandedNode(node.id);
        } else {
          setExpandedNode(node.id);
        }
      } else if (node.path) {
        if (event.metaKey || event.ctrlKey) {
          window.open(`${process.env.PUBLIC_URL}${node.path}`, '_blank');
        } else {
          navigate(node.path, { state: { path: pathname } });
          if (isTemporarilyExpanded) {
            toggleSidebar();
          }
        }
      } else {
        node.onClick?.();
        if (isTemporarilyExpanded) {
          toggleSidebar();
        }
      }
    },
  };

  return (
    <>
      <ListItem disablePadding disableGutters>
        {isPermanentlyExpanded || !isCollapsed ? (
          <NavListItem {...navProps} name={nodeName} />
        ) : (
          <NavIcon {...navProps} icon={navProps.icon as IconDefinition} tooltip={nodeName} />
        )}
      </ListItem>
      {isParentNode &&
        !isCollapsed &&
        isNodeExpanded &&
        (node.childNodes || [])
          .filter((node) => !node.isHidden)
          .map((childNode) => (
            <DrawerNode
              key={childNode.id}
              node={childNode}
              isPermanentlyExpanded={isPermanentlyExpanded}
            />
          ))}
    </>
  );
}
