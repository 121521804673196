import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  type Benchmark,
  type CreateBenchmarkRequest,
  type Entity,
  getStringifiedBenchmarkRules,
  useCreateBenchmark,
  useEditBenchmark,
} from '@trustyou/shared';
import { BackdropSpinner, Button, snackbar } from '@trustyou/ui';

import { DASHBOARD_UPDATE_DELAY_IN_MINUTES, DEFAULT_SET_NAME } from '../../../constants/benchmarks';
import { manageDrawer } from '../../../constants/messages/benchmarks';
import { useDashboardDelayMessage } from '../../../hooks';

type Props = {
  data?: Benchmark;
  onSave: () => void;
};

export const SaveAction = ({ data, onSave }: Props) => {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage(DASHBOARD_UPDATE_DELAY_IN_MINUTES);
  const createBenchmark = useCreateBenchmark();
  const editBenchmark = useEditBenchmark();
  const { getValues, setValue, trigger, formState, control } = useFormContext();

  const onSubmit = async () => {
    let isFormValid = !Object.keys(formState.errors).length;
    if (isFormValid) {
      isFormValid = await trigger();
    }

    const values = getValues();
    const savingValues: CreateBenchmarkRequest = {
      name: values.name,
      set_name: values.set_name || DEFAULT_SET_NAME,
    };

    if (!values.isUnmanagedBenchmark) {
      const ownedDefinitionRules = getStringifiedBenchmarkRules(values.entities_rules);
      const competitorsDefinitionRules = getStringifiedBenchmarkRules(values.competitors_rules);
      const ownedDefinitionValid = ownedDefinitionRules || values.additional_entities.length;
      const competitorsDefinitionValid =
        competitorsDefinitionRules || values.additional_competitors.length;

      if (!ownedDefinitionValid) {
        control.setError('owned_definition', {});
        setValue('selectedDefinitionTab', 'entities');
      }

      if (!competitorsDefinitionValid) {
        control.setError('competitors_definition', {});
        if (ownedDefinitionValid) {
          setValue('selectedDefinitionTab', 'competitors');
        }
      }

      if (!isFormValid || !ownedDefinitionValid || !competitorsDefinitionValid) return;

      savingValues.definition = {
        owned: {
          rule: ownedDefinitionRules,
          additional_entities: values.additional_entities.map((row: Entity) => row.id),
        },
        competitors: {
          rule: competitorsDefinitionRules,
          additional_entities: values.additional_competitors.map((row: Entity) => row.id),
        },
      };
    }

    if (!isFormValid) return;

    if (values.id) {
      editBenchmark.mutate(
        {
          id: values.id,
          managed: !values.isUnmanagedBenchmark,
          ...savingValues,
        },
        {
          onSuccess: () => {
            onSave();
            snackbar.success(
              intl.formatMessage(manageDrawer.updateSuccess, {
                delay: dashboardDelayMessage,
              })
            );
          },
          onError: snackbar.genericError,
        }
      );
    } else {
      createBenchmark.mutate(savingValues, {
        onSuccess: () => {
          onSave();
          snackbar.success(
            intl.formatMessage(manageDrawer.addSuccess, {
              name: savingValues.name,
              delay: dashboardDelayMessage,
            })
          );
        },
        onError: snackbar.genericError,
      });
    }
  };

  return (
    <>
      <Button onClick={onSubmit} variant="contained">
        {intl.formatMessage(manageDrawer.saveButton)}
      </Button>
      <BackdropSpinner isLoading={createBenchmark.isPending || editBenchmark.isPending} />
    </>
  );
};
