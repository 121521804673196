/**
 * Documentation references to implement "manual" server-side:
 *    https://mui.com/x/react-data-grid/filtering/server-side/
 *    https://mui.com/x/react-data-grid/pagination/#server-side-pagination
 *    https://mui.com/x/react-data-grid/sorting/#server-side-sorting
 *
 * Server-side data support by MUI-X (PLANNED):
 *    https://mui.com/x/react-data-grid/server-side-data/
 *    https://github.com/mui/mui-x/issues/8179
 *
 * Persist DataGrid state:
 *    https://mui.com/x/react-data-grid/state/#save-and-restore-the-state-from-external-storage
 */
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { alpha, styled } from '@mui/material';
import {
  DataGridPro,
  type GridColumnVisibilityModel,
  type GridEventListener,
  type GridFilterModel,
  type GridPaginationModel,
  type GridRowHeightParams,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  DEFAULT_INBOX_PAGE_SIZE_OPTIONS,
  useDataGridIntl,
  useInboxStore,
  useLanguageStore,
} from '@trustyou/shared';

import { CustomColumnMenu } from './custom-column-menu';
import { CustomNoRowsOverlay } from './custom-no-rows-overlay';
import { CustomToolbar } from './custom-toolbar';
import { useInboxColumns } from './use-inbox-columns';
import { useResponsiveInbox } from './use-responsive-inbox';
import { type QueryOptions, useReviewsData } from './use-reviews-data';

import {
  CHARACTERS_PER_LINE,
  MAX_ROW_HEIGHT,
  MIN_ROW_HEIGHT,
  NON_SELECTABLE_REVIEW_STATUSES,
} from '../../constants';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  borderInline: 'none',
  borderBottom: 'none',
  borderRadius: 0,

  // Visual adjustment of the "Score" column as the first column in mobile viewport.
  '& .MuiDataGrid-columnHeader.score-header': {
    [theme.breakpoints.down('md')]: {
      paddingInlineStart: theme.spacing(2),
    },
  },
  '& .MuiDataGrid-cell.score-cell': {
    [theme.breakpoints.down('md')]: {
      paddingInlineStart: theme.spacing(2),
    },
  },

  // row styles based on review status
  '& .inbox-app-theme--unread': {
    fontWeight: 700,
  },
  '& .inbox-app-theme--read': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& .inbox-app-theme--responded': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& .inbox-app-theme--confirmed': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& .inbox-app-theme--marked_as_deleted': {
    opacity: 0.3,
  },
  '& .inbox-app-theme--deleted': {
    opacity: 0.3,
  },
  '& .inbox-app-theme--marked_as_inappropriate': {
    opacity: 1,
  },
  '& .inbox-app-theme--inappropriate': {
    opacity: 0.3,
  },

  // hover styles
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.3),
      },
    },
  },
}));

export function ReviewsDataGrid() {
  const apiRef = useGridApiRef();
  const { isMobile, isDesktop } = useResponsiveInbox();

  const columns = useInboxColumns();
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const navigate = useNavigate();

  const { dataGrid, setDataGrid, currentView } = useInboxStore();

  const queryOptions: QueryOptions = {
    sortModel: dataGrid.sorting?.sortModel,
    page: dataGrid.pagination?.paginationModel?.page,
    pageSize: dataGrid.pagination?.paginationModel?.pageSize,
  };
  const {
    isLoading,
    rows,
    filteredTotal: rowCount,
    isRefetching,
  } = useReviewsData(queryOptions, currentView.filters ?? {});

  // https://mui.com/x/react-data-grid/pagination/#basic-implementation
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((previous) => (rowCount !== undefined ? rowCount : previous));
  }, [rowCount]);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleFilter = useCallback(
    (filterModel: GridFilterModel) => {
      setDataGrid({ ...dataGrid, filter: { filterModel } });
    },
    [dataGrid, setDataGrid]
  );

  const handlePagination = useCallback(
    (paginationModel: GridPaginationModel) => {
      setDataGrid({ ...dataGrid, pagination: { paginationModel } });
    },
    [dataGrid, setDataGrid]
  );

  const handleSort = useCallback(
    (sortModel: GridSortModel) => {
      setDataGrid({
        ...dataGrid,
        sorting: {
          sortModel,
        },
        // reset to the first page to visually get clear results after re-sorting
        pagination: {
          paginationModel: {
            pageSize: dataGrid.pagination?.paginationModel?.pageSize,
            page: 0,
          },
        },
      });
    },
    [dataGrid, setDataGrid]
  );

  const handleSelection = useCallback((rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }, []);

  const handleColumnVisibilityChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      setDataGrid({ ...dataGrid, columns: { columnVisibilityModel: model } });
    },
    [dataGrid, setDataGrid]
  );

  const navigateToReview: GridEventListener<'rowClick'> = (params) => {
    navigate(params.row.id);
  };

  const getRowHeight = (params: GridRowHeightParams) => {
    return params.model.text?.length > CHARACTERS_PER_LINE ? MAX_ROW_HEIGHT : MIN_ROW_HEIGHT;
  };

  return (
    <StyledDataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      rowCount={rowCountState}
      loading={isLoading || isRefetching}
      initialState={dataGrid}
      // 📊 Filtering
      // Not used at the moment because a custom filtering logic for Inbox has been implemented instead (filters drawer).
      // Filtering options are manually hidden from each column header.
      // Official MUI DataGrid documentation about its built-in filtering:
      //   - https://mui.com/x/react-data-grid/filtering/
      //   - https://mui.com/x/react-data-grid/filtering-recipes/#quick-filter-outside-of-the-grid
      filterMode="server"
      onFilterModelChange={handleFilter}
      ignoreDiacritics
      // 🔀 Sorting
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
      onSortModelChange={handleSort}
      // 📄 Pagination
      pagination
      pageSizeOptions={DEFAULT_INBOX_PAGE_SIZE_OPTIONS}
      paginationMode="server"
      paginationModel={dataGrid.pagination?.paginationModel as GridPaginationModel}
      onPaginationModelChange={handlePagination}
      // ✅ Selection
      checkboxSelection={isDesktop}
      isRowSelectable={({ row }: GridRowParams) =>
        !NON_SELECTABLE_REVIEW_STATUSES.includes(row.status)
      }
      keepNonExistentRowsSelected
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={handleSelection}
      // 👆🏼 Click/tap on row
      onRowClick={navigateToReview}
      disableRowSelectionOnClick
      // ✨ Customization
      getRowClassName={({ row }) => `inbox-app-theme--${row.status}`}
      getRowHeight={isMobile ? (params) => getRowHeight(params) : undefined}
      columnVisibilityModel={dataGrid.columns?.columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityChange}
      density="standard"
      localeText={dataGridIntl[locale]?.components.MuiDataGrid.defaultProps.localeText}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        toolbar: CustomToolbar,
        columnMenu: CustomColumnMenu,
      }}
      slotProps={{
        toolbar: {
          selectedRows: rowSelectionModel,
          resetSelectedRows: () => setRowSelectionModel([]),
        },
      }}
    />
  );
}
