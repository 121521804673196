/* eslint-disable @typescript-eslint/no-explicit-any */
import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';
import axios from 'axios';

import type { SemaLanguage } from '../../../client';

type ObjectOfAny = Record<string, any>;
type ObjectOfStrings = Record<string, string>;

export async function fetchMetaCategory(shortLocale: string) {
  const { data } = await axios.get<ObjectOfAny>(
    `https://analytics.trustyou.com/i18n/bundles/metacategory/${shortLocale}`
  );
  return data;
}

export async function fetchTopLevelCategoryMapping() {
  const { data } = await $assApi.get<ObjectOfAny>(
    `${INBOX_BASE_PATH}/sema-mappings/top-level-categories`
  );
  return data;
}

export async function fetchSubcategoryMapping() {
  const { data } = await $assApi.get<ObjectOfAny>(`${INBOX_BASE_PATH}/sema-mappings/subcategories`);
  return data;
}

export async function fetchTopLevelCategories(semaLanguage: SemaLanguage) {
  const { data } = await $assApi.get<ObjectOfAny>(
    `${INBOX_BASE_PATH}/sema-mappings/top-level-category-translations`,
    {
      params: {
        language: semaLanguage,
      },
    }
  );
  return data;
}

export async function fetchSubCategories(semaLanguage: SemaLanguage) {
  const { data } = await $assApi.get<ObjectOfAny>(
    `${INBOX_BASE_PATH}/sema-mappings/subcategory-translations`,
    {
      params: {
        language: semaLanguage,
      },
    }
  );
  return data;
}

export async function fetchSemaLanguages(semaLanguage: SemaLanguage) {
  const { data } = await $assApi.get<ObjectOfStrings>(
    `${INBOX_BASE_PATH}/sema-mappings/get-languages`,
    {
      params: {
        language: semaLanguage,
      },
    }
  );
  return data;
}
