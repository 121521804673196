import { useState } from 'react';

import { type Entity, useCustomAttributeDefinitions } from '@trustyou/shared';
import { EntityCustomAttributesView } from '@trustyou/ui';

import { EditDialog } from './EditDialog/EditDialog';

import { useUpdateEntityCustomAttributesPermission } from '../../hooks';

type Props = {
  entity: Entity;
  allowEdit?: boolean;
};

export const CustomAttributesValuesView = ({ entity, allowEdit = true }: Props) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const canUpdateCustomAttributes = useUpdateEntityCustomAttributesPermission();
  const { data: customAttributeDefinitions = [] } = useCustomAttributeDefinitions();
  const descriptions = customAttributeDefinitions
    .filter((row) => !!row.description)
    .reduce((prev, cur) => ({ ...prev, [cur.name]: cur.description }), {});
  const toggleEditDialog = () => setIsEditDialogOpen((state) => !state);
  return (
    <>
      <EntityCustomAttributesView
        attributes={entity.custom_attributes}
        descriptions={descriptions}
        onEdit={allowEdit && canUpdateCustomAttributes ? toggleEditDialog : undefined}
      />
      {isEditDialogOpen && <EditDialog entityId={entity.id} onClose={toggleEditDialog} />}
    </>
  );
};
