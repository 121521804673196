import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faChevronDown, faChevronUp, faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, Collapse, Divider, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { PaginationCard } from './pagination-card';

type ContentHeaderProps = {
  openContentDialog: () => void;
  openSectionDialog: () => void;
};

export const ContentHeader = ({ openContentDialog, openSectionDialog }: ContentHeaderProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.survey-content.title"
            defaultMessage="Survey content"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.survey-content.description"
            defaultMessage="Edit the main content of your survey and rearrange them in the order you need"
          />
        </Typography>
      </Stack>
      <Stack flexDirection="row" sx={{ justifyContent: 'space-between' }}>
        <Stack flexDirection="row" sx={{ gap: 3 }}>
          <Button
            variant="outlined"
            startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
            sx={{ width: 140 }}
            onClick={openContentDialog}
          >
            <FormattedMessage id="survey.common.content" defaultMessage="Content" />
          </Button>
          <Button
            variant="outlined"
            startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
            sx={{ width: 140 }}
            onClick={openSectionDialog}
          >
            <FormattedMessage id="survey.common.section" defaultMessage="Section" />
          </Button>
        </Stack>
        <Button
          endIcon={
            <StyledFontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} size="xs" />
          }
          onClick={() => setExpanded(!expanded)}
        >
          <FormattedMessage id="survey.common.pagination" defaultMessage="Pagination" />
        </Button>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <PaginationCard />
      </Collapse>
      <Divider style={{ marginInline: '-24px' }} />
    </Stack>
  );
};
