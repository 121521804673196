import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { CONNECT_PROVIDER_NAMES, useEntityReviewProviders } from '@trustyou/shared';
import { FullScreenModal, Grid, Stack, Typography } from '@trustyou/ui';

import { BookingConnectManager, GoogleConnectManager } from '../../../../../components';
import { ReviewProviderCard } from '../../../../../components/ReviewProviderCard/ReviewProviderCard';
import { entityMessage } from '../../../../../constants/messages/entities';

export const EntityReviewProviders = () => {
  const { entityId = '' } = useParams();
  const { data: reviewProviders } = useEntityReviewProviders(entityId);

  const [bookingConnectDialogOpen, setBookingConnectDialogOpen] = useState(false);
  const toggleBookingConnectDialog = () => setBookingConnectDialogOpen((state) => !state);

  const [googleConnectDialogOpen, setGoogleConnectDialogOpen] = useState(false);
  const toggleGoogleConnectDialog = () => setGoogleConnectDialogOpen((state) => !state);

  const handleConnectProvider = (name: string) => {
    if (name === CONNECT_PROVIDER_NAMES.BOOKING) {
      toggleBookingConnectDialog();
    } else if (name === CONNECT_PROVIDER_NAMES.GOOGLE) {
      toggleGoogleConnectDialog();
    }
  };

  return (
    <Stack gap={2}>
      <Typography variant="body1">
        <FormattedMessage {...entityMessage.connectionsHeader} />
      </Typography>
      <Grid container spacing={2}>
        {reviewProviders
          ?.filter((row) => row.name === 'google.com' || row.name === 'booking.com')
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(
            ({
              id,
              name,
              provider_account_count: connectedAPIs,
              is_configuring: isConfiguring,
            }) => (
              <Grid item key={id} sm={6} xs={12}>
                <ReviewProviderCard
                  id={id}
                  title={name}
                  isConfiguring={isConfiguring}
                  connectedAPIs={connectedAPIs}
                  handleConnectProvider={handleConnectProvider}
                  isConnectEnabled
                />
              </Grid>
            )
          )}
      </Grid>
      <FullScreenModal
        open={bookingConnectDialogOpen}
        onClose={toggleBookingConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
      >
        <BookingConnectManager entityId={entityId} onClose={toggleBookingConnectDialog} />
      </FullScreenModal>
      <FullScreenModal
        open={googleConnectDialogOpen}
        onClose={toggleGoogleConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
      >
        <GoogleConnectManager entityId={entityId} />
      </FullScreenModal>
    </Stack>
  );
};
