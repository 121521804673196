import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  dashboardContainer: {
    gap: 3,
    marginX: 'auto',
    height: '100%',
    minHeight: '650px',
    '> iframe': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      width: '100%',
      height: 'calc(100% - 72px)',
      borderRadius: '4px',
    },
  },
  loaderContainer: { marginX: 'auto', marginBottom: 'auto' },
  loaderHeader: { marginTop: 3 },
  loaderIcon: { marginTop: 5 },
};

export default styles;
