import type { ReactNode } from 'react';

import type { IconButtonProps } from '@mui/material';
import { faArrowLeft, faBars } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  APP_BAR_HEIGHT,
  AppBar,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Toolbar,
  Typography,
} from '@trustyou/ui';

export type TopBarProps = {
  pageName: ReactNode;
  isRoot?: boolean;
  onClick: IconButtonProps['onClick'];
};

export function MobileTopBar({ pageName, isRoot, onClick }: TopBarProps) {
  return (
    <AppBar
      color="inherit"
      elevation={0}
      sx={{
        height: APP_BAR_HEIGHT,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <IconButton color="inherit" sx={{ marginInlineEnd: 2 }} onClick={onClick}>
          <Icon icon={isRoot ? faBars : faArrowLeft} />
        </IconButton>
        <Typography component="h1" variant="h6">
          {pageName}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
