import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  contentPane: { height: '100%' },
  contentPaneBodyStyles: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tableContainer: {
    width: '25%',
    borderRight: `1px ${grey[300]} solid`,
    paddingRight: 2,
    marginRight: 2,
  },
  table: {
    minHeight: 0,
    '& .MuiTablePagination-root .MuiToolbar-root': {
      '& .MuiTablePagination-selectLabel': {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        display: 'none',
      },
    },
    '& .selected-row': {
      backgroundColor: grey[100],
    },
  },
  detailsContainer: {
    flex: 1,
    height: '100%',
  },
  detailsHeader: {
    paddingLeft: 2,
    paddingY: 1,
    gap: 1,
    minHeight: '64px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailsBody: {
    height: 'calc(100% - 64px)',
  },
  detailsTabs: { paddingX: 3 },
  body: {
    height: '100%',
    overflow: 'auto',
  },
  tabsBody: {
    paddingY: 3,
    paddingX: 2,
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
};

export default styles;
