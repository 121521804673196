import { FormattedMessage } from 'react-intl';

import type { UploadError } from '@trustyou/shared';
import { Alert, AlertTitle } from '@trustyou/ui';

const MandatoryColumnsError = ({ columns }: UploadError) => (
  <Alert severity="error">
    <AlertTitle>
      <FormattedMessage
        id="entitiesImporter.uploadSpreadsheet.missingMandatoryColumns"
        defaultMessage="Missing mandatory columns"
      />
    </AlertTitle>
    <FormattedMessage
      id="entitiesImporter.uploadSpreadsheet.missingMandatoryColumnsErrorDescription"
      defaultMessage="There following mandatory columns are missing: {columns}. Please add them and try again"
      values={{ columns: columns?.join(', ') }}
    />
  </Alert>
);

export default MandatoryColumnsError;
