import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button } from '@trustyou/ui';

import { useStore } from '../../store/store';

export function NewSurveyButton() {
  const navigate = useNavigate();
  const setIsSurveyEditorOpen = useStore.use.setIsSurveyEditorOpen();

  const navigateToSurveyEditor = () => {
    setIsSurveyEditorOpen(true);
    navigate('survey/editor');
  };

  return (
    <Button
      variant="contained"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={navigateToSurveyEditor}
    >
      <FormattedMessage id="survey.common.new-survey" defaultMessage="New Survey" />
    </Button>
  );
}
