import {
  SISENSE_SERVER_PATH,
  downloadExcel,
  useExportDashboardExcel,
  useLanguageStore,
} from '@trustyou/shared';

export const useExportDashboardHandle = () => {
  const { locale } = useLanguageStore();
  const exportDashboardExcel = useExportDashboardExcel();

  const exportDashboard = (dashboardId: string, iframe: React.RefObject<HTMLIFrameElement>) => {
    const stopLoader = () => {
      iframe.current?.contentWindow?.postMessage(
        {
          action: 'dashboardActions-stopLoader',
        },
        SISENSE_SERVER_PATH as string
      );
    };
    exportDashboardExcel.mutate(
      {
        id: dashboardId,
        language: locale.split('-')[0],
      },
      {
        onSuccess: (data) => {
          downloadExcel(data.name, data.content);
        },
        onSettled: stopLoader,
      }
    );
  };

  return { exportDashboard, isPending: exportDashboardExcel.isPending };
};
