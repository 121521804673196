import type { IStyles } from '../../types/common';

const styles: IStyles = {
  listItemButton: {
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  },
  checkedIcon: {
    color: 'primary.main',
  },
  list: {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    flex: '1',
    height: 'fit-content',
    overflowY: 'auto',
  },
  disabledList: {
    overflowY: 'hidden',
  },
  listItemIcon: {
    minWidth: 'unset',
  },
  loader: {
    position: 'absolute',
    top: '56px',
    left: 0,
    width: '100%',
    height: 'calc(100% - 56px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
  },
};

export default styles;
