import { defineMessages } from 'react-intl';

export const common = defineMessages({
  name: {
    id: 'custom-attributes.name',
    defaultMessage: 'Name',
  },
  type: {
    id: 'custom-attributes.type',
    defaultMessage: 'Type',
  },
  description: {
    id: 'custom-attributes.description',
    defaultMessage: 'Description',
  },
  freeTextType: {
    id: 'custom-attributes.type.free-text',
    defaultMessage: 'Free text',
  },
  singleSelectType: {
    id: 'custom-attributes.type.single-select',
    defaultMessage: 'Single select',
  },
  multiSelectType: {
    id: 'custom-attributes.type.multi-select',
    defaultMessage: 'Multi select',
  },
  attributeOption: { id: 'custom-attributes.attribute-option', defaultMessage: 'Attribute option' },
});

export const info = defineMessages({
  description: {
    id: 'custom-attributes.info.description',
    defaultMessage:
      'A custom attribute is what defines and differentiates your entities. It enriches your entity data, and allows you to segment your data.',
  },
  moreInfo: {
    id: 'custom-attributes.info.more-info',
    defaultMessage: 'More info',
  },
  infoModalHeader: {
    id: 'custom-attributes.info-modal.header',
    defaultMessage: 'What is a custom attribute?',
  },
  infoModalAttributeTypeHeader: {
    id: 'custom-attributes.info-modal.attribute-type.header',
    defaultMessage: 'Types of custom attributes',
  },
  infoModalAttributeTypeExample: {
    id: 'custom-attributes.info-modal.attribute-type.example',
    defaultMessage: 'Example',
  },
  freeTextTypeDescription: {
    id: 'custom-attributes.attribute-type.free-text.description',
    defaultMessage:
      'Free text with a unique value, suitable for entity specific data such as an identification number.',
  },
  singleSelectTypeDescription: {
    id: 'custom-attributes.attribute-type.single-select.description',
    defaultMessage:
      'Choose one option from a list of options you define in the next step, suitable for categorization such as star rating of hotels.',
  },
  multiSelectTypeDescription: {
    id: 'custom-attributes.attribute-type.multi-select.description',
    defaultMessage:
      'Choose multi select from a list you define in the next step, suitable when entities can belong to more than one category or have multiple characteristics, such as available services or amenities.',
  },
});

export const emptyState = defineMessages({
  title: {
    id: 'custom-attributes.empty-state.title',
    defaultMessage: 'No attributes yet',
  },
  description: {
    id: 'custom-attributes.empty-state.description',
    defaultMessage: 'When you add attributes, they will show up here.',
  },
  newAttribute: {
    id: 'custom-attributes.empty-state.new-attribute',
    defaultMessage: 'New attribute',
  },
});

export const manageDrawer = defineMessages({
  createButton: {
    id: 'custom-attributes.add-button',
    defaultMessage: 'Add custom attribute',
  },
  createHeader: {
    id: 'custom-attributes.manage-drawer.create-header',
    defaultMessage: 'Create a new custom attribute',
  },
  editHeader: {
    id: 'custom-attributes.manage-drawer.edit-header',
    defaultMessage: 'Edit custom attribute',
  },
  save: {
    id: 'custom-attributes.manage-drawer.save',
    defaultMessage: 'Save',
  },
  namePlaceholder: {
    id: 'custom-attributes.manage-drawer.name.place-holder',
    defaultMessage: 'Type name',
  },
  nameRequired: {
    id: 'custom-attributes.manage-drawer.name.required',
    defaultMessage: 'Name is required',
  },
  nameExist: {
    id: 'custom-attributes.manage-drawer.name.exist',
    defaultMessage: 'Attribute already exists',
  },
  nameReserved: {
    id: 'custom-attributes.manage-drawer.name.reserved',
    defaultMessage: 'This attribute name is reserved',
  },
  nameUsedInASegment: {
    id: 'custom-attributes.manage-drawer.name.used-in-a-segment',
    defaultMessage: 'This attribute name is already used in a segment',
  },
  typePlaceholder: {
    id: 'custom-attributes.manage-drawer.type.place-holder',
    defaultMessage: 'Select',
  },
  typeUnchangeableCreateMode: {
    id: 'custom-attributes.manage-drawer.create.type-unchangeable',
    defaultMessage: 'Custom attribute type cannot be modified after the initial save.',
  },
  typeUnchangeableEditMode: {
    id: 'custom-attributes.manage-drawer.edit.type-unchangeable',
    defaultMessage: 'Custom attribute type is unchangeable.',
  },
  descriptionPlaceholder: {
    id: 'custom-attributes.manage-drawer.description.place-holder',
    defaultMessage:
      'Provide a brief description (max 100 characters) to guide admins when adding or selecting this custom attribute.',
  },
  descriptionAddButton: {
    id: 'custom-attributes.manage-drawer.description.add-button',
    defaultMessage: 'Add description',
  },
  uniqueLabel: {
    id: 'custom-attributes.manage-drawer.unique.label',
    defaultMessage: 'Unique value',
  },
  uniqueInfo: {
    id: 'custom-attributes.manage-drawer.unique.info',
    defaultMessage:
      'Selecting this option ensures the information is for unique, non-replicable identification',
  },
  mandatoryLabel: {
    id: 'custom-attributes.manage-drawer.mandatory.label',
    defaultMessage: 'Make attribute mandatory for all entities',
  },
  mandatoryInfo: {
    id: 'custom-attributes.manage-drawer.mandatory.info',
    defaultMessage: 'All entities must provide values for mandatory custom attributes',
  },
  protectedLabel: {
    id: 'custom-attributes.manage-drawer.protected.label',
    defaultMessage: 'Limit editing the attribute values to organization admins only',
  },
  addSuccess: {
    id: 'custom-attributes.manage-drawer.add.success',
    defaultMessage: '{name} added',
  },
  optionSelectorHeader: {
    id: 'custom-attributes.manage-drawer.option-selector.header',
    defaultMessage: 'Custom attribute options',
  },
  optionSelectorAddOption: {
    id: 'custom-attributes.manage-drawer.option-selector.add-option',
    defaultMessage: 'Add option',
  },
  optionSelectorDeleteOption: {
    id: 'custom-attributes.manage-drawer.option-selector.delete-option',
    defaultMessage: 'Delete',
  },
  optionSelectorSetDefaultOption: {
    id: 'custom-attributes.manage-drawer.option-selector.set-default-option',
    defaultMessage: 'Set default',
  },
  optionSelectorRemoveDefaultOption: {
    id: 'custom-attributes.manage-drawer.option-selector.remove-default-option',
    defaultMessage: 'Remove default',
  },
  optionSelectorDefault: {
    id: 'custom-attributes.manage-drawer.option-selector.default',
    defaultMessage: 'Default',
  },
  optionSelectorNew: {
    id: 'custom-attributes.manage-drawer.option-selector.new',
    defaultMessage: 'New',
  },
  optionSelectorOptionRequired: {
    id: 'custom-attributes.manage-drawer.option.required',
    defaultMessage: 'Option is required',
  },
  optionSelectorOptionExist: {
    id: 'custom-attributes.manage-drawer.option.exist',
    defaultMessage: 'Option already exists',
  },
  deleteButton: {
    id: 'custom-attributes.manage-drawer.delete-button',
    defaultMessage: 'Delete custom attribute',
  },
  confirmedDeleteButton: {
    id: 'custom-attributes.manage-drawer.confirmed-delete-button',
    defaultMessage: 'Delete',
  },
  deleteTitle: {
    id: 'custom-attributes.manage-drawer.delete-title',
    defaultMessage: 'Delete {name}?',
  },
  deleteContent: {
    id: 'custom-attributes.manage-drawer.delete-content',
    defaultMessage: 'This will affect all entities. This action cannot be undone. ',
  },
  deleteSuccess: {
    id: 'custom-attributes.manage-drawer.delete.success',
    defaultMessage: '{name} deleted. {delay}',
  },
  updateSuccess: {
    id: 'custom-attributes.manage-drawer.update.success',
    defaultMessage: 'Changes saved',
  },
  hasValuesAssigned: {
    id: 'custom-attributes.manage-drawer.has-values-assigned',
    defaultMessage: 'Some values are assigned to more than one entity',
  },
  deleteProhibitionDialogTitle: {
    id: 'custom-attributes.manage-drawer.delete-prohibition-dialog.title',
    defaultMessage: 'Remove from segments before deleting',
  },
  deleteProhibitionDialogContent: {
    id: 'custom-attributes.manage-drawer.delete-prohibition-dialog.content',
    defaultMessage:
      'The attribute can’t be deleted as it is being used in the Segments. Go to Entities > Segments, remove it from the below segment(s) and try again.',
  },
  updateProhibitionDialogTitle: {
    id: 'custom-attributes.manage-drawer.update-prohibition-dialog.title',
    defaultMessage: 'Remove from segments before deleting',
  },
  updateProhibitionDialogContent: {
    id: 'custom-attributes.manage-drawer.update-prohibition-dialog.content',
    defaultMessage:
      'The following attribute options can’t be deleted as they are being used in the Segments. Go to Entities > Segments, remove it from the segment(s) and try again.',
  },
  updateConfirmationDialogTitle: {
    id: 'custom-attributes.manage-drawer.update-confirmation-dialog.title',
    defaultMessage: 'Confirm changes',
  },
  updateConfirmationDialogPrimaryContent: {
    id: 'custom-attributes.manage-drawer.update-confirmation-dialog.primary-content',
    defaultMessage: 'This change will immediately affect {entities_count} entities.',
  },
  updateConfirmationDialogSecondaryContent: {
    id: 'custom-attributes.manage-drawer.update-confirmation-dialog.secondary-content',
    defaultMessage: 'The following update will affect the attribute definition.',
  },
  updateConfirmationDialogRenamed: {
    id: 'custom-attributes.manage-drawer.update-confirmation-dialog.renamed',
    defaultMessage: 'Renamed',
  },
  updateConfirmationDialogDeleted: {
    id: 'custom-attributes.manage-drawer.update-confirmation-dialog.deleted',
    defaultMessage: 'Deleted',
  },
  updateConfirmationDialogNew: {
    id: 'custom-attributes.manage-drawer.update-confirmation-dialog.new',
    defaultMessage: 'Added new',
  },
});

export const valuesUpdateDialog = defineMessages({
  title: {
    id: 'custom-attributes.values-update-dialog.title',
    defaultMessage: 'Edit custom attributes',
  },
  mandatoryField: {
    id: 'custom-attributes.values-update-dialog.mandatory-field',
    defaultMessage: 'Mandatory field',
  },
  protectedFieldInfo: {
    id: 'custom-attributes.values-update-dialog.protected-field.info',
    defaultMessage: 'Only organization admins can edit this value',
  },
  successMessage: {
    id: 'custom-attributes.values-update-dialog.success-message',
    defaultMessage:
      'Changes saved. If used in the Segments, it might take {delay} for this change to reflect on your dashboard.',
  },
  selectPlaceholder: {
    id: 'custom-attributes.values-update-dialog.select.place-holder',
    defaultMessage: 'Select',
  },
  selectCount: {
    id: 'custom-attributes.values-update-dialog.select.options-count',
    defaultMessage: '{count} options selected ',
  },
});
