import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Chip, PopperMenu } from '@trustyou/ui';

import { messages } from './messages';

import { AUTHOR_PLACEHOLDER } from '../../../../constants';
import { useReview } from '../../../../hooks';
import { getHumanDate } from '../../../../utils/date';
import { StatusList, type StatusListItem } from './status-list';

export function ResponseConfirmedChip() {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });

  const markedAsRespondedItem = {
    concept: intl.formatMessage(messages.markedAsResponded),
    content: getHumanDate(reviewRoot?.response?.date ?? ''),
  };
  const respondedByItem = {
    concept: intl.formatMessage(messages.respondedBy),
    content: reviewRoot?.response?.author ?? AUTHOR_PLACEHOLDER,
  };
  const responseConfirmedItem = {
    concept: intl.formatMessage(messages.responseConfirmed),
    content: getHumanDate(reviewRoot?.response?.produced_at ?? ''),
  };

  const statusListItems: StatusListItem[] = [];
  statusListItems.push(markedAsRespondedItem);
  if (reviewRoot?.response?.author) {
    statusListItems.push(respondedByItem);
  }
  statusListItems.push(responseConfirmedItem);

  return (
    <PopperMenu
      disablePortal={false}
      placement="bottom"
      Trigger={({ onClick }) => (
        <Chip
          label={intl.formatMessage(messages.responded)}
          size="small"
          variant="pastelSuccess"
          onClick={onClick}
        />
      )}
      Content={() => <StatusList items={statusListItems} />}
    />
  );
}
