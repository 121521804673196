import {
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { Alert, type AlertColor, Slide, Snackbar, type SnackbarProps } from '@mui/material';

type Message = ReactNode;

type SnackbarUtility = {
  success: (message: Message) => void;
  info: (message: Message) => void;
  warning: (message: Message) => void;
  error: (message: Message) => void;
  genericError: () => void;
};

// Create a context to provide the snackbar functionality globally
const SnackbarContext = createContext({});

// This utility object will hold the methods to trigger snackbars globally
export const snackbar = {} as SnackbarUtility;

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const genericMessage = intl.formatMessage({
    id: 'alert.default.error',
    defaultMessage: 'Something went wrong. Please try again.',
  });

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<ReactNode>();
  const [severity, setSeverity] = useState<AlertColor>('info');

  const openSnackbar = (message: Message, severity: AlertColor) => {
    setMessage(message);
    setSeverity(severity);
    setIsOpen(true);
  };

  const closeSnackbar: SnackbarProps['onClose'] = (event, reason) => {
    // Only close the snackbar if the reason is not 'clickaway'
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const closeAlert = () => {
    setIsOpen(false);
  };

  const shouldAutoHide = ['success', 'info'].includes(severity);

  // Using a ref to store the openSnackbar function globally
  const snackbarRef = useRef({});

  useEffect(() => {
    // Assigning methods to the `snackbar` utility to access globally
    snackbarRef.current = {
      success: (message: Message) => openSnackbar(message, 'success'),
      info: (message: Message) => openSnackbar(message, 'info'),
      warning: (message: Message) => openSnackbar(message, 'warning'),
      error: (message: Message) => openSnackbar(message, 'error'),
      genericError: () => openSnackbar(genericMessage, 'error'),
    };

    // Attach snackbarRef methods to the `snackbar` object
    Object.assign(snackbar, snackbarRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={shouldAutoHide ? 5000 : null}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ alignItems: 'flex-end', minHeight: 'auto !important' }}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={closeAlert}
          severity={severity}
          elevation={6}
          sx={{ minWidth: 320, maxWidth: 480 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
