import { BaseDashboard } from '@trustyou/analytics';
import { useDashboardId } from '@trustyou/shared';
import { useSurveys } from '@trustyou/survey-manager';

import { EmptyState } from './EmptyState';

export const SurveyReviewsDashboard = () => {
  const { data: dashboardIdResponse } = useDashboardId();
  const { data: surveys = [], isFetched: isSurveysLoaded } = useSurveys();
  if (isSurveysLoaded && !surveys.length) return <EmptyState />;
  if (!dashboardIdResponse?.survey_insights_id) return null;
  return <BaseDashboard dashboardId={dashboardIdResponse?.survey_insights_id} />;
};
