import { FormattedMessage } from 'react-intl';

import { DeleteModal } from '@trustyou/ui';

type ReviewProviderAccountRemoveModalProps = {
  providerAccountId?: string;
  providerAccountCount: number;
  onClose: () => void;
  onDelete: () => void;
  isDeleteInProgress: boolean;
  displayedProviderName: string;
};

export function ReviewProviderAccountRemoveModal({
  providerAccountId,
  providerAccountCount,
  onClose,
  onDelete,
  isDeleteInProgress,
  displayedProviderName,
}: ReviewProviderAccountRemoveModalProps) {
  return (
    <DeleteModal
      cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
      content={
        providerAccountCount > 1 ? (
          <FormattedMessage
            id="review-providers.remove-account.modal.description"
            defaultMessage="Your API connections associated with this ID will be disconnected. You’ll not be able to use direct responses, and the reviews of these entities will be collected via web crawling."
          />
        ) : (
          <FormattedMessage
            id="review-providers.remove-last-account.modal.description"
            defaultMessage="You’ll not be able to use direct responses, and the reviews of these entities will be collected via web crawling."
          />
        )
      }
      dataName={`remove-provider-account-for-${displayedProviderName}`}
      deleteText={
        <FormattedMessage
          id="review-providers.remove-account.modal.accept"
          defaultMessage="Remove"
        />
      }
      onClose={onClose}
      onDelete={onDelete}
      title={
        <FormattedMessage
          id="review-providers.remove-account.modal.title"
          defaultMessage="Remove connected account?"
        />
      }
      open={!!providerAccountId}
      isDeleteInProgress={isDeleteInProgress}
    />
  );
}
