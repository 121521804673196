import { orange, red, teal } from '@mui/material/colors';
import { faFaceFrownSlight, faMeh, faSmile } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { IStyles } from '@trustyou/shared';
import { SpotIcon } from '@trustyou/ui';

import type { SentimentEnum } from '../client';

const ICON_MAP = {
  positive: faSmile,
  neutral: faMeh,
  negative: faFaceFrownSlight,
};

const styles: IStyles = {
  base: { height: '40px', width: '40px', color: 'common.white' },
  positive: { backgroundColor: teal[300] },
  neutral: { backgroundColor: orange[300] },
  negative: { backgroundColor: red[300] },
};

type SentimentIconProps = {
  type: SentimentEnum;
};

export const SentimentIcon = ({ type }: SentimentIconProps) => (
  <SpotIcon
    icon={ICON_MAP[type]}
    sx={{
      ...styles.base,
      ...styles[type],
    }}
  />
);
