import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type PreviewEntityData, type UploadError, useUploadEntities } from '@trustyou/shared';
import { Box, Typography } from '@trustyou/ui';
import { isString } from 'lodash';

import Errors from './Errors';
import UploadingState from './UploadingState';

import { FileUploader } from '../../../components/FileUploader/FileUploader';
import MatchedEntities from '../../../components/LoadingScreen/MatchedEntities';
import { Footer } from '../Footer';
import styles from './styles';

const FILE_SIZE = 20_000_000; // 20 MB
const FILE_TYPE = { 'text/csv': ['.csv'] };

export type UploadSpreadsheetProps = {
  onClickBack: () => void;
  onSuccess: (previewEntities: PreviewEntityData) => void;
};

export default function UploadSpreadsheet({ onClickBack, onSuccess }: UploadSpreadsheetProps) {
  const [errors, setErrors] = useState<UploadError[]>([]);

  const uploadEntities = useUploadEntities();

  const onFileUpload = (files: File[]) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadEntities.mutate(formData, {
      onSuccess: (data) => {
        setErrors([]);
        setTimeout(() => {
          onSuccess(data);
        }, 2000);
      },
      onError: (error) => {
        // @ts-expect-error Property 'error' does not exist on type '{}'
        const uploadError = error.response?.data?.error;
        if (uploadError) {
          setErrors([
            {
              type: uploadError.code,
              message: uploadError.message,
            },
          ]);
        } else {
          setErrors([
            {
              type: 'undefined-error',
              message: isString(error.response?.data) ? error.response?.data : undefined,
            },
          ]);
        }
      },
    });
  };

  const onUploadError = (uploadErrors: string[]) => {
    setErrors(uploadErrors.map((type) => ({ type })));
  };

  if (uploadEntities.isPending) {
    return <UploadingState />;
  }

  if (uploadEntities.isSuccess) {
    return <MatchedEntities />;
  }

  return (
    <>
      <Box sx={styles.uploaderContainer}>
        <Typography variant="h6">
          <FormattedMessage
            id="entitiesImporter.uploadSpreadsheet.readyToUpload"
            defaultMessage="Ready to upload?"
          />
        </Typography>
        <Typography variant="body2" sx={styles.uploaderInfoMessage}>
          <FormattedMessage
            id="entitiesImporter.uploadSpreadsheet.fileUploadInfoMessage"
            defaultMessage="Click to upload or drag and drop your file below. We only support CSV format and a maximum file size of 20 MB."
          />
        </Typography>
        <FileUploader
          onChange={onFileUpload}
          onUploadError={onUploadError}
          accept={FILE_TYPE}
          maxSize={FILE_SIZE}
          buttonContent={
            <FormattedMessage
              id="entitiesImporter.uploadSpreadsheet.selectCsv"
              defaultMessage="Select CSV"
            />
          }
        >
          <Typography variant="h6">
            <FormattedMessage
              id="entitiesImporter.uploadSpreadsheet.clickToUpload"
              defaultMessage="Click to upload"
            />
          </Typography>
          <Typography variant="body2" sx={styles.uploaderBodyMessage}>
            <FormattedMessage
              id="entitiesImporter.uploadSpreadsheet.orDragDropHere"
              defaultMessage="or drag & drop here"
            />
          </Typography>
        </FileUploader>
        <Errors errors={errors} />
      </Box>
      <Footer onClickBack={onClickBack} />
    </>
  );
}
