import { useEffect, useRef, useState } from 'react';

import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
// eslint-disable-next-line import/no-unresolved
import type { OidcAccessToken } from '@axa-fr/react-oidc/dist/ReactOidc';
import {
  $assApi,
  AUTH_TOKEN_SERVICE_PATH,
  type AccessTokenResponse,
  TOKEN_INFO_PATH,
  isExpired,
  useAccessToken,
  useAuthorizationStore,
  useChangelingStore,
  useMembershipStore,
} from '@trustyou/shared';
import axios, { type AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';

export const useAuthorization = () => {
  const [init, setInit] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [authError, setAuthError] = useState<Error | null>(null);

  const { isChangeling } = useChangelingStore();
  const { isAuthenticated, renewTokens } = useOidc();
  const { accessToken: keycloakAccessToken } = useOidcAccessToken();

  const { tokenStore, setTokenStore } = useAuthorizationStore();
  const { membership, setMembership } = useMembershipStore();

  const fetchAccessToken = useAccessToken({ isChangeling });

  const assApiReqInterceptor = useRef<number | null>(null);
  const assApiResInterceptor = useRef<number | null>(null);
  const isFetchFromUrlRunOnce = useRef(false);
  const isFetchChangelingRunOnce = useRef(false);

  const routerPath = process.env.REACT_APP_ROUTER_BASENAME
    ? `/${process.env.REACT_APP_ROUTER_BASENAME}`
    : '';

  const handleSuccess = (data: AccessTokenResponse) => {
    const subscriptionId = data.claims.subscription_id;
    const membershipId = data.claims.membership_id;
    setAuthError(null);
    setMembership({
      id: membershipId,
      organizationId: data.claims.organization_id,
      subscriptionId,
    });
    if (!tokenStore) {
      setTokenStore({
        [membershipId]: {
          org_center: data,
          analytics: null,
        },
      });
      return;
    }
    setTokenStore({
      ...tokenStore,
      [membershipId]: {
        ...tokenStore[membershipId],
        org_center: data,
      },
    });
    setIsCompleted(true);
  };

  const handleError = (error: Error) => {
    setAuthError(error);
  };

  const handleFetchAccessToken = (membershipId: string) => {
    fetchAccessToken.mutate(
      {
        keycloakAccessToken,
        membershipId,
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    );
  };

  useEffect(() => {
    if (!membership?.id) {
      console.warn('Membership ID is missing');
    }
    setInit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !keycloakAccessToken) return;
    if (init) return;

    // If I don't have the access token on tokenStore on localStorage I fetch to get a new token
    if (!tokenStore || !Object.values(tokenStore)[0].org_center) {
      if (!isFetchChangelingRunOnce.current) {
        if (membership?.id) {
          handleFetchAccessToken(membership.id);
        }
        isFetchChangelingRunOnce.current = true;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, tokenStore, isAuthenticated, keycloakAccessToken]);

  useEffect(() => {
    // This will execute if I have a tokenStore.
    // This way I'm sure that this will be always executed after I eventually fetch the standard token in previous effect.
    if (!tokenStore || !membership?.id) return;

    // This will need to run only once or I will have an infinite loop cause of fetching and at same time
    // having the tokenStore on dependencies of useEffect
    if (!isFetchFromUrlRunOnce.current) {
      if (membership?.id) {
        handleFetchAccessToken(membership.id);
      }
      isFetchFromUrlRunOnce.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership?.id, tokenStore]);

  // This effect depends on organization change. It will basically set the interceptors passing the correct tokens for
  // any call of the app.
  useEffect(() => {
    if (!tokenStore || !membership?.id) return;

    assApiReqInterceptor.current = $assApi.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        // If get_access_token call I use the config as it is
        if (config.url?.includes(AUTH_TOKEN_SERVICE_PATH)) return config;
        if (!membership.organizationId) return config;
        if (!membership.id) return config;

        // console.log('inside request interceptor: ', tokenStore);
        // console.log('organization id: ', organization);

        const accessToken = tokenStore[membership.id].org_center?.access_token;
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`;
        if (
          config.url?.includes('{organization_id}') &&
          tokenStore[membership.id].org_center?.claims?.organization_id
        ) {
          // eslint-disable-next-line no-param-reassign
          config.url = config.url.replace(
            '{organization_id}',
            tokenStore[membership.id].org_center?.claims?.organization_id as string
          );
        }
        return config;
      }
    );

    assApiResInterceptor.current = $assApi.interceptors.response.use(
      (response: AxiosResponse) => response,
      // eslint-disable-next-line consistent-return
      (error: AxiosError) => {
        // In case of a 401 on response of any api call I will update the tokenStore.
        // This will trigger the fetch in the previous tokenStore effect.
        // api failed recall will happen automatically thanks to react-query.
        if (error.response?.status === 401) {
          if (error.config?.url?.includes(TOKEN_INFO_PATH)) {
            console.error('Token info api error');
            return Promise.reject(error);
          }
          if (error.config?.url?.includes(AUTH_TOKEN_SERVICE_PATH)) {
            const currentToken = error.config.headers.Authorization?.toString().split(' ')[1];
            if (!currentToken) {
              console.error('Wrong bearer token in header!');
              return Promise.reject(error);
            }
            try {
              const payload = JSON.parse(atob(currentToken.split('.')[1]));
              const isKeycloakTokenExpired = isExpired(payload.exp);
              if (isKeycloakTokenExpired) {
                return renewTokens().then((newToken) => {
                  if (error.config?.headers?.Authorization) {
                    // eslint-disable-next-line no-param-reassign
                    error.config.headers.Authorization = `Bearer ${
                      (newToken as OidcAccessToken).accessToken
                    }`;
                    return axios(error.config);
                  }
                  return Promise.reject(error);
                });
              }
            } catch (err) {
              console.error('Fail to parse bearer token: ', err);
              return Promise.reject(error);
            }
          }
          if (tokenStore) {
            if (!membership.id) return Promise.reject(error);
            const newToken = {
              ...tokenStore,
              [membership.id]: {
                ...tokenStore[membership.id],
                org_center: null,
              },
            };
            setTokenStore(newToken);
          }
          isFetchFromUrlRunOnce.current = false;
          isFetchChangelingRunOnce.current = false;
          if (error.config?.method === 'post' || error.config?.method === 'delete') {
            return Promise.reject(error);
          }
        } else if (error.response?.status === 403) {
          // only if get_access_token call. When get a 403 I will display an alert
          // and redirect to a org path with a valid token.
          if (error.config?.url?.includes(AUTH_TOKEN_SERVICE_PATH)) {
            setIsCompleted(true);

            // eslint-disable-next-line no-alert
            alert(`${error.response.data}. Redirecting to original subscription...`);

            // TODO: if confirmed logic refactor and simplify
            // Redirect with default org_id from tokenStore
            if (window.location.pathname.startsWith(`${routerPath}/org/`)) {
              const subId = Object.values(tokenStore)[0].org_center?.claims?.subscription_id;
              const subIdFromUrl = window.location.pathname.split('/org/')[1];
              const lastUrlSegment = subIdFromUrl.substring(subIdFromUrl.indexOf('/') + 1);
              window.location.pathname = `${routerPath}/org/${subId}/${lastUrlSegment}`;
            }
            throw new Error(error.response.data as string);
          }
        } else {
          setIsCompleted(true);
          return Promise.reject(error);
        }
      }
    );

    /* 
      If accessToken for chosen organization exists, we could fire requests
      Check tokenStore type for more information
     */
    if (tokenStore && tokenStore[membership.id] && tokenStore[membership.id].org_center) {
      setIsCompleted(true);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // All interceptors needs to be ejected once effect is unmounted.
      if (assApiReqInterceptor.current !== null)
        $assApi.interceptors.request.eject(assApiReqInterceptor.current);
      if (assApiResInterceptor.current !== null)
        $assApi.interceptors.response.eject(assApiResInterceptor.current);
    };
    // renewTokens from react-oidc is not memoized as it should be, so the fastest solution is to ignore a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership, routerPath, setTokenStore, tokenStore]);

  const accessToken =
    tokenStore && membership?.id && tokenStore[membership.id]?.org_center?.access_token;

  return { isCompleted, authError, accessToken };
};
