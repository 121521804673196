import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { DialogProps } from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@trustyou/ui';

type DiscardAlertDialogProps = DialogProps & {
  open: boolean;
  title: ReactNode;
  content: ReactNode;
  onClose?: () => void;
};

export function InfoDialog({
  open,
  title,
  content,
  onClose,
  ...dialogProps
}: DiscardAlertDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
