import type { PropsWithChildren, ReactNode } from 'react';

import { Toolbar } from '@mui/material';
import { APP_BAR_HEIGHT, AppBar, Box, Typography } from '@trustyou/ui';

import { HelpMenu } from './HelpMenu';
import { UserMenu } from './UserMenu';

export type TopBarProps = PropsWithChildren & {
  logo?: ReactNode;
  pageName?: string;
  onLogout: () => void;
};

export const TopBar = ({ children, logo, pageName, onLogout }: TopBarProps) => {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        height: APP_BAR_HEIGHT,
        background: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {logo}
        {pageName && (
          <Typography component="h1" variant="subtitle1" color="text.primary">
            {pageName}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <HelpMenu />
        {children}
        <UserMenu onLogout={onLogout} />
      </Toolbar>
    </AppBar>
  );
};
