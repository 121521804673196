import type { EntitiesResponse, SetCompetitorsPayload } from '../../../../types/model/entity';
import type { PaginationRequest } from '../../../../types/model/shared';
import { $assApi } from '../../../api';
import { ORGANIZATION_BASE_PATH } from '../../path';

export async function fetchCompetitors(
  { limit, offset }: PaginationRequest,
  sortKey: string,
  excludedEntityIds: string[],
  searchKey?: string
) {
  const { data } = await $assApi.get<EntitiesResponse>(
    `${ORGANIZATION_BASE_PATH}/search_competitors`,
    {
      params: {
        limit,
        offset,
        order: sortKey,
        exclude_entity_ids: excludedEntityIds,
        ...(searchKey && { q: searchKey }),
      },
      paramsSerializer: {
        // Avoid brackets in array query params:
        // `&exclude_entity_ids=entity_uuid_1&exclude_entity_ids=entity_uuid_2` instead of Axios default behavior
        // `&exclude_entity_ids[]=entity_uuid_1&exclude_entity_ids[]=entity_uuid_2`
        indexes: null,
      },
    }
  );
  return data;
}

export async function setEntityCompetitors(payload: SetCompetitorsPayload) {
  const { data } = await $assApi.post<EntitiesResponse>(
    `${ORGANIZATION_BASE_PATH}/set_owned_entity_competitors`,
    payload
  );
  return data.data;
}
