import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  roleChip: {
    marginLeft: 'auto',
  },
  orgLogoTitleWrapper: {
    paddingBlock: 1,
    paddingInline: 2,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 2,
  },
  orgLogoTitleMinHeight: {
    minHeight: '64px',
  },
  orgLogo: {
    maxHeight: '48px',
    maxWidth: '160px',
  },
  listItem: {
    display: 'flex',
    paddingInline: 2,
    paddingBlock: 1,
    gap: 2,
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'action.hover',
    },
  },
  listItemSelected: {
    backgroundColor: 'rgba(0, 87, 178, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 87, 178, 0.12)',
    },
  },
  listItemInner: {
    justifyContent: 'center',
    gap: 0,
  },
  plan: {
    color: 'text.secondary',
  },
};

export default styles;
