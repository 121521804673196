import { useMutation, useQuery } from '@tanstack/react-query';

import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_ID,
  FETCH_SISENSE_IS_AUTHENTICATED,
  FETCH_SISENSE_JWT_TOKEN,
  LOGOUT,
  SET_ROW_LEVEL_SECURITY,
} from '../../../constants/queryKeys';
import {
  exportDashboardExcel,
  fetchDashboard,
  fetchDashboardId,
  fetchSisenseIsAuthenticated,
  fetchSisenseJWTToken,
  setRowLevelSecurity,
  sisenseLogout,
} from '../../../queries';

export function useSisenseJWTToken() {
  return useQuery({
    queryKey: [FETCH_SISENSE_JWT_TOKEN],
    queryFn: fetchSisenseJWTToken,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useSisenseIsAuthenticated(enabled = true) {
  return useQuery({
    queryKey: [FETCH_SISENSE_IS_AUTHENTICATED],
    queryFn: fetchSisenseIsAuthenticated,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
}

export function useRowLevelSecurity() {
  return useMutation({
    mutationFn: (language: string) => setRowLevelSecurity(language),
    mutationKey: [SET_ROW_LEVEL_SECURITY],
  });
}

export function useSisenseLogout({ onSettled }: { onSettled?: () => void }) {
  return useMutation({
    mutationFn: sisenseLogout,
    mutationKey: [LOGOUT],
    onSettled,
  });
}

export function useDashboardId() {
  return useQuery({
    queryKey: [FETCH_DASHBOARD_ID],
    queryFn: fetchDashboardId,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useExportDashboardExcel() {
  return useMutation({ mutationFn: exportDashboardExcel });
}

export function useDashboard(id?: string, enabled = true) {
  return useQuery({
    queryKey: [FETCH_DASHBOARD, { id }],
    queryFn: () => fetchDashboard(id as string),
    refetchOnWindowFocus: false,
    enabled: enabled && !!id,
  });
}
