import { FormattedMessage, type IntlShape, defineMessages, useIntl } from 'react-intl';

import type {
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  type CombinedUserAndInvitation,
  type CombinedUserResponse,
  type StyleUnit,
  USER_STATUSES,
  commonFields,
  getUserRoleDefaultMessage,
  getUserRoleIntlId,
  useResponsive,
} from '@trustyou/shared';
import {
  DataGrid,
  DataGridCell,
  Typography,
  USER_STATUS_CHIP_MAP,
  UserCredentialsCell,
  UserScopeCell,
} from '@trustyou/ui';

import UserActionCell from './UserActionCell';

import styles from './styles';

const userTableHeaderNames = defineMessages({
  name: {
    id: 'user.table.header.name',
    defaultMessage: 'Name',
  },
  role: {
    id: 'user.table.header.role',
    defaultMessage: 'Role',
  },
  access: {
    id: 'user.table.header.access',
    defaultMessage: 'Access',
  },
});

const getUserColumns: (intl: IntlShape) => GridColDef[] = (intl: IntlShape) => [
  {
    field: 'name',
    headerName: intl.formatMessage(userTableHeaderNames.name),
    renderCell: ({ row }: GridCellParams) => (
      <UserCredentialsCell
        name={row.name}
        email={row.email}
        isInvited={row.status === USER_STATUSES.INVITED}
      />
    ),
    flex: 1,
    minWidth: 350,
  },
  {
    field: 'role',
    headerName: intl.formatMessage(userTableHeaderNames.role),
    renderCell: ({ row }: GridCellParams) => (
      <Typography variant="body2">
        <FormattedMessage
          id={getUserRoleIntlId(row.role)}
          defaultMessage={getUserRoleDefaultMessage(row.role)}
        />
      </Typography>
    ),
    width: 150,
  },
  {
    field: 'scope',
    headerName: intl.formatMessage(userTableHeaderNames.access),
    renderCell: ({ row }: GridCellParams) => <UserScopeCell scope={row.scope} />,
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'subscription',
    headerName: intl.formatMessage(commonFields.subscription),
    minWidth: 350,
    flex: 1,
    sortable: false,
    renderCell: ({ row }: GridCellParams) => (
      <DataGridCell primaryValue={row.subscription.name} secondaryValue={row.subscription.plan} />
    ),
  },
  {
    field: 'status',
    headerName: intl.formatMessage(commonFields.status),
    renderCell: ({ row }: GridCellParams) => USER_STATUS_CHIP_MAP[row.status as USER_STATUSES],
  },
];

export type UserTableProps = {
  data?: CombinedUserResponse;
  isLoading: boolean;
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;
  containerStyle?: StyleUnit;
  enabledColumns?: string[];
  onSortModelChange: (model: GridSortModel) => void;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  handleUserDetails?: (user: CombinedUserAndInvitation) => void;
  handleEdit?: (user: CombinedUserAndInvitation) => void;
  handleDelete?: (user: CombinedUserAndInvitation) => void;
  handleResendInvitation?: (user: CombinedUserAndInvitation) => void;
};

const UsersTable = ({
  data,
  isLoading,
  sortModel,
  paginationModel,
  containerStyle,
  enabledColumns,
  onSortModelChange,
  onPaginationModelChange,
  handleUserDetails,
  handleEdit,
  handleDelete,
  handleResendInvitation,
}: UserTableProps) => {
  const intl = useIntl();
  const { isMobile } = useResponsive();

  const actionColumn = {
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: ({ row }: GridCellParams) => (
      <UserActionCell
        user={row}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleResendInvitation={handleResendInvitation}
      />
    ),
  };

  const rows = data?.data || [];
  let columns = getUserColumns(intl);
  columns.push(actionColumn);
  if (enabledColumns) {
    columns = columns.filter((column) => enabledColumns.includes(column.field));
  }

  return (
    <DataGrid
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      sx={styles.table}
      rowHeight={64}
      getRowId={(row) => row.membership_id || row.id || row.email}
      columns={columns}
      rows={rows}
      rowCount={data?.pagination.total}
      onRowClick={(params) => handleUserDetails && handleUserDetails(params.row)}
      loading={isLoading}
      getCellClassName={(params: GridCellParams) => {
        if (
          params.field !== 'action' &&
          params.field !== 'status' &&
          params.row.status === USER_STATUSES.INVITED
        ) {
          return 'inactive-cell';
        }
        return '';
      }}
      pinnedColumns={!isMobile ? { right: ['action'] } : undefined}
      autoHeight={false}
      containerStyle={containerStyle}
    />
  );
};

export default UsersTable;
