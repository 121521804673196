import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  rulesStack: {
    gap: 1.5,
    padding: 2,
    borderRadius: '4px',
    border: `1px solid ${grey[300]}`,
    background: grey[50],
  },
  addRuleButton: { gap: 1, width: 'fit-content' },
  ruleSelect: {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '50%',
    backgroundColor: 'white',
    '& .MuiSelect-select': {
      paddingX: 1.5,
      paddingY: 1,
    },
  },
  ruleValueSelect: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: 'white',
    '& .MuiInputBase-root': {
      paddingTop: 1,
      paddingBottom: 0,
      paddingX: 1.5,
      minHeight: '40px',
    },
    '& .MuiAutocomplete-tag': {
      height: '24px',
      margin: 0,
      marginRight: 1,
      marginBottom: 1,
    },
    '& .MuiAutocomplete-tag.MuiChip-root': {
      background: 'transparent',
      border: '1px solid',
      borderColor: 'primary.main',
      color: 'primary.main',
      '& .MuiChip-deleteIcon': {
        fontSize: '16px',
        color: '#4e88c9',
      },
    },
    '& .MuiAutocomplete-input': {
      minWidth: 'unset !important',
      padding: '0 !important',
      marginBottom: 1,
    },
  },
  ruleValueSelectOption: {
    justifyContent: 'space-between',
    height: '50px',
  },
};
