import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Box, FormGroup, Stack } from '@trustyou/ui';

import { EnabledSwitch, ScoreSelect, SelectProps } from './form-fields';
import { NestedFilters } from './nested-filters';

import type { EmailNotificationSettings_Input } from '../../../client';
import { DropdownSurveyName } from '../../dropdowns/filters-dropdowns';

export function FeedbacksSection() {
  const intl = useIntl();
  const { control, watch } = useFormContext<EmailNotificationSettings_Input>();
  const { fields } = useFieldArray({ control, name: 'feedbacks' });

  return (
    <FormGroup sx={{ gap: 2 }}>
      {fields.map((field, index) => (
        <Stack key={field.id}>
          <EnabledSwitch
            fieldName={`feedbacks.${index}.enabled`}
            label={intl.formatMessage({
              id: 'inbox.settings.notifications.realtime.label',
              defaultMessage: 'Receive email notifications for new survey reviews on arrival',
            })}
          />
          <NestedFilters isVisible={watch(`feedbacks.${index}.enabled`)}>
            <ScoreSelect
              fieldName={`feedbacks.${index}.filters.score`}
              initialValue={field.filters?.score ?? []}
            />
            <SurveyNameSelect
              fieldName={`feedbacks.${index}.filters.survey.ids`}
              initialValue={field.filters?.survey?.ids ?? []}
            />
          </NestedFilters>
        </Stack>
      ))}
    </FormGroup>
  );
}

export function SurveyNameSelect({ fieldName, initialValue }: SelectProps) {
  return (
    <Box width={220}>
      <DropdownSurveyName fieldName={fieldName} initialValue={initialValue} fullWidth />
    </Box>
  );
}
