import { FormattedMessage } from 'react-intl';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHelpLinks } from '@trustyou/shared';
import { Box, Button, Card, Typography } from '@trustyou/ui';

import blueBanner from '../../assets/banners/blue-banner.svg';

export function GreetingBanner() {
  const { accessTokenPayload } = useOidcAccessToken();
  const helpLinks = useHelpLinks();

  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        gap: 2,
        padding: 3,
        color: 'common.white',
        backgroundImage: `url(${blueBanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Typography variant="h5">
        <FormattedMessage
          id="home.helloUser"
          defaultMessage="Hello, {user}!"
          values={{ user: accessTokenPayload.name }}
        />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'start', md: 'end' },
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {helpLinks
          .filter((link) => !link.isHidden)
          .map((link) => (
            <Button
              key={link.id}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={link.icon} />}
              onClick={link.onClick}
            >
              {link.name}
            </Button>
          ))}
      </Box>
    </Card>
  );
}
