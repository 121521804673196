import { type QueryOptions, buildQueries, queryHelpers } from '@testing-library/react';

const queryAllByDataIcon = (
  container: HTMLElement,
  id: string,
  options?: QueryOptions
): HTMLElement[] => queryHelpers.queryAllByAttribute('data-icon', container, id, options);

const getMultipleError = (c: HTMLElement, dataIcon: string): string =>
  `Found multiple elements with the data-icon attribute of: ${dataIcon}`;
const getMissingError = (c: HTMLElement, dataIcon: string): string =>
  `Unable to find an element with the data-icon attribute of: ${dataIcon}`;

const [queryByDataIcon, getAllByDataIcon, getByDataIcon, findAllByDataIcon, findByDataIcon] =
  buildQueries(
    queryAllByDataIcon,
    // @ts-expect-error Type 'null' is not assignable to type 'HTMLElement'
    getMultipleError,
    getMissingError
  );

export { queryByDataIcon, getAllByDataIcon, getByDataIcon, findAllByDataIcon, findByDataIcon };
