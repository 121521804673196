import type { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { Stack, TrustYouLogo } from '@trustyou/ui';

import { ChangelingAlert } from './ChangelingAlert';
import { TopBar } from './TopBar';

type TopBarLayoutProps = PropsWithChildren & {
  hasLogo?: boolean;
  backgroundColor?: string;
  onLogout: () => void;
};

export function TopBarLayout({ children, hasLogo, backgroundColor, onLogout }: TopBarLayoutProps) {
  return (
    <Stack sx={{ backgroundColor }}>
      <ChangelingAlert />
      <TopBar
        logo={hasLogo && <TrustYouLogo variant="bubbles" color="blue" />}
        onLogout={onLogout}
      />
      <Stack component="main" sx={{ flexGrow: 1 }}>
        {children || <Outlet />}
      </Stack>
    </Stack>
  );
}
