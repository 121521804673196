import { FormattedMessage } from 'react-intl';

import { actionMessages } from '@trustyou/shared';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@trustyou/ui';

export const DeleteDialog = ({
  open,
  onClose,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogTitle>
      <FormattedMessage
        id="visit-data.data-point-drawer.delete-dialog-title"
        defaultMessage="Delete this data point?"
      />
    </DialogTitle>
    <DialogContent>
      <FormattedMessage
        id="visit-data.data-point-drawer.delete-dialog-description"
        defaultMessage="This action is not reversible."
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="inherit">
        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
      </Button>
      <Button onClick={onDelete} autoFocus variant="contained" color="error">
        <FormattedMessage {...actionMessages.delete} />
      </Button>
    </DialogActions>
  </Dialog>
);
