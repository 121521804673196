import { FormattedMessage } from 'react-intl';

import { useRouteUtils } from '@trustyou/shared';
import { Box, Button, Stack, Typography, magnifyingGlassSearchingFile } from '@trustyou/ui';

export const VisitData = () => {
  const { navigateWithBase } = useRouteUtils();

  return (
    <Stack sx={{ flexDirection: 'row', marginTop: 5, gap: 3 }}>
      <img src={magnifyingGlassSearchingFile} alt="No rows" />
      <Stack spacing={2}>
        <Box>
          <Typography variant="body1">
            <FormattedMessage
              id="app.settings.visit-data.description"
              defaultMessage="Configure your visit data points and upload visit data for multiple entities at once. The data will be used for defining survey distribution rules, conditional questions, and filtering. "
            />
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="app.settings.visit-data-status"
              defaultMessage="Visit data status"
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id="app.settings.visit-data.not-uploaded-yet"
              defaultMessage="You haven’t uploaded any visit data yet"
            />
          </Typography>
        </Box>
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <Button variant="contained" onClick={() => navigateWithBase('visit-data/import')}>
            <FormattedMessage id="app.settings.visit-data.upload-csv" defaultMessage="Upload CSV" />
          </Button>
          <Button variant="text">
            <FormattedMessage
              // FIXME: Typo `dowload` should be `download`
              id="app.settings.visit-data.dowload-template"
              defaultMessage="Download template"
            />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
