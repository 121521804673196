import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  wrapper: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    padding: 4,
    backgroundColor: 'background.paper',
  },
  textWrapper: {
    maxWidth: 600,
    textAlign: 'center',
  },
};

export default styles;
