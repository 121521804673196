import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  markAsInappropriate: {
    id: 'inbox.review.flag.mark-as-inappropriate',
    defaultMessage: 'Mark as inappropriate',
  },
  markedAsInappropriate: {
    id: 'inbox.review.flag.marked-as-inappropriate',
    defaultMessage: 'Marked as inappropriate',
  },
  markedInappropriate: {
    id: 'inbox.review.flag.marked-inappropriate',
    defaultMessage: 'Marked inappropriate',
  },
  cannotBeMarkedAgainAsInappropriate: {
    id: 'inbox.review.flag.cannot-be-marked-again-as-inappropriate',
    defaultMessage: 'This review has already been requested to be moderated and it was rejected.',
  },
  inappropriate: {
    id: 'inbox.review.flag.status.inappropriate',
    defaultMessage: 'Inappropriate',
  },
  inappropriateProcessing: {
    id: 'inbox.review.flag.status.inappropriate-processing',
    defaultMessage: 'Inappropriate (processing)',
  },
  moderationConfirmed: {
    id: 'inbox.response.status.menu.moderation-confirmed',
    defaultMessage: 'Confirmed inappropriate',
  },
});

export const discardDialogMessages = defineMessages({
  title: {
    id: 'inbox.review.flag.dialog.discard.title',
    defaultMessage: 'Discard changes?',
  },
  content: {
    id: 'inbox.review.flag.dialog.discard.description',
    defaultMessage: 'The review won’t be marked as inappropriate if you leave this editor.',
  },
});

export const guidelinesMessages = defineMessages({
  disclaimer: {
    id: 'inbox.review.flag.form.guidelines.disclaimer',
    defaultMessage:
      'Before marking a review inappropriate and sending it for moderation, please review our guidelines regarding the process.',
  },
  button: {
    id: 'inbox.review.flag.form.guidelines.button',
    defaultMessage: 'Guidelines',
  },
  checkboxLabel: {
    id: 'inbox.review.flag.form.guidelines.checkbox.label',
    defaultMessage: 'I agree to the guidelines for marking a review as inappropriate',
  },
  helperText: {
    id: 'inbox.review.flag.form.guidelines.helper-text',
    defaultMessage: 'Please agree to the guidelines before continuing',
  },
});

export const reasonAndNotesMessages = defineMessages({
  reasonFieldLabel: {
    id: 'inbox.review.flag.form.reason.label',
    defaultMessage: 'Reason',
  },
  notesFieldLabel: {
    id: 'inbox.review.flag.form.notes.label',
    defaultMessage: 'Notes',
  },
});

// TODO: Remove these messages once the backend exposes translations for the moderation reasons
export const reasonMessages = defineMessages({
  inappropriateContent: {
    id: 'inbox.review.flag.form.reason.inappropriate-content',
    defaultMessage: 'Inappropriate content',
  },
  sensitiveContent: {
    id: 'inbox.review.flag.form.reason.sensitive-content',
    defaultMessage: 'Sensitive content',
  },
  testReview: {
    id: 'inbox.review.flag.form.reason.test-review',
    defaultMessage: 'Test review',
  },
  fraudulentOrFakeReview: {
    id: 'inbox.review.flag.form.reason.fraudulent-or-fake-review',
    defaultMessage: 'Fraudulent / fake review',
  },
  reviewWrittenByEmployees: {
    id: 'inbox.review.flag.form.reason.review-written-by-employees',
    defaultMessage: 'Review written by employees',
  },
  mistakeOrErroneousReview: {
    id: 'inbox.review.flag.form.reason.mistake-or-erroneous-review',
    defaultMessage: 'Mistake / erroneous review',
  },
  other: {
    id: 'inbox.review.flag.form.reason.other',
    defaultMessage: 'Other',
  },
});

export const alertMessages = defineMessages({
  markedInappropriate: {
    id: 'inbox.review.flag.alert.marked-inappropriate',
    defaultMessage:
      'This review was marked as inappropriate and will remain in this status until the request is approved or rejected by a moderator. This review keeps affecting survey review KPIs.',
  },
  inappropriateProcessing: {
    id: 'inbox.review.flag.alert.inappropriate-processing',
    defaultMessage:
      'This review was marked and confirmed to be inappropriate. It’s not possible to respond to it. Once it’s processed, it won’t affect your survey review KPIs.',
  },
  inappropriate: {
    id: 'inbox.review.flag.alert.inappropriate',
    defaultMessage:
      'This review was marked and confirmed to be inappropriate. It’s not possible to respond to it and it doesn’t affect your survey review KPIs.',
  },
  details: {
    id: 'inbox.review.flag.alert.details',
    defaultMessage: 'Details',
  },
  reject: {
    id: 'inbox.review.flag.alert.reject',
    defaultMessage: 'Reject',
  },
  approve: {
    id: 'inbox.review.flag.alert.approve',
    defaultMessage: 'Approve',
  },
});

export const detailsDialogMessages = defineMessages({
  title: {
    id: 'inbox.review.flag.dialog.details.title',
    defaultMessage: 'Review marked as inappropriate',
  },
  subtitle: {
    id: 'inbox.review.flag.dialog.details.subtitle',
    defaultMessage: 'By {user} on {date}',
  },
});

export const rejectionDialogMessages = defineMessages({
  title: {
    id: 'inbox.review.flag.dialog.rejection.title',
    defaultMessage: 'Are you sure you want to revert the inappropriate status?',
  },
  explanation: {
    id: 'inbox.review.flag.dialog.rejection.explanation',
    defaultMessage:
      'The status of the review will immediately change back to its previous state. This action can’t be reverted.',
  },
  notes: {
    id: 'inbox.review.flag.dialog.rejection.notes.label',
    defaultMessage: 'Reason to revert',
  },
});

export const snackbarMessages = defineMessages({
  rejected: {
    id: 'inbox.review.flag.snackbar.reject',
    defaultMessage: 'This review is appropriate',
  },
  approved: {
    id: 'inbox.review.flag.snackbar.approved',
    defaultMessage: 'Inappropriate status approved',
  },
  confirmed: {
    id: 'inbox.review.flag.snackbar.confirmed',
    defaultMessage: 'Inappropriate status confirmed',
  },
});
