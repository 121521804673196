import { green } from '@mui/material/colors';
import { alpha } from '@mui/system';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  pageContainer: {
    position: 'relative',
    justifyContent: 'center',
    flex: 1,
  },
  detailsCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
  },
  detailsCellText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  connectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
  },
  authWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 1,
  },
  googleStaticConnectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: 2,
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    color: 'success.main',
    backgroundColor: green[50],
  },
  disconnectAPIButton: {
    position: 'absolute',
    top: '8px',
    right: '24px',
    zIndex: 2,
    color: 'error.main',
    border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
    '&:hover': {
      filter: 'blur(0.5px)',
    },
  },
  initialLoadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
  },
};

export default styles;
