import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { IconButtonProps, TooltipProps } from '@mui/material';
import {
  Box,
  IconButton,
  StyledFontAwesomeIcon,
  type StyledFontAwesomeIconProps,
  Tooltip,
} from '@trustyou/ui';

export type IconButtonWithTooltipProps = IconButtonProps & {
  icon: FontAwesomeIconProps['icon'];
  tooltip: TooltipProps['title'];
  IconProps?: Partial<StyledFontAwesomeIconProps>;
};

export const IconButtonWithTooltip = ({
  icon,
  tooltip,
  IconProps,
  ...rest
}: IconButtonWithTooltipProps) => (
  <Tooltip arrow placement="top" title={tooltip}>
    {/* The Box wrapper is needed to display the tooltip when the IconButton is disabled */}
    <Box>
      <IconButton {...rest}>
        <StyledFontAwesomeIcon icon={icon} {...IconProps} />
      </IconButton>
    </Box>
  </Tooltip>
);
