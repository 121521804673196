import { FormattedMessage } from 'react-intl';

import type { StyleUnit } from '@trustyou/shared';
import { type EmptyStateAction, EmptyStatePlaceholder } from '@trustyou/ui';

import personSelectingData from '../../assets/illustrations/person-selecting-data.svg';
import styles from './styles';

export type EmptyBenchmarksProps = {
  sx?: StyleUnit;
  action?: EmptyStateAction;
};

export const EmptyBenchmarks = ({ sx, action }: EmptyBenchmarksProps) => (
  <EmptyStatePlaceholder
    img={personSelectingData}
    title={
      <FormattedMessage
        id="tyDesign.emptyState.noBenchmarksYet"
        defaultMessage="No benchmarks yet"
      />
    }
    description={
      <FormattedMessage
        id="tyDesign.emptyState.noBenchmarksDescription"
        defaultMessage="When you add benchmarks, they will show up here."
      />
    }
    emptyStateBody={{ textAlign: 'center' }}
    emptyStateHeader={styles.emptyStateHeader}
    emptyStateContainer={{ ...styles.emptyStateContainer, ...styles.noDataContainer, ...sx }}
    action={action ? { ...action, styles: styles.actionButton } : undefined}
  />
);
