import { useIntl } from 'react-intl';

import { INBOX_CONNECTIVITY_MAX_DELAY_IN_HOURS } from '../constants/reviewProviders';

export function useInboxDelayTimeMessage() {
  const intl = useIntl();
  return intl.formatMessage(
    {
      id: 'review-providers.delay.time',
      defaultMessage: 'up to {hours} hours',
    },
    { hours: INBOX_CONNECTIVITY_MAX_DELAY_IN_HOURS }
  );
}
