import { FormattedMessage } from 'react-intl';

import type { ButtonProps } from '@mui/material';
import { Box, Button, CardActions, CardContent, Typography, alpha } from '@trustyou/ui';

import { PersonNextToACheckmark } from './person-next-to-a-checkmark';

type ThankYouStepProps = {
  onClose: ButtonProps['onClick'];
};

export function ThankYouStep({ onClose }: ThankYouStepProps) {
  return (
    <>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage
            id="inbox.response.ai.rating.dialog.successHeader"
            defaultMessage="Thank you for your feedback"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="inbox.response.ai.rating.dialog.successSubHeader"
            defaultMessage="It helps us greatly with improving our AI."
          />
        </Typography>
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.2),
        }}
      >
        <PersonNextToACheckmark />
      </Box>
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button variant="contained" onClick={onClose}>
          <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
        </Button>
      </CardActions>
    </>
  );
}
