import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  type BasicEntity,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  FETCH_INFINITE_ENTITIES,
  useInfiniteEntities,
} from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';

import { EntitySelectorModal } from '../../components';
import { advancedScopeMessages } from '../../constants/messages/users';

const MAX_ADDITIONAL_ENTITIES_COUNT = 100;

type Props = {
  defaultItems: BasicEntity[];
  onClose: () => void;
  onSave: (selectedItems: BasicEntity[]) => void;
};

export const AdditionalEntitiesModal = ({ defaultItems, onClose, onSave }: Props) => {
  const queryClient = useQueryClient();

  const [entities, setEntities] = useState<BasicEntity[]>([]);
  const [searchKey, setSearchKey] = useState('');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isPending: isLoading,
  } = useInfiniteEntities(DEFAULT_PAGE_SIZE, DEFAULT_SORT_KEY, searchKey);

  useEffect(() => {
    setEntities(data?.pages.map((page) => page.data).flat() || []);
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
  };

  const renderRowContent = (entity: BasicEntity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  return (
    <EntitySelectorModal
      items={entities}
      defaultItems={defaultItems}
      title={<FormattedMessage {...advancedScopeMessages.additionalEntitiesModalHeader} />}
      selectedHeaderTitle={(count) => (
        <FormattedMessage
          {...advancedScopeMessages.additionalEntitiesModalSelectedCount}
          values={{ count }}
        />
      )}
      subHeader={
        <FormattedMessage
          {...advancedScopeMessages.additionalEntitiesModalSubHeader}
          values={{ count: MAX_ADDITIONAL_ENTITIES_COUNT }}
        />
      }
      isOpen
      maxItems={MAX_ADDITIONAL_ENTITIES_COUNT}
      isLoading={isLoading}
      onClose={onClose}
      onSave={onSave}
      onSearch={onSearch}
      renderRowContent={renderRowContent}
      onFetchNextPage={onFetchNextPage}
    />
  );
};
