import type { ReactNode } from 'react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { SxProps, Theme } from '@mui/material';
import { StyledFontAwesomeIcon, Tooltip, Typography } from '@trustyou/ui';

type StampProps = {
  status: ReactNode;
  icon?: IconProp;
  tooltip?: string;
  sx?: SxProps<Theme>;
};

export function Stamp({ status, icon, tooltip, sx }: StampProps) {
  const stamp = (
    <Typography variant="caption" color="text.secondary" sx={{ ...sx }}>
      {icon && <StyledFontAwesomeIcon icon={icon} size="lg" sx={{ mr: 0.75 }} />}
      {status}
    </Typography>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="top" arrow>
      {stamp}
    </Tooltip>
  ) : (
    stamp
  );
}
