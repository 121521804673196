import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';

import type {
  GenerateResponseIn,
  GetLanguagesApiInboxOrganizationIdResponseAiGetLanguagesGetResponse,
  Profile_Input,
  Profile_Output,
  TranslateIn,
} from '../../../client';
import type {
  LanguageItem,
  ResponseAIFeedback,
  ResponseAITranslateData,
  ResponseResult,
  UserProfileData,
} from '../../../types';

export async function translateViaResponseAI(payload: TranslateIn) {
  const { data } = await $assApi.post<ResponseAITranslateData>(
    `${INBOX_BASE_PATH}/response-ai/translate`,
    payload
  );
  return data;
}

export async function fetchLanguagesForResponseAI() {
  const { data } =
    await $assApi.get<GetLanguagesApiInboxOrganizationIdResponseAiGetLanguagesGetResponse>(
      `${INBOX_BASE_PATH}/response-ai/get-languages`
    );
  return data.map(({ language, name }) => ({
    value: language,
    label: name ?? '',
  })) as LanguageItem[];
}

export async function fetchResponseAIProfile() {
  const { data } = await $assApi.get<UserProfileData>(`${INBOX_BASE_PATH}/response-ai/get-profile`);
  return data;
}

export async function editProfile(profileData: Profile_Input) {
  const { data } = await $assApi.put<Profile_Output>(
    `${INBOX_BASE_PATH}/response-ai/edit-profile`,
    profileData
  );
  return data;
}

let abortController: AbortController | undefined;

export const newAbortController = () => {
  if (abortController) {
    abortController = undefined;
  }
  abortController = new AbortController();
};

export const resetAbortController = () => {
  if (abortController) {
    abortController.abort();
  }
  abortController = undefined;
};

export async function generateResponseAI(payload: GenerateResponseIn) {
  const { data } = await $assApi.post<ResponseResult>(
    `${INBOX_BASE_PATH}/response-ai/generate-response`,
    payload,
    {
      signal: abortController?.signal,
    }
  );
  return data;
}

export async function saveResponseAIFeedback(payload: ResponseAIFeedback) {
  const { data } = await $assApi.patch(`${INBOX_BASE_PATH}/response-ai/update-feedback`, payload);
  return data;
}
