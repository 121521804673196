import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  table: {
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
    },
    '& .MuiDataGrid-main': {
      marginTop: {
        xs: '44px',
        sm: 0,
      },
    },
  },
  deleteButton: { color: 'rgba(0, 0, 0, 0.56)' },
  avatar: { mr: 2.5 },
  actionCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
    ml: 2.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  actionCellMenuItem: {
    minWidth: '220px',
  },
  actionCellMenuItemBody: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  actionCellMenuItemIcon: {
    color: 'action.active',
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    height: 0,
    zIndex: 2,
  },
  tableContainer: {
    minHeight: 0,
  },
  renderCell: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default styles;
