import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  header: {
    paddingBlock: 1,
    paddingInlineStart: 2,
    paddingInlineEnd: 3,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.56)',
      height: '14px',
      padding: 0.5,
    },
  },
  popper: {
    width: 'calc(100% - 16px)',
    marginRight: '8px !important',
    zIndex: 1,
  },
};

export default styles;
