import { useEffect, useState } from 'react';

import { type BasicEntity, DEFAULT_SORT_KEY, useInfiniteEntities } from '@trustyou/shared';

import { MAX_ENTITIES } from '../../../constants';

export function useEntityFilterSearch() {
  const [searchKey, setSearchKey] = useState('');
  const [entities, setEntities] = useState<BasicEntity[]>([]);

  const infiniteEntitiesQuery = useInfiniteEntities(MAX_ENTITIES, DEFAULT_SORT_KEY, searchKey);

  useEffect(() => {
    setEntities(infiniteEntitiesQuery.data?.pages.map((page) => page.data).flat() || []);
  }, [infiniteEntitiesQuery.data]);

  return {
    entities,
    setSearchKey,
    infiniteEntitiesQuery,
  };
}
