import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: {
    height: '100%',
    textAlign: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    '> iframe': {
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.12) !important',
    },
  },
};

export default styles;
