import type { IStyles } from '@trustyou/shared';


const styles: IStyles = {
  nameField: {
    '.MuiInputBase-root': {
      width: '200px',
    },
  },
};

export default styles;