import { useIntl } from 'react-intl';

import { reasonMessages } from './messages';

// TODO: Remove this hook once the backend exposes translations for the moderation reasons
export function useReasonOptions() {
  const intl = useIntl();

  const options = [
    {
      label: intl.formatMessage(reasonMessages.inappropriateContent),
      value: 'inappropriate_content',
    },
    {
      label: intl.formatMessage(reasonMessages.sensitiveContent),
      value: 'sensitive_content',
    },
    {
      label: intl.formatMessage(reasonMessages.testReview),
      value: 'test_review',
    },
    {
      label: intl.formatMessage(reasonMessages.fraudulentOrFakeReview),
      value: 'fraudulent_or_fake_review',
    },
    {
      label: intl.formatMessage(reasonMessages.reviewWrittenByEmployees),
      value: 'review_written_by_employees',
    },
    {
      label: intl.formatMessage(reasonMessages.mistakeOrErroneousReview),
      value: 'mistaken_or_erroneous_review',
    },
    {
      label: intl.formatMessage(reasonMessages.other),
      value: 'other',
    },
  ];

  return options;
}
