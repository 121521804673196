import { useEffect } from 'react';

import { useInboxStore, useResponsive } from '@trustyou/shared';

export function useResponsiveInbox() {
  const { dataGrid, setDataGrid } = useInboxStore();
  const { isPhone, isTablet, isMobile } = useResponsive();

  const isDesktop = !isPhone && !isTablet;

  useEffect(() => {
    if (isPhone) {
      setDataGrid({
        ...dataGrid,
        columns: {
          columnVisibilityModel: {
            id: false,
            author: false,
            source: false,
            date: false,
            entity: false,
          },
        },
      });
    } else if (isTablet) {
      setDataGrid({
        ...dataGrid,
        columns: {
          columnVisibilityModel: {
            id: false,
            author: false,
          },
        },
      });
    } else {
      setDataGrid({
        ...dataGrid,
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhone, isTablet]);

  return {
    isPhone,
    isTablet,
    isMobile,
    isDesktop,
  };
}
