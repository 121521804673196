export type Font = {
  name: string;
  fontFamily: string;
};

export const sansSerifFonts: Font[] = [
  { name: 'Anton', fontFamily: 'Anton, sans-serif' },
  { name: 'Fira Sans', fontFamily: '"Fira Sans", sans-serif' },
  { name: 'Inter', fontFamily: 'Inter, sans-serif' },
  { name: 'Lato', fontFamily: 'Lato, sans-serif' },
  { name: 'Monda', fontFamily: 'Monda, sans-serif' },
  { name: 'Montserrat', fontFamily: 'Montserrat, sans-serif' },
  { name: 'Noto Sans', fontFamily: '"Noto Sans", sans-serif' },
  { name: 'Open Sans', fontFamily: '"Open Sans", sans-serif' },
  { name: 'Oswald', fontFamily: 'Oswald, sans-serif' },
  { name: 'Poppins', fontFamily: 'Poppins, sans-serif' },
  { name: 'Roboto', fontFamily: 'Roboto, sans-serif' },
  { name: 'Work Sans', fontFamily: '"Work Sans", sans-serif' },
];

export const serifFonts: Font[] = [
  { name: 'Bitter', fontFamily: 'Bitter, serif' },
  { name: 'Cantata One', fontFamily: '"Cantata One", serif' },
  { name: 'Cardo', fontFamily: 'Cardo, serif' },
  { name: 'Crimson Text', fontFamily: '"Crimson Text", serif' },
  { name: 'EB Garamond', fontFamily: '"EB Garamond", serif' },
  { name: 'IBM Plex Serif', fontFamily: '"IBM Plex Serif", serif' },
  { name: 'Lora', fontFamily: 'Lora, serif' },
  { name: 'Merriweather', fontFamily: 'Merriweather, serif' },
  { name: 'Playfair Display', fontFamily: '"Playfair Display", serif' },
  { name: 'Source Serif 4', fontFamily: '"Source Serif 4", serif' },
  { name: 'Unna', fontFamily: 'Unna, serif' },
  { name: 'Vollkorn', fontFamily: 'Vollkorn, serif' },
];

const defaultFont: Font = { name: 'Roboto', fontFamily: 'Roboto, sans-serif' };

export function getFontFamily(name?: string) {
  const font = sansSerifFonts.find((font) => font.name === name);
  if (font) {
    return font.fontFamily;
  }

  const serifFont = serifFonts.find((font) => font.name === name);
  if (serifFont) {
    return serifFont.fontFamily;
  }

  return defaultFont.fontFamily;
}
