import { BROWSER_LANGUAGES } from '../constants';

export function getInitialLocale() {
  const browserLocale = navigator.language.split('_')[0];
  return BROWSER_LANGUAGES[browserLocale] || 'en-US';
}

// RTL stands for Right-to-Left
export function isRTL(locale: string) {
  return [
    'ar', // Arabic
    'he', // Hebrew
    'fa', // Persian
  ].includes(locale);
}
