import { FormattedMessage, useIntl } from 'react-intl';

import { green, orange, red } from '@mui/material/colors';
import { Box, Tooltip, Typography } from '@trustyou/ui';

import type { SemaMatchesSchema } from '../../../client';
import { useSemanticCategoryItems } from '../../../hooks';

const SENTIMENT_COLORS = {
  n: {
    backgroundColor: red[50],
    color: red[900],
  },
  p: {
    backgroundColor: green[50],
    color: green[900],
  },
  o: {
    backgroundColor: orange[50],
    color: orange[900],
  },
};

type HighlightedSpanProps = {
  text: string;
  semaMatches: SemaMatchesSchema;
};

export function TextWithHighlights({ text, semaMatches }: HighlightedSpanProps) {
  if (!text) {
    return (
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage id="inbox.no-summary" defaultMessage="No summary" />
      </Typography>
    );
  }

  return (
    <Typography
      variant="body2"
      sx={{
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
      }}
    >
      <SpansWithHighlights text={text} semaMatches={semaMatches} />
    </Typography>
  );
}

function SpansWithHighlights({ text, semaMatches }: HighlightedSpanProps) {
  const intl = useIntl();
  const { getCategoriesForText } = useSemanticCategoryItems();

  const uniqueOpinions = Array.from(
    new Map(
      semaMatches?.opinions?.map((opinion) => [
        `${opinion.char_start}-${opinion.char_end}`,
        opinion,
      ])
    ).values()
  ).sort((a, b) => a.char_start - b.char_start);

  const elements = [];
  let lastIndex = 0;

  if (text) {
    uniqueOpinions.forEach((opinion, index) => {
      if (opinion.char_start > lastIndex) {
        elements.push(
          <span key={`text-${index}`}>{text.substring(lastIndex, opinion.char_start)}</span>
        );
      }
      const category = getCategoriesForText(opinion.text);

      if (category) {
        const sentimentColors = SENTIMENT_COLORS[opinion.sentiment] || 'inherit';
        elements.push(
          <Tooltip
            key={`tooltip-${index}`}
            placement="top"
            arrow
            title={`${intl.formatMessage({
              id: 'inbox.common.related-to',
              defaultMessage: 'Related to',
            })}: ${category}`}
          >
            <Box
              component="span"
              style={{
                display: 'inline',
                WebkitBoxDecorationBreak: 'clone',
                boxDecorationBreak: 'clone',
                paddingInline: '4px',
                borderRadius: '4px',
                backgroundColor: sentimentColors.backgroundColor,
                color: sentimentColors.color,
              }}
            >
              {text.substring(opinion.char_start, opinion.char_end)}
            </Box>
          </Tooltip>
        );
      } else {
        elements.push(
          <Box component="span" key={`text-${index}-nocategory`}>
            {text.substring(opinion.char_start, opinion.char_end)}
          </Box>
        );
      }
      lastIndex = opinion.char_end;
    });

    if (lastIndex < text.length) {
      elements.push(
        <Box component="span" key="text-end">
          {text.substring(lastIndex)}
        </Box>
      );
    }
  }

  return elements;
}
