import { useState } from 'react';
import { useIntl } from 'react-intl';

import { commonFields } from '@trustyou/shared';
import { Button, DeleteModal, snackbar } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';
import { useDeleteReport } from '../../service/hooks';
import styles from './styles';

type Props = {
  reportId: string;
  onDeleted: () => void;
};

export const DeleteReport = ({ reportId, onDeleted }: Props) => {
  const intl = useIntl();
  const deleteReport = useDeleteReport();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} color="error" sx={styles.deleteButton}>
        {intl.formatMessage(commonMessages.deleteReport)}
      </Button>
      <DeleteModal
        fullWidth
        maxWidth="xs"
        cancelText={intl.formatMessage(commonFields.cancel)}
        content={intl.formatMessage(commonMessages.deleteReportContent)}
        dataName="report-delete"
        deleteText={intl.formatMessage(commonMessages.delete)}
        onClose={() => setIsOpen(false)}
        onDelete={() => {
          deleteReport.mutate(reportId, {
            onSuccess: () => {
              snackbar.info(intl.formatMessage(commonMessages.deleteReportSuccess));
              onDeleted();
            },
          });
        }}
        title={intl.formatMessage(commonMessages.deleteReportTitle)}
        open={isOpen}
      />
    </>
  );
};
