import {
  type UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { FETCH_USERS_BY_ORGANIZATION, type PaginationRequest } from '@trustyou/shared';

import {
  DELETE_REPORT_MUTATION_KEY,
  MODIFY_REPORT_MUTATION_KEY,
  SCHEDULE_REPORT_MUTATION_KEY,
} from './constants';
import { reportQueryKeys } from './reportsQueryKeys';

import type {
  NewReportSchema,
  NewReportSchemaResponse,
  ReportStatusHistorySchema,
} from '../NewReportSchema';
import type { ReportResponse } from '../service/FetchReportsSchema';
import {
  deleteReportById,
  fetchReportById,
  fetchReports,
  fetchStatusHistoryByReportId,
  fetchUsersByOrganization,
  modifyReportById,
  scheduleReport,
} from '../service/reports';

export function useCreateScheduledReport() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      dashboardId,
      form,
    }: {
      dashboardId: string;
      form: Omit<NewReportSchema, 'dashboard_id' | 'emails'>;
    }) => scheduleReport(dashboardId, form),
    mutationKey: [SCHEDULE_REPORT_MUTATION_KEY],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.lists() });
    },
  });
}

export const reportsColumnToFieldMap: Record<string, string> = {
  name: 'report_name',
  createdAt: 'created_at',
} as const;

export type SortFields = 'report_name' | 'created_at';
export type SortOrder = 'asc' | 'desc';

type SortOption = { field: SortFields; order: SortOrder };

export type ReportTableView = {
  id: number;
  report: ReportResponse;
};

export function useUsersByOrganization(
  pagination: PaginationRequest,
  sortKey: string,
  searchKey?: string
) {
  return useQuery({
    queryKey: [FETCH_USERS_BY_ORGANIZATION, { pagination, sortKey, searchKey }],
    queryFn: () => fetchUsersByOrganization(pagination, sortKey, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
    placeholderData: keepPreviousData,
  });
}

export const useFetchPaginatedReports = ({
  page,
  pageSize,
  sort,
}: {
  page: number;
  pageSize: number;
  sort: SortOption;
}): UseQueryResult<{ count: number; reports: ReportTableView[] }> => {
  return useQuery({
    queryKey: [...reportQueryKeys.lists(), page, pageSize, sort],
    queryFn: async ({ signal }) => {
      const fetchReportsResponse = await fetchReports(
        {
          limit: pageSize,
          skip: (page - 1) * pageSize,
          sort_field: sort.field,
          sort_order: sort.order,
        },
        { signal }
      );
      return {
        count: fetchReportsResponse.meta.totalRecords,
        reports: fetchReportsResponse.data.map((report, index) => {
          return {
            id: index,
            report,
          };
        }),
      };
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

export const useFetchReportByIdLazy = (id?: string): UseQueryResult<NewReportSchemaResponse> => {
  return useQuery({
    queryKey: [...reportQueryKeys.details(), id],
    queryFn: async ({ signal }) => {
      const fetchReportByIdResponse = await fetchReportById(id as string, { signal });
      return fetchReportByIdResponse;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

export const useFetchStatusHistoryForReport = (
  id: string
): UseQueryResult<ReportStatusHistorySchema> => {
  return useQuery({
    queryKey: [...reportQueryKeys.statusById(id), { id }],
    queryFn: async () => {
      const fetchReportByIdResponse = await fetchStatusHistoryByReportId(id);
      return fetchReportByIdResponse.data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });
};

export function useDeleteReportById() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteReportById(id),
    mutationKey: [DELETE_REPORT_MUTATION_KEY],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: reportQueryKeys.lists() });
    },
  });
}

export const useModifyReportById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, report }: { id: string; report: Omit<NewReportSchema, 'emails'> }) =>
      modifyReportById(id, report),
    mutationKey: [MODIFY_REPORT_MUTATION_KEY],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: reportQueryKeys.lists() });
    },
  });
};
