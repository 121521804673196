import { FormattedMessage } from 'react-intl';

import { Typography } from '@trustyou/ui';
import dayjs from 'dayjs';

export type LastUpdateTimestampProps = {
  lastProducedAt: string;
};

export function LastUpdateTimestamp({ lastProducedAt }: LastUpdateTimestampProps) {
  return (
    <Typography variant="caption" color="text.secondary">
      <FormattedMessage
        id="inbox.reviews-last-update"
        defaultMessage="Last update: {lastUpdate} UTC"
        values={{
          lastUpdate: dayjs(lastProducedAt).format('DD.MM.YYYY, HH:mm'),
        }}
      />
    </Typography>
  );
}
