import type { BoxProps, TooltipProps } from '@mui/material';
import { Box, Tooltip } from '@trustyou/ui';

type CustomTooltipProps = {
  title: TooltipProps['title'];
  sx?: BoxProps['sx'];
  children: BoxProps['children'];
};

export function CustomTooltip({ title, sx, children }: CustomTooltipProps) {
  return (
    <Tooltip arrow placement="top" title={title}>
      <Box sx={sx}>{children}</Box>
    </Tooltip>
  );
}
