import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { AutocompleteValue } from '@mui/material';
import { Autocomplete, TextField } from '@trustyou/ui';

import { submissionMessages } from '../../../i18n-submission-ui-subset/messages';
import type { SelectQuestionProps } from '../question-types';
import type { Option } from '../types';

export type DropdownSingleProps = SelectQuestionProps;

export function DropdownSingle({ name, control, options }: DropdownSingleProps) {
  const intl = useIntl();
  const { field } = useController({ name, control });
  const [selectedOption, setSelectedOption] = useState<Option | null | undefined>(
    field.value ? options.find((item) => item.value === field.value[0]) : null
  );

  const handleChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<Option, false, false, false>
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    field.onChange([value!.value]);
    setSelectedOption(value);
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={options}
      getOptionLabel={(option) => option.label as string}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={intl.formatMessage(submissionMessages.questionDropdownPlaceholder)}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id ?? (option.label as string)}>
          {option.label}
        </li>
      )}
      value={selectedOption}
      onChange={handleChange}
    />
  );
}
