import { useRef, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { faCaretDown } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { isTemporaryHidden, useChangelingStore, useManageUploadEntities } from '@trustyou/shared';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  StyledFontAwesomeIcon as Icon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@trustyou/ui';

import { useShowAddEntities } from '../../hooks';
import styles from './styles';

const actionMessages = defineMessages({
  import: {
    id: 'organization.action.importEntities',
    defaultMessage: 'Import entities',
  },
  add: {
    id: 'organization.action.addEntity',
    defaultMessage: 'Add entity',
  },
});

export enum ManageEntitiesType {
  IMPORT = 'import',
  ADD = 'add',
}

const DEFAULT_ENTITY_BUTTONS = [ManageEntitiesType.ADD, ManageEntitiesType.IMPORT];

export type ManageEntitiesButtonsProps = {
  buttons?: ManageEntitiesType[];
  onImportEntities?: () => void;
  onAddEntity?: () => void;
  color?: 'primary' | 'white';
  disabled?: boolean;
};

export const ManageEntitiesButtonBlock = ({
  buttons = DEFAULT_ENTITY_BUTTONS,
  onImportEntities,
  onAddEntity,
  color = 'primary',
  disabled = false,
}: ManageEntitiesButtonsProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const { isChangeling } = useChangelingStore();

  const isAddEntityAllowed = useShowAddEntities();
  const isAddEntityHidden = isTemporaryHidden('CXP-customers-add-entity');

  const isImportEntitiesAllowed = useManageUploadEntities();
  const isImportEntitiesHidden = isTemporaryHidden('CXP-customers-import-new-entities-button');

  const getAppliedButtons = () =>
    buttons.filter((type) => {
      switch (type) {
        case ManageEntitiesType.IMPORT:
          return (
            !!onImportEntities && isImportEntitiesAllowed && !isImportEntitiesHidden && isChangeling
          );
        case ManageEntitiesType.ADD:
          return !!onAddEntity && isAddEntityAllowed && !isAddEntityHidden;
        default:
          return false;
      }
    });

  const getButtonVariant = () => (color === 'primary' ? 'contained' : 'outlined');

  const getDataTestId = (action: ManageEntitiesType) => {
    switch (action) {
      case ManageEntitiesType.IMPORT:
        return 'import-entities';
      case ManageEntitiesType.ADD:
        return 'add-entities';
      default:
        return 'unknown-action';
    }
  };

  const getActionFunction = (action: ManageEntitiesType) => {
    switch (action) {
      case ManageEntitiesType.IMPORT:
        return onImportEntities;
      case ManageEntitiesType.ADD:
        return onAddEntity;
      default:
        return undefined;
    }
  };

  const getActionMenuFunction = (action: ManageEntitiesType) => () => {
    const actionFunction = getActionFunction(action);
    if (actionFunction) {
      actionFunction();
    }
    setMenuOpen(false);
  };

  const getActionText = (action: ManageEntitiesType) => {
    switch (action) {
      case ManageEntitiesType.IMPORT:
        return <FormattedMessage {...actionMessages.import} />;
      case ManageEntitiesType.ADD:
        return <FormattedMessage {...actionMessages.add} />;
      default:
        return '';
    }
  };

  const renderActionButton = (action: ManageEntitiesType) => (
    <Button
      data-testid={getDataTestId(action)}
      variant={getButtonVariant()}
      size="medium"
      onClick={getActionFunction(action)}
      disabled={disabled}
    >
      {getActionText(action)}
    </Button>
  );

  const renderActionMenuItem = (action: ManageEntitiesType) => (
    <MenuItem
      key={action}
      data-testid={getDataTestId(action)}
      onClick={getActionMenuFunction(action)}
    >
      <ListItemText>{getActionText(action)}</ListItemText>
    </MenuItem>
  );

  const appliedButtons = getAppliedButtons();

  if (appliedButtons.length === 0) return null;

  if (appliedButtons.length === 1) {
    return (
      <Tooltip
        arrow
        placement="left"
        title={
          disabled ? (
            <FormattedMessage
              id="entity.manage-entities.tooltip"
              defaultMessage="To add more entities, upgrade your plan or remove existing entities."
            />
          ) : (
            ''
          )
        }
      >
        <Box sx={styles.singleButtonWrapper}>{renderActionButton(appliedButtons[0])}</Box>
      </Tooltip>
    );
  }

  const menuItems = appliedButtons.filter((item, index) => index > 0);

  return (
    <>
      <Tooltip
        arrow
        placement="left"
        title={
          disabled ? (
            <FormattedMessage
              id="entity.manage-entities.tooltip"
              defaultMessage="To add more entities, upgrade your plan or remove existing entities."
            />
          ) : (
            ''
          )
        }
      >
        <ButtonGroup variant={getButtonVariant()} sx={styles.buttonGroup} ref={anchorRef}>
          {renderActionButton(appliedButtons[0])}
          <Button
            data-testid="open-manage-entities-menu"
            variant={getButtonVariant()}
            onClick={() => setMenuOpen(true)}
            disabled={disabled}
          >
            <Icon icon={faCaretDown} />
          </Button>
        </ButtonGroup>
      </Tooltip>
      <Popper
        sx={styles.popper}
        open={menuOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper variant="elevation" elevation={8} square={false}>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <MenuList autoFocusItem>
                  {menuItems.map((item) => renderActionMenuItem(item))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
