import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SidebarState = {
  isCollapsed: boolean;
  isTemporarilyExpanded?: boolean;
  expandedNode?: string;
  toggleSidebar: () => void;
  expandTemporarily: () => void;
  setExpandedNode: (node: string) => void;
};

export const useSidebarStore = create<SidebarState>()(
  persist(
    (set) => ({
      isCollapsed: false,
      toggleSidebar: () =>
        set(({ isCollapsed }) => ({ isCollapsed: !isCollapsed, isTemporarilyExpanded: false })),
      expandTemporarily: () => set(() => ({ isCollapsed: false, isTemporarilyExpanded: true })),
      setExpandedNode: (node: string) =>
        set(({ expandedNode }) => ({ expandedNode: expandedNode === node ? undefined : node })),
    }),
    {
      name: `sidebar-storage`,
      partialize: (state) => ({
        isCollapsed: state.isCollapsed,
        expandedNode: state.expandedNode,
      }),
    }
  )
);
