import { useEffect } from 'react';

import {
  initUserlane,
  sendUserlaneData,
  useLanguageStore,
  useMembershipStore,
  useResponsive,
  useScript,
} from '@trustyou/shared';

import useCurrentUser from '../useCurrentUser';

const USERLANE_URL = 'https://cdn.userlane.com/userlane.js';

const useUserlane = () => {
  const { membership, getMembershipDetails } = useMembershipStore();
  const { locale } = useLanguageStore();
  const { currentBreakpoint } = useResponsive();
  const currentUser = useCurrentUser();
  const membershipDetails = getMembershipDetails();
  const userId = currentUser?.id;
  const userData = {
    userRole: membershipDetails?.role,
    productPlan: membershipDetails?.subscription?.plan,
    breakpoint: currentBreakpoint,
  };

  useScript({
    tag: 'userlane-script',
    src: USERLANE_URL,
    enabled: !!membership && !!process.env.REACT_APP_USERLANE_ID && !!currentUser?.id,
    onLoadCallback: () => {
      initUserlane(userId as string, userData, locale);
    },
  });

  useEffect(() => {
    if (userId) {
      sendUserlaneData(userId, userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBreakpoint]);
};

export default useUserlane;
