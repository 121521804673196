import { type Control, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, MenuItem, Select } from '@trustyou/ui';

import { commonMessages } from '../../constants/messages';
import { useFrequencyOptions } from '../../hooks';
import { FormControlLabel } from '../form-control-label/form-control-label';
import styles from './styles';

type Props = {
  control: Control;
};

export const FrequencyFormControl = ({ control }: Props) => {
  const frequencyOptions = useFrequencyOptions();
  return (
    <FormControl sx={styles.formControl}>
      <FormControlLabel>
        <FormattedMessage {...commonMessages.frequency} />
      </FormControlLabel>
      <Controller
        control={control}
        name="frequency"
        render={({ field: { value, onChange } }) => (
          <Select
            aria-labelledby="frequency"
            value={value}
            defaultValue={control._defaultValues.frequency}
            id="frequency"
            onChange={(event) => {
              const frequency = event.target.value;
              onChange(frequency);
            }}
          >
            {frequencyOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};
