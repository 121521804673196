import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, FormControl, MenuItem, Select, Stack } from '@trustyou/ui';

import { DashboardReportList } from './dashboard-report-list';
import { DashboardReportsHistory } from './dashboard-reports-history';

import { commonMessages } from '../../../constants/messages';
import { useHasSchedulePermission } from '../../../hooks';

export const reportType = [
  {
    value: 'list',
    label: <FormattedMessage {...commonMessages.frequencyTypeScheduled} />,
  },
  {
    value: 'sent',
    label: <FormattedMessage {...commonMessages.frequencyTypeOneTime} />,
  },
];

export const DashboardReport = () => {
  const hasSchedulePermission = useHasSchedulePermission();
  const [type, setType] = useState(hasSchedulePermission ? 'list' : 'sent');

  useEffect(() => {
    if (window.location.href.includes('one-time')) {
      setType('sent');
    } else if (window.location.href.includes('scheduled')) {
      setType('list');
    }
  }, []);

  return (
    <Stack spacing={3}>
      {hasSchedulePermission && (
        <Box sx={{ width: 220 }}>
          <FormControl fullWidth>
            <Select value={type} onChange={(value) => setType(value.target.value)}>
              {reportType.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Stack>{type === 'list' ? <DashboardReportList /> : <DashboardReportsHistory />}</Stack>
    </Stack>
  );
};
