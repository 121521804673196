import type { PropsWithChildren } from 'react';

import { Typography, type TypographyProps } from '@mui/material';

type ClampedTypographyProps = TypographyProps & {
  maxLines: number;
};

export function ClampedTypography({
  maxLines,
  children,
  sx,
  ...rest
}: PropsWithChildren<ClampedTypographyProps>) {
  return (
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // "webkit" prefixes enable support for Safari
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
