import type { InboxStatusEnum } from '../client';

export const REVIEW_SCORE_DENOMINATOR = 10;
export const STATUS_CHANGE_REFETCH_INTERVAL_MS = 1000;
export const TRUSTYOU_SURVEY_DOMAIN_NAME = 'TrustYou Survey';
export const TRUSTYOU_SURVEY_DOMAIN_ID = '60d911a8-3501-48b8-810c-7127e1d33c3b';
export const MARK_AS_DELETED_THRESHOLD_IN_DAYS = 75;
export const NON_SELECTABLE_REVIEW_STATUSES: InboxStatusEnum[] = ['deleted', 'marked_as_deleted'];
export const MARKED_AS_RESPONDED_WITH_AN_EMPTY_TEXT = '[marked as responded with an empty text]';
export const USER_PLACEHOLDER = '[unknown user]';
export const AUTHOR_PLACEHOLDER = '[unknown author]';
export const REQUESTOR_PLACEHOLDER = '[unknown requestor]';
export const APPROVER_PLACEHOLDER = '[unknown approver]';
