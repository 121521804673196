import { useIntl } from 'react-intl';

import { TENTATIVE_WAITING_TIME_IN_MINUTES } from '../constants/organization';

export function useDashboardDelayTimeMessage(delay?: number) {
  const intl = useIntl();

  return intl.formatMessage(
    {
      id: 'notifications.dashboardDelay.time',
      defaultMessage: 'up to {minutes} minutes',
    },
    {
      minutes: delay || TENTATIVE_WAITING_TIME_IN_MINUTES,
    }
  );
}

export function useDashboardDelayMessage(delay?: number) {
  const intl = useIntl();
  const dashboardDelayTimeMessage = useDashboardDelayTimeMessage(delay);

  return intl.formatMessage(
    {
      id: 'notifications.dashboardDelay',
      defaultMessage: 'It might take {delay} for this change to reflect on your dashboard.',
    },
    {
      delay: dashboardDelayTimeMessage,
    }
  );
}
