import { defineMessages, useIntl } from 'react-intl';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const messages = defineMessages({
  title: {
    id: 'inbox.leave.dialog.title',
    defaultMessage: 'Leave and discard draft?',
  },
  content: {
    id: 'inbox.leave.dialog.content',
    defaultMessage: 'Your changes will be lost if you leave this page.',
  },
  cancel: {
    id: 'inbox.action.cancel',
    defaultMessage: 'Cancel',
  },
  discard: {
    id: 'inbox.action.discard',
    defaultMessage: 'Discard',
  },
});

type ConfirmDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  content?: string;
};

export function ConfirmDialog({ open, onConfirm, onCancel, title, content }: ConfirmDialogProps) {
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title ?? intl.formatMessage(messages.title)}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 1 }}>
        <Typography sx={{ paddingTop: 1 }}>
          {content ?? intl.formatMessage(messages.content)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          {intl.formatMessage(messages.discard)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
