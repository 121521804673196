import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { CONNECT_PROVIDER_NAMES } from '@trustyou/shared';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  StyledFontAwesomeIcon,
  Typography,
  reviewProvidersLogo,
} from '@trustyou/ui';
import { capitalize } from 'lodash';

type ReviewProviderCardProps = {
  title: string;
  id: string;
  connectedAPIs?: number;
  isConfiguring?: boolean;
  isConnectEnabled?: boolean;
  handleConnectProvider: (name: string) => void;
};

export function ReviewProviderCard({
  id,
  title,
  connectedAPIs = 0,
  isConfiguring,
  isConnectEnabled,
  handleConnectProvider,
}: ReviewProviderCardProps) {
  const navigate = useNavigate();

  const isConnected = isConnectEnabled && connectedAPIs > 0;

  const handleNavigationToConnectedProvider = () => {
    if (isConnectEnabled && connectedAPIs > 0)
      navigate(id, { state: { header: { id, message: capitalize(title) } } });
  };

  const renderActionOrStatus = () => {
    const isBookingConnectEnabled =
      connectedAPIs === 0 && title === CONNECT_PROVIDER_NAMES.BOOKING && isConnectEnabled;
    const isGoogleConnectEnabled =
      connectedAPIs === 0 && title === CONNECT_PROVIDER_NAMES.GOOGLE && isConnectEnabled;
    if (isConfiguring)
      return (
        <Chip
          label={
            <FormattedMessage id="reviewProvider.card.configuring" defaultMessage="Configuring" />
          }
          variant="pastelWarning"
          size="small"
        />
      );
    if (isBookingConnectEnabled)
      return (
        <Button
          variant="outlined"
          onClick={() => handleConnectProvider(CONNECT_PROVIDER_NAMES.BOOKING)}
        >
          <FormattedMessage id="common.connect" defaultMessage="Connect" />
        </Button>
      );
    if (isGoogleConnectEnabled)
      return (
        <Button
          variant="outlined"
          onClick={() => handleConnectProvider(CONNECT_PROVIDER_NAMES.GOOGLE)}
        >
          <FormattedMessage id="common.connect" defaultMessage="Connect" />
        </Button>
      );
    if (connectedAPIs > 0)
      return (
        <Chip
          label={
            connectedAPIs > 1 ? (
              <FormattedMessage
                id="reviewProvider.card.apiConnectionsCount"
                defaultMessage="{count} API connections"
                values={{ count: connectedAPIs }}
              />
            ) : (
              <FormattedMessage
                id="reviewProvider.card.connectedAPI"
                defaultMessage="Connected To API"
              />
            )
          }
          variant="pastelInfo"
          size="small"
        />
      );
    return null;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        padding: 1,
        ...(isConnected && {
          cursor: 'pointer',
          transition: 'all 150ms ease-in-out',
          ':hover': {
            translate: '0 -4px',
            boxShadow: 4,
          },
        }),
        '& .MuiCardContent-root:last-child': {
          paddingBlockEnd: 2,
        },
      }}
      onClick={handleNavigationToConnectedProvider}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Avatar variant="rounded" src={reviewProvidersLogo[title] || reviewProvidersLogo.default} />
        {renderActionOrStatus()}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1" color="text.primary" sx={{ marginBlockStart: 1 }}>
          {title}
        </Typography>
        {isConnected && (
          <StyledFontAwesomeIcon icon={faArrowRight} size="lg" sx={{ color: 'primary.main' }} />
        )}
      </Box>
    </Card>
  );
}
