import type { GridSortModel } from '@mui/x-data-grid-pro';

export const DEFAULT_PAGINATION_MODEL = {
  pageSize: 10,
  page: 0,
};

export const DEFAULT_SORT_MODEL: GridSortModel = [{ field: 'name', sort: 'asc' }];

export const ENTITY_GLOBAL_ATTRIBUTES = [
  'name',
  'brand',
  'sector',
  'type',
  'country',
  'continent',
  'city',
  'zip',
  'address',
];

export const DEFAULT_SORT_KEY = 'name';
export const DEFAULT_PAGE_SIZE = 15;
export const TENTATIVE_WAITING_TIME_IN_MINUTES = 60;

export const SEGMENT_NAME_MAX_LENGTH = 50;
