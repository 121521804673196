import { FormattedMessage } from 'react-intl';

import { Alert } from '@trustyou/ui';

export function GDPRAlert() {
  return (
    <Alert severity="warning">
      <FormattedMessage
        id="inbox.response.gdpr.info"
        defaultMessage="This is a <bold>public response</bold>. Do not use <bold>personal data</bold>, in particular the guest’s name or contact details."
        values={{
          bold: (str) => <b>{str}</b>,
        }}
      />
    </Alert>
  );
}
