import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  StyledFontAwesomeIcon as Icon,
  Tooltip,
} from '@trustyou/ui';

import { manageDrawer } from '../../constants/messages/customAttributes';
import { styles } from './styles';

export const MandatoryController = () => {
  const { control } = useFormContext();
  return (
    <FormControl sx={styles.checkboxControllerWrapper}>
      <Controller
        name="mandatory"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={<FormattedMessage {...manageDrawer.mandatoryLabel} />}
            sx={styles.checkboxFormControlLabel}
          />
        )}
      />
      <Tooltip placement="top" arrow title={<FormattedMessage {...manageDrawer.mandatoryInfo} />}>
        <Icon icon={faInfoCircle} sx={{ color: 'text.secondary' }} />
      </Tooltip>
    </FormControl>
  );
};
