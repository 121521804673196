import { isValidElement, useState } from 'react';
import { useController } from 'react-hook-form';

import { Asterisk, Checkbox, FormControlLabel, FormGroup, Typography } from '@trustyou/ui';
import DOMPurify from 'dompurify';

import type { BooleanQuestionProps } from '../question-types/boolean/boolean-question';
import { makeLinksOpenInNewTab } from '../utils';

export type CheckboxQuestionProps = BooleanQuestionProps;

export const CheckboxSingle = ({
  name,
  control,
  options,
  showMandatoryAsterisk,
  isLegalCheckbox,
  errors,
}: CheckboxQuestionProps) => {
  const { field } = useController({ name, control });
  const [checked, setChecked] = useState(field.value ?? false);
  const uniqueOption = options[0];
  const label = uniqueOption?.label;
  const sanitizedHtml = DOMPurify.sanitize(label as string | Node);
  const modifiedHtml = makeLinksOpenInNewTab(sanitizedHtml);

  const errorType = errors?.[name]?.type;

  //TODO: This needs to be checked in sometime to find better way
  const showMandatoryErrorForLegalCheckbox =
    (errorType === 'optionality' || errorType === 'nullable') && isLegalCheckbox;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.checked);
    setChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={
          <>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: showMandatoryErrorForLegalCheckbox ? 'error.main' : null }}
              dangerouslySetInnerHTML={{ __html: modifiedHtml }}
            />
            {showMandatoryAsterisk && <Asterisk />}
          </>
        }
      />
    </FormGroup>
  );
};
