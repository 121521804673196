import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@trustyou/ui';
import { groupBy, orderBy, uniqBy } from 'lodash';

import { type GroupedItem, type Item, ListItem } from './list-item';

import { commonMessages } from '../../constants/messages';
import styles from './styles';

type ItemSelectorProps = {
  items: Item[];
  selected: string[];
  onSelect: (values: string[]) => void;
};

export const GroupedItemSelector = ({ items, selected, onSelect }: ItemSelectorProps) => {
  const [groupExpanded, setGroupExpanded] = useState<Record<string, boolean>>({});

  const toggleGroupExpand = (group: string) => {
    setGroupExpanded((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  // TODO: Move this grouping logic to BE.
  const orderedData = orderBy(items, 'order');
  const groupedData = groupBy(orderedData, 'group');
  const uniqData = uniqBy(orderedData, 'group').map((item) => ({
    ...item,
    groupItems: (item.group && groupedData[item.group]) || undefined,
  }));
  const processedData: GroupedItem[] = [
    ...uniqData.filter((row) => row.groupItems),
    ...orderedData.filter((row) => !row.group),
  ];
  const orderedProcessedData = orderBy(processedData, 'order');

  const isSelected = (item: GroupedItem) => {
    if (item.groupItems) {
      return item.groupItems.map((row) => row.id).every((id) => selected.includes(id));
    }
    return selected.includes(item.id);
  };

  const toggleSelect = (item?: GroupedItem) => {
    if (!item) {
      onSelect(selected.length === items.length ? [] : items.map((row) => row.id));
    } else if (item.groupItems) {
      const togglingItems = item.groupItems.map((row) => row.id);
      onSelect(
        togglingItems.every((id) => selected.includes(id))
          ? selected.filter((id) => !togglingItems.includes(id))
          : [...selected, ...togglingItems]
      );
    } else {
      onSelect(
        selected.includes(item.id)
          ? selected.filter((id) => id !== item.id)
          : [...selected, item.id]
      );
    }
  };

  return (
    <List sx={styles.list}>
      <ListItemButton sx={styles.listTopItemButton} key="select-all" onClick={() => toggleSelect()}>
        <ListItemIcon>
          <Checkbox checked={items.length === selected.length} />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage {...commonMessages.selectAll} />} />
      </ListItemButton>
      {orderedProcessedData.map((item) => (
        <Fragment key={item.id}>
          <ListItem
            item={item}
            isSelected={isSelected(item)}
            groupExpanded={!!item.group && groupExpanded[item.group]}
            onToggle={() => toggleSelect(item)}
            onToggleExpand={() => toggleGroupExpand(item.group as string)}
          />
          {item.groupItems && (
            <Collapse in={!!groupExpanded[item.group as string]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.groupItems.map((subItem) => (
                  <ListItem
                    key={subItem.id}
                    isSubItem
                    item={subItem}
                    isSelected={isSelected(subItem)}
                    onToggle={() => toggleSelect(subItem)}
                  />
                ))}
              </List>
            </Collapse>
          )}
        </Fragment>
      ))}
    </List>
  );
};
