import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { styled } from '@mui/material';
import { Alert, Stack, Typography } from '@trustyou/ui';

import { TRUSTYOU_SURVEY_DOMAIN_NAME } from '../../../../constants';
import { useReview } from '../../../../hooks';
import { SurveyResponse } from './survey-response';

const GreyInfoAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '& .MuiAlert-icon': {
    color: theme.palette.text.secondary,
  },
}));

export function ExistingResponse() {
  const intl = useIntl();
  const publicResponseHeading = intl.formatMessage({
    id: 'inbox.response.survey.heading.public',
    defaultMessage: 'Public response',
  });
  const privateResponseHeading = intl.formatMessage({
    id: 'inbox.response.survey.heading.private',
    defaultMessage: 'Email response',
  });
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const response = reviewRoot?.response;
  const isSurvey = reviewRoot?.review.domain_name === TRUSTYOU_SURVEY_DOMAIN_NAME;

  const hasPublicText = !!reviewRoot?.response?.text;
  const publicResponseText = reviewRoot?.response?.text;

  const hasPrivateText = !!reviewRoot?.response?.private_text;
  const privateResponseText = reviewRoot?.response?.private_text;

  if (!response) return null;

  return (
    <Stack spacing={2} sx={{ padding: 2 }}>
      {reviewRoot?.meta.status === 'responded' && (
        <GreyInfoAlert severity="info">
          <FormattedMessage
            id="inbox.response.info.notYetConfirmed"
            defaultMessage="This was your drafted response when you clicked on “Respond”, or you manually marked the review as responded. We'll update it with the actual text once confirmed with the OTA."
          />
        </GreyInfoAlert>
      )}
      {isSurvey ? (
        <Stack spacing={3}>
          {hasPublicText && (
            <SurveyResponse heading={publicResponseHeading} responseText={publicResponseText} />
          )}
          {hasPrivateText && (
            <SurveyResponse heading={privateResponseHeading} responseText={privateResponseText} />
          )}
        </Stack>
      ) : (
        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
          {response.text || (
            <FormattedMessage
              id="inbox.response.info.manuallyMarkedAsResponded"
              defaultMessage="This review was manually marked as responded."
            />
          )}
        </Typography>
      )}
    </Stack>
  );
}
