import { useIntl } from 'react-intl';

import { snackbar } from '@trustyou/ui';
import { DateTime } from 'luxon';

import { ReportForm } from './ReportForm';
import { useCreateScheduledReport } from './hooks/reports';

export const NewReport = ({
  dashboardId,
  onSuccess,
  onCancel,
  enabledFormats,
}: {
  dashboardId: string;
  onSuccess: () => void;
  onCancel: () => void;
  enabledFormats?: string[];
}) => {
  const intl = useIntl();
  const createScheduledReport = useCreateScheduledReport();

  return (
    <ReportForm
      dashboardId={dashboardId}
      enabledFormats={enabledFormats}
      isLoading={createScheduledReport.isPending}
      onCancel={onCancel}
      onSubmit={(form) => {
        const timeOfDay = DateTime.fromISO(form.timeOfDay);
        const dayOfMonth = form.dayOfMonth ? form.dayOfMonth : null;
        createScheduledReport.mutate(
          {
            dashboardId,
            form: {
              disable: false,
              user_ids: form.recipients.map((recipient) => recipient.value).join(','),
              report_name: form.name,
              hour: timeOfDay.hour,
              period: form.frequency,
              day_of_week: form.dayOfWeek ? form.dayOfWeek - 1 : null,
              day: dayOfMonth,
              timezone: timeOfDay.zone.name,
              file_format: form.fileFormat,
              dashboard_filters: form.dashboardFilters,
              excluded_filters: form.excludedFilters,
              language: form.language,
              widget_id: '',
            },
          },
          {
            onSuccess: () => {
              snackbar.success(
                intl.formatMessage({
                  id: 'analytics.reports.new.schedule-success',
                  defaultMessage: 'Report scheduled successfully',
                })
              );
              onSuccess();
            },
          }
        );
      }}
    />
  );
};
