import { FormattedMessage } from 'react-intl';

import { faPaperPlane, faPencil, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type CombinedUserAndInvitation,
  USER_ACTIONS,
  USER_ROLES,
  USER_STATUSES,
  manageUserActions,
  useResponsive,
} from '@trustyou/shared';
import { ActionCell, type SingleActionProps } from '@trustyou/ui';

import { useCurrentUser, useManageInvitations, useManageUsers } from '../../../../../hooks';

export type UserActionCellProp = {
  user: CombinedUserAndInvitation;
  handleEdit?: (user: CombinedUserAndInvitation) => void;
  handleDelete?: (user: CombinedUserAndInvitation) => void;
  handleResendInvitation?: (user: CombinedUserAndInvitation) => void;
};

const UserActionCell = ({
  user,
  handleEdit,
  handleDelete,
  handleResendInvitation,
}: UserActionCellProp) => {
  const { isMobile } = useResponsive();
  const currentUser = useCurrentUser();
  const actions: SingleActionProps[] = [];

  const isManageUsersAllowed = useManageUsers();
  const isInvitationManageAllowed = useManageInvitations();

  const availableAction = manageUserActions(
    user,
    user.status === USER_STATUSES.ACTIVE ? isManageUsersAllowed : isInvitationManageAllowed,
    currentUser
  );

  if (handleEdit && !isMobile && availableAction !== USER_ACTIONS.READ_ONLY) {
    actions.push({
      testId: 'user-action-edit',
      icon: faPencil,
      handler: () => handleEdit(user),
      message: <FormattedMessage id="organization.users.edit" defaultMessage="Edit" />,
    });
  }

  if (
    handleResendInvitation &&
    !isMobile &&
    user.status === USER_STATUSES.INVITED &&
    (availableAction !== USER_ACTIONS.READ_ONLY || user.role === USER_ROLES.OWNER)
  ) {
    actions.push({
      testId: 'user-action-resend-invitation',
      icon: faPaperPlane,
      handler: () => handleResendInvitation(user),
      message: (
        <FormattedMessage id="organization.users.resendInvite" defaultMessage="Resend invitation" />
      ),
    });
  }

  if (handleDelete && availableAction !== USER_ACTIONS.READ_ONLY) {
    actions.push({
      testId: 'user-action-remove',
      icon: faTrash,
      handler: () => handleDelete(user),
      message: <FormattedMessage id="organization.users.remove" defaultMessage="Remove" />,
    });
  }

  return actions.length ? <ActionCell testId="user-action-menu" actions={actions} /> : null;
};

export default UserActionCell;
