import { FormattedMessage } from 'react-intl';

import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { isTemporaryHidden, useResponsive, useRouteUtils } from '@trustyou/shared';
import { Box, Button, Stack, StyledFontAwesomeIcon } from '@trustyou/ui';

import { Views } from './views';

export function InboxHeader() {
  const { isDesktop } = useResponsive();
  const { navigateWithBase } = useRouteUtils();

  return (
    <Stack
      direction="row"
      sx={{
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Views />
      <Box sx={{ flexGrow: 1 }} />
      {!isTemporaryHidden('CXP-customers-inbox-reports') && (
        <Button
          variant="outlined"
          endIcon={isDesktop && <StyledFontAwesomeIcon icon={faArrowRight} />}
          onClick={() => navigateWithBase('reports/new-report?fromInbox')}
        >
          <FormattedMessage id="inbox.create-report" defaultMessage="Create report" />
        </Button>
      )}
    </Stack>
  );
}
