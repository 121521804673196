import { UPDATE_GROUP_PERMISSION, usePermissionStore, useResponsive } from '@trustyou/shared';

const useManageGroups = () => {
  const { hasPermissions } = usePermissionStore();
  const isManageGroupsAllowed = hasPermissions(UPDATE_GROUP_PERMISSION);
  const { isMobile } = useResponsive();
  return !isMobile && isManageGroupsAllowed;
};

export default useManageGroups;
