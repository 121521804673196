import { useOidc } from '@axa-fr/react-oidc';
import {
  AuthPendingState,
  useAuthorizationStore,
  useChangelingStore,
  useMembershipStore,
  useSisenseLogout,
} from '@trustyou/shared';

export function useCustomerLogout() {
  const { logout: keycloakLogout } = useOidc();
  const { setPendingState, setTokenStore, tokenStore } = useAuthorizationStore();
  const { clearStore, getMembershipsCount } = useMembershipStore();
  const { clearChangelingState, isChangeling } = useChangelingStore();

  const clearAndKeycloakLogout = () => {
    clearChangelingState();
    clearStore();
    setTokenStore(null);
    keycloakLogout(`${process.env.PUBLIC_URL}`);
  };

  const sisenseLogout = useSisenseLogout({ onSettled: clearAndKeycloakLogout });

  const customerLogout = () => {
    setPendingState(AuthPendingState.LOGGING_OUT);
    if (
      /* Disabling logout call in case of localhost with REACT_APP_AUTH = false in .env
         (to make all routes public actually disabling authentication in development) */
      ['localhost', '127.0.0.1'].includes(window.location.hostname) &&
      process.env.REACT_APP_AUTH === 'false'
    ) {
      console.warn('Auth disabled on localhost via REACT_APP_AUTH env');
      return;
    }
    const isStopChangelingMode = isChangeling && !tokenStore;
    const isNoOrganizationUser = getMembershipsCount() === 0 && !tokenStore;
    if (isStopChangelingMode || isNoOrganizationUser) {
      clearAndKeycloakLogout();
    } else {
      // Sisense SSO logout
      sisenseLogout.mutate();
    }
  };

  return customerLogout;
}
