import type { ReactNode } from 'react';

import type { Option } from './types';

export function getLabels(options: Option[]) {
  return options.reduce(
    (previous, current) => {
      previous[Number(current.value)] = current.label;
      return previous;
    },
    {} as { [key: number]: ReactNode }
  );
}

export function makeLinksOpenInNewTab(sanitizedHtml: string) {
  // Create a temporary DOM element to manipulate the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = sanitizedHtml;

  // Find all <a> tags and add target="_blank" and rel="noopener noreferrer"
  const links = tempDiv.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute('target', '_blank');
    links[i].setAttribute('rel', 'noopener noreferrer');
  }

  // Return the modified HTML string
  return tempDiv.innerHTML;
}
