import { useState } from 'react';
import { useController } from 'react-hook-form';

import type { CheckboxProps } from '@mui/material';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@trustyou/ui';

import type { SelectQuestionProps } from '../question-types/select/select-question';
import type { Option } from '../types';

export type CheckboxGroupProps = SelectQuestionProps;

export function CheckboxGroup({ name, control, options }: CheckboxGroupProps) {
  const { field } = useController({ name, control });
  const [selectedValues, setSelectedValues] = useState<Option['value'][]>(field.value ?? []);

  const handleChange: CheckboxProps['onChange'] = (event, checked) => {
    if (!checked) {
      const newCheckedValues = selectedValues.filter((value) => value !== event.target.name);
      field.onChange(newCheckedValues);
      setSelectedValues(newCheckedValues);
      return;
    }
    const newCheckedValues = [...selectedValues, event.target.name];
    field.onChange(newCheckedValues);
    setSelectedValues(newCheckedValues);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.id ?? option.value.toString()}
            control={
              <Checkbox
                checked={selectedValues.includes(option.value)}
                onChange={handleChange}
                name={option.value.toString()}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
