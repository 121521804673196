// https://mui.com/material-ui/react-autocomplete/#country-select
import { useIntl } from 'react-intl';

import { InputLabel, createFilterOptions } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Asterisk, Autocomplete, Box, StyledFormControl, TextField } from '@trustyou/ui';

import { countries } from './countries';

type CountrySelectProps = {
  isMandatory?: boolean;
};

export function CountrySelect({ isMandatory }: CountrySelectProps) {
  const intl = useIntl();
  const { isSmallPhone, isPhone, isTablet } = useResponsive();

  const getMinWidth = () => {
    if (isSmallPhone) return 0;
    if (isPhone) return '50%';
    if (isTablet) return 230;
    return 300;
  };

  return (
    <StyledFormControl sx={{ minWidth: getMinWidth() }}>
      <InputLabel shrink htmlFor="country-select">
        {intl.formatMessage({
          id: 'survey.question.text.phone.label',
          defaultMessage: 'Phone',
        })}
        {isMandatory && <Asterisk />}
      </InputLabel>
      <Autocomplete
        id="country-select"
        size="small"
        options={countries}
        autoHighlight
        getOptionLabel={(option) => `${option.label} (+${option.phone})`}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        disableClearable
        renderOption={(props, option) => {
          // @ts-expect-error Property 'key' does not exist on type 'HTMLAttributes<HTMLLIElement>'.
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label} (+{option.phone})
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
              placeholder: intl.formatMessage({
                id: 'survey.question.text.phone-country-code.placeholder',
                defaultMessage: 'Choose a country',
              }),
            }}
          />
        )}
        filterOptions={createFilterOptions({ stringify: (option) => JSON.stringify(option) })}
      />
    </StyledFormControl>
  );
}
