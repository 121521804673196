import type {
  CreateSegment,
  CreateSegmentResponse,
  IsValidSegmentNameResponse,
  SegmentsResponse,
  UpdateDashboardSegments,
  UpdateSegment,
} from '../../../../types/model/segment';
import type { PaginationRequest } from '../../../../types/model/shared';
import { $assApi } from '../../../api';
import { SEGMENTS_BASE_PATH } from '../../path';

export async function fetchSegments(
  { limit, offset }: PaginationRequest,
  sortKey: string
): Promise<SegmentsResponse> {
  const { data } = await $assApi.get(`${SEGMENTS_BASE_PATH}/get_segments`, {
    params: {
      limit,
      offset,
      order: sortKey,
    },
  });
  return data;
}

export async function isValidSegmentName(name: string): Promise<IsValidSegmentNameResponse> {
  const { data } = await $assApi
    .get(`${SEGMENTS_BASE_PATH}/is_valid_segment_name`, {
      params: {
        name,
      },
    })
    .then((response) => response.data)
    .catch(() => ({ data: { valid: false } }));

  return data;
}

export async function createSegment(segment: CreateSegment): Promise<CreateSegmentResponse> {
  const { data } = await $assApi.post(`${SEGMENTS_BASE_PATH}/create_segment`, segment);
  return data.data;
}

export async function updateSegment(segment: UpdateSegment): Promise<UpdateSegment> {
  const { data } = await $assApi.post(`${SEGMENTS_BASE_PATH}/update_segment`, segment);
  return data.data;
}

export async function deleteSegment(segmentId: string): Promise<string> {
  const { data } = await $assApi.delete(`${SEGMENTS_BASE_PATH}/delete_segment`, {
    params: {
      segment_id: segmentId,
    },
  });
  return data;
}

export async function updateDashboardSegments(
  dashboardSegments: UpdateDashboardSegments
): Promise<string> {
  const { data } = await $assApi.post(
    `${SEGMENTS_BASE_PATH}/set_selected_segments_for_dashboard`,
    dashboardSegments
  );
  return data.data;
}
