import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_ADDABLE_ENTITIES,
  searchPlaceholders,
  useAddableEntities,
} from '@trustyou/shared';
import { ListItemText, Selector } from '@trustyou/ui';

export interface AddEntitySelectorProps {
  selectedEntities: Entity[];
  setSelectedEntities: React.Dispatch<React.SetStateAction<Entity[]>>;
  maxEntitiesAllowed: number;
  areItemsDisabled: boolean;
}

export const AddEntitySelector = ({
  selectedEntities,
  setSelectedEntities,
  maxEntitiesAllowed,
  areItemsDisabled,
}: AddEntitySelectorProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [entities, setEntities] = useState<Entity[]>([]);
  const [searchKey, setSearchKey] = useState('');

  const { data, fetchNextPage, hasNextPage, isPending, isFetchingNextPage } = useAddableEntities(
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_KEY,
    searchKey
  );

  const isLoading = isPending || isFetchingNextPage;

  useEffect(() => {
    setEntities(data?.pages.map((page) => page.data).flat() || []);
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_ADDABLE_ENTITIES] });
  };

  const renderRowContent = (entity: Entity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  return (
    <Selector
      isLoading={isLoading}
      items={entities}
      onSelectItems={setSelectedEntities}
      onSearch={onSearch}
      renderRowContent={renderRowContent}
      selectedItems={selectedEntities}
      searchPlaceholder={intl.formatMessage(searchPlaceholders.searchEntities)}
      selectedHeaderTitle={
        <FormattedMessage
          id="add-entity.selected-entities"
          defaultMessage="{maxEntitiesAllowed, plural,
        one {Selected entity}
        other {Selected entities}
      }"
          values={{ maxEntitiesAllowed }}
        />
      }
      onFetchNextPage={onFetchNextPage}
      areItemsDisabled={areItemsDisabled}
      hideSelectResults
      height="100%"
    />
  );
};
