import { useState } from 'react';

import { faEye } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Dialog, DialogContent, IconButton, StyledFontAwesomeIcon } from '@trustyou/ui';

import { useTextContent } from '../../../hooks/use-text-content';
import type { Content } from '../../../types/content';
import { SurveyMessageContent } from '../../feedback/static-elements';

type ViewIconButtonProps = {
  row: Content;
};

export function ViewIconButton({ row }: ViewIconButtonProps) {
  const [open, setOpen] = useState(false);

  const { title, description, secondTitle, secondDescription, isEmailInvitation } =
    useTextContent(row);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} size="small" sx={{ aspectRatio: '1 / 1' }}>
        <StyledFontAwesomeIcon icon={faEye} fixedWidth />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <SurveyMessageContent title={title} description={description} />
          {isEmailInvitation && (
            <SurveyMessageContent title={secondTitle} description={secondDescription} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
