import { useSearchParams } from 'react-router-dom';

import { BaseDashboard } from './BaseDashboard';

export const DebuggerDashboard = () => {
  const [searchParams] = useSearchParams();
  const dashboardId = searchParams.get('dashboard_id');
  if (!dashboardId) return null;
  return <BaseDashboard dashboardId={dashboardId} />;
};
