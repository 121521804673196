import { useEffect } from 'react';

import { SISENSE_SERVER_PATH, useDashboardId, useRouteUtils } from '@trustyou/shared';

import { BaseDashboard } from './BaseDashboard';

export const CompetitorsDashboard = () => {
  const { data: dashboardIdResponse } = useDashboardId();
  const { navigateWithBase } = useRouteUtils();

  const messageEventHandler = (event: MessageEvent) => {
    if (
      event.origin === SISENSE_SERVER_PATH &&
      typeof event.data === 'string' &&
      event.data.includes('addCompetitors')
    ) {
      navigateWithBase('organization/entities');
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dashboardIdResponse?.competitor_dashboard_id) return null;
  return <BaseDashboard dashboardId={dashboardIdResponse?.competitor_dashboard_id} />;
};
