import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Link, Stack, Typography } from '@trustyou/ui';

import personInFrontOfAnErrorScreen from '../../assets/illustrations/person-in-front-of-an-error-screen.svg';
import styles from './styles';

const useContactSupportUrl = () => {
  const basicUrl = process.env.REACT_APP_URLS__ZENDESK_HELP_MANUAL as string;
  const { locale } = useIntl();
  const urlWithLocale = basicUrl.replace('{lang_code}', locale.toLowerCase());
  return urlWithLocale;
};

const StyledSupportLink = () => {
  const supportUrl = useContactSupportUrl();
  return (
    <Typography variant="body2" component="span" sx={{ color: 'primary.main' }}>
      <Link href={supportUrl} target="_blank" underline="hover">
        <FormattedMessage id="tyDesign.contactSupport" defaultMessage="contact support" />
      </Link>
    </Typography>
  );
};

export const SomethingWrongRoute = () => (
  <Stack sx={styles.wrapper}>
    <Box
      component="img"
      src={personInFrontOfAnErrorScreen}
      alt="Person in front of an error screen"
    />
    <Stack sx={styles.textWrapper}>
      <Typography variant="h6">
        <FormattedMessage
          id="tyDesign.somethingWentWrong"
          defaultMessage="Oops! Something went wrong"
        />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="tyDesign.somethingWrongDescription"
          defaultMessage="This may be caused by a server error or a connection issue. Refresh the page and make sure that your internet connection is working. If the problem persists, {contact} for assistance."
          values={{ contact: <StyledSupportLink /> }}
        />
      </Typography>
    </Stack>
  </Stack>
);
