import { useIntl } from 'react-intl';

import {
  type CombinedUserAndInvitation,
  FETCH_COMBINED_ORGANIZATION_USERS,
  SEARCH_DEBOUNCE_TIME_MS,
  USER_STATUSES,
  UserViewTypes,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useChangelingStore,
  useCombinedUsersByOrganization,
  useCombinedUsersGridStore,
  useCurrentSubscription,
  useDeleteInvitation,
  useDeleteUser,
  useGridParams,
  useMembershipStore,
  useResendInvitation,
  useResponsive,
  useSubscription,
  useUserNavStore,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  ContentPane,
  EmptyMemberState,
  SearchBar,
  SecondaryDrawer,
  UserDetailsView,
  snackbar,
} from '@trustyou/ui';

import InvitationDeleteModal from './components/DeleteModal/InvitationDeleteModal';
import UserDeleteModal from './components/DeleteModal/UserDeleteModal';
import { InviteUpdateUser } from './components/InviteUpdateUser';
import UsersTable from './components/UsersTable/UsersTable';

import {
  useCurrentUser,
  useManageEntitySubscriptions,
  useManageSubscription,
  useManageUsers,
} from '../../../hooks';
import styles from './styles';

export function AllUsers() {
  const intl = useIntl();
  const { isMobile } = useResponsive();
  const { membership } = useMembershipStore();
  const { isChangeling } = useChangelingStore();
  const isEntitySubscriptionAllowed = useManageEntitySubscriptions();
  const isSubscriptionAllowed = useManageSubscription();
  const isManageUserAllowed = useManageUsers();
  const currentUser = useCurrentUser();

  const { view, navToList, navToEdit, navToDetail, navToDelete, navToDeleteDiscard } =
    useUserNavStore();
  const { type, user, isDeleting } = view;

  const gridState = useCombinedUsersGridStore();
  const { paginationModel, sortModel, searchKey } = gridState;
  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_COMBINED_ORGANIZATION_USERS,
    gridState
  );

  const { data, isFetching } = useCombinedUsersByOrganization(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    { searchKey },
    membership?.organizationId
  );
  const { data: currentSubscription } = useCurrentSubscription();
  const { data: userSubscription } = useSubscription(
    isSubscriptionAllowed ? user?.subscription.id : undefined
  );

  const deleteUser = useDeleteUser({});
  const resendInvitation = useResendInvitation();
  const deleteInvitation = useDeleteInvitation({});
  const isLoading =
    deleteUser.isPending || deleteInvitation.isPending || resendInvitation.isPending;

  const handleResendInvitation = (chosenUser: CombinedUserAndInvitation) => {
    resendInvitation.mutate(
      {
        email: chosenUser.email,
        invitation_id: chosenUser.membership_id,
      },
      {
        onSuccess: (data) => {
          snackbar.success(
            intl.formatMessage(
              {
                id: 'organization.users.invitationResendAlert',
                defaultMessage: 'Invitation to {email} is resent',
              },
              { email: data }
            )
          );
        },
        onSettled: navToList,
      }
    );
  };

  const handleDeleteUser = () => {
    deleteUser.mutate(user?.membership_id as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(
            {
              id: 'organization.users.userRemovedAlert',
              defaultMessage: '{user} removed',
            },
            { user: user?.name || user?.email }
          )
        );
      },
      onSettled: navToList,
    });
  };

  const onDeleteInvitation = () => {
    deleteInvitation.mutate(user?.membership_id as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(
            {
              id: 'organization.users.invitationDeleteAlert',
              defaultMessage: 'Invitation for {email} was canceled',
            },
            { email: user?.email }
          )
        );
      },
      onSettled: navToList,
    });
  };

  const enabledColumns = ['name', 'role', 'scope', 'status', 'action'];

  if (isEntitySubscriptionAllowed) {
    enabledColumns.push('subscription');
  }

  if (type === UserViewTypes.INVITE)
    return (
      <InviteUpdateUser subscription={currentSubscription} user={undefined} onCancel={navToList} />
    );

  if (type === UserViewTypes.EDIT)
    return (
      <InviteUpdateUser
        subscription={isSubscriptionAllowed ? userSubscription : currentSubscription}
        user={user}
        onCancel={navToList}
      />
    );

  if (data?.pagination?.total === 0 && !searchKey)
    return (
      <ContentPane>
        <EmptyMemberState />
      </ContentPane>
    );

  return (
    <>
      <ContentPane sx={{ border: 0 }} bodyStyles={{ padding: 0 }}>
        <Box sx={styles.searchBar}>
          <SearchBar
            onSearch={onSearch}
            debounceTime={SEARCH_DEBOUNCE_TIME_MS}
            placeholder={intl.formatMessage(searchPlaceholders.searchMembers)}
            id="users"
            variant="standard"
            defaultValue={searchKey}
          />
        </Box>
        <UsersTable
          data={data}
          isLoading={isFetching}
          handleUserDetails={navToDetail}
          handleEdit={navToEdit}
          handleDelete={navToDelete}
          handleResendInvitation={handleResendInvitation}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          containerStyle={styles.tableContainer}
          enabledColumns={enabledColumns}
        />
      </ContentPane>
      <SecondaryDrawer
        header={null}
        open={type === UserViewTypes.DETAIL && !!user}
        onClose={navToList}
        width="400px"
        changelingMode={isChangeling}
      >
        {type === UserViewTypes.DETAIL && user && (
          <UserDetailsView
            user={user}
            isManageUserAllowed={isManageUserAllowed}
            handleDeleteUser={navToDelete}
            handleEditUser={!isMobile ? navToEdit : undefined}
            currentUser={currentUser}
          />
        )}
      </SecondaryDrawer>
      <UserDeleteModal
        onClose={navToDeleteDiscard}
        onDelete={handleDeleteUser}
        user={isDeleting && user?.status === USER_STATUSES.ACTIVE ? user : undefined}
      />
      <InvitationDeleteModal
        onClose={navToDeleteDiscard}
        onDelete={onDeleteInvitation}
        invitation={isDeleting && user?.status === USER_STATUSES.INVITED ? user : undefined}
      />
      <BackdropSpinner isLoading={isLoading} />
    </>
  );
}
