import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { faUserRobot } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useConfirm, useRouteUtils } from '@trustyou/shared';
import {
  Button,
  ConfirmDialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@trustyou/ui';

import { useLanguage } from '../../../hooks';
import { useStore } from '../../../store/store';
import type { Language } from '../../../types';

const responseAIMessages = defineMessages({
  generateResponse: {
    id: 'inbox.response.ai.generateResponse',
    defaultMessage: 'Generate response',
  },
  generating: {
    id: 'inbox.response.ai.generating',
    defaultMessage: 'Generating...',
  },
  generateAnother: {
    id: 'inbox.response.ai.generateAnother',
    defaultMessage: 'Generate another',
  },
  settings: {
    id: 'inbox.settings',
    defaultMessage: 'Settings',
  },
  languageLabel: {
    id: 'inbox.response.ai.language.label',
    defaultMessage: 'Language',
  },
});

export type ResponseAIActionsProps = {
  isGenerating: boolean;
  onGenerateResponseAI: () => void;
};

export function ResponseAIActions({ isGenerating, onGenerateResponseAI }: ResponseAIActionsProps) {
  const intl = useIntl();
  const { formState } = useFormContext();
  const { isDirty } = formState;
  const { languagesWithAuto, getLanguageByCode, isLoading: isLanguagesLoading } = useLanguage();
  const updateGenerationLanguage = useStore.use.updateGenerationLanguage();
  const generationLanguage = useStore.use.generationLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const { isDialogOpen, handleAction, handleConfirm, handleCancel } = useConfirm(isDirty);
  const { navigateWithBase } = useRouteUtils();

  useEffect(() => {
    if (isLanguagesLoading) return;
    const storedLanguage = localStorage.getItem('generateAIResponseLanguage') as Language;
    const initialLanguage = getLanguageByCode(storedLanguage);
    initialLanguage && updateGenerationLanguage(initialLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLanguagesLoading]);

  const languageFieldLabel = intl.formatMessage(responseAIMessages.languageLabel);
  const responseAIButtonInitialText = intl.formatMessage(
    responseAIMessages[hasGeneratedResponse ? 'generateAnother' : 'generateResponse']
  );
  const responseAIGeneratingText = intl.formatMessage(responseAIMessages.generating);

  const goToSettings = () => {
    handleAction(() => navigateWithBase('settings/user/inbox'));
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" sx={{ gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
        <FormControl sx={{ width: 220 }}>
          <InputLabel shrink id="response-ai-language-label">
            {languageFieldLabel}
          </InputLabel>
          <Select
            aria-labelledby="response-ai-language-label"
            label={languageFieldLabel}
            value={generationLanguage.value}
            onChange={(event) => {
              const newLanguage = getLanguageByCode(event.target.value as Language | 'AUTO');
              localStorage.setItem('generateAIResponseLanguage', newLanguage?.value || 'AUTO');
              newLanguage && updateGenerationLanguage(newLanguage);
            }}
            MenuProps={{
              sx: {
                maxHeight: 460,
              },
            }}
          >
            {languagesWithAuto.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LoadingButton
          loading={isGenerating}
          loadingPosition="start"
          startIcon={<FontAwesomeIcon icon={faUserRobot} />}
          color="primary"
          variant="contained"
          onClick={onGenerateResponseAI}
          data-gtm-id={`inbox_response_ai_generate_${
            hasGeneratedResponse ? 'another' : 'response'
          }`}
        >
          {isGenerating ? responseAIGeneratingText : responseAIButtonInitialText}
        </LoadingButton>
        <Button
          onClick={goToSettings}
          variant="text"
          size="small"
          color="primary"
          data-gtm-id="inbox_settings_button"
        >
          {intl.formatMessage(responseAIMessages.settings)}
        </Button>
      </Stack>
      <ConfirmDialog open={isDialogOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
    </Stack>
  );
}
