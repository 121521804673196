import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormControl, FormHelperText, MenuItem, Select } from '@trustyou/ui';

type LanguageSwitcherProps = {
  languages: {
    value: string;
    label: string;
    dataTestId: string;
  }[];
};

export function LanguageSwitcher({ languages }: LanguageSwitcherProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="language"
      render={({ field: { value = 'en-US', onChange } }) => (
        <FormControl>
          <Select data-testid="language-select" value={value} onChange={onChange}>
            {value === 'auto' && (
              <MenuItem key="lang-auto" data-testid="lang-auto" value="auto">
                <FormattedMessage
                  id="settings.languageSwitcher.autoDetectOption"
                  defaultMessage="Auto-detect browser language"
                />
              </MenuItem>
            )}
            {languages.map((language) => (
              <MenuItem
                key={language.value}
                data-testid={language.dataTestId}
                value={language.value}
              >
                {language.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            <FormattedMessage
              id="settings.languageSwitcher.info"
              defaultMessage="English will be used, if your browser language is not supported"
            />
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
