import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dialog, DialogContent, DialogTitle, type RatingProps } from '@mui/material';
import { faThumbsUp, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { IconButton, Rating, StyledFontAwesomeIcon } from '@trustyou/ui';

import { FeedbackFormStep } from './feedback-form-step';
import { ThankYouStep } from './thank-you-step';
import { useResponseAIRating } from './use-response-ai-rating';

import type { Score } from '../../../types';
import { IconButtonWithTooltip } from '../../icon-button-with-tooltip';

type ResponseAIRatingMobileProps = {
  isDisabled?: boolean;
};

export function ResponseAIRatingMobile({ isDisabled }: ResponseAIRatingMobileProps) {
  const intl = useIntl();
  const {
    issueOptions,
    score,
    setScore,
    showFeedbackStep,
    setShowFeedbackStep,
    showThankYouStep,
    setShowThankYouStep,
    selectedIssues,
    isOtherSelected,
    feedbackText,
    setFeedbackText,
    closeFeedbackStep,
    toggleSelectedIssue,
    submit,
  } = useResponseAIRating();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpen = () => setIsDialogOpen(true);
  const handleClose = () => {
    setIsDialogOpen(false);
    setShowFeedbackStep(false);
    closeFeedbackStep();
  };

  const handleRatingChange: RatingProps['onChange'] = (event, score) => {
    if (!score) return;
    setScore(score as Score);
    if (score > 3) setIsDialogOpen(false);
  };

  const handleSkip = () => {
    setShowFeedbackStep(false);
    setShowThankYouStep(true);
  };

  const handleSend = () => {
    submit();
    setShowFeedbackStep(false);
    setShowThankYouStep(true);
  };

  const showRating = !showFeedbackStep && !showThankYouStep;
  const showFeedback = showFeedbackStep && !showThankYouStep;
  const showThankYou = !showFeedbackStep && showThankYouStep;

  return (
    <>
      <IconButtonWithTooltip
        aria-label="textbox rate button"
        data-gtm-class="inbox_response_textbox_action"
        data-gtm-id="rate"
        tooltip={intl.formatMessage({
          id: 'inbox.response.ai.rating.label',
          defaultMessage: 'Rate this response',
        })}
        icon={faThumbsUp}
        onClick={handleOpen}
        disabled={isDisabled}
      />
      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth>
        {showRating && (
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormattedMessage
              id="inbox.response.ai.rating.label"
              defaultMessage="Rate this response"
            />
            <IconButton onClick={handleClose}>
              <StyledFontAwesomeIcon icon={faXmark} />
            </IconButton>
          </DialogTitle>
        )}
        {showRating && (
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', marginBlock: 2 }}>
            <Rating
              size="large"
              value={score}
              onChange={handleRatingChange}
              sx={{
                alignSelf: 'center',
                '& .MuiRating-icon': {
                  marginInlineEnd: 2,
                },
              }}
            />
          </DialogContent>
        )}
        {showFeedback && (
          <FeedbackFormStep
            issueOptions={issueOptions}
            selectedIssues={selectedIssues}
            isOtherSelected={isOtherSelected}
            feedbackText={feedbackText}
            setFeedbackText={setFeedbackText}
            onSkip={handleSkip}
            toggleSelectedIssue={toggleSelectedIssue}
            onSend={handleSend}
          />
        )}
        {showThankYou && <ThankYouStep onClose={handleClose} />}
      </Dialog>
    </>
  );
}
