import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import type { ErrorWithResponse } from '@trustyou/inbox';
import { useChangelingStore, useScopeStore } from '@trustyou/shared';
import { Box, Dialog, DiscardDialog, snackbar } from '@trustyou/ui';

import type { CreateQuestionnaireSchema } from '../../../client';
import { EditorContent } from '../../../components/shared/editor-content';
import { EditorFooter } from '../../../components/shared/editor-footer';
import { EditorHeader } from '../../../components/shared/editor-header';
import { EditorSideNav } from '../../../components/shared/editor-side-nav';
import styles from '../../../components/shared/styles';
import { useSurveyQuestionnaireEditorSteps } from '../../../components/survey/questionnaire-editor/use-steps';
import { useCreateSurveyQuestionnaire } from '../../../service/hooks';
import { useStore } from '../../../store/store';
import type {
  SurveyEditorDataProps,
  SurveyQuestionnaireEditorContentOutput,
} from '../../../types/survey';
import {
  convertSurveyData,
  unflattenQuestionsArray,
} from '../../../utils/survey-questionnaire-editor';
import { SurveyQuestionnaireEditorPreview } from './survey-questionnaire-editor-preview';

export function SurveyQuestionnaireEditor() {
  const navigate = useNavigate();
  const { isChangeling } = useChangelingStore();
  const { state } = useLocation();
  const intl = useIntl();
  const surveyQuestionnaireEditorStartingPoint =
    useStore.use.surveyQuestionnaireEditorStartingPoint();
  const surveyEditorFormHook = useForm<SurveyEditorDataProps>();
  const { handleSubmit, trigger, setValue, watch } = surveyEditorFormHook;
  const createSurveyQuestionnaire = useCreateSurveyQuestionnaire();
  const { selectedEntities, isValid } = useScopeStore();
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  // dialog
  const [open, setOpen] = useState(true);
  const closeEditor = () => {
    setOpenDiscardDialog(false);
    setOpen(false);
    navigate(-1);
  };

  const openDiscardDialogHandler = () => {
    setOpenDiscardDialog(true);
  };

  // stepper
  const steps = useSurveyQuestionnaireEditorSteps();
  const [activeStep, setActiveStep] = useState(0);
  const hasPreviousStep = steps[activeStep - 1] !== undefined;
  const hasNextStep = steps[activeStep + 1] !== undefined;

  const updateSurveyContent = () => {
    if (activeStep === 4) {
      setValue(
        'surveyContent',
        unflattenQuestionsArray(
          watch('surveyContentWithRule') as SurveyQuestionnaireEditorContentOutput[]
        )
      );
    }
  };

  const goToNextStep = (data: SurveyEditorDataProps) => {
    updateSurveyContent();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goToPreviousStep = () => {
    updateSurveyContent();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goToSideBarChosenStep = (index: number) => {
    updateSurveyContent();
    const onfulfilled = (value: boolean) => {
      if (value) setActiveStep(index);
      else {
        snackbar.error('Check the fields with warnings.');
      }
    };
    const onrejected = (reason: unknown) => console.error('onrejected', reason);
    trigger?.().then(onfulfilled, onrejected);
  };

  // editor
  const saveDraft = (data: SurveyEditorDataProps) => {
    publish(data);
  };
  const publish = (data: SurveyEditorDataProps) => {
    createSurveyQuestionnaire.mutate(
      convertSurveyData({
        surveyId: state.surveyId,
        data,
        selectedEntities: selectedEntities,
      }) as CreateQuestionnaireSchema,
      {
        onSuccess: () => {
          closeEditor();
          snackbar.success(
            intl.formatMessage({
              id: 'survey.survey-editor.success',
              defaultMessage: 'Survey created',
            })
          );
        },
        onError: (err) => {
          const { response } = err as unknown as ErrorWithResponse;
          const errorText = response.data.detail.includes('Scope') ? response.data.detail : null;
          if (!errorText) return;
          snackbar.error(
            intl.formatMessage({
              id: 'survey.survey-editor.error',
              defaultMessage: 'Error creating survey',
            })
          );
        },
      }
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      sx={{ paddingTop: isChangeling ? 8 : 0 }}
      onClose={openDiscardDialogHandler}
    >
      <FormProvider {...surveyEditorFormHook}>
        <Box sx={styles.editorMain}>
          <EditorHeader
            title={
              surveyQuestionnaireEditorStartingPoint === 'new-survey-questionnaire' ? (
                <FormattedMessage
                  id="survey.common.new-questionnaire"
                  defaultMessage="New Questionnaire"
                />
              ) : surveyQuestionnaireEditorStartingPoint === 'edit-survey-questionnaire' ? (
                <FormattedMessage
                  id="survey.survey-editor.edit.title"
                  defaultMessage="Editing {surveyName}"
                  values={{ surveyName: '[Name of survey]' }}
                />
              ) : (
                ''
              )
            }
            close={openDiscardDialogHandler}
            save={handleSubmit(saveDraft)}
          />
          <EditorContent
            sideNavigator={
              <EditorSideNav
                activeStep={activeStep}
                setActiveStep={goToSideBarChosenStep}
                steps={steps}
              />
            }
            content={steps[activeStep].content}
            contentExtraStyles={{
              ...([4, 8].includes(activeStep) && styles.editorContentExtraStyles),
            }}
            preview={<SurveyQuestionnaireEditorPreview activeStep={activeStep} />}
            previewExtraStyles={{
              ...([4, 8].includes(activeStep) && styles.editorPreviewExtraStyles),
            }}
          />
          <EditorFooter
            hasNextStep={hasNextStep}
            hasPreviousStep={hasPreviousStep}
            nextStepLabel={steps[activeStep + 1]?.label}
            previousStepLabel={steps[activeStep - 1]?.label}
            goToNextStep={handleSubmit(goToNextStep)}
            goToPreviousStep={goToPreviousStep}
            publish={handleSubmit(publish)}
            disablePublishButton={!isValid()}
            extraMainStyles={{ ...([4, 8].includes(activeStep) && styles.editorFooterExtraStyles) }}
          />
        </Box>
        <DiscardDialog
          // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
          title={
            <FormattedMessage
              id="survey.survey-editor.discard-editor.title"
              defaultMessage="Leave without saving changes?"
            />
          }
          // @ts-expect-error Type 'Element' is not assignable to type 'ReactNode'.
          content={
            <FormattedMessage
              id="survey.survey-editor.discard-editor.description"
              defaultMessage="All changes will be lost."
            />
          }
          open={openDiscardDialog}
          onCancel={() => setOpenDiscardDialog(false)}
          onDiscard={closeEditor}
        />
      </FormProvider>
    </Dialog>
  );
}
