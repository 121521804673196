import { type ReactElement, type SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SISENSE_SERVER_PATH,
  useAuthorizationStore,
  useLanguageStore,
  useMembershipStore,
  useRowLevelSecurity,
  useSisenseJWTToken,
} from '@trustyou/shared';
import { DashboardLoader } from '@trustyou/ui';

const SISENSE_JWT_BASE_PATH = `${SISENSE_SERVER_PATH}/jwt`;

type AuthWrapperProps = {
  children?: ReactElement;
  loader?: ReactElement;
};

export function AuthWrapper({ children, loader }: AuthWrapperProps) {
  const firstLangRender = useRef(true);

  const { tokenStore, setTokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();
  const { locale } = useLanguageStore();

  const navigate = useNavigate();
  const location = useLocation();

  // Navigate to previous page after back button
  const popEventHandler = () => {
    if (window.location.href.includes('/analytics') && !location.state?.path) {
      // Skip sisense jwt route on back button click
      navigate(-2);
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', popEventHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call for JWT Token (used below)
  const { data: ssoTokenData, refetch, isRefetching } = useSisenseJWTToken();

  // Call for Row Level Security (used below)
  const rowLevelSecurity = useRowLevelSecurity();

  useEffect(() => {
    if (ssoTokenData) {
      const tokenParts = ssoTokenData.sso_token.split('.');
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const expiresIn = payload.exp - currentTime;
        const refreshIn = expiresIn * 1000 - 60000;
        // Set up a timer to refetch the token 1 minute before it expires
        const timerId = setTimeout(() => {
          refetch();
        }, refreshIn);
        return () => clearTimeout(timerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoTokenData]);

  useEffect(() => {
    if (firstLangRender.current) {
      firstLangRender.current = false;
      return;
    }
    rowLevelSecurity.mutate(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  // Url to use for auth Iframe
  const url = useMemo(() => {
    if (isRefetching || !ssoTokenData?.sso_token) return '';
    return `${SISENSE_JWT_BASE_PATH}?jwt=${ssoTokenData.sso_token}&return_to=/empty`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoTokenData, isRefetching]);

  const handleIframeLoad = (event: SyntheticEvent<HTMLIFrameElement, Event>) => {
    if (tokenStore && membership?.id && ssoTokenData?.sso_token) {
      setTokenStore({
        ...tokenStore,
        [membership.id]: {
          ...tokenStore[membership.id],
          analytics: {
            sso_token: ssoTokenData?.sso_token,
          },
        },
      });
    }
    if (!rowLevelSecurity.isSuccess) {
      rowLevelSecurity.mutate(locale);
    }
  };

  return (
    <>
      {rowLevelSecurity.isSuccess
        ? children
        : loader || (
            <DashboardLoader
              message={
                <FormattedMessage
                  id="analytics.loadingDashboard"
                  defaultMessage="Loading your dashboard..."
                />
              }
            />
          )}
      {url && (
        <iframe
          src={url}
          title="Sisense Embed"
          style={{
            border: 'none',
            display: 'none',
          }}
          onLoad={handleIframeLoad}
        />
      )}
    </>
  );
}
