import { useState } from 'react';
import { type UseControllerProps, useController } from 'react-hook-form';

import type { RadioGroupProps } from '@mui/material';
import { FormControl, FormControlLabel, RadioGroup as MuiRadioGroup, Radio } from '@trustyou/ui';

import type { Option } from '../types';

type SurveyRadioGroupProps = UseControllerProps & {
  options: Option[];
};

export function RadioGroup({ name, control, options }: SurveyRadioGroupProps) {
  const { field } = useController({ name, control });
  const [selectedValue, setSelectedValue] = useState<Option['value']>(field.value?.[0] ?? null);

  const handleChange: RadioGroupProps['onChange'] = (event, value) => {
    field.onChange([value]);
    setSelectedValue(value);
  };

  return (
    <FormControl>
      <MuiRadioGroup
        aria-labelledby="radio-buttons-group"
        name="radio-buttons-group"
        value={selectedValue}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id ?? option.value.toString()}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
