import { useMutation } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { IMPACT_SCORE } from '../../constants/query-keys';
import type { ImpactScoreRequest, ImpactScoreResponse } from '../../types';

async function fetchImpactScore(request: ImpactScoreRequest) {
  const { data } = await $assApi.post<ImpactScoreResponse>(
    `${ANALYTICS_BASE_PATH}/impact_score/calculate`,
    request
  );
  return data;
}

export const useImpactScore = () => {
  return useMutation({
    mutationFn: (request: ImpactScoreRequest) => fetchImpactScore(request),
    mutationKey: [IMPACT_SCORE],
  });
};
