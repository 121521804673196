import type { MouseEvent, ReactNode } from 'react';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { SxProps, Theme } from '@mui/material';
import { faChevronDown, faChevronUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  ICON_BUTTON_HEIGHT,
  ICON_BUTTON_WIDTH,
  ICON_HEIGHT,
  ICON_WIDTH,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  NAV_ICON_BACKGROUND_COLOR,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';
import { isUndefined } from 'lodash';

export interface NavListItemProps {
  id: string;
  name: ReactNode;
  icon?: IconDefinition;
  selected?: boolean;
  sx?: SxProps<Theme>;
  isNodeExpanded?: boolean;
  onClick: (event: MouseEvent) => void;
}

export function NavListItem({
  id,
  name,
  icon,
  selected,
  sx,
  isNodeExpanded,
  onClick,
}: NavListItemProps) {
  return (
    <ListItemButton
      data-testid={`${id}-list-item-button`}
      sx={{
        paddingInline: 1.5,
        height: ICON_BUTTON_HEIGHT,
        width: ICON_BUTTON_WIDTH,
        gap: 2,
        textWrap: 'nowrap',
        borderRadius: 1,
        ...(selected && {
          color: 'primary.main',
          backgroundColor: NAV_ICON_BACKGROUND_COLOR,
          '&:hover': {
            color: 'primary.main',
            backgroundColor: NAV_ICON_BACKGROUND_COLOR,
          },
        }),
        ...sx,
      }}
      onClick={onClick}
    >
      {icon ? (
        <ListItemIcon
          sx={{
            minWidth: 0,
            ...(selected && { color: 'primary.main' }),
          }}
        >
          <StyledFontAwesomeIcon
            icon={icon}
            sx={{
              height: ICON_HEIGHT,
              width: ICON_WIDTH,
            }}
          />
        </ListItemIcon>
      ) : (
        <Box
          sx={{
            height: ICON_HEIGHT,
            width: ICON_WIDTH,
          }}
        />
      )}
      <ListItemText primary={<Typography variant="body2">{name}</Typography>} />
      {!isUndefined(isNodeExpanded) && (
        <ListItemIcon
          sx={{
            minWidth: 0,
          }}
        >
          <StyledFontAwesomeIcon icon={isNodeExpanded ? faChevronUp : faChevronDown} />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
}
