import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLanguageStore } from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';

import type { Subcategory } from '.';
import type { SemaLanguage } from '../../../client';
import { useFetchSubCategories } from '../../../hooks';
import { FiltersFormFields } from '../../../types';
import { FilterDialogButton } from '../../filter-dialog-button';
import { SelectorModal } from '../../selector-modal';

interface SubcategoriesProps {
  initialSelection?: string[] | null;
  isLoading?: boolean;
  saveTopicIds: () => void;
}

export const Subcategories = ({
  initialSelection: initialSelectedIds = [],
  isLoading: isParentLoading,
  saveTopicIds,
}: SubcategoriesProps) => {
  const intl = useIntl();
  const { setValue, getValues } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSubcategories, setSelectedSubcategories] = useState<Subcategory[]>([]);
  const [displayedSubcategories, setDisplayedSubcategories] = useState<Subcategory[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);

  const { locale } = useLanguageStore();
  const { data, isPending } = useFetchSubCategories(locale as SemaLanguage);
  useEffect(() => {
    if (!isPending && data) {
      const reshapedData = Object.entries(data).reduce(
        (res: { id: string; title: string }[], [key, value]) => {
          res.push({ id: key, title: value });
          return res;
        },
        []
      );
      const initialSelection = reshapedData.filter(({ id }) => initialSelectedIds?.includes(id));
      setValue(
        FiltersFormFields.Subcategories,
        initialSelection.map(({ id }) => id),
        { shouldDirty: false }
      );
      setSelectedSubcategories(initialSelection);
      setDisplayedSubcategories(reshapedData);
      setSubcategories(reshapedData);
    }
  }, [data, initialSelectedIds, isPending, setValue]);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDisplayedSubcategories(subcategories);
  };

  const saveCategories = () => {
    const ids = selectedSubcategories.map(({ id }) => id);
    setValue(FiltersFormFields.Subcategories, ids, { shouldDirty: true });
    saveTopicIds();
    closeDialog();
  };

  const cancel = () => {
    setSelectedSubcategories(
      subcategories.filter(({ id }) => getValues(FiltersFormFields.Subcategories)?.includes(id)) ||
        []
    );
    closeDialog();
  };

  const renderRowContent = (subcategory: Subcategory) => (
    <ListItemText primary={subcategory.title} />
  );

  const onSearch = (value: string) => {
    if (value === '') {
      setDisplayedSubcategories(subcategories);
    } else
      setDisplayedSubcategories(
        subcategories.filter(({ title }) => title.toLowerCase().includes(value.toLowerCase()))
      );
  };

  return (
    <>
      <FilterDialogButton
        title={
          <FormattedMessage id="inbox.filter.subcategories.name" defaultMessage="Subcategories" />
        }
        description={
          <FormattedMessage
            id="inbox.filter.selected-ratio"
            defaultMessage="{count} / {total} selected"
            values={{
              count:
                selectedSubcategories.length > 0
                  ? selectedSubcategories.length
                  : subcategories.length,
              total: subcategories.length,
            }}
          />
        }
        onClick={openDialog}
      />
      <SelectorModal
        isOpen={isOpen}
        items={displayedSubcategories}
        selectedItems={selectedSubcategories}
        isLoading={isPending || isParentLoading}
        setSelectedItems={setSelectedSubcategories}
        onClose={cancel}
        onSave={saveCategories}
        renderRowContent={renderRowContent}
        searchPlaceholder={intl.formatMessage({
          id: 'inbox.search.subcategories',
          defaultMessage: 'Search subcategories',
        })}
        title={<FormattedMessage id="inbox.filter.subcategories" defaultMessage="Subcategories" />}
        allTab={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.button.all"
            defaultMessage="All subcategories"
          />
        }
        selectTab={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.button.select"
            defaultMessage="Select subcategories"
          />
        }
        selectedHeaderTitle={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.selected-count"
            defaultMessage="Selected subcategories ({count})"
            values={{ count: selectedSubcategories.length }}
          />
        }
        alertDescription={
          <FormattedMessage
            id="inbox.filter.subcategories.modal.alert"
            defaultMessage="Please select at least one subcategory"
          />
        }
        onSearch={onSearch}
      />
    </>
  );
};
