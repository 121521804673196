import { type MouseEventHandler, type ReactNode, useState } from 'react';
import { useController } from 'react-hook-form';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useResponsive } from '@trustyou/shared';
import {
  Button,
  ButtonGroup as MuiButtonGroup,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
  useTheme,
} from '@trustyou/ui';

import type { SelectQuestionProps } from '../question-types/select/select-question';

const BUTTON_WIDTH = '96px';

export type ButtonGroupWithIconsProps = SelectQuestionProps;

export function ButtonGroup({ name, control, options }: ButtonGroupWithIconsProps) {
  const { field } = useController({ name, control });
  const { isPhone } = useResponsive();
  const [selectedOption, setSelectedOption] = useState(field.value ?? '');

  const selectedOptionValue = Array.isArray(selectedOption) ? selectedOption[0] : selectedOption;

  const handleOptionChange = (value: string | number | boolean) => {
    if (value === selectedOption) {
      field.onChange('');
      setSelectedOption('');
      return;
    }
    if (typeof value === 'boolean') field.onChange(value);
    else field.onChange([value]);
    setSelectedOption(value);
  };

  return (
    <Stack sx={{ color: 'text.secondary' }}>
      <MuiButtonGroup
        variant="outlined"
        aria-label="button group"
        fullWidth
        sx={{
          ...(isPhone && {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(${BUTTON_WIDTH}, 1fr))`,
            gap: 2,
            '& .MuiButtonGroup-grouped:not(:last-of-type), & .MuiButtonGroup-grouped:not(:first-of-type)':
              {
                borderRadius: 'inherit',
                borderColor: 'primary.main',
                margin: 0,
                '&:hover': {
                  borderColor: 'primary.main',
                },
              },
          }),
        }}
      >
        {options.map((option) => (
          <CustomButton
            key={option.value.toString()}
            label={option.label}
            icon={option.icon as IconDefinition}
            isSelected={option.value === selectedOptionValue}
            onClick={() => handleOptionChange(option.value as string)}
          />
        ))}
      </MuiButtonGroup>
    </Stack>
  );
}

type CustomButtonProps = {
  label: ReactNode;
  isSelected: boolean;
  onClick: MouseEventHandler;
  icon?: IconDefinition;
};

function CustomButton({ label, isSelected, onClick, icon }: CustomButtonProps) {
  const theme = useTheme();
  const contrastedColor = theme.palette.getContrastText(theme.palette.primary.main);

  return (
    <Button
      onClick={onClick}
      sx={{
        flexDirection: 'column',
        gap: 1,
        padding: icon ? 2 : 1,
        ...(isSelected && {
          backgroundColor: theme.palette.primary.main,
          color: contrastedColor,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            borderColor: 'transparent !important',
          },
        }),
      }}
    >
      {icon && <StyledFontAwesomeIcon icon={icon} size="xl" sx={{ color: 'inherit' }} />}
      <Typography variant="body2" sx={{ color: 'inherit', fontWeight: 500 }}>
        {label}
      </Typography>
    </Button>
  );
}
