import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameRequired: {
    id: 'segments.name.required',
    defaultMessage: 'Name is required',
  },
  nameOfSegment: {
    id: 'organization.nameOfSegment',
    defaultMessage: 'Name of segment',
  },
  nameHelperText: {
    id: 'organization.egRegion',
    defaultMessage: 'e.g. Region',
  },
  nameExist: {
    id: 'segment.exists',
    defaultMessage: 'Entered segment already exists',
  },
  nameUsedInACustomAttribute: {
    id: 'segment.name.used-in-a-custom-attribute',
    defaultMessage: 'Entered segment already used in a custom attribute',
  },
  nameReserved: {
    id: 'segment.name.reserved',
    defaultMessage: 'Entered segment is reserved',
  },
  addSegmentFormHeader: {
    id: 'organization.nameSegment',
    defaultMessage: 'Name segment',
  },
  addSegmentFormInfo: {
    id: 'organization.nameSegmentInfo',
    defaultMessage:
      'A segment helps you organize your entities, manage your user permission and competitor sets',
  },
  editSegmentFormHeader: {
    id: 'organization.editSegment',
    defaultMessage: 'Edit segment',
  },
  changesSaved: {
    id: 'organization.segments.changesSaved',
    defaultMessage: 'Changes saved. {delay}',
  },
});
