import {
  INBOX_MODERATION_APPROVE_PERMISSION,
  INBOX_MODERATION_REJECT_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';

export const useModerationRevisionPermission = () => {
  const { hasPermissions } = usePermissionStore();
  const canApprove = hasPermissions(INBOX_MODERATION_APPROVE_PERMISSION);
  const canReject = hasPermissions(INBOX_MODERATION_REJECT_PERMISSION);
  return canApprove && canReject;
};

export default useModerationRevisionPermission;
