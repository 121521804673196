import ReactDOM from 'react-dom/client';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { messages as analyticsMessages } from '@trustyou/analytics';
import { messages as inboxMessages } from '@trustyou/inbox';
import { messages as reportsMessages } from '@trustyou/reports';
import {
  messages as cxpMessages,
  errorMessages,
  ignoreMissingTranslationWarnings,
  useLanguageStore,
} from '@trustyou/shared';
import {
  messages as surveyMessages,
  submissionUiSubsetMessages as surveySubmissionUiSubsetMessages,
} from '@trustyou/survey-manager';
import { SnackbarProvider, getLocalizedTheme, snackbar } from '@trustyou/ui';
import '@trustyou/webfonts';
import { ClickToComponent } from 'click-to-react-component';
import _ from 'lodash';
import 'regenerator-runtime/runtime';

import { GTMComponent } from './components';
import ChangelingLayer from './components/Auth/ChangelingLayer';
import AuthenticationProvider from './context/Auth/AuthenticationProvider';
import { useGTM, useUserlane } from './hooks';
import './index.css';

import { useNonProductionMock } from './service/mocks/env/useNonProductionMock';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEYS__MUI_PRO as string);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(error);
      // FIXME: This is a workaround to avoid showing the generic error message on some expected 4xx responses.
      if (error.status && error.status >= 500) {
        snackbar.error(<FormattedMessage {...errorMessages.genericError} />);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.error(error);
      // FIXME: This is a workaround to avoid showing the generic error message on some expected 4xx responses.
      if (error.status && error.status >= 500) {
        snackbar.error(<FormattedMessage {...errorMessages.genericError} />);
      }
    },
  }),
});

const AppWrapper = () => {
  const { locale } = useLanguageStore();
  // the order matters: in case of duplicated translation keys across modules, the last objects override the previous ones
  const messages = _.merge(
    inboxMessages,
    surveyMessages,
    surveySubmissionUiSubsetMessages,
    analyticsMessages,
    reportsMessages,
    cxpMessages
  );
  useNonProductionMock(queryClient);
  useUserlane();
  useGTM();

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        onError={ignoreMissingTranslationWarnings}
        locale={locale}
        messages={messages[locale as keyof typeof messages]}
      >
        <ThemeProvider theme={getLocalizedTheme(locale)}>
          <CssBaseline />
          <SnackbarProvider>
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
              <AuthenticationProvider>
                <ChangelingLayer />
              </AuthenticationProvider>
              <GTMComponent />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </IntlProvider>
      <ClickToComponent />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

root.render(<AppWrapper />);
