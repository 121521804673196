import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Font, sansSerifFonts, serifFonts } from '@trustyou/shared';
import {
  ColorPicker,
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Stack,
  Typography,
} from '@trustyou/ui';

import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

type ColorPickerOpen = {
  [key: string]: boolean;
};

const FontPicker = () => (
  <CustomTextFieldWithSeparatedLabel select>
    <ListSubheader sx={{ fontWeight: 'bold', color: 'primary.main' }}>Sans serif</ListSubheader>
    {sansSerifFonts.map((font: Font) => (
      <MenuItem key={font.name} value={font.name}>
        {font.name}
      </MenuItem>
    ))}
    <ListSubheader sx={{ fontWeight: 'bold', color: 'primary.main' }}>Serif</ListSubheader>
    {serifFonts.map((font: Font) => (
      <MenuItem key={font.name} value={font.name}>
        {font.name}
      </MenuItem>
    ))}
  </CustomTextFieldWithSeparatedLabel>
);

export function ThemeEditorStyleContent() {
  const [colorPickerOpen, setColorPickerOpen] = useState<ColorPickerOpen>({
    primaryText: false,
    secondaryText: false,
    sectionTitle: false,
    entityName: false,
    background: false,
    linksButtonBackground: false,
    buttonText: false,
    progressBar: false,
  });

  const colorPickerHandler = (id?: string) => {
    const newColorPickerOpen = Object.keys(colorPickerOpen).reduce<ColorPickerOpen>((agg, key) => {
      if (key === id) agg[key] = !colorPickerOpen[id];
      else {
        agg[key] = false;
      }
      return agg;
    }, {});
    setColorPickerOpen(newColorPickerOpen);
  };
  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="survey.common.style" defaultMessage="Style" />
      </Typography>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <ColorPicker
            id="primaryText"
            label={
              <FormattedMessage
                id="survey.theme-editor.primary-text"
                defaultMessage="Primary text"
              />
            }
            open={colorPickerOpen.primaryText}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#000000', label: '#212121' }}
            onChange={(id, color) => console.log(id, color)}
          />
          <ColorPicker
            id="secondaryText"
            label={
              <FormattedMessage
                id="survey.theme-editor.secondary-text"
                defaultMessage="Secondary text"
              />
            }
            open={colorPickerOpen.secondaryText}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#616161', label: '#616161' }}
            onChange={(id, color) => console.log(id, color)}
          />
          {/* TODO: Un-hide it when we solve the problem of the migrated surveys with the same color for background and section title. */}
          {/* <ColorPicker
            id="sectionTitle"
            label={
              <FormattedMessage
                id="survey.theme-editor.section-title"
                defaultMessage="Section title"
              />
            }
            open={colorPickerOpen.sectionTitle}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#000000', label: '#212121' }}
            onChange={(id, color) => console.log(id, color)}
          /> */}
          <ColorPicker
            id="entityName"
            label={
              <FormattedMessage id="survey.theme-editor.entity-name" defaultMessage="Entity name" />
            }
            open={colorPickerOpen.entityName}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#000000', label: '#212121' }}
            onChange={(id, color) => console.log(id, color)}
          />
        </Stack>
        <Stack spacing={2}>
          <ColorPicker
            id="background"
            label={
              <FormattedMessage id="survey.theme-editor.background" defaultMessage="Background" />
            }
            open={colorPickerOpen.background}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#EBEBEB', label: '#EBEBEB' }}
            onChange={(id, color) => console.log(id, color)}
          />
          <ColorPicker
            id="linksButtonBackground"
            label={
              <FormattedMessage
                id="survey.theme-editor.links-button-background"
                defaultMessage="Links, button background"
              />
            }
            open={colorPickerOpen.linksButtonBackground}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#0057B2', label: '#0057B2' }}
            onChange={(id, color) => console.log(id, color)}
          />
          <ColorPicker
            id="buttonText"
            label={
              <FormattedMessage id="survey.theme-editor.button-text" defaultMessage="Button text" />
            }
            open={colorPickerOpen.buttonText}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#0057B2', label: '#0057B2' }}
            onChange={(id, color) => console.log(id, color)}
          />
          <ColorPicker
            id="progressBar"
            label={
              <FormattedMessage
                id="survey.theme-editor.progress-bar"
                defaultMessage="Progress bar"
              />
            }
            open={colorPickerOpen.progressBar}
            colorPickerHandler={colorPickerHandler}
            color={{ color: '#0057B2', label: '#0057B2' }}
            onChange={(id, color) => console.log(id, color)}
          />
        </Stack>
        <Divider />
        <Typography variant="h6">
          <FormattedMessage id="survey.common.fonts" defaultMessage="Fonts" />
        </Typography>
        <Stack spacing={2} sx={{ maxWidth: 302 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              <FormattedMessage
                id="survey.theme-editor.font-style.body-text"
                defaultMessage="Body text"
              />
            </InputLabel>
            <FontPicker />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              <FormattedMessage
                id="survey.theme-editor.font-style.section-title.entity-name"
                defaultMessage="Section title & Entity name"
              />
            </InputLabel>
            <FontPicker />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}
