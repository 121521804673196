import { useEffect, useState } from 'react';

import { useGoogleAuthUrl } from '@trustyou/shared';

import GoogleConnectInstructions from './GoogleConnectInstructions';

import { GOOGLE_REDIRECT_URL } from '../../constants/reviewProviders';
import { useReviewProviderStore } from '../../store';

type GoogleConnectManagerProps = {
  entityId?: string;
};

export function GoogleConnectManager({ entityId }: GoogleConnectManagerProps) {
  const { setGoogleConnectEntityId } = useReviewProviderStore();
  const [isConnectingGoogle, setIsConnectingGoogle] = useState<boolean>(false);

  const { data } = useGoogleAuthUrl(GOOGLE_REDIRECT_URL, isConnectingGoogle);

  useEffect(() => {
    setGoogleConnectEntityId(entityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  if (data?.auth_url) {
    window.location.href = data.auth_url;
    return null;
  }

  return <GoogleConnectInstructions onAuthorize={() => setIsConnectingGoogle(true)} />;
}
