import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Box,
  Collapse,
  ContentPane,
  List,
  ListItem,
  Modal,
  Slide,
  Typography,
  snackbar,
} from '@trustyou/ui';
import { DateTime } from 'luxon';

import { DiscardReportChangesModal } from './DiscardReportChangesModal';
import { ReportForm } from './ReportForm';
import { DRAWER_WIDTH_PX } from './constants';
import { useFetchStatusHistoryForReport, useModifyReportById } from './hooks/reports';
import { commonMessages } from './messages';

export const Report = ({
  id,
  dashboardId,
  onClose,
  changelingMode,
  enabledFormats,
}: {
  id: string;
  dashboardId: string;
  onClose: () => void;
  changelingMode?: boolean;
  enabledFormats?: string[];
}) => {
  const intl = useIntl();
  const [shouldDiscardChanges, setShouldDiscardChanges] = useState(false);
  const [isReportFormDirty, setIsReportFormDirty] = useState(false);
  const { data: statusHistory = [] } = useFetchStatusHistoryForReport(id);
  const modifyReport = useModifyReportById();

  return (
    <Modal open={true} hideBackdrop={true}>
      <Slide direction="left" in={true}>
        <Box>
          <ContentPane
            sx={{
              width: DRAWER_WIDTH_PX,
              height: '100vh',
              position: 'fixed',
              right: 0,
              paddingTop: changelingMode ? 8 : 0,
            }}
            headerStyles={{
              paddingBlock: 1.5,
              paddingInline: 3,
            }}
            headers={[
              <Typography variant="h6">
                <FormattedMessage
                  id="analytics.reports.details.scheduled-report-title"
                  defaultMessage="Scheduled report"
                />
              </Typography>,
            ]}
            bodyStyles={{
              overflow: 'scroll',
            }}
            navigationHandle={() => {
              if (isReportFormDirty) {
                setShouldDiscardChanges(true);
              } else {
                onClose();
              }
            }}
          >
            <ReportForm
              dashboardId={dashboardId}
              enabledFormats={enabledFormats}
              reportId={id}
              isLoading={modifyReport.isPending}
              onCancel={() => {
                if (isReportFormDirty) {
                  setShouldDiscardChanges(true);
                } else {
                  onClose();
                }
              }}
              onFormStateChange={(formState) => {
                if (Object.keys(formState.dirtyFields).length > 0) {
                  setIsReportFormDirty(true);
                } else {
                  setIsReportFormDirty(false);
                }
              }}
              onSubmit={(form) => {
                const timeOfDay = DateTime.fromISO(form.timeOfDay);
                const dayOfMonth = form.dayOfMonth ? form.dayOfMonth : null;
                modifyReport.mutate(
                  {
                    id: id,
                    report: {
                      disable: false,
                      user_ids: form.recipients.map((recipient) => recipient.value).join(','),
                      report_name: form.name,
                      hour: timeOfDay.hour,
                      period: form.frequency,
                      day_of_week: form.dayOfWeek ? form.dayOfWeek - 1 : null,
                      day: dayOfMonth,
                      timezone: timeOfDay.zone.name,
                      dashboard_id: form.dashboardId,
                      file_format: form.fileFormat,
                      dashboard_filters: form.dashboardFilters,
                      excluded_filters: form.excludedFilters,
                      language: form.language,
                      widget_id: '',
                    },
                  },
                  {
                    onSuccess: () => {
                      snackbar.success(intl.formatMessage(commonMessages.modifyReportSuccess));
                      onClose();
                    },
                  }
                );
              }}
            />
            <Collapse in={statusHistory.filter((item) => item.status === 'sent').length > 0}>
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                <FormattedMessage
                  id="analytics.reports.details.last-sent-title"
                  defaultMessage="Last sent"
                />
              </Typography>
              <List
                dense
                sx={{
                  listStyle: 'disc',
                  // Hack to work around the position absolute of TabActions (rendered at the end of the component ReportForm).
                  paddingBlockEnd: 8,
                }}
              >
                {statusHistory
                  .filter((item) => item.status === 'sent')
                  .sort((item1, item2) => {
                    return (
                      DateTime.fromISO(item2.updated_at).toMillis() -
                      DateTime.fromISO(item1.updated_at).toMillis()
                    );
                  })
                  .map((item, index) => {
                    return (
                      <ListItem
                        key={`${item.updated_at}-${index}`}
                        disablePadding
                        disableGutters
                        sx={{ display: 'list-item', marginInlineStart: 3 }}
                      >
                        {DateTime.fromISO(item.updated_at, {
                          zone: 'system',
                        }).toFormat('dd/MM/yyyy, HH:00')}
                      </ListItem>
                    );
                  })}
              </List>
            </Collapse>
          </ContentPane>
          <DiscardReportChangesModal
            open={shouldDiscardChanges}
            onClose={() => {
              setShouldDiscardChanges(false);
            }}
            onDiscard={onClose}
          />
        </Box>
      </Slide>
    </Modal>
  );
};
