import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  pageContainer: {
    position: 'relative',
    justifyContent: 'center',
    flex: 1,
    border: 'none',
  },
  headerStyles: {
    paddingBottom: 0,
  },
  detailsCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
  },
  detailsCellText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  initialLoadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
  },
};

export default styles;
