import { grey } from '@mui/material/colors';
import { faArrowRightFromBracket } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type Node, useCustomerLogout, useRouteUtils } from '@trustyou/shared';
import {
  Box,
  DRAWER_WIDTH_MOBILE,
  DrawerHeader,
  DrawerNodeList,
  Stack,
  SwipeableDrawer,
} from '@trustyou/ui';

import { MobileUserMenu } from '../UserMenu/UserMenu';

type MobileDrawerProps = {
  primaryNodes: Node[];
  secondaryNodes: Node[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const MobileDrawer = ({
  primaryNodes,
  secondaryNodes,
  isOpen,
  setIsOpen,
}: MobileDrawerProps) => {
  const customerLogout = useCustomerLogout();
  const { navigateToHome } = useRouteUtils();

  const logoutNode = {
    id: 'tyDesign.userMenu.logout',
    name: 'Logout',
    icon: faArrowRightFromBracket,
    onClick: customerLogout,
  };

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      sx={{
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH_MOBILE,
        },
      }}
    >
      <Stack sx={{ background: grey[50] }}>
        <DrawerHeader
          logoColor="blue"
          onClose={() => setIsOpen(false)}
          onClick={navigateToHome}
          sx={{
            '& img': {
              height: '40px',
              width: '40px',
            },
            '& p': {
              fontSize: '20px',
              fontWeight: '500',
            },
          }}
        />
        <MobileUserMenu />
      </Stack>
      <Stack sx={{ height: '100%', paddingBlock: 2, gap: 4 }}>
        <DrawerNodeList nodes={primaryNodes} isPermanentlyExpanded />
        <Box sx={{ flexGrow: 1 }} />
        <DrawerNodeList nodes={secondaryNodes} isPermanentlyExpanded />
        <DrawerNodeList nodes={[logoutNode]} isPermanentlyExpanded />
      </Stack>
    </SwipeableDrawer>
  );
};
