import { FormattedMessage } from 'react-intl';

import { faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { Entity } from '@trustyou/shared';
import { ActionCell } from '@trustyou/ui';

interface EntityActionCellProps {
  entity: Entity;
  handleDelete: (entity: Entity) => void;
}

const EntityActionCell = ({ entity, handleDelete }: EntityActionCellProps) => (
  <ActionCell
    testId="entity-action-menu"
    actions={[
      {
        testId: 'entity-action-delete',
        icon: faTrash,
        handler: () => handleDelete(entity),
        message: <FormattedMessage id="organization.action.delete" defaultMessage="Delete" />,
      },
    ]}
  />
);

export default EntityActionCell;
