export const RESPONSE_AI_LANGUAGES = 'response_ai_languages';
export const RESPONSE_AI_PROFILE = 'response_ai_profile';
export const RESPONSE_AI_RESULT = 'response_ai_result';
export const REVIEWS_INFO_DATA = 'reviews_info_data';
export const SOURCES = 'sources';
export const SURVEY_NAMES = 'survey_names';
export const REVIEW_DETAIL = 'review_detail';
export const PAGINATED_REVIEWS = 'paginated_reviews';
export const PAGINATED_DETAILED_REVIEWS = 'paginated_detailed_reviews';
export const REVIEWS_FILTERS_DATA = 'reviews_filters_data';
export const RESPONSE_URL = 'response_url';
export const METACATEGORY = 'metacategory';
export const TOP_LEVEL_CATEGORY_MAPPING = 'top_level_category_mapping';
export const SUBCATEGORY_MAPPING = 'subcategory_mapping';
export const TOP_LEVEL_CATEGORIES = 'top_level_categories';
export const SUBCATEGORIES = 'subcategories';
export const SEMA_LANGUAGES = 'sema_languages';
export const EMAIL_NOTIFICATION = 'email_notification';
export const EMAIL_NOTIFICATION_SURVEY_MODERATION = 'email_notification_survey_moderation';
export const RESPONSE_TEMPLATES = 'response_templates';
export const SIGNATURE = 'signature';
export const VIEWS = 'views';
export const USER_VIEWS = 'user_views';
export const MODERATION_REASONS = 'moderation_reasons';
