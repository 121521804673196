import type { PropsWithChildren } from 'react';

import { Stack } from '@trustyou/ui';

export function NestedFilters({ isVisible, children }: PropsWithChildren & { isVisible: boolean }) {
  return (
    <Stack
      spacing={3}
      sx={{
        marginBlockStart: 2,
        marginInlineStart: 6,
        ...(isVisible && {
          marginBlockEnd: 5,
        }),
        ...(!isVisible && {
          height: 0,
          visibility: 'hidden',
          overflow: 'hidden',
        }),
      }}
    >
      {children}
    </Stack>
  );
}
