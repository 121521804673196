import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SEARCH_DEBOUNCE_TIME_MS, useIsValidCustomAttributeName } from '@trustyou/shared';
import {
  CircularProgress,
  FormControl,
  FormFieldLabel,
  InputAdornment,
  TextField,
} from '@trustyou/ui';
import { debounce } from 'lodash';

import { common, manageDrawer } from '../../constants/messages/customAttributes';
import { styles } from './styles';

const NAME_MAX_LENGTH = 100;

export const NameController = () => {
  const { control } = useFormContext();
  const intl = useIntl();

  const isValidCustomAttributeName = useIsValidCustomAttributeName();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheck = useCallback(
    debounce(async (value: string) => {
      if (!value || control._defaultValues.name === value) return;
      const response = await isValidCustomAttributeName.mutateAsync(value);
      if (!response || response.valid) return;
      let message = '';
      if (response.error === 'matches_global_attribute_name') {
        message = intl.formatMessage(manageDrawer.nameReserved);
      } else if (response.error === 'matches_segment_name') {
        message = intl.formatMessage(manageDrawer.nameUsedInASegment);
      }
      control.setError('name', {
        type: 'custom',
        message: message || intl.formatMessage(manageDrawer.nameExist),
      });
    }, SEARCH_DEBOUNCE_TIME_MS),
    []
  );

  return (
    <FormControl>
      <FormFieldLabel required>{intl.formatMessage(common.name)}</FormFieldLabel>
      <Controller
        name="name"
        control={control}
        rules={{
          required: intl.formatMessage(manageDrawer.nameRequired),
          onChange: async (event) => {
            if (event) debounceCheck(event.target.value);
          },
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            sx={styles.nameField}
            placeholder={intl.formatMessage(manageDrawer.namePlaceholder)}
            id="custom-attribute-name"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isValidCustomAttributeName.isPending && <CircularProgress size={20} />}
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: NAME_MAX_LENGTH,
            }}
          />
        )}
      />
    </FormControl>
  );
};
