import { FormattedMessage, useIntl } from 'react-intl';

import { useRouteUtils } from '@trustyou/shared';
import { Box, Button, Chip, Stack, Typography } from '@trustyou/ui';

import personCheckingFile from '../../assets/illustrations/person-checking-file.svg';
import personDownloadingFile from '../../assets/illustrations/person-downloading-file.svg';
import personUploadingFile from '../../assets/illustrations/person-uploading-file.svg';
import styles from './styles';

type Image = {
  src: string;
  alt: string;
};

type Step = {
  id: number;
  label: string;
  image: Image;
};

const StepBoxes = () => {
  const intl = useIntl();

  const steps: Step[] = [
    {
      id: 1,
      label: intl.formatMessage({
        id: 'welcome.step-one',
        defaultMessage: 'Download and fill in spreadsheet',
      }),
      image: {
        src: personDownloadingFile,
        alt: 'Person downloading file',
      },
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: 'welcome.step-two',
        defaultMessage: 'Upload spreadsheet',
      }),
      image: {
        src: personUploadingFile,
        alt: 'Person uploading file',
      },
    },
    {
      id: 3,
      label: intl.formatMessage({
        id: 'welcome.step-three',
        defaultMessage: 'Preview data and complete',
      }),
      image: {
        src: personCheckingFile,
        alt: 'Person checking file',
      },
    },
  ];

  return (
    <Stack spacing={8} direction={{ xs: 'column', sm: 'row' }} sx={styles.stepsContainer}>
      {steps.map((step) => (
        <Box key={step.id}>
          <Stack alignItems="center">
            <img src={step.image.src} alt={step.image.alt} height={160} />
            <Stack direction="row" alignItems="center" sx={styles.stepsStack}>
              <Chip label={step.id} size="small" color="secondary" />
              <Typography variant="body2" sx={styles.stepsText}>
                {step.label}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

export const Welcome = () => {
  const { navigateWithBase } = useRouteUtils();

  const onClickGetStarterHandler = () => {
    navigateWithBase('load-data');
  };

  return (
    <Stack sx={styles.container}>
      <Typography variant="h6" sx={styles.headerText}>
        <FormattedMessage
          id="organization.action.importEntities"
          defaultMessage="Import entities"
        />
      </Typography>
      <Typography variant="body2" sx={styles.bodyPrimaryText}>
        <FormattedMessage
          id="welcome.welcomeMessage"
          defaultMessage="Let’s set up your organization, so that you can access your review data. Here's how it works:"
        />
      </Typography>
      <StepBoxes />
      <Button
        data-testid="button-get-started"
        variant="contained"
        size="large"
        sx={styles.getStartedButton}
        onClick={onClickGetStarterHandler}
      >
        <FormattedMessage id="welcome.getStarted" defaultMessage="Get started" />
      </Button>
    </Stack>
  );
};
