import { FormattedMessage } from 'react-intl';

import { Alert, AlertTitle } from '@trustyou/ui';

const MandatoryValuesError = () => (
  <Alert severity="error">
    <AlertTitle>
      <FormattedMessage
        id="entitiesImporter.uploadSpreadsheet.missingMandatoryValues"
        defaultMessage="Missing mandatory values"
      />
    </AlertTitle>
    <FormattedMessage
      id="entitiesImporter.uploadSpreadsheet.missingMandatoryValuesErrorDescription"
      defaultMessage="There are null values in your file please check and try again"
    />
  </Alert>
);

export default MandatoryValuesError;
